import { head } from 'lodash'
import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import IIDetector from '@utils/ii-detector'
import TooltipTemplate, { EXIT_TIMEOUT } from './TooltipTemplate.jsx'


const OVERLAY_PORTAL = 'overlay-portal'
const OVERLAY_TOOLTIP_PORTAL = 'overlay-tooltip-portal'
const PAGE_TOOLTIP_PORTAL = 'page-tooltip-portal'

export const getPortal = (wrapperNode) => {
	let portal

	// if there is overlay and tooltip's parent lives inside overlay then render tooltip inside overlay
	const overlayPortal = document.getElementById(OVERLAY_PORTAL)
	if (overlayPortal && overlayPortal.contains(wrapperNode)) {
		portal = overlayPortal.querySelector(`#${OVERLAY_TOOLTIP_PORTAL}`)
		if (!portal) {
			portal = document.createElement('div')
			portal.id = OVERLAY_TOOLTIP_PORTAL
			overlayPortal.appendChild(portal)
		}
	}
	// otherwise render tooltip inside page body
	else {
		portal = document.getElementById(PAGE_TOOLTIP_PORTAL)

		if (!portal) {
			portal = document.createElement('div')
			portal.id = PAGE_TOOLTIP_PORTAL
			document.body.appendChild(portal)
		}
	}

	return portal
}

class Tooltip extends PureComponent {

	static defaultProps = {
		event: 'hover',
		disabled: false,
		mouseEnterTimeout: 0,
		mouseLeaveTimeout: 100,
	}

	// due to animation requirements our tooltip is rendered with 2 steps:
	// - first render empty container
	// - than render tooltip content
	state = {
		isContentMounted: false,
		isContentVisible: false,
	}

	debounceMouseLeaveTimer = null
	unmountContentTimer = null

	componentDidMount () {
		// in ios safari, we have to set a manual tap away listener, instead of using onBlur
		document.addEventListener('touchstart', this.handleTouchStart)
	}

	componentDidUpdate (_, prevState) {

		if (!prevState.isContentMounted && this.state.isContentMounted) {
			clearTimeout(this.unmountContentTimer)

			this.setState({ isContentVisible: true })
		}
		else if (prevState.isContentVisible && !this.state.isContentVisible) {
			// here we need to set timeout so exit animation could finish
			this.unmountContentTimer = setTimeout(() => {
				this.setState({ isContentMounted: false })
			}, EXIT_TIMEOUT)
		}
	}

	componentWillUnmount () {
		if (this.tooltip) {
			ReactDOM.unmountComponentAtNode(this.tooltip)
			this.portal && this.portal.removeChild(this.tooltip)
			this.tooltip = null
		}

		document.removeEventListener('touchstart', this.handleTouchStart)
	}

	handleTouchStart = ({ target, relatedTarget }) => {
		if (this.elementRef !== target && this.tooltip !== target) {
			this.hideTooltip()
		}
	}

	handleWrapperMouseEnter = () => {
		const { mouseEnterTimeout } = this.props
		clearTimeout(this.debounceMouseLeaveTimer)
		this.debounceMouseEnterTimer = setTimeout(this.showTooltip, mouseEnterTimeout)
	}

	handleContentMouseEnter = () => {
		const { onMouseEnter, mouseEnterTimeout } = this.props
		clearTimeout(this.debounceMouseLeaveTimer)
		clearTimeout(this.debounceMouseEnterTimer)
		this.debounceMouseEnterTimer = setTimeout(this.showTooltip, mouseEnterTimeout)

		onMouseEnter && onMouseEnter()
	}

	handleWrapperMouseLeave = () => {
		clearTimeout(this.debounceMouseLeaveTimer)
		clearTimeout(this.debounceMouseEnterTimer)
		this.debounceMouseLeaveTimer = setTimeout(() => {
			this.hideTooltip()
		}, this.props.mouseLeaveTimeout)
	}

	handleContentMouseLeave = (e) => {
		e.stopPropagation()

		const { onMouseLeave, mouseLeaveTimeout } = this.props

		clearTimeout(this.debounceMouseLeaveTimer)
		this.debounceMouseLeaveTimer = setTimeout(() => {
			this.hideTooltip()
			onMouseLeave && onMouseLeave()
		}, mouseLeaveTimeout)
	}

	handleWrapperClick = (e) => {
		e.stopPropagation()

		if (this.state.isContentRendered) {
			this.hideTooltip()
		}
		else {
			this.showTooltip()
		}
	}

	hideTooltip = () => {
		if (this.state.isContentVisible) {
			this.setState({ isContentVisible: false })
		}
	}

	showTooltip = () => {
		if (!this.state.isContentMounted) {
			this.setState({ isContentMounted: true })
		}
		else if (!this.state.isContentVisible) {
			clearTimeout(this.unmountContentTimer)
			this.setState({ isContentVisible: true })
		}
	}

	updateElementRef = (ref) => {
		this.elementRef = ref
	}

	_getTooltip = () => {
		const { state, props } = this
		const { placement, content, event, styles } = props

		const propsTemplate = {
			content,
			placement,
			referenceElement: this.elementRef,
			isVisible: state.isContentVisible,
			styles,
		}

		if (event === 'hover') {
			propsTemplate.onMouseEnter = this.handleContentMouseEnter
			propsTemplate.onMouseLeave = this.handleContentMouseLeave
		}

		if (!this.tooltip) {
			this.tooltip = document.createElement('div')
			this.portal = getPortal(this.elementRef)
			this.portal.appendChild(this.tooltip)
		}

		return ReactDOM.createPortal(
			<TooltipTemplate {...propsTemplate}/>,
			this.tooltip
		)
	}

	render () {

		const { children, event } = this.props

		if (this.props.disabled) {
			return (
				<div>
					{children}
				</div>
			)
		}

		const wrapperProps = {
			style: { outline: 'none' },
			tabIndex: 1,
		}

		if (IIDetector.canTouch) {
			wrapperProps.onClick = this.handleWrapperClick
		}
		else if (event === 'hover') {
			wrapperProps.onMouseEnter = this.handleWrapperMouseEnter
			wrapperProps.onMouseLeave = this.handleWrapperMouseLeave
			wrapperProps.onClick = this.handleWrapperClick
			wrapperProps.onBlur = this.hideTooltip
		}
		else if (event === 'focus') {
			wrapperProps.onClick = this.handleWrapperClick
			wrapperProps.onBlur = this.hideTooltip
		}

		// we need to set display style for wrapper same as it is on children
		if (this.elementRef) {
			wrapperProps.style.display = getComputedStyle(this.elementRef, null).getPropertyValue('display')
		}

		const body = (!children || typeof children.type !== 'string')
			? (
				// if children is a react component we need to wrap it in a div
				<div ref={this.updateElementRef} style={wrapperProps.style}>
					{children}
				</div>
			)
			: (
				head(React.Children.map(children, (child) =>
					React.cloneElement(child, {
						ref: this.updateElementRef,
					})
				))
			)

		const tooltip = this.state.isContentMounted ? this._getTooltip() : null

		return (
			<div {...wrapperProps}>
				{body}
				{tooltip}
			</div>
		)
	}
}

export { TooltipTemplate }
export default Tooltip
