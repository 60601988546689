import { memoize } from 'lodash'
import { geoRequest as request } from '@utils/request'

import confy from '@utils/confy'
import info from '@utils/info'


let cache = {}
const requestTimeouts = {
	response: 5000, // Wait 5 s for the server to start sending,
	deadline: 15000, // but allow 15 s for the file to finish loading.
}

const saveToCache = (airportsMap) => {

	cache = {
		...cache,
		...airportsMap,
	}
}

const normalizeData = ({ airports, cities }) => {
	const finder = (metro) => cities.find(({ iata }) => (iata === metro))

	return airports.reduce((prev, airport) => ({
		...prev,
		[airport.iata]: {
			...airport,
			city: finder(airport.metro),
		},
	}), {})

}

// cache values are promises, so if race occurs,
// the looser gets previously initialized Promise
const cacheResolver = (iatas) => (iatas.sort().join(','))
export const fetchAirports = memoize((iatas = []) => new Promise((resolve, reject) => {

	const joinedIatas = iatas.join(',')
	const url = confy.get('API.geo.airports')

 	request
		.get(url)
		.timeout(requestTimeouts)
		.query({ iatas: joinedIatas })
		.end((err, res) => {
			if (err || !res || !res.body) {
				info('network error of airpots endpoint', joinedIatas, err)
				resolve({})
			}

			if (res && res.body) {
				resolve(res.body)
			}
		})
}), cacheResolver)


export const getAirports = async (iatas = []) => {

	const missingIatas = iatas.filter((iata) => !cache[iata])
	try {
		if (missingIatas.length) {
			const data = await fetchAirports(missingIatas)
			const map = normalizeData(data)
			saveToCache(map)
		}

		return cache
	}
	catch (e) {
		info(e)
		return null
	}
}
