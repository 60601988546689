import { get } from 'lodash'
import { call, put, select } from 'redux-saga/effects'

import feofan from '@libs/feofan'
import { channelSelector } from '@store/state/appState/selectors'
import info from '@utils/info'
import { OPERATION_STATUSES } from '@libs/foma/types'

import { makeCancellable, withError } from '../utils'


export const cancellableReconfigureOffer = makeCancellable(feofan.reconfigureOffer)

export default function* handleReconfigureOffer (evtChannel, meta) {

	const channel = yield select(channelSelector)

	const { offerId, enabledCounts, fareVariants } = meta

	const optsReconfigure = {
		id: offerId,
		channel,
		enabledCounts,
		fareVariants,
	}

	try {
		const resData = yield call(cancellableReconfigureOffer, optsReconfigure)
		yield put(evtChannel, resData)

		return resData.status
	}
	catch (err) {
		info('cant reconfigure offer', err)
		const status = get(err, 'data.status', OPERATION_STATUSES.ERROR)
		yield put(evtChannel, withError({ ...err.data, status }))

		return status
	}
}
