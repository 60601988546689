export default function redirectBySubmitForm (document, meta, paramsKey = 'form') {
	const form = document.createElement('form')
	form.method = 'post'
	form.action = meta.url
	// form.target = '_blank'

	const params = meta[paramsKey]

	Object.keys(params).forEach((paramName) => {
		const node = document.createElement('input')
		node.name = paramName
		node.value = params[paramName]

		form.appendChild(node)
	})

	document.body.appendChild(form)
	form.style.display = 'none'
	form.submit()
}
