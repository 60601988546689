import RawBtn from './rawBtn.js'

import styles from '../styles/primaryOrange.js'


export default ({ children, classNames, ...props }) => (
	<RawBtn {...props} classNames={[ styles.primaryOrange, classNames ]}>
		{children}
	</RawBtn>
)
