import store from 'store'

import info from '@utils/info'

import * as sociomantic from './sociomantic-wrapper'
import * as endpoints from './endpoints'


export const getLsKey = (offerId) => (`SOCIOMANTIC.${offerId}`)
export const getLsPriceKey = (productId) => (`PREV_PRICE.${productId}`)

const storeProductPrice = (productId, price) => {
	const key = getLsPriceKey(productId)
	store.set(key, price)
}

export const assignCustomer = ({ id, segment }) => {
	sociomantic.assignCustomer(id, segment)
}

export const sendSearchRequest = async (searchQuery, channel) => {
	const searchParams = { ...searchQuery, channel }
	try {
		const product = await endpoints.create({ searchParams })
		sociomantic.setProduct(product.product_id, product.valid, product.date)
	}
	catch (e) {
		info(e)
	}
}

export const sendProduct = async (offer, productIdFromParams) => {
	// this should not happen but report if so
	if (!offer) {
		return info('Atempt to call sendProduct with no offer set. This is a NOOP!')
	}

	const { id: offerId, price } = offer

	// we have to create productId on our backend if has no productId
	// store offerId -> productId pairs as we'll
	// need to delete the product on our backend later
	// frontend only exposes to sociomantic the productId
	// the rest is being handled by our backend
	try {
		let product = {}
		if (!productIdFromParams) {
			product = await endpoints.create({ offerId })
		}
		const productId = productIdFromParams || product['product_id']

		store.set(getLsKey(offerId), productId)
		sociomantic.setProduct(productId, product.valid, product.date)
		storeProductPrice(productId, price)
	}
	catch (e) {
		info(e)
	}
}


export const soldProduct = (params) => {

	const { offerId } = params
	const productId = store.get(getLsKey(offerId))

	if (!productId) {
		return info('Atempt to call clearProduct with no stored offer. This is a NOOP!')
	}
	// we need to let sociomantic know
	// when the product is sold and add some metadata
	sociomantic.setBasket({
		...params,
		productId,
	})
	// when the product is sold we make backend
	// delete the record to clean stuff up
	endpoints
		.remove(productId)
		.catch(info)
}

export const trackClick = ({ price, productId }) => {
	if (!productId) {
		return info('Atempt to call clearProduct with no stored offer. This is a NOOP!')
	}
	storeProductPrice(productId, price)

	endpoints
		.trackClick(productId, price)
		.catch(info)
}
