import { createSelector } from 'reselect'
import { toCheckout } from '@utils/router'
import { toCheckoutQueryStringSelectorFromProduct } from '@store/state/selectors'
import resWithPseudoOfferSelector from '@store/sagas/offerConfigurator/selectors/resWithPseudoOffer'
import newBuildOfferDataSelector from '@store/sagas/offerConfigurator/selectors/buildOfferData'
import {
	byCurrentSearchQuery,
	currentOfferIdSelector,
	currentSearchQuerySelector,
} from '@store/state/appState/selectors'


export const checkIsPartnerLink = (checkoutQueryStr) => checkoutQueryStr.includes('book_by_redirect=true')
const checkoutQuerySelector = byCurrentSearchQuery(toCheckoutQueryStringSelectorFromProduct)
const pseudoOfferIdSelector = (state, { product }) => {
	const { enabledCounts, products = [] } = newBuildOfferDataSelector(state, {
		replacedProducts: [ [ product.type, product.fareIds ] ],
		removedProducts: [],
	})
	const query = currentSearchQuerySelector(state)
	const resWithPseudoOffer = resWithPseudoOfferSelector(state, query, enabledCounts, products)
	return resWithPseudoOffer.data.offers[0].id
}

const getCheckoutUrl = (currentOfferId, checkoutQuery, pseudoOfferId) => {
	if (!currentOfferId && !checkIsPartnerLink(checkoutQuery)) return null
	const offerId = currentOfferId || pseudoOfferId
	return toCheckout(offerId, checkoutQuery)
}

export const checkoutUrlSelector = createSelector([
	(state, { isAdded }) => isAdded && currentOfferIdSelector(state),
	(state, { product }) => checkoutQuerySelector(state, product.fareIds.join(':')),
	pseudoOfferIdSelector,
], getCheckoutUrl
)

export const isPartnerLinkSelector = (state, { product }) => {
	const query = checkoutQuerySelector(state, product.fareIds.join(':'))
	return checkIsPartnerLink(query)
}
