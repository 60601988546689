import { push } from 'connected-react-router'
import { takeEvery, select, call, put, all } from 'redux-saga/effects'

import { toCabinet, toIndex, _setWindowLocation } from '@utils/router'
import { USER_ROLE } from '@libs/foma/types'
import accountDataFetcher, { LOGIN_STATUS } from '@libs/account-data-fetcher'

import { LOGIN, LOGOUT } from '@store/state/types'
import { loginSelectors } from '@store/state/uiState/forms/selectors'
import * as actions from '@store/state/appState/actions'
import { clearStore } from '@store/state/domainData/actions'
import { ACCOUNT_DATA_SEARCH_QUERY } from '@store/sagas/router/handlers/cabinet'


export function* loginWorker () {

	const [ login, password ] = yield all([
		select(loginSelectors.loginSelector),
		select(loginSelectors.passwordSelector),
	])

	try {
		const { body: userData } = yield call(accountDataFetcher.authorize, login, password)
		yield put(actions.setUserData(userData))
		if (userData.role === USER_ROLE.AGENT) {
			yield call(_setWindowLocation, toCabinet())
		}
		else {
			yield all([
				put(actions.setLoginStatus(LOGIN_STATUS.SUCCESS)),
				put(push(toCabinet())),
			])
		}
	}
	catch (e) {
		yield put(actions.setLoginStatus(LOGIN_STATUS.ERROR))
	}

}

export function* logoutWorker () {
	yield all([
		call(accountDataFetcher.logout),
		put(clearStore(ACCOUNT_DATA_SEARCH_QUERY)),
		put(push(toIndex())),
	])
}

export default function* watchLogin () {
	yield all([
		takeEvery(LOGIN, loginWorker),
		takeEvery(LOGOUT, logoutWorker),
	])
}
