import { difference } from 'lodash'
import { put, select, all } from 'redux-saga/effects'

import { flightConsistFromOfferSelector } from '@store/state/domainData/selectors'
import {
	byCurrentOfferId,
	userCCListSelector,
} from '@store/state/appState/selectors'
import { setCCFormMode } from '@store/state/appState/actions'
import { initFormsList, removeForm, rehydrateForm } from '@store/state/uiState/forms/actions'
import { PASSENGERS_FORM, CUSTOMER_FORM } from '@store/state/uiState/forms/types'
import { passengerSelectors } from '@store/state/uiState/forms/selectors'


export const currentOfferConsistSelector = byCurrentOfferId(flightConsistFromOfferSelector)

const PREFIX_BY_OFFSET = [ 'a', 'c', 'i' ]
export const getKeysByConsist = (consist) => (
	consist.reduce((acc, count, offset) => {
		const prefix = PREFIX_BY_OFFSET[offset]
		const newKeys = Array(count).fill(null).map((_, i) => `${prefix}${i}`)
		return [ ...acc, ...newKeys ]
	}, [])
)

export default function* initForms () {
	const [ currentKeys, consist ] = yield all([
		select(passengerSelectors.paxKeysListSelector),
		select(currentOfferConsistSelector),
	])

	if (!consist) return

	const nextKeys = getKeysByConsist(consist)
	const keysToRemove = difference(currentKeys, nextKeys)

	yield all([
		put(initFormsList(PASSENGERS_FORM.NAME, nextKeys)),
		...keysToRemove.map((key) => put(removeForm(PASSENGERS_FORM.NAME, key))),
	])

	yield put(rehydrateForm({ formName: CUSTOMER_FORM.NAME }))
	yield all(nextKeys.map((formIndex) => put(
		rehydrateForm({ formName: PASSENGERS_FORM.NAME, formIndex })
	)))

	const userCCList = yield select(userCCListSelector)
	const hasSavedCards = userCCList !== null
	yield put(setCCFormMode(!hasSavedCards))
}
