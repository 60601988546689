import { take, put, select } from 'redux-saga/effects'
import { matchPath } from 'react-router'

import { PATHS } from '@utils/router'
import {
	SET_DOMAIN_DATA,
	LOCATION_CHANGE,
	ADD_LOADING_EVENT,
	REMOVE_LOADING_EVENT,
} from '@store/state/types'
import { locationSelector, locationQuerySelector } from '@store/state/appState/selectors'
import {
	isBlocked as isBlockedSelector,
} from '@store/state/selectors'
import { faresWithVarsCountSelector } from '@organisms/CaOrderTicketsInfo/selectors'


const isCheckoutSelector = (state) => {
	const location = locationSelector(state)
	return Boolean(matchPath(location.pathname, PATHS.CHECKOUT))
}
const isRegularCheckoutSelector = (state) => {
	const isCheckout = isCheckoutSelector(state)
	const {
		order_id: orderId,
		book_by_redirect: isBookByRedirect,
	} = locationQuerySelector(state)
	return isCheckout && !(orderId || isBookByRedirect)
}

const EVENT_NAME = 'CA_FLIGHT_FARE_VARIANTS_LOADED'
export default function* (analyticEventChan) {
	let hasVariants = false
	let isRegularCheckout = yield select(isRegularCheckoutSelector)
	while (true) {
		if (!isRegularCheckout) {
			const { payload } = yield take(LOCATION_CHANGE)
			if (payload.action !== 'REPLACE') {
				isRegularCheckout = yield select(isRegularCheckoutSelector)
			}
		}
		else {
			yield take([ SET_DOMAIN_DATA, ADD_LOADING_EVENT, REMOVE_LOADING_EVENT ])
			const faresWithVarsCount = yield select(faresWithVarsCountSelector, {})
			const isBlocked = yield select(isBlockedSelector)
			hasVariants = faresWithVarsCount > 0 && !isBlocked
			if (hasVariants) {
				isRegularCheckout = false
				yield put(analyticEventChan, { name: EVENT_NAME })
			}
		}
	}
}

