import { connect } from 'react-redux'
import { createEnhancer } from '@utils/decoract'

import { onElementClick } from '@store/state/appState/actions'
import RouteInfo from '@molecules/RouteInfo'
import SearchBar from 'app-components/SearchBar'

import styles from './styles'


const cq = {
	sm: {
		maxWidth: 721,
	},
}
const enhancer = createEnhancer({ cq })
const noOp = () => {}

class Search extends React.Component {
	state = {
		isSearchBarShown: false,
	}

	_showSearchBar = () => this.setState({ isSearchBarShown: true }, () => {
		this.props.onElementClick('editRoute')
	})
	_hideSearchBar = () => this.setState({ isSearchBarShown: false })

	render () {
		const {
			showSearchBar,
			isEditable,
			cq = {},
		} = this.props

		const propsRouteInfo = {
			sm: cq.sm,
			onEditRouteClick: isEditable ? this._showSearchBar : noOp,
		}
		const routeInfo = (
			<div className={styles.routeInfo}>
				<RouteInfo {...propsRouteInfo}/>
			</div>
		)

		const searchBar = (
			<div className={styles.searchBar}>
				<SearchBar onSearchClick={this._hideSearchBar}/>
			</div>
		)

		const isSearchBarShown = (this.state.isSearchBarShown || showSearchBar) && isEditable
		const content = isSearchBarShown ? searchBar : routeInfo

		return (
			<div className={styles.container}>
				{content}
			</div>
		)
	}
}

export default connect(null, { onElementClick })(enhancer(Search))
