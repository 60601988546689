import { checkBookSelector } from '@store/state/selectors'
import {
	orderFromOfferIdSelector,
	offerByIdSelector,
} from '@store/state/domainData/selectors'
import { byCurrentOfferId, fakeSelectedTransferSelector } from '@store/state/appState/selectors'


export default function makeAnaliticsStore (store) {

	const wrapSelector = (selector) => () => selector(store.getState())

	window.analiticsStore = {
		getBookingStatus: wrapSelector(checkBookSelector),
		getOrder: wrapSelector(byCurrentOfferId(orderFromOfferIdSelector)),
		getOffer: wrapSelector(byCurrentOfferId(offerByIdSelector)),
		getFakeSelectedTransfer: wrapSelector(fakeSelectedTransferSelector),
	}
}
