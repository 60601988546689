import qs from 'qs'
import { INBOUND_DATE_PLACEHOLDER } from '@utils/common/searchQuery'
import { setParamsInPath } from './helpers.js'
import PATHS from './paths.js'


export const toSerp = ({ src, dst, outboundDate, inboundDate, adults, children, infants, tab }) => (
	setParamsInPath(PATHS.SERP, {
		src,
		dst,
		outboundDate,
		inboundDate: inboundDate || INBOUND_DATE_PLACEHOLDER,
		adults,
		children,
		infants,
		tab: tab || 'tickets',
	})
)

export const toCheckout = (offerId, search = '') => (
	`${setParamsInPath(PATHS.CHECKOUT, { offerId })}${search}`
)

export const toIndex = () => (
	PATHS.INDEX
)

export const toContacts = () => ({
	pathname: PATHS.CONTACTS,
	state: { isOverlaid: true },
})

export const toAdvantages = () => ({
	pathname: PATHS.ADVANTAGES,
	state: { isOverlaid: true },
})

export const toLogin = () => ({
	pathname: PATHS.LOGIN,
	state: { isOverlaid: true },
})

export const toPassReset = () => ({
	pathname: PATHS.PASSRESET,
	state: { isOverlaid: true },
})

export const toPassRecovery = () => ({
	pathname: PATHS.PASSRECOVERY,
	state: { isOverlaid: true },
})

export const toFaq = (questionId = '', query = {}) => (
	`${setParamsInPath(PATHS.FAQ, { questionId })}${qs.stringify(query)}`
)

export const toAbout = () => PATHS.ABOUT
export const toHistory = () => PATHS.HISTORY

export const toCabinet = (orderSupportId, queryParams = {}) => (
	`${setParamsInPath(PATHS.CABINET, {
		orderSupportId: orderSupportId || '',
	})}${qs.stringify(queryParams)}`
)

export const toTelegramCcBot = (channel) => PATHS.TELEGRAM_CC_BOT.replace(':channel', channel)
export const toExternalLogin = () => PATHS.EXTERNAL_LOGIN
export const toExternalCabinet = (orderSupportId, queryParams = {}) => (
	`${setParamsInPath(PATHS.EXTERNAL_CABINET, {
		orderSupportId: orderSupportId || '',
	})}${qs.stringify(queryParams)}`
)
