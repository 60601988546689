import { delay, call, put, take } from 'redux-saga/effects'
import getUserId from '@libs/user-id-tracker'
import { LOGROCKET_SESSION_URL_RESOLVED } from '@store/state/types'
import { userSessionDataResolve } from '@store/state/appState/actions'


const ATTEMPTS_COUNT_THESHOLD = 8
const TIMEOUT_THRESHOLD = 5 * 60 * 1000 // 5 mins
const BASE = 2

// timeout time grows exponentially
// with the base of BASE = 2 until 256s
// 2s, 4s, 8s, ..., 256s, 300s
export function* getTimeout () {
	for (let attemptsCount = 0; attemptsCount <= ATTEMPTS_COUNT_THESHOLD; attemptsCount++) {
		yield BASE ** attemptsCount * 1000
	}
	while (true) yield TIMEOUT_THRESHOLD
}

export default function* trackUserIdWorker () {
	const { payload } = yield take(LOGROCKET_SESSION_URL_RESOLVED)

	let sessionData = {
		lrSessionUrl: payload.sessionUrl,
	}
	const timeoutGen = getTimeout()

	while (!sessionData.userId) {
		yield delay(timeoutGen.next().value)
		const userId = yield call(getUserId)
		sessionData.userId = userId
	}

	// I have no idea what I'm doing.
	// Do not ever do like this!
	yield put(userSessionDataResolve(sessionData))
}
