import { wwwRequest as request } from '@utils/request'
import confy from '@utils/confy'


export const FEEDBACK_SEND_STATUS = Object.freeze({
	INITIAL: 'FEEDBACK_SEND_STATUS/INITIAL',
	LOADING: 'FEEDBACK_SEND_STATUS/LOADING',
	SUCCESS: 'FEEDBACK_SEND_STATUS/SUCCESS',
	ERROR: 'FEEDBACK_SEND_STATUS/ERROR',
})


const sendFeedback = ({ name, email, orderId, comment, subject }) => {
	const url = confy.get('API.cl.feedback')

	const data = {
		'name': name,
		'email': email,
		'order_id': orderId,
		'comment': comment,
		'subject': subject,
	}

	return request
		.post(url)
		.send(data)
}

const sendFeedback_v2 = ({ name, email, orderId, comment, subject }) => { // eslint-disable-line camelcase
	const url = `${confy.get('API.cl.feedback_v2')}/${orderId}/inquiries`

	const data = {
		'name': name,
		'email': email,
		'comment': comment,
		'subject': subject,
	}

	return request
		.post(url)
		.withCredentials()
		.send(data)
}

export default {
	sendFeedback,
	sendFeedback_v2, // eslint-disable-line camelcase
}
