import { values } from 'lodash'
import {
	byCurrentSearchQuery,
} from '@store/state/appState/selectors'
import {
	getHotels,
	faresByHotelIdSelector,
	activitiesByFareIdSelector,
} from '@store/state/domainData/selectors'


export const hasAccommodationForQueryResult = (fareIds, activities, checkin, checkout) => (
	fareIds.filter((fareId) => {
		// activities <-> hotel has one-by-one relation
		// so we can use first element of acitivites array
		const [ activity ] = values(activities[fareId])
		if (!activity) return false
		const [ fareCheckin, fareCheckout ] = [ activity.checkin, activity.checkout ].map((isoDate) => {
			const [ date, _ ] = isoDate.split('T')
			return date
		})
		return checkin === fareCheckin && checkout === fareCheckout
	}).length > 0
)

export const hasAccommodationForQuerySelector = (state, checkin, checkout, hotelId) => {
	const faresByHotelId = byCurrentSearchQuery(faresByHotelIdSelector)(state)
	const fareIds = Object.keys(faresByHotelId[hotelId])
	const activitiesByFareId = byCurrentSearchQuery(activitiesByFareIdSelector)(state)
	return hasAccommodationForQueryResult(fareIds, activitiesByFareId, checkin, checkout)
}

export const isLoadedReviewsSelector = (state, hotelId) => {
	const hotels = byCurrentSearchQuery(getHotels)(state)
	const { reviews = [] } = hotels[hotelId] || {}
	return reviews.length > 0
}
