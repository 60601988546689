import { flow, groupBy, pick, mapValues } from 'lodash'
import { CONSIST_PTC } from '@libs/foma/types'
import { getQueryFromString, getStringFromQuery } from '@utils/common/searchQuery'
import { getFares, offerByIdSelector, flightConsistFromOfferSelector } from '@store/state/domainData/selectors'
import offerIdByFaresSelector from './offerIdByFares.js'


export const getNewQuery = (queryStr, [ adults, children, infants ]) => {
	const query = getQueryFromString(queryStr)
	return getStringFromQuery({ ...query, adults, children, infants })
}

const ptcs = [ CONSIST_PTC.ADULTS, CONSIST_PTC.CHILDREN, CONSIST_PTC.INFANTS ]
export const getPtcsFromConsist = (consist) => ptcs.filter((ptc, i) => consist[i])
export const getFaresByService = flow(
	pick,
	(fares) => groupBy(fares, 'service')
)
export const makePtcToFareIdMapper = (faresFromService) => flow(
	(ptc) => faresFromService.find(({ details }) => (ptc === details.ptc)) || {},
	({ id }) => id
)
export const makeFareIdsToFareCountsMapper = (fareCount) => (acc, fareId) => ({
	...acc,
	[fareId]: fareCount,
})
export const getSpecifiedFareCounts = (fareCounts, consist, fares, newFares = {}) => {
	const fareIds = [ ...Object.keys(fareCounts), ...Object.keys(newFares) ]
	const mergedFares = { ...fares, ...newFares }
	const faresByService = getFaresByService(mergedFares, fareIds)
	const ptcs = getPtcsFromConsist(consist)
	return Object.keys(fareCounts).reduce((acc, fareId) => {
		const { service, details = {} } = fares[fareId]
		const fareCount = fareCounts[fareId]
		if (!details.ptc) return { ...acc, [fareId]: fareCount }

		const faresFromService = faresByService[service]
		const toFareIdFromPtc = makePtcToFareIdMapper(faresFromService)
		const fareIdsFromPtc = ptcs.map(toFareIdFromPtc).filter(Boolean)
		const toFareCountFromFareId = makeFareIdsToFareCountsMapper(fareCount)
		return {
			...acc,
			...fareIdsFromPtc.reduce(toFareCountFromFareId, {}),
		}
	}, {})
}

const getPaxCount = (consist) => consist.reduce((acc, i) => (acc + i), 0)
export const getNewEnabledCounts = (newQuery, offer, consist, newConsist, fares, newFares) => {
	const {
		fare_counts: currentFareCounts,
	} = offer
	const isAddPax = (getPaxCount(newConsist) - getPaxCount(consist)) > 0
	const fareCounts = getSpecifiedFareCounts(currentFareCounts, newConsist, fares, isAddPax && newFares)
	return mapValues(fareCounts, ([ _, enabledCount ]) => (enabledCount))
}

const offerIdByConsistSelector = (state, searchQuery, offerId, newConsist) => {
	const newQuery = getNewQuery(searchQuery, newConsist)
	const offer = offerByIdSelector(state, searchQuery, offerId)
	const {
		fare_variants: fareVariants,
	} = offer
	const consist = flightConsistFromOfferSelector(state, searchQuery, offerId)
	const fares = getFares(state, searchQuery)
	const newFares = getFares(state, newQuery)
	const newEnabledCounts = getNewEnabledCounts(newQuery, offer, consist, newConsist, fares, newFares)

	// TODO: make function for get newFareVariants when will be adding visas upsale
	const newOfferId = offerIdByFaresSelector(state, newQuery, null, newEnabledCounts, fareVariants, searchQuery)
	return newOfferId
}

export default offerIdByConsistSelector
