export default {
	'RUS': 'Россия',
	'ARM': 'Армения',
	'AZE': 'Азербайджан',
	'BLR': 'Беларусь',
	'BGR': 'Болгария',
	'GEO': 'Грузия',
	'ITA': 'Италия',
	'KAZ': 'Казахстан',
	'KGZ': 'Киргизия',
	'MDA': 'Молдавия',
	'TJK': 'Таджикистан',
	'THA': 'Таиланд',
	'TKM': 'Туркмения',
	'UKR': 'Украина',
	'UZB': 'Узбекистан',
	'ABH': 'Абхазия',
	'AUS': 'Австралия',
	'AUT': 'Австрия',
	'ALA': 'Аландские острова',
	'ALB': 'Албания',
	'DZA': 'Алжир',
	'ASM': 'Американское Самоа',
	'AND': 'Андорра',
	'AGO': 'Ангола',
	'AIA': 'Ангилья',
	'ATA': 'Антарктида',
	'ATG': 'Антигуа и Барбуда',
	'ARG': 'Аргентина',
	'ABW': 'Аруба',
	'AFG': 'Афганистан',
	'BHR': 'Бахрейн',
	'BGD': 'Бангладеш',
	'BRB': 'Барбадос',
	'BEL': 'Бельгия',
	'BLZ': 'Белиз',
	'BEN': 'Бенин',
	'BMU': 'Бермуды',
	'BTN': 'Бутан',
	'BOL': 'Боливия',
	'BIH': 'Босния и Герцеговина',
	'BWA': 'Ботсвана',
	'BVT': 'Остров Буве',
	'BRA': 'Бразилия',
	'IOT': 'Британская территория в Индийском океане',
	'VGB': 'Британские Виргинские острова',
	'BRN': 'Бруней',
	'BFA': 'Буркина-Фасо',
	'MMR': 'Мьянма',
	'BDI': 'Бурунди',
	'KHM': 'Камбоджа',
	'CMR': 'Камерун',
	'CAN': 'Канада',
	'CPV': 'Кабо-Верде',
	'CYM': 'Острова Кайман',
	'CAF': 'ЦАР',
	'TCD': 'Чад',
	'CHL': 'Чили',
	'CHN': 'Китай',
	'CXR': 'Остров Рождества',
	'CCK': 'Кокосовые острова',
	'COL': 'Колумбия',
	'COM': 'Коморы',
	'COK': 'Острова Кука',
	'CRI': 'Коста-Рика',
	'CIV': 'Кот-д’Ивуар',
	'HRV': 'Хорватия',
	'CUB': 'Куба',
	'CYP': 'Кипр',
	'CZE': 'Чехия',
	'COD': 'Демократическая Республика Конго',
	'DNK': 'Дания',
	'DJI': 'Джибути',
	'DMA': 'Доминика',
	'DOM': 'Доминиканская Республика',
	'ECU': 'Эквадор',
	'EGY': 'Египет',
	'SLV': 'Сальвадор',
	'GNQ': 'Экваториальная Гвинея',
	'ERI': 'Эритрея',
	'EST': 'Эстония',
	'ETH': 'Эфиопия',
	'FLK': 'Фолклендские острова',
	'FRO': 'Фареры',
	'FJI': 'Фиджи',
	'FIN': 'Финляндия',
	'FRA': 'Франция',
	'GUF': 'Гвиана',
	'PYF': 'Французская Полинезия',
	'ATF': 'Французские Южные и Антарктические Территории',
	'GAB': 'Габон',
	'DEU': 'Германия',
	'GHA': 'Гана',
	'GIB': 'Гибралтар',
	'GRC': 'Греция',
	'GRL': 'Гренландия',
	'GRD': 'Гренада',
	'GLP': 'Гваделупа',
	'GUM': 'Гуам',
	'GTM': 'Гватемала',
	'GGY': 'Гернси',
	'GIN': 'Гвинея',
	'GNB': 'Гвинея-Бисау',
	'GUY': 'Гайана',
	'HTI': 'Гаити',
	'HMD': 'Херд и Макдональд',
	'VAT': 'Ватикан',
	'HND': 'Гондурас',
	'HKG': 'Гонконг',
	'HUN': 'Венгрия',
	'ISL': 'Исландия',
	'IND': 'Индия',
	'IDN': 'Индонезия',
	'IRN': 'Иран',
	'IRQ': 'Ирак',
	'IRL': 'Ирландия',
	'IMN': 'Остров Мэн',
	'ISR': 'Израиль',
	'JAM': 'Ямайка',
	'JPN': 'Япония',
	'JEY': 'Джерси',
	'JOR': 'Иордания',
	'KEN': 'Кения',
	'KIR': 'Кирибати',
	'PRK': 'КНДР',
	'KOR': 'Республика Корея',
	'KWT': 'Кувейт',
	'LAO': 'Лаос',
	'LVA': 'Латвия',
	'LBN': 'Ливан',
	'LSO': 'Лесото',
	'LBR': 'Либерия',
	'LBY': 'Ливия',
	'LIE': 'Лихтенштейн',
	'LTU': 'Литва',
	'LUX': 'Люксембург',
	'MAC': 'Макао',
	'MKD': 'Македония',
	'MDG': 'Мадагаскар',
	'MWI': 'Малави',
	'MYS': 'Малайзия',
	'MDV': 'Мальдивы',
	'MLI': 'Мали',
	'MLT': 'Мальта',
	'MHL': 'Маршалловы Острова',
	'MTQ': 'Мартиника',
	'MRT': 'Мавритания',
	'MUS': 'Маврикий',
	'MYT': 'Майотта',
	'MEX': 'Мексика',
	'FSM': 'Микронезия',
	'MCO': 'Монако',
	'MNG': 'Монголия',
	'MNE': 'Черногория',
	'MSR': 'Монтсеррат',
	'MAR': 'Марокко',
	'MOZ': 'Мозамбик',
	'NAM': 'Намибия',
	'NRU': 'Науру',
	'NPL': 'Непал',
	'NLD': 'Нидерланды',
	'ANT': 'Нидерландские Антильские острова',
	'NCL': 'Новая Каледония',
	'NZL': 'Новая Зеландия',
	'NIC': 'Никарагуа',
	'NER': 'Нигер',
	'NGA': 'Нигерия',
	'NIU': 'Ниуэ',
	'NFK': 'Остров Норфолк',
	'MNP': 'Северные Марианские острова',
	'NOR': 'Норвегия',
	'OMN': 'Оман',
	'PAK': 'Пакистан',
	'PLW': 'Палау',
	'PSE': 'Государство Палестина',
	'PAN': 'Панама',
	'PNG': 'Папуа — Новая Гвинея',
	'PRY': 'Парагвай',
	'PER': 'Перу',
	'PHL': 'Филиппины',
	'PCN': 'Острова Питкэрн',
	'POL': 'Польша',
	'PRT': 'Португалия',
	'PRI': 'Пуэрто-Рико',
	'QAT': 'Катар',
	'COG': 'Республика Конго',
	'REU': 'Реюньон',
	'ROU': 'Румыния',
	'RWA': 'Руанда',
	'BLM': 'Сен-Бартелеми',
	'SHN': 'Острова Святой Елены, Вознесения и Тристан-да-Кунья',
	'KNA': 'Сент-Китс и Невис',
	'LCA': 'Сент-Люсия',
	'MAF': 'Сен-Мартен',
	'SPM': 'Сен-Пьер и Микелон',
	'VCT': 'Сент-Винсент и Гренадины',
	'WSM': 'Самоа',
	'SMR': 'Сан-Марино',
	'STP': 'Сан-Томе и Принсипи',
	'SAU': 'Саудовская Аравия',
	'SEN': 'Сенегал',
	'SRB': 'Сербия',
	'SYC': 'Сейшельские Острова',
	'SLE': 'Сьерра-Леоне',
	'SGP': 'Сингапур',
	'SVK': 'Словакия',
	'SVN': 'Словения',
	'SLB': 'Соломоновы Острова',
	'SOM': 'Сомали',
	'ZAF': 'ЮАР',
	'SGS': 'Южная Георгия и Южные Сандвичевы острова',
	'ESP': 'Испания',
	'LKA': 'Шри-Ланка',
	'SDN': 'Судан',
	'SUR': 'Суринам',
	'SJM': 'Шпицберген и Ян-Майен',
	'SWZ': 'Свазиленд',
	'SWE': 'Швеция',
	'CHE': 'Швейцария',
	'SYR': 'Сирия',
	'TWN': 'Тайвань',
	'TZA': 'Танзания',
	'BHS': 'Багамы',
	'GMB': 'Гамбия',
	'TLS': 'Восточный Тимор',
	'TGO': 'Того',
	'TKL': 'Токелау',
	'TON': 'Тонга',
	'TTO': 'Тринидад и Тобаго',
	'TUN': 'Тунис',
	'TUR': 'Турция',
	'TCA': 'Тёркс и Кайкос',
	'TUV': 'Тувалу',
	'UGA': 'Уганда',
	'ARE': 'ОАЭ',
	'GBR': 'Великобритания',
	'USA': 'США',
	'UMI': 'Внешние малые острова (США)',
	'URY': 'Уругвай',
	'VUT': 'Вануату',
	'VEN': 'Венесуэла',
	'VNM': 'Вьетнам',
	'VIR': 'Виргинские Острова (США)',
	'WLF': 'Уоллис и Футуна',
	'ESH': 'САДР',
	'YEM': 'Йемен',
	'ZMB': 'Замбия',
	'ZWE': 'Зимбабве',
}
