import { flow } from 'lodash'
import { delay, call, all, race, take, select, spawn, put, fork } from 'redux-saga/effects'

import loadData from '@store/sagas/apiService'
import { LOAD_TYPE, OPERATION_STATUSES } from '@libs/foma/types'
import { parse as parseOfferId } from '@utils/common/offerId'
import getQuizThatHasNoAnswer from '@utils/quizes'
import { ACCEPT_QUIZ_ANSWER } from '@store/state/types'
import { byCurrentSearchQuery, currentOfferIdSelector, quizesSelector } from '@store/state/appState/selectors'
import { setCurrentOfferId } from '@store/state/appState/actions'
import { offerByIdSelector } from '@store/state/domainData/selectors'


export const WAIT_QUIZ_ANSWER_TIME_MS = 20000
// TODO: should be test, but later, cause it's prototype
export function* getWaitForQuizEffect () {
	const quizesAnswers = yield select(quizesSelector)
	const quiz = yield call(getQuizThatHasNoAnswer, quizesAnswers)
	return Boolean(quiz) && race([ take(ACCEPT_QUIZ_ANSWER), delay(WAIT_QUIZ_ANSWER_TIME_MS) ])
}

export function* checkCurrentOffer () {
	const currentOfferId = yield select(currentOfferIdSelector)
	const optsCheckOffer = {
		type: LOAD_TYPE.CHECK_OFFER,
		meta: {
			offerId: currentOfferId,
		},
	}
	yield call(loadData, optsCheckOffer)
}

export function* createAndCheck (opts) {
	const opStatus = yield call(loadData, opts)

	if (opStatus === OPERATION_STATUSES.AVAIL) {
		yield fork(checkCurrentOffer)
	}

	return opStatus
}

export const hasOfferSelector = flow(
	byCurrentSearchQuery(offerByIdSelector),
	Boolean
)

export default function* loadOffer (offerId) {
	let opStatus = OPERATION_STATUSES.AVAIL
	let bgTask = null

	const hasOffer = yield select(hasOfferSelector, offerId)

	const infoFromOfferId = parseOfferId(offerId)
	// can parse offer id if it's pseudo offer id
	const isPseudo = infoFromOfferId !== null

	// if has offer set offer id directly in state
	// if has no offer set offer id in fomaEventWatcher after successfull offer loading
	if (hasOffer) yield put(setCurrentOfferId(offerId))

	const optsFetchOffer = {
		type: LOAD_TYPE.OFFER,
		updateSearchQuery: !hasOffer,
		meta: {
			offerId: isPseudo ? null : offerId,
			optsCreateOffer: isPseudo ? infoFromOfferId : null,
		},
	}

	if (hasOffer) {
		bgTask = isPseudo
			? yield spawn(createAndCheck, optsFetchOffer)
			: yield spawn(checkCurrentOffer)
	}
	else {

		const waitForQuizEffect = yield call(getWaitForQuizEffect)

		const { status } = yield all({
			status: call(loadData, optsFetchOffer),
			waitForQuiz: waitForQuizEffect,
		})
		opStatus = status

		if (opStatus === OPERATION_STATUSES.AVAIL) {
			bgTask = yield spawn(checkCurrentOffer)
		}
	}

	return { opStatus, bgTask }
}
