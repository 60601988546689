import fastDeepEqual from 'fast-deep-equal'
import { xor, flow, mergeWith, values, mapValues, mapKeys, filter, get, omit } from 'lodash'
import hashLib from '@libs/object-hash'
import {
	getOffers,
	getFares,
} from '@store/state/domainData/selectors'


const toEnabledCounts = ([ _, count ]) => (count)

export const fareToHash = (fare) => {
	const { report = [], risks = [], service = '', price } = fare
	const ptc = get(fare, 'details.ptc', '')
	const subtype = get(fare, 'details.subtype', '')
	return hashLib.MD5([ report, ptc, subtype, risks, service, price.buyer ])
}

export const fareIdKeyToHash = (fares) => flow(
	(obj) => Object.keys(obj).reduce((acc, k) => ({ ...acc, [k]: { k, v: obj[k] } }), {}),
	(obj) => mapKeys(obj, (_, fareId) => {
		const fare = fares[fareId]
		return fareToHash(fare)
	})
)

export const mergeFareStructures = (fares) => (dst, src) => {
	const keyToHash = fareIdKeyToHash(fares)
	const dstByHash = keyToHash(dst)
	const srcByHash = keyToHash(src)
	const mergedByHash = mergeWith({}, dstByHash, srcByHash, (dstVal, srcVal) => (srcVal))
	return values(mergedByHash).reduce((acc, { v, k }) => ({ ...acc, [k]: v }), {})
}

export const byFareCounts = (fares, sampleEnabledCounts, sampleFareVariants) => (offer) => {
	const { fare_counts: fareCounts, fare_variants: fareVariants } = offer
	const enabledCounts = mapValues(fareCounts, toEnabledCounts)
	const curriedFareIdsKeyToHash = fareIdKeyToHash(fares)
	return [ [ sampleEnabledCounts, enabledCounts ], [ sampleFareVariants, fareVariants ] ]
		.filter(([ sample, option ]) => (sample && option))
		.reduce((acc, [ sample, option ]) => {
			const sampleWithoutFareId = mapValues(curriedFareIdsKeyToHash(sample), (v) => omit(v, 'k'))
			const optionWithoutFareId = mapValues(curriedFareIdsKeyToHash(option), (v) => omit(v, 'k'))
			return acc && fastDeepEqual(sampleWithoutFareId, optionWithoutFareId)
		}, true)
}

export const mergedEnabledCountsResult = (offers, fares, offerId, enabledCounts) => {
	const enabledFareCounts = mapValues(enabledCounts, (count) => ([ 0, count ]))
	let fareCounts = enabledFareCounts
	if (offerId) {
		const { fare_counts: currentFareCounts } = offers[offerId]
		fareCounts = mergeFareStructures(fares)(currentFareCounts, enabledFareCounts)
	}
	return mapValues(fareCounts, toEnabledCounts)
}
export const mergedFareVariantsResult = (offers, fares, offerId, newFareVariants = {}) => {
	let fareVariants = newFareVariants
	if (offerId) {
		const { fare_variants: currentFareVariants = {} } = offers[offerId]
		if (!currentFareVariants) return null
		fareVariants = mergeFareStructures(fares)(currentFareVariants, newFareVariants)
	}
	return fareVariants
}

export const offerIdByFaresResult = (offers, fares, enabledCounts, fareVariants) => {
	const filteredOffers = filter(offers, byFareCounts(fares, enabledCounts, fareVariants))
	const offer = filteredOffers.length > 1
		? filteredOffers.find(({ fares }) => xor(fares, Object.keys(enabledCounts)).length <= 0)
		: filteredOffers[0]
	return offer ? offer.id : null
}

const offerIdByFaresSelector = (state, searchQuery, offerId, enabledCounts, newFareVariants, prevSearchQuery) => {
	const offers = getOffers(state, searchQuery)
	const fares = getFares(state, searchQuery)
	const sampleFares = prevSearchQuery ? getFares(state, prevSearchQuery) : fares
	const mergedFares = prevSearchQuery ? { ...fares, ...sampleFares } : fares
	const mergedEnabledCounts = mergedEnabledCountsResult(offers, mergedFares, offerId, enabledCounts)
	const fareVariants = mergedFareVariantsResult(offers, mergedFares, offerId, newFareVariants)

	return offerIdByFaresResult(offers, mergedFares, mergedEnabledCounts, fareVariants)
}

export default offerIdByFaresSelector
