import l from '@libs/lang'

import { toTelegramCcBot } from '@utils/router'
import $ from './styles/index.js'
import icoTelegram from './images/telegram.svg'


const TelegramFooterBtn = (props) => {
	const {
		withText,
	} = props

	const txt = l('Ваш помощник')

	return (
		<a className={$.container} target="_blank" href={toTelegramCcBot('footer')}>
			<img src={icoTelegram}/>
			{withText ? <span className={$.text}>{txt}</span> : null}
		</a>
	)
}

export default TelegramFooterBtn
