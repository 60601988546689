import { Link } from 'react-router-dom'
import l from '@libs/lang'
import { LeftChev as Button } from 'core-components/Button'
import $ from './styles'


const txtBackTitle = l('Назад к поиску рейсов')

const BackBtn = ({ onClick, to }) => (
	<Link className={$.wrapper} to={to}>
		<Button onClick={onClick}>{txtBackTitle}</Button>
	</Link>
)

export default BackBtn
