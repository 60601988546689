import { useState, useEffect } from 'react'
import cn from 'classnames'

import LoadingSpinner from '@atoms/LoadingSpinner'
import InfoMsg from '@atoms/CommonInfoMsg'

import styles from './styles'


const PageLoader = ({ title, hint, isOverlayed }) => {
	const [ isLoaderShown, setIsLoaderShown ] = useState(false)
	useEffect(() => {
		const timer = setTimeout(() => setIsLoaderShown(true), 2000)
		return () => {
			clearTimeout(timer)
		}
	}, [])
	const loader = (
		<div className={styles.spinner}>
			<LoadingSpinner/>
		</div>
	)

	const content = (
		<div className={styles.content}>
			{hint}
		</div>
	)

	const propsInfoMsg = {
		title,
		content,
		footer: loader,
	}

	const containerClass = cn(styles.container, {
		[styles.overlayed]: isOverlayed,
	})
	return (
		<div className={containerClass}>
			{isLoaderShown && (
				<InfoMsg {...propsInfoMsg}/>
			)}
		</div>
	)
}

export default PageLoader
