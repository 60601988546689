import { get } from 'lodash'
import {
	outboundFlightFromOfferSelector,
	inboundFlightFromOfferSelector,
} from './flights.js'


export const flightBaseDateResult = (outboundFlight, inboundFlight) => {
	const baseFlight = inboundFlight.length > 0 ? inboundFlight : outboundFlight
	const [ baseSegment ] = baseFlight
	return get(baseSegment, 'depart.time', null)
}

export default function flightBaseDateSelector (state, query, offerId) {
	const outboundFlight = outboundFlightFromOfferSelector(state, query, offerId)
	const inboundFlight = inboundFlightFromOfferSelector(state, query, offerId)
	return flightBaseDateResult(outboundFlight, inboundFlight)
}
