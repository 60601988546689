import {
	SET_FORM_FIELD_VALUE,
	SET_FORM_FIELD_CHECKED,
	CLEAR_FORM,
	INIT_FORMS_LIST,
	ADD_FORM,
	REMOVE_FORM,
	PERSIST_FORM,
	REHYDRATE_FORM,
} from '@store/state/types'


export const setFormFieldValue = ({ value, formName, formIndex = null, fieldName }) => ({
	type: SET_FORM_FIELD_VALUE,
	payload: {
		value,
		formName,
		formIndex,
		fieldName,
	},
})

export const setFormFieldChecked = ({ checked, formName, formIndex = null, fieldName }) => ({
	type: SET_FORM_FIELD_CHECKED,
	payload: {
		checked,
		formName,
		formIndex,
		fieldName,
	},
})

export const initFormsList = (formName, indexes) => ({
	type: INIT_FORMS_LIST,
	payload: {
		formName,
		indexes,
	},
})

export const addForm = (formName, formIndex) => ({
	type: ADD_FORM,
	payload: {
		formName,
		formIndex,
	},
})

export const removeForm = (formName, formIndex) => ({
	type: REMOVE_FORM,
	payload: {
		formName,
		formIndex,
	},
})

export const clearForm = (formName, formIndex = null) => ({
	type: CLEAR_FORM,
	payload: {
		formName,
		formIndex,
	},
})

export const persistForm = ({ formName, formIndex = null }) => ({
	type: PERSIST_FORM,
	payload: {
		formName,
		formIndex,
	},
})

export const rehydrateForm = ({ formName, formIndex = null }) => ({
	type: REHYDRATE_FORM,
	payload: {
		formName,
		formIndex,
	},
})
