import { channel } from 'redux-saga'
import { all, fork, takeEvery, call, select } from 'redux-saga/effects'

import analyticTracker from '@libs/analytics-tracker'
import { channelSelector } from '@store/state/appState/selectors'

import actionListeners from './actionListeners'


const parseChannel = (channel) => channel.split(':')[0]
const parsedChannelSelector = (state) => {
	const channel = channelSelector(state)
	return parseChannel(channel)
}

function* eventsHandler ({ name, payload }) {
	const channel = yield select(parsedChannelSelector)
	yield call(analyticTracker.track, name, { ...payload, channel })
}

export default function* analyticsSaga () {
	const analyticEventChan = yield call(channel)
	yield all([
		takeEvery(analyticEventChan, eventsHandler),
		fork(actionListeners, analyticEventChan),
	])
}
