import { takeEvery, select, call, put, all } from 'redux-saga/effects'
import supportFetcher, { FEEDBACK_SEND_STATUS } from '@libs/support-fetcher'

import { SEND_FEEDBACK } from '@store/state/types'
import { feedbackSelectors } from '@store/state/uiState/forms/selectors'
import * as actions from '@store/state/appState/actions'
import { clearForm } from '@store/state/uiState/forms/actions'
import { FEEDBACK_FORM } from '@store/state/uiState/forms/types'


export function* sendFeedbackWorker () {

	const fieldsValues = yield select(feedbackSelectors.formValuesSelector)
	const response = yield call(supportFetcher.sendFeedback, fieldsValues)

	if (response.ok) {
		yield all([
			put(actions.setSendFeedbackStatus(FEEDBACK_SEND_STATUS.SUCCESS)),
			put(clearForm(FEEDBACK_FORM.NAME)),
		])
	}
	else {
		yield put(actions.setSendFeedbackStatus(FEEDBACK_SEND_STATUS.ERROR))
	}
}

export default function* watchSendFeedback () {
	yield takeEvery(SEND_FEEDBACK, sendFeedbackWorker)
}
