const leftBorderRadius = {
	overflow: 'hidden',
	borderTopLeftRadius: 4,
	borderBottomLeftRadius: 4,
}
const topLeftBorderRadius = {
	overflow: 'hidden',
	borderTopLeftRadius: 4,
}
const bottomLeftBorderRadius = {
	overflow: 'hidden',
	borderBottomLeftRadius: 4,
}
const rightBorderRadius = {
	overflow: 'hidden',
	borderTopRightRadius: 4,
	borderBottomRightRadius: 4,
}
const topRightBorderRadius = {
	overflow: 'hidden',
	borderTopRightRadius: 4,
}
const bottomRightBorderRadius = {
	overflow: 'hidden',
	borderBottomRightRadius: 4,
}
const bottomBorderRadius = {
	...bottomLeftBorderRadius,
	...bottomRightBorderRadius,
}
const topBorderRadius = {
	...topLeftBorderRadius,
	...topRightBorderRadius,
}

const borderRadiusSetByLayout = {
	lg: [
		[ leftBorderRadius, topLeftBorderRadius ],
		[],
		[],
		[],
		[ rightBorderRadius, rightBorderRadius ],
	],
	md: [
		[ topLeftBorderRadius, topLeftBorderRadius ],
		[ topRightBorderRadius, topRightBorderRadius ],
		[],
		[],
		[ bottomBorderRadius, bottomBorderRadius ],
	],
	sm: [
		[ topBorderRadius, topBorderRadius ],
		[],
		[],
		[],
		[ bottomBorderRadius, bottomBorderRadius ],
	],
}

export default (cq) => {
	const layoutKey = Object.keys(cq).find((key) => cq[key])
	return borderRadiusSetByLayout[layoutKey || 'lg']
}
