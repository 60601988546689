import { values, isEmpty, flow } from 'lodash'
import { all, fork, select, call, put, take, cancelled } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import accountDataFetcher from '@libs/account-data-fetcher'
import { OPERATION_STATUSES, LOAD_TYPE, ACCOUNT_DATA_STATUS } from '@libs/foma/types'
import { toIndex, toLogin } from '@utils/router'
import { LOCATION_CHANGE } from '@store/state/types'
import {
	currentSearchQuerySelector,
	byCurrentSearchQuery,
	locationQuerySelector,
	userDataSelector,
} from '@store/state/appState/selectors'
import {
	setCurrentSearchQuery,
	setAccountDataStatus,
	setCurrentOfferId,
	setUserData,
} from '@store/state/appState/actions'
import sendSupportFeedbackWatcher from '@store/sagas/watchSendSupportFeedback'
import { getOrders } from '@store/state/domainData/selectors'
import loadData from '@store/sagas/apiService'


export const ACCOUNT_DATA_SEARCH_QUERY = 'PER/SON/AL/ACCOUNT/DA/T/A'

export const hasDataSelector = flow(
	byCurrentSearchQuery(getOrders),
	(orders) => !isEmpty(orders)
)

export const offerBySupportIdSelector = (state, supportId) => {
	const ordersList = values(getOrders(state, ACCOUNT_DATA_SEARCH_QUERY))
	const order = ordersList.find(({ support_id: id }) => (id === supportId))
	return order ? order.offer : null
}


export function* loadAccountData (orderSupportId) {
	let opStatus = OPERATION_STATUSES.NONE

	yield put(setCurrentSearchQuery(ACCOUNT_DATA_SEARCH_QUERY))
	const hasData = yield select(hasDataSelector)

	if (!hasData) {
		const options = {
			type: LOAD_TYPE.ACCOUNT_DATA,
			withoutChangingCurrentOffer: true,
			meta: {
				orderSupportId,
			},
		}

		opStatus = yield call(loadData, options)
	}

	if (orderSupportId) {
		const offerId = yield select(offerBySupportIdSelector, orderSupportId)
		if (offerId) yield put(setCurrentOfferId(offerId))
	}

	// there is comparing with NONE because a backend isn't supporting response statuses at the moment
	if (opStatus === OPERATION_STATUSES.NONE) {
		yield put(setAccountDataStatus(ACCOUNT_DATA_STATUS.SUCCESS))
	}
	else {
		yield put(setAccountDataStatus(ACCOUNT_DATA_STATUS.ERROR))
	}
	return opStatus
}

export const tokenSelector = flow(
	locationQuerySelector,
	({ token }) => token,
)

export function* tryAuthByToken () {
	const token = yield select(tokenSelector)
	if (token) {
		try {
			const userData = yield call(accountDataFetcher.authorizeByToken, '', token)
			yield put(setUserData(userData))
		}
		catch (err) {
			// do nothing
		}
	}
}

export const isAuthorizedUserSelector = flow(
	userDataSelector,
	(data) => !isEmpty(data)
)

const makeCabinetHandler = () => function* cabinetHandler ({ orderSupportId }) {
	const prevQuery = yield select(currentSearchQuerySelector)
	try {
		const isAuthorizedUser = yield select(isAuthorizedUserSelector)
		if (!isAuthorizedUser) yield call(tryAuthByToken)

		const opStatus = yield call(loadAccountData, orderSupportId)

		if (opStatus === 401) { // fix when lk-backend responses errors are similar with others
			yield put(push(toIndex()))
			yield put(push(toLogin()))
		}
		else {
			yield all([
				fork(sendSupportFeedbackWatcher),
			])
			// wait for cancel task
			while (true) {
				yield take(LOCATION_CHANGE)
			}
		}
	}
	finally {
		const isCancelled = yield cancelled()
		if (isCancelled) {
			yield put(setCurrentSearchQuery(prevQuery))
			yield put(setCurrentOfferId(null))
		}
	}
}

export default makeCabinetHandler
