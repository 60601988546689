import { mapValues } from 'lodash'
import { createSelector } from 'reselect'
import { byCurrentOfferId } from '@store/state/appState/selectors'
import {
	isInternationalFlightFromOffer,
	isProhibitBirthCertCountry,
	flightBaseDateSelector,
	isMiddleNameRequiredSelector,
} from '@store/state/domainData/selectors'
import {
	passengerAgeSelector,
	documentExpDateWithDefaultSelector,
	passengerAgeByFormIndexSelector,
	documentExpDateWithDefaultByFormIndexSelector,
	documentTypeSelector,
	documentTypeByFormIndexSelector,
} from './derivatives.js'
import {
	lastNameSelector,
	firstNameSelector,
	middleNameSelector,
	genderSelector,
	birthdateSelector,
	citizenshipSelector,
	documentNumSelector,
	lastNameByFormIndexSelector,
	firstNameByFormIndexSelector,
	middleNameByFormIndexSelector,
	genderByFormIndexSelector,
	birthdateByFormIndexSelector,
	citizenshipByFormIndexSelector,
	documentNumByFormIndexSelector,
} from './values.js'
import {
	isNameValid,
	isGenderValid,
	isBirthdateValid,
	isCitizenshipValid,
	isDocumentExpDateValid,
	isDocumentNumValid,
	isMiddleNameValid,
} from '../../validators'


export const isLastNameValidSelector = createSelector(
	lastNameSelector,
	byCurrentOfferId(isInternationalFlightFromOffer),
	isNameValid
)
export const isLastNameValidByFormIndexSelector = createSelector(
	lastNameByFormIndexSelector,
	byCurrentOfferId(isInternationalFlightFromOffer),
	(valueByIndex, isInternationalFlight) => mapValues(
		valueByIndex, (value) => isNameValid(value, isInternationalFlight)
	)
)

export const isFirstNameValidSelector = createSelector(
	firstNameSelector,
	byCurrentOfferId(isInternationalFlightFromOffer),
	isNameValid
)
export const isFirstNameValidByFormIndexSelector = createSelector(
	firstNameByFormIndexSelector,
	byCurrentOfferId(isInternationalFlightFromOffer),
	(valueByIndex, isInternationalFlight) => mapValues(
		valueByIndex, (value) => isNameValid(value, isInternationalFlight)
	)
)

export const isMiddleNameValidSelector = createSelector(
	middleNameSelector,
	byCurrentOfferId(isMiddleNameRequiredSelector),
	documentTypeSelector,
	isMiddleNameValid
)
export const isMiddleNameValidByFormIndexSelector = createSelector(
	middleNameByFormIndexSelector,
	byCurrentOfferId(isMiddleNameRequiredSelector),
	documentTypeByFormIndexSelector,
	(valueByIndex, isRequired, docTypeByIndex) => mapValues(
		valueByIndex, (value, index) => isMiddleNameValid(value, isRequired, docTypeByIndex[index])
	)
)

export const isGenderValidSelector = createSelector(
	genderSelector,
	isGenderValid
)
export const isGenderValidByFormIndexSelector = createSelector(
	genderByFormIndexSelector,
	(valueByIndex) => mapValues(
		valueByIndex,
		(value) => isGenderValid(value)
	)
)

export const isBirthdateValidSelector = createSelector(
	birthdateSelector,
	isBirthdateValid
)
export const isBirthdateValidByFormIndexSelector = createSelector(
	birthdateByFormIndexSelector,
	(valueByIndex) => mapValues(valueByIndex, isBirthdateValid)
)

export const isCitizenshipValidSelector = createSelector(
	citizenshipSelector,
	isCitizenshipValid
)
export const isCitizenshipValidByFormIndexSelector = createSelector(
	citizenshipByFormIndexSelector,
	(valueByIndex) => mapValues(valueByIndex, isCitizenshipValid)
)

export const isDocumentNumValidSelector = createSelector(
	documentNumSelector,
	citizenshipSelector,
	byCurrentOfferId(isInternationalFlightFromOffer),
	passengerAgeSelector,
	isProhibitBirthCertCountry,
	isDocumentNumValid
)
export const isDocumentNumValidByFormIndexSelector = createSelector(
	documentNumByFormIndexSelector,
	citizenshipByFormIndexSelector,
	byCurrentOfferId(isInternationalFlightFromOffer),
	passengerAgeByFormIndexSelector,
	(valueByIndex, citizenshipByIndex, isInternationalFlight, paxAgeByIndex) => mapValues(
		valueByIndex,
		(value, index) => isDocumentNumValid(
			value,
			citizenshipByIndex[index],
			isInternationalFlight,
			paxAgeByIndex[index]
		)
	)
)

export const isDocumentExpDateValidSelector = createSelector(
	documentExpDateWithDefaultSelector,
	byCurrentOfferId(flightBaseDateSelector),
	isDocumentExpDateValid
)
export const isDocumentExpDateValidByFormIndexSelector = createSelector(
	documentExpDateWithDefaultByFormIndexSelector,
	byCurrentOfferId(flightBaseDateSelector),
	(valueByIndex, baseDate) => mapValues(
		valueByIndex,
		(value) => isDocumentExpDateValid(value, baseDate)
	)
)

export const isFormValidSelector = createSelector(
	isLastNameValidSelector,
	isFirstNameValidSelector,
	isMiddleNameValidSelector,
	isGenderValidSelector,
	isBirthdateValidSelector,
	isCitizenshipValidSelector,
	isDocumentNumValidSelector,
	isDocumentExpDateValidSelector,
	(...isValidValueList) => isValidValueList.every(Boolean)
)
export const isFormValidByFormIndexSelector = createSelector(
	isLastNameValidByFormIndexSelector,
	isFirstNameValidByFormIndexSelector,
	isMiddleNameValidByFormIndexSelector,
	isGenderValidByFormIndexSelector,
	isBirthdateValidByFormIndexSelector,
	isCitizenshipValidByFormIndexSelector,
	isDocumentNumValidByFormIndexSelector,
	isDocumentExpDateValidByFormIndexSelector,
	(isLastNameValidByIndex, ...isValidValueByIndexList) => mapValues(
		isLastNameValidByIndex,
		(isValid, index) => (
			isValid && isValidValueByIndexList.every((isValidValueByIndex) => Boolean(
				isValidValueByIndex[index]
			))
		)
	)
)
