import { first } from 'lodash'
import { createSelector } from 'reselect'
import * as customerSelectors from './customer'
import * as passengerSelectors from './passenger'
import * as creditCardSelectors from './creditCard.js'
import * as feedbackSelectors from './feedback.js'
import * as loginSelectors from './login.js'
import * as resetPasswordSelectors from './resetPassword.js'
import * as searchBarSelectors from './searchBar.js'
import * as searchTransferSelectors from './searchTransfer.js'
import * as arrivalSmsUpsaleSelectors from './arrivalSmsUpsale.js'
import * as flightCheckInUpsaleSelectors from './flightCheckInUpsale.js'
import {
	makeValueSelector,
	makeErrorsSelector,
	getFormsState,
} from './core.js'


export {
	makeValueSelector,
	makeErrorsSelector,
	getFormsState,
	customerSelectors,
	passengerSelectors,
	creditCardSelectors,
	feedbackSelectors,
	loginSelectors,
	resetPasswordSelectors,
	searchBarSelectors,
	searchTransferSelectors,
	arrivalSmsUpsaleSelectors,
	flightCheckInUpsaleSelectors,
}

const isFlatArray = true
const customerErrorsSelector = makeErrorsSelector(customerSelectors.errorFieldsSelectors, isFlatArray)
const passengerErrorsSelector = makeErrorsSelector(passengerSelectors.errorFieldsSelectors, isFlatArray)
const creditCardErrorsSelector = makeErrorsSelector(creditCardSelectors.errorFieldsSelectors, isFlatArray)
const arrivalSmsUpsaleErrorsSelector = makeErrorsSelector(arrivalSmsUpsaleSelectors.errorFieldsSelectors, isFlatArray)

export const firstInvalidFieldSelector = createSelector(
	customerErrorsSelector,
	passengerErrorsSelector,
	creditCardErrorsSelector,
	arrivalSmsUpsaleErrorsSelector,
	(customerErrors, passengerErrors, ccErrors, arrivalSmsUpsaleErrors) => first([
		...customerErrors,
		...passengerErrors,
		...ccErrors,
		...arrivalSmsUpsaleErrors,
	])
)

export const invalidFieldsSelector = createSelector(
	customerErrorsSelector,
	passengerErrorsSelector,
	creditCardErrorsSelector,
	arrivalSmsUpsaleErrorsSelector,
	(customerErrors, passengerErrors, ccErrors, arrivalSmsUpsaleErrors) => [
		...customerErrors,
		...passengerErrors,
		...ccErrors,
		...arrivalSmsUpsaleErrors,
	]
)
