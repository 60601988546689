import info from '@utils/info'


const TOKEN = 'clickavia-ru'
const ERROR_MESSAGE = 'Sociomantic is probably not there or broken'

export const clear = () => {
	try {
		window.sociomantic.sonar.adv[TOKEN].clear()
	}
	catch (e) {
		info(ERROR_MESSAGE, e)
	}
}

export const track = () => {
	try {
		window.sociomantic.sonar.adv[TOKEN].track()
	}
	catch (e) {
		info(ERROR_MESSAGE, e)
	}
}

export const assignCustomer = (identifier, segment) => {
	clear()
	window.customer = {
		// no identifier for now
		// identifier,
		segment,
	}
	track()
}
export const setProduct = (productId, valid, date) => {
	clear()
	window.product = {
		identifier: productId,
		valid,
		date,
	}
	track()
}

export const setBasket = ({ productId, orderNum, paymentAmount }) => {
	clear()
	window.basket = {
		products: [ {
			identifier: productId,
			amount: paymentAmount,
			currency: 'RUB',
			quantity: 1,
		} ],
		transaction: orderNum,
	}
	track()
}

