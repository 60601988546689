import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { all, fork } from 'redux-saga/effects'

import configureStore, { history } from '@store'
import formsPersistanceWorker from '@store/sagas/formsPersistance'
import searchBar from '@store/sagas/searchBar'
import absVisionTrackerWorker from '@store/sagas/absVisionTracker'
import focusWorker from '@store/sagas/focus'
import checkUserAuth from '@store/sagas/checkUserAuth'
import routerWorker from '@store/sagas/router'
import offerConfiguratorWorker from '@store/sagas/offerConfigurator'
import accommodationExtrInfoWorker from '@store/sagas/accommodationExtraInfo'
import channelPersistanceWorker from '@store/sagas/channelPersistance'
import loginWatcher from '@store/sagas/watchLogin'
import quizShowWorker from '@store/sagas/quizShow'
import priceCalendarWorker from '@store/sagas/priceCalendar'

import { checkIntl } from '@utils/polyfills'
import BaseCSS from '@utils/taffy/BaseCSS/index.jsx'
import ConfySwitcher from '@utils/confy/ConfySwitcher/index.jsx'

import RootComponent from '../index.js'


window.APP_VERSION = __APP_VERSION__ // eslint-disable-line no-undef

function* rootSaga () {
	yield all([
		fork(absVisionTrackerWorker),
		fork(channelPersistanceWorker),
		fork(formsPersistanceWorker),
		fork(searchBar),
		fork(focusWorker),
		fork(checkUserAuth),
		fork(routerWorker),
		fork(offerConfiguratorWorker),
		fork(accommodationExtrInfoWorker),
		fork(loginWatcher),
		fork(quizShowWorker),
		fork(priceCalendarWorker),
	])
}

const renderAppWithStore = () => {
	const store = configureStore()
	store.runSaga(rootSaga)

	const mount = () => render(
		<Provider store={store}>
			<ConnectedRouter history={history}>
				<BaseCSS>
					<ConfySwitcher>
						<RootComponent/>
					</ConfySwitcher>
				</BaseCSS>
			</ConnectedRouter>
		</Provider>,
		document.getElementById('root'),
	)

	checkIntl(mount)

	if (module.hot) {
		module.hot.accept('../index.js', () => checkIntl(mount))
	}

	// Disable bluebird warining for sagas
	return null
}

renderAppWithStore()

