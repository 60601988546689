import { all, take, select, fork } from 'redux-saga/effects'
import { matchPath } from 'react-router-dom'
import { currentSearchQuerySelector, currentOfferIdSelector } from '@store/state/appState/selectors'
import { PATHS } from '@utils/router'
import setOfferIdActionMatcher from '@store/sagas/router/helpers/setOfferIdActionMatcher.js'
import { isPseudo } from '@utils/common/offerId'
import { SET_CURRENT_OFFER_ID, LOCATION_CHANGE } from '@store/state/types'
import { getQueryFromString } from '@utils/common/searchQuery'
import { track } from '@tracer/tl-tracker'


export const matchGoToPage = (path) => ({ type, payload }) => {
	if (type !== LOCATION_CHANGE) return false
	const match = matchPath(payload.pathname, path)
	return Boolean(match && match.isExact)
}

export const matchGoToSerp = matchGoToPage(PATHS.SERP)
export const matchGoToCheckout = matchGoToPage(PATHS.CHECKOUT)

export const matchSetNonPseudoOfferId = (action) => {
	const isSetOffer = setOfferIdActionMatcher(action)
	if (!isSetOffer) return false

	const { type, payload } = action

	const offerId = (type === SET_CURRENT_OFFER_ID)
		? payload
		: payload.find(({ type }) => (type === SET_CURRENT_OFFER_ID)).payload

	return !isPseudo(offerId)
}

export default function* tlTracker () {
	while (true) {
		yield take(matchGoToSerp) // at serp
		yield take(matchGoToCheckout) // at checkout
		yield take(matchSetNonPseudoOfferId) // non pseudo offer created
		// that`s it! select current query, parse it and fork the tracker
		const [ searchQueryString, offerId ] = yield all([
			select(currentSearchQuerySelector),
			select(currentOfferIdSelector),
		])
		const searchQuery = getQueryFromString(searchQueryString)

		yield fork(track, { searchQuery, offerId })
	}
}
