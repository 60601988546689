import { get } from 'lodash'


export const getContactsFromOrder = (contacts, order) => get(order, 'contacts', [])
	.reduce((acc, contactId) => ({ ...acc, [contactId]: contacts[contactId] }), {})
export const getFaresFromOffer = (fares, offer) => get(offer, 'fares', [])
	.reduce((acc, fareId) => ({ ...acc, [fareId]: fares[fareId] }), {})
// product is tuple [ type, fareIds ]
export const getFaresFromProduct = (fares, [ _, fareIds ]) => fareIds
	.reduce((acc, fareId) => ({ ...acc, [fareId]: fares[fareId] }), {})

export const getAgreementsFromOffer = (agreements, offer) => offer.agreements
	.reduce((acc, agreementId) => ({ ...acc, [agreementId]: agreements[agreementId] }), {})
export const getPackageFromOffer = (packages, { package: id }) => packages[id]

export const getBrandFromFare = (brands, { supplier }) => brands[supplier]
export const getServiceFromFare = (services, { service }) => services[service]

export const getActivitiesFromService = (activities, service) => service.activities
	.reduce((acc, activityId) => ({ ...acc, [activityId]: activities[activityId] }), {})

export const getSegmentsFromActivity = (segments, activity) => activity.segments
	&& activity.segments.reduce((acc, segmentId) => ({ ...acc, [segmentId]: segments[segmentId] }), {})
export const getHotelFromActivity = (hotels, { hotel }) => hotels[hotel]

export const getAirlineFromSegment = (airlines, { airline }) => airlines[airline]
export const getDepartAirportFromSegment = (airports, { depart }) => airports[depart.airport]
export const getArriveAirportFromSegment = (airports, { arrive }) => airports[arrive.airport]
export const getAirportsFromSegment = (airports, { depart, arrive }) => ({
	[depart.airport]: airports[depart.airport],
	[arrive.airport]: airports[arrive.airport],
})
