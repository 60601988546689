import { call, select, put } from 'redux-saga/effects'
import loadData from '@store/sagas/apiService'
import { LOAD_TYPE, OPERATION_STATUSES } from '@libs/foma/types'
import {
	currentOfferIdSelector,
	currentSearchQuerySelector,
	byCurrentOfferId,
} from '@store/state/appState/selectors'
import { setCurrentOfferId, setOfferReconfigureStatus } from '@store/state/appState/actions'
import { setDomainData } from '@store/state/domainData/actions'
import offerIdByFaresSelector from './selectors/offerIdByFares.js'
import resWithNewFaresByVariants from './selectors/resWithNewFaresByVariants.js'


export const enhancedOfferIdByFaresSelector = byCurrentOfferId(offerIdByFaresSelector)

export default function* handleReconfigureOffer ({ payload }) {
	const { serviceType, enabledCounts = {}, fareVariants = {} } = payload
	const newOfferId = yield select(enhancedOfferIdByFaresSelector, enabledCounts, fareVariants)
	let opStatus = OPERATION_STATUSES.AVAIL
	if (newOfferId) {
		yield put(setCurrentOfferId(newOfferId))
		if (Object.keys(fareVariants).length > 0) {
			const query = yield select(currentSearchQuerySelector)
			const resWithNewFares = yield select(resWithNewFaresByVariants, query, fareVariants)
			yield put(setDomainData(resWithNewFares, query))
		}
	}
	else {
		const offerId = yield select(currentOfferIdSelector)
		const loadOptions = {
			type: LOAD_TYPE.RECONFIGURE_OFFER,
			meta: { offerId, enabledCounts, fareVariants },
		}
		opStatus = yield call(loadData, loadOptions)
	}
	yield put(setOfferReconfigureStatus(serviceType, opStatus))
}


