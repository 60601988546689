import PropTypes from 'prop-types'
import cn from 'classnames'

import createOptionsList, { DATA_ATTR_KEY } from 'core-components/OptionsList'
import Dropdown from 'core-components/Dropdown'

import {
	getFlatNextProps,
	getFlatPrevProps,
} from './utils/listNavigation.js'


const OptionsList = createOptionsList({
	getDown: getFlatNextProps,
	getUp: getFlatPrevProps,
})

class Autocomplete extends React.Component {
	_renderInput = (showPopover, hidePopover, isShownPopover) => {
		const {
			inputComponent: Input,
			value,
			placeholder,
			placeholderColor,
			isFocused,
			isValid,
			style,
			classNames,
			onChange,
		} = this.props
		const propsInput = {
			value,
			placeholder,
			placeholderColor,
			isFocused,
			onChange,
		}
		const inputWrapperClass = cn({
			[classNames.inputWrapper]: true,
			[classNames.invalidInputWrapper]: !isValid,
		})
		return (
			<div style={style} className={inputWrapperClass}>
				<Input {...propsInput}/>
			</div>
		)
	}

	_getItemSelectHandler = (hidePopover) => (idx) => {
		const { suggestions, onSelect } = this.props
		hidePopover()
		onSelect(suggestions[idx])
	}

	_renderSuggestions = (setPopoverRef, hidePopover) => {
		const { suggestions, classNames, getSuggestionItemMapper } = this.props
		const itemSelectHandler = this._getItemSelectHandler(hidePopover)
		if (suggestions.length <= 0) return null
		return (
			<OptionsList dataList={suggestions} onEnter={itemSelectHandler}>
				{({ hovered }, onKeyDown, onMouseEnter, clearHover) => {
					const propsSuggestionsContainer = {
						ref: setPopoverRef,
						className: classNames.suggestionsContainer,
						onKeyDown,
						onMouseLeave: clearHover,
					}
					const toItem = getSuggestionItemMapper(hovered, onMouseEnter, itemSelectHandler, DATA_ATTR_KEY)
					return (
						<div {...propsSuggestionsContainer}>
							{suggestions.map(toItem)}
						</div>
					)
				}}
			</OptionsList>
		)
	}

	render () {
		const { isShownOnFocused, isHiddenOnBlur, onFocus, onBlur } = this.props
		const propsDropdown = {
			renderInput: this._renderInput,
			renderPopover: this._renderSuggestions,
			isShownOnFocused,
			isHiddenOnBlur,
			onFocus,
			onBlur,
		}
		return (<Dropdown {...propsDropdown}/>)
	}
}

Autocomplete.propTypes = {
	inputComponent: PropTypes.func.isRequired,
	value: PropTypes.string.isRequired,
	placeholder: PropTypes.string.isRequired,
	placeholderColor: PropTypes.string.isRequired,
	isValid: PropTypes.bool.isRequired,
	isFocused: PropTypes.bool.isRequired,
	isShownOnFocused: PropTypes.bool.isRequired,
	isHiddenOnBlur: PropTypes.bool.isRequired,
	suggestions: PropTypes.array.isRequired,
	style: PropTypes.object,
	classNames: PropTypes.object.isRequired,
	getSuggestionItemMapper: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	onSelect: PropTypes.func.isRequired,
	onFocus: PropTypes.func.isRequired,
	onBlur: PropTypes.func.isRequired,
}

export default Autocomplete
