import { call } from 'redux-saga/effects'

import info from '@utils/info'
import { OPERATION_STATUSES } from '@libs/foma/types'

import { makeOrder } from './handleBook'


export default function* handleGenerateTinkoffPayment (evtChannel, meta) {
	const { offerId, maybeOrderId, offerPrice } = meta

	let orderId = null

	try {
		info({ type: 'createOrder' })
		orderId = yield call(makeOrder, evtChannel, offerId, maybeOrderId, offerPrice)

		if (!orderId) {
			return OPERATION_STATUSES.ERROR
		}

		return OPERATION_STATUSES.AVAIL
	}
	catch (err) {
		info('cant create an order', err)

		return OPERATION_STATUSES.ERROR
	}

}
