import { uniq } from 'lodash'
import {
	FETCH_SUGGESTIONS_RESOLVE,
	SET_GEO_DATA,
} from '@store/state/types'


const initialState = {
	cities: {},
	airports: {},
	fetchedLocationsTerms: [],
	suggestions: [],
}

export default function reducer (state = initialState, { type, payload }) {
	switch (type) {
		case SET_GEO_DATA: {
			const { cities = {}, airports = {} } = payload
			return {
				...state,
				cities: { ...state.cities, ...cities },
				airports: { ...state.airports, ...airports },
			}
		}

		case FETCH_SUGGESTIONS_RESOLVE: {
			const { title, suggestions } = payload
			return {
				...state,
				fetchedLocationsTerms: uniq([ ...state.fetchedLocationsTerms, title ]),
				suggestions: {
					...state.suggestions,
					[title]: [
						...(state.suggestions[title] || []),
						...suggestions,
					],
				},
			}
		}
		default: return state
	}
}
