import { takeWhile, last } from 'lodash'
import { select, put, call } from 'redux-saga/effects'
import info from '@utils/info'
import {
	getDate,
	getRangeInDays,
	getRangeInMonth,
	getDatesRangeArr,
} from '@utils/time-utils'
import { getData } from '@store/sagas/apiService'
import { LOAD_TYPE } from '@libs/foma/types'
import {
	fetchTopPricesResolve,
	fetchPricesByMonthResolve,
	fetchPricesByDayResolve,
} from '@store/state/appState/actions'
import {
	topPricesSelector,
	pricesByMonthSelector,
	pricesByDaySelector,
} from '@store/state/selectors'


const MIN_LOOKUP_DATES_COUNT = 3
// should return chunks of dates
// with min diff between each other (1 day or 1 month).
// for example by days:
// [ '2018-05-14', '2018-05-15', '2018-05-17', 2018-05-18' ]
// should return two chunks:
// [ [ '2018-05-14', '2018-05-15' ], [ '2018-05-17', '2018-05-18' ] ]
const getChunks = (array, isMonth) => {
	const len = array.length
	let lastIdx = 0
	let tmp = []
	let chunks = []
	while (lastIdx < len) {
		tmp = array.slice(lastIdx)
		const chunk = takeWhile(tmp, (date, i, a) => {
			if (i <= 0) return true
			const prevIdx = i - 1
			const prevDate = a[prevIdx]
			const range = isMonth
				? getRangeInMonth(getDate(date), getDate(prevDate))
				: getRangeInDays(getDate(date), getDate(prevDate))
			return range <= 1
		})
		lastIdx = chunk.length > 0 ? array.indexOf(last(chunk)) + 1 : lastIdx + 1
		chunks.push(chunk)
	}
	return chunks
}

// should return chunks for loading with start date and count
// for example by days:
// [ [ '2018-05-14', '2018-05-15', '2018-05-16' ], [ '2018-05-18' ] ]
// should return only one chunk with start date is '2018-05-14' and count is 3,
// cause last chunk doesn't pass a filtering by MIN_LOOKUP_DATES_COUNT
const getChunksForLoading = (prices, startDate, count, isMonth) => {
	const datesRangeArr = getDatesRangeArr(startDate, count, isMonth)
	const unknownPriceDates = datesRangeArr.filter((date) => !prices[date])
	const chunks = getChunks(unknownPriceDates, isMonth)
	return chunks
		.filter((datesArr) => datesArr.length >= MIN_LOOKUP_DATES_COUNT)
		.map((datesArr) => ({
			startDate: datesArr[0],
			count: datesArr.length,
		}))
}

const makePricesGetter = (type, storeAction, pricesSelector) => function* (...args) {
	const [
		startDate,
		stayLength,
		src,
		dst,
		count,
		isDummyLogic, // use it if you don't need chunks inference
	] = args

	const prices = (yield select(pricesSelector)) || {}
	const isMonth = type === LOAD_TYPE.PRICES_BY_MONTH
	const chunksForLoading = isDummyLogic
		? [ { startDate, count } ]
		: (yield call(getChunksForLoading, prices, startDate, count, isMonth))

	// load necessary dates chunks
	for (let chunk of chunksForLoading) {
		const getPricesOptions = {
			type,
			meta: {
				src,
				dst,
				stayLength,
				startDate: chunk.startDate,
				count: chunk.count,
			},
		}
		try {
			const resData = yield call(getData, getPricesOptions)
			const prices = resData.data.dates
			yield put(storeAction(prices, src, dst, stayLength))
		}
		catch (err) {
			info(err)
		}
	}
}

export const loadTopPrices = makePricesGetter(
	LOAD_TYPE.TOP_PRICES,
	fetchTopPricesResolve,
	topPricesSelector,
)
export const loadPricesByMonth = makePricesGetter(
	LOAD_TYPE.PRICES_BY_MONTH,
	fetchPricesByMonthResolve,
	pricesByMonthSelector,
)
export const loadPricesByDay = makePricesGetter(
	LOAD_TYPE.PRICES_BY_DAY,
	fetchPricesByDayResolve,
	pricesByDaySelector,
)

