export const OPERATION_STATUSES = {
	CANCELLED: 'CANCELLED',
	NONE: 'NONE',
	LOADING: 'LOADING',
	ERROR: 'ERROR',
	AVAIL: 'AVAIL',
	UNAVAIL: 'UNAVAIL',
	VALIDATION_ERROR: 'VALIDATION_ERROR',
	INVALID: 'INVALID',
	SUCCESS: 'SUCCESS',
	PRICE_CHANGED: 'PRICE_CHANGED',
	CHECK_PRICE_CHANGED: 'CHECK_PRICE_CHANGED',
	INVALID_CARD_DATA: 'INVALID_CARD_DATA',
	INSUFFICIENT_FUNDS: 'INSUFFICIENT_FUNDS',
	PAYMENT_3DS_FAILED: 'PAYMENT_3DS_FAILED',
	PAYMENT_REJECTED: 'PAYMENT_REJECTED',
	PAYMENT_ERROR: 'PAYMENT_ERROR',
	BOOKED: 'BOOKED',
	BOOKING: 'BOOKING',
	PAYMENT_3DS_REQUIRED: 'PAYMENT_3DS_REQUIRED',
	PAYMENT_3DS_V2_REQUIRED: 'PAYMENT_3DS_V2_REQUIRED',
	IN_PROGRESS: 'IN_PROGRESS',
	TRIED_TO_BOOK: 'TRIED_TO_BOOK',
	SBP_ERROR: 'SBP_ERROR',
	SBP_TIMELIMIT: 'SBP_TIMELIMIT',
	TINKOFF_ERROR: 'TINKOFF_ERROR',
	TINKOFF_TIMELIMIT: 'TINKOFF_TIMELIMIT',
}

export const ORDER_STATUSES = {
	AVAIL: 'AVAIL',
	BOOKING: 'BOOKING',
	BOOKED: 'BOOKED',
	UNAVAIL: 'UNAVAIL',
	INVALID: 'INVALID',
	CHECK_BOOKING_TIMEOUT_EXCEEDED: 'CHECK_BOOKING_TIMEOUT_EXCEEDED',
	UNROLLING: 'UNROLLING',
	UNROLL_ERROR: 'UNROLL_ERROR',
}

export const PAYMENT_STATUSES = {
	UNPAID: 'UNPAID',
	BLOCKING: 'BLOCKING',
	BLOCKED: 'BLOCKED',
	CHARGING: 'CHARGING',
	CHARGED: 'CHARGED',
	READY_TO_SPLIT: 'READY_TO_SPLIT',
	SPLITTING: 'SPLITTING',
	SPLITTED: 'SPLITTED',
	REFUNDED: 'REFUNDED',
}
