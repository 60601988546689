/*
	there is no better way to pass options
	to the Intl.DateTimeFormat object
*/

const date = {
	onlyLongMonth: {
		month: 'long',
	},
	shortMonth: {
		style: 'date',
		day: 'numeric',
		month: 'short',
	},
	shortWeekday: {
		weekday: 'short',
	},
	longMonth: {
		month: 'long',
		day: 'numeric',
	},
	weekday: {
		weekday: 'long',
	},
	short: {
		month: 'numeric',
		day: 'numeric',
		year: '2-digit',
	},
	shortWithoutYear: {
		month: 'numeric',
		day: 'numeric',
	},
	mediumYear4digit: {
		month: 'numeric',
		day: 'numeric',
		year: 'numeric',
	},
	medium: {
		month: 'short',
		day: 'numeric',
		year: 'numeric',
	},
	long: {
		month: 'long',
		day: 'numeric',
		year: 'numeric',
	},
	full: {
		weekday: 'long',
		month: 'long',
		day: 'numeric',
		year: 'numeric',
	},
}

const time = {
	short: {
		hour: 'numeric',
		minute: 'numeric',
	},
	medium: {
		hour: 'numeric',
		minute: 'numeric',
		second: 'numeric',
	},
	long: {
		hour: 'numeric',
		minute: 'numeric',
		second: 'numeric',
		timeZoneName: 'short',
	},
	full: {
		hour: 'numeric',
		minute: 'numeric',
		second: 'numeric',
		timeZoneName: 'short',
	},
}


for (let f in time) {
	time[f].timeZone = 'UTC'
}

for (let f in date) {
	date[f].timeZone = 'UTC'
}


export default { date, time }
