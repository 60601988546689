import { createSelector } from 'reselect'
import {
	makeErrorsSelector,
	customerSelectors,
	passengerSelectors,
	creditCardSelectors,
} from '@store/state/uiState/forms/selectors'


const errorFieldsSelectors = [
	...customerSelectors.errorFieldsSelectors,
	...passengerSelectors.errorFieldsSelectors,
	...creditCardSelectors.errorFieldsSelectors,
]
const formsErrorsSelector = makeErrorsSelector(errorFieldsSelectors)

const getEmptyFieldsCount = ({ emptyFields }) => emptyFields.length

export default createSelector(
	formsErrorsSelector,
	getEmptyFieldsCount
)
