import { all, fork } from 'redux-saga/effects'

import env from '@utils/env'

import trackUserIdWorker from './trackUserId'
import tlTracker from './trackTl.js'
// import somicTracker from './trackSomic.js'
// import intentMediaTracker from './trackIntentMedia.js'

import analyticsSaga from '../analytics'


export default function* absVisionTrackerWorker () {
	yield all([
		env.isProd && fork(trackUserIdWorker),
		fork(analyticsSaga),
		env.isProd && fork(tlTracker),
		// env.isProd && fork(somicTracker),
		// env.isProd && fork(intentMediaTracker),
	].filter(Boolean))
}
