import { take, put } from 'redux-saga/effects'

import { ON_ELEMENT_CLICK, APPLY_FILTERS } from '@store/state/types'


const filterKeyList = [
	'transfersCount',
	'travelTime',
	'baggage',
	'flightTime',
]
export default (eventConfigs) => (function* (analyticEventChan) {
	let openedFilter = ''
	while (true) {
		let { type, meta } = yield take([ ON_ELEMENT_CLICK, APPLY_FILTERS ])
		if (type === ON_ELEMENT_CLICK && filterKeyList.some((k) => meta.elementKey.includes(k))) {
			openedFilter = meta.elementKey
		}
		else if (type === APPLY_FILTERS) {
			meta = { openedFilter }
		}
		const { eventName } = eventConfigs.find(({ pattern }) => pattern({ type, meta })) || {}
		if (eventName) yield put(analyticEventChan, { name: eventName })
	}
})
