import { connect } from 'react-redux'
import { matchPath } from 'react-router-dom'
import { push } from 'connected-react-router'
import deepEqual from 'fast-deep-equal'

import { createEnhancer } from '@utils/decoract'
import { PATHS, toSerp } from '@utils/router'
import { getSearchParams } from '@utils/common/searchQuery.js'
import { runSearch, setIsSearchbarHighlighted, searchClick } from '@store/state/appState/actions'
import { SEARCH_BAR_FORM } from '@store/state/uiState/forms/types'
import { setFormFieldValue } from '@store/state/uiState/forms/actions'
import { searchBarSelectors } from '@store/state/uiState/forms/selectors'
import {
	makeFilteredSuggestionsSelector,
	isSearchbarHighlightedSelector,
	routerDataSelector,
} from '@store/state/appState/selectors'

import { isSearchButtonEnabledSelector } from './selectors'

import SearchBar from './searchBar.js'

// container query stuff
const cq = {
	sm: {
		maxWidth: 719,
	},
	md: {
		minWidth: 720,
		maxWidth: 1091,
	},
	lg: {
		minWidth: 1092,
	},
}
export const enhancer = createEnhancer({ cq })

// react-redux connector
const {
	srcSelector,
	dstSelector,
	outboundDateSelector,
	inboundDateSelector,
	adultsSelector,
	childrenSelector,
	infantsSelector,
	isSrcValidSelector,
	isDstValidSelector,
	isOutboundDateValidSelector,
	isInboundDateValidSelector,
	isFormValidSelector,
} = searchBarSelectors

const filteredSrcSuggestionsSelector = makeFilteredSuggestionsSelector()
const filteredDstSuggestionsSelector = makeFilteredSuggestionsSelector()

const mapStateToProps = (state) => {
	const src = srcSelector(state)
	const dst = dstSelector(state)
	const { location } = routerDataSelector(state)
	const matchedSerp = matchPath(location.pathname, { path: PATHS.SERP, exact: true })

	return {
		isSerp: Boolean(matchedSerp),
		paramsFromLocation: matchedSerp && matchedSerp.params,
		isValid: isFormValidSelector(state),
		src,
		dst,
		isSrcValid: isSrcValidSelector(state),
		isDstValid: isDstValidSelector(state),
		srcSuggestions: filteredSrcSuggestionsSelector(state, src),
		dstSuggestions: filteredDstSuggestionsSelector(state, dst),
		outboundDate: outboundDateSelector(state),
		inboundDate: inboundDateSelector(state),
		isOutboundDateValid: isOutboundDateValidSelector(state),
		isInboundDateValid: isInboundDateValidSelector(state),
		adultsValue: adultsSelector(state),
		childrenValue: childrenSelector(state),
		infantsValue: infantsSelector(state),
		isSearchButtonEnabled: isSearchButtonEnabledSelector(state),
		areDatesBlinking: isSearchbarHighlightedSelector(state),
	}
}

const mapDispatchToProps = (dispatch, { basename, queryString }) => ({
	onDatesBlinkingAnimationEnd: () => dispatch(setIsSearchbarHighlighted(false)),
	onSrcChange: (value) => (
		dispatch(setFormFieldValue({
			formName: SEARCH_BAR_FORM.NAME,
			fieldName: SEARCH_BAR_FORM.FIELDS.SRC,
			value,
		}))
	),
	onDstChange: (value) => (
		dispatch(setFormFieldValue({
			formName: SEARCH_BAR_FORM.NAME,
			fieldName: SEARCH_BAR_FORM.FIELDS.DST,
			value,
		}))
	),
	onOutboundDateChange: (value) => (
		dispatch(setFormFieldValue({
			formName: SEARCH_BAR_FORM.NAME,
			fieldName: SEARCH_BAR_FORM.FIELDS.OUTBOUND_DATE,
			value,
		}))
	),
	onInboundDateChange: (value) => (
		dispatch(setFormFieldValue({
			formName: SEARCH_BAR_FORM.NAME,
			fieldName: SEARCH_BAR_FORM.FIELDS.INBOUND_DATE,
			value,
		}))
	),
	onAdultsChange: (value) => (
		dispatch(setFormFieldValue({
			formName: SEARCH_BAR_FORM.NAME,
			fieldName: SEARCH_BAR_FORM.FIELDS.ADULTS,
			value,
		}))
	),
	onChildrenChange: (value) => (
		dispatch(setFormFieldValue({
			formName: SEARCH_BAR_FORM.NAME,
			fieldName: SEARCH_BAR_FORM.FIELDS.CHILDREN,
			value,
		}))
	),
	onInfantsChange: (value) => (
		dispatch(setFormFieldValue({
			formName: SEARCH_BAR_FORM.NAME,
			fieldName: SEARCH_BAR_FORM.FIELDS.INFANTS,
			value,
		}))
	),
	runSearch: (params) => {
		dispatch(runSearch(params))
	},
	pushToSerp: (params) => {
		if (basename) window.location = `${basename}${toSerp(params)}${queryString}`
		else dispatch(push(toSerp(params)))
	},
	searchClick: (...args) => dispatch(searchClick(...args)),
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	const {
		isSerp,
		paramsFromLocation,
		adultsValue,
		childrenValue,
		infantsValue,
		...restState
	} = stateProps
	const {
		pushToSerp,
		runSearch,
		searchClick,
		onAdultsChange,
		onChildrenChange,
		onInfantsChange,
		...restDispatch
	} = dispatchProps
	const {
		onSearchClick,
		...restOwnProps
	} = ownProps
	return {
		...restState,
		...restDispatch,
		...restOwnProps,
		search: () => {
			const params = {
				src: restState.src,
				dst: restState.dst,
				outboundDate: restState.outboundDate,
				inboundDate: restState.inboundDate,
				adults: adultsValue,
				children: childrenValue,
				infants: infantsValue,
			}
			searchClick(params)
			const isSameParams = isSerp && deepEqual(params, getSearchParams(paramsFromLocation))
			const action = (isSerp && isSameParams) ? runSearch : pushToSerp
			action(params)
			onSearchClick && onSearchClick()
		},
		adults: {
			value: adultsValue,
			onValueChange: onAdultsChange,
		},
		children: {
			value: childrenValue,
			onValueChange: onChildrenChange,
		},
		infants: {
			value: infantsValue,
			onValueChange: onInfantsChange,
		},
	}
}

const connector = connect(mapStateToProps, mapDispatchToProps, mergeProps)

export default connector(enhancer(SearchBar))
