import scrollIntoView from 'scroll-into-view'
import { delay, call, put, takeEvery } from 'redux-saga/effects'

import { GO_TO_INVALID_FORM_FIELD } from '@store/state/types'

import { focusElement } from '@store/state/appState/actions'
import { createFormFieldFocusKeyGetter } from '@store/focusMiddleware'


export const DELAY_BEFORE_FOCUS_MS = 600
// TODO: test this
export function* goToInvalidFormFieldWorker ({ payload }) {
	const { formName, formIndex, fieldName } = payload

	const getFieldKey = yield call(createFormFieldFocusKeyGetter, formName)
	const fieldKey = yield call(getFieldKey, fieldName, formIndex)

	const node = yield call([ document, 'querySelector' ], `[data-focus="${fieldKey}"]`)
	yield call(scrollIntoView, node)
	yield delay(DELAY_BEFORE_FOCUS_MS)
	yield put(focusElement(fieldKey))
}

export default function* goToInvalidFormFieldWatcher () {
	yield takeEvery(GO_TO_INVALID_FORM_FIELD, goToInvalidFormFieldWorker)
}
