import { PureComponent, createElement } from 'react'
import { findDOMNode } from 'react-dom'
import matchQueries from 'container-query-toolkit/lib/matchQueries'
import ContainerQueryCore from './ContainerQueryCore'


export function applyContainerQuery (Component, query, initialSize) {
	return class ContainerQuery extends PureComponent {

		cqCore = null
		domNode = null
		initialOpacity = 1

		state = {
			params: initialSize ? matchQueries(query)(initialSize) : {},
		}

		componentDidMount () {
			this.domNode = findDOMNode(this)

			if (this.domNode) {
				this.initialOpacity = this.domNode.style.opacity
				this.domNode.style.opacity = 0
			}
			this._tryObserving()
		}

		componentDidUpdate () {
			const prevDomNode = this.domNode
			this.domNode = findDOMNode(this)
			if (prevDomNode !== this.domNode) this._tryObserving()
		}

		_tryObserving () {
			if (this.domNode) {
				this.cqCore = new ContainerQueryCore(this.domNode, query, (params, size) => {
					const opacity = parseInt(this.domNode.style.opacity, 10)
					if (size.width && !opacity) {
						setTimeout(() => {
							if (this.domNode) {
								this.domNode.style.opacity = this.initialOpacity
							}
							this.setState({ params })
						})
					}
				})

				this.cqCore.observe(this.domNode)
			}
		}

		componentWillUnmount () {
			this.cqCore && this.domNode && this.cqCore.unobserve(this.domNode)
			this.cqCore = null
			this.domNode = null
		}

		render () {
			const props = {
				...this.props,
				containerQuery: this.state.params,
			}

			return createElement(Component, props)
		}
	}
}
