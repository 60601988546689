import superagent from 'superagent'
import superagentUse from 'superagent-use'
import superagentRetryDelay from 'superagent-retry-delay'

// superagent doesn't have an option for infinite retries,
// so we'll use ridiculously large number of retries
const MAX_RETRIES = 9999
const RETRY_DELAY = 5000

const superagentWithRetries = (maxRetries = MAX_RETRIES, delay = RETRY_DELAY) => {
	const agent = superagentUse(superagentRetryDelay(superagent))
	agent.use((request) => (request.retry(maxRetries, delay)))
	return agent
}

export default superagentWithRetries
