import { get, last, head } from 'lodash'
import { getTotalTime } from '@utils/flight-calc'
import l from '@libs/lang'

import getItems from './getItems.js'


export default function (flight, direction, faresIdsSet, geoData) {
	const { segments } = flight
	const src = head(segments).depart
	const dst = last(segments).arrive
	const srcCityMetro = src.airport.metro
	const dstCityMetro = dst.airport.metro

	return {
		src: get(geoData, `cities.${srcCityMetro}.morpho.${l.parentLocale}.nom`, src.city),
		dst: get(geoData, `cities.${dstCityMetro}.morpho.${l.parentLocale}.nom`, dst.city),
		direction,
		travelTime: getTotalTime(segments) / 60000, // in minutes
		items: getItems(segments, direction, faresIdsSet, geoData),
	}
}
