import { combineReducers } from 'redux'
import {
	CUSTOMER_FORM,
	PASSENGERS_FORM,
	CREDIT_CARD_FORM,
	SEARCH_BAR_FORM,
	LOGIN_FORM,
	FEEDBACK_FORM,
	RESET_FORM,
	SEARCH_TRANSFER_FORM,
	ARRIVAL_SMS_FORM,
	FLIGHT_CHECK_IN_FORM,
} from '../types'
import createSingleFormReducer from './singleFormReducerCreator.js'
import createMultiFormReducer from './multiFormReducerCreator.js'


const customerFormReducer = createSingleFormReducer(
	CUSTOMER_FORM.NAME,
	{
		[CUSTOMER_FORM.FIELDS.EMAIL]: '',
		[CUSTOMER_FORM.FIELDS.PHONE]: '',
	}
)

const arrivalSmsFormReducer = createSingleFormReducer(
	ARRIVAL_SMS_FORM.NAME,
	{
		[ARRIVAL_SMS_FORM.FIELDS.PHONE]: '',
	}
)

const flightCheckInFormReducer = createSingleFormReducer(
	FLIGHT_CHECK_IN_FORM.NAME,
	{
		[FLIGHT_CHECK_IN_FORM.FIELDS.ROW]: null,
		[FLIGHT_CHECK_IN_FORM.FIELDS.SEAT]: null,
	}
)

const passengersFormsReducer = createMultiFormReducer(
	PASSENGERS_FORM.NAME,
	{
		[PASSENGERS_FORM.FIELDS.LAST_NAME]: '',
		[PASSENGERS_FORM.FIELDS.FIRST_NAME]: '',
		[PASSENGERS_FORM.FIELDS.MIDDLE_NAME]: '',
		[PASSENGERS_FORM.FIELDS.GENDER]: '',
		[PASSENGERS_FORM.FIELDS.BIRTHDATE]: '',
		[PASSENGERS_FORM.FIELDS.CITIZENSHIP]: '',
		[PASSENGERS_FORM.FIELDS.DOCUMENT_NUM]: '',
		[PASSENGERS_FORM.FIELDS.DOCUMENT_EXP_DATE]: '',
	}
)

const creditCardFormReducer = createSingleFormReducer(
	CREDIT_CARD_FORM.NAME,
	{
		[CREDIT_CARD_FORM.FIELDS.PAN]: '',
		[CREDIT_CARD_FORM.FIELDS.EXP_DATE]: '',
		[CREDIT_CARD_FORM.FIELDS.CVV]: '',
		[CREDIT_CARD_FORM.FIELDS.CARD_HOLDER]: '',
		[CREDIT_CARD_FORM.FIELDS.CARD_ID]: null,
		[CREDIT_CARD_FORM.FIELDS.SHOULD_STORE_CARD]: false,
	}
)

const feedbackFormReducer = createSingleFormReducer(
	FEEDBACK_FORM.NAME,
	{
		[FEEDBACK_FORM.FIELDS.EMAIL]: '',
		[FEEDBACK_FORM.FIELDS.COMMENT]: '',
		[FEEDBACK_FORM.FIELDS.NAME]: '',
		[FEEDBACK_FORM.FIELDS.SUBJECT]: '',
		[FEEDBACK_FORM.FIELDS.ORDER_NUMBER]: '',
	}
)

const searchBarFormReducer = createSingleFormReducer(
	SEARCH_BAR_FORM.NAME,
	{
		[SEARCH_BAR_FORM.FIELDS.SRC]: '',
		[SEARCH_BAR_FORM.FIELDS.DST]: '',
		[SEARCH_BAR_FORM.FIELDS.OUTBOUND_DATE]: '',
		[SEARCH_BAR_FORM.FIELDS.INBOUND_DATE]: '',
		[SEARCH_BAR_FORM.FIELDS.ADULTS]: 1,
		[SEARCH_BAR_FORM.FIELDS.CHILDREN]: 0,
		[SEARCH_BAR_FORM.FIELDS.INFANTS]: 0,
	}
)

const loginFormReducer = createSingleFormReducer(
	LOGIN_FORM.NAME,
	{
		[LOGIN_FORM.FIELDS.LOGIN]: '',
		[LOGIN_FORM.FIELDS.PASSWORD]: '',
	}
)

const resetFormReducer = createSingleFormReducer(
	RESET_FORM.NAME,
	{
		[RESET_FORM.FIELDS.PASSWORD]: '',
		[RESET_FORM.FIELDS.PASSWORD_CONFIRM]: '',
	}
)

const searchTransferFormReducer = createSingleFormReducer(
	SEARCH_TRANSFER_FORM.NAME,
	{
		[SEARCH_TRANSFER_FORM.FIELDS.ARRIVE_DST]: '',
		[SEARCH_TRANSFER_FORM.FIELDS.DEPART_SRC]: '',
		[SEARCH_TRANSFER_FORM.FIELDS.DIRECTION]: null,
	},
)

export default combineReducers({
	[CUSTOMER_FORM.NAME]: customerFormReducer,
	[ARRIVAL_SMS_FORM.NAME]: arrivalSmsFormReducer,
	[FLIGHT_CHECK_IN_FORM.NAME]: flightCheckInFormReducer,
	[PASSENGERS_FORM.NAME]: passengersFormsReducer,
	[CREDIT_CARD_FORM.NAME]: creditCardFormReducer,
	[FEEDBACK_FORM.NAME]: feedbackFormReducer,
	[SEARCH_BAR_FORM.NAME]: searchBarFormReducer,
	[LOGIN_FORM.NAME]: loginFormReducer,
	[RESET_FORM.NAME]: resetFormReducer,
	[SEARCH_TRANSFER_FORM.NAME]: searchTransferFormReducer,
})
