import request from 'superagent'
import confy from '@utils/confy'
import getResponseHandler from './responseHandler.js'
import { OPERATION_STATUSES } from './statuses.js'


const ERROR_STATUSES = [
	OPERATION_STATUSES.UNAVAIL,
	OPERATION_STATUSES.VALIDATION_ERROR,
]
const OK_STATUSES = [
	OPERATION_STATUSES.AVAIL,
]

export default function ({ channel, enabledCounts, products, routeInfo }) {
	const url = confy.get('API.cl.createOffer')
	const data = {
		channel,
		'enabled_counts': enabledCounts,
		products,
		'route_info': routeInfo,
	}

	const req = request
		.post(url)
		.set('Content-Type', 'application/json')
		.send(data)

	const executor = (resolve, reject) => {
		const handler = getResponseHandler({
			resolve,
			reject,
			okStatuses: OK_STATUSES,
			errStatuses: ERROR_STATUSES,
			requestType: 'POST /offer/new',
		})

		req.end(handler)
	}

	return {
		promise: new Promise(executor),
		cancelFn: () => req.abort(),
	}
}
