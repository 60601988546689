import request from 'superagent'
import confy from '@utils/confy'
import getResponseHandler from './responseHandler.js'
import { OPERATION_STATUSES } from './statuses.js'


const ERROR_STATUSES = [
	OPERATION_STATUSES.UNAVAIL,
	OPERATION_STATUSES.VALIDATION_ERROR,
]
const OK_STATUSES = [
	OPERATION_STATUSES.AVAIL,
]

export default function ({ id, frontUrl, channel, ...props }) {
	const {
		consist = {},
		serviceType,
		subType,
		isEnabled = false,
	} = props

	const url = `${confy.get('API.cl.offer')}/${id}/extend`
	const queryObject = {
		front_url: frontUrl, // eslint-disable-line camelcase
		channel,
		service_type: serviceType, // eslint-disable-line camelcase
		[`${serviceType}_subtypes`]: subType,
		enabled: isEnabled ? 1 : 0,
		...consist,
	}

	const req = request
		.get(url)
		.query(queryObject)

	const executor = (resolve, reject) => {
		const handler = getResponseHandler({
			resolve,
			reject,
			okStatuses: OK_STATUSES,
			errStatuses: ERROR_STATUSES,
			requestType: 'GET /offer/extend',
		})

		req.end(handler)
	}

	return {
		promise: new Promise(executor),
		cancelFn: () => req.abort(),
	}
}
