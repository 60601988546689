import { find, mapValues } from 'lodash'
import fastDeepEqual from 'fast-deep-equal'
import { getOffers } from '@store/state/domainData/selectors'
import { MIN_FARE_COUNT } from './resWithPseudoOffer.js'


export const offerIdByProductsResult = (offers, fareCounts, products) => {
	const offer = find(offers, (offer) => (
		fastDeepEqual(fareCounts, offer['fare_counts'])
		&& fastDeepEqual(products, offer['products'])
	))
	return offer ? offer.id : null
}

const offerIdByProductsSelector = (state, searchQuery, enabledCounts, products) => {
	const offers = getOffers(state, searchQuery)
	const fareCounts = mapValues(enabledCounts, (count) => ([ MIN_FARE_COUNT, count ]))
	return offerIdByProductsResult(offers, fareCounts, products)
}

export default offerIdByProductsSelector
