import env from '@utils/env'


const tracker = {
	track: (event, payload) => {
		if (env.isProd) {
			if ('ecommerce' in payload) {
				window.dataLayer && window.dataLayer.push({
					event,
					ecommerce: payload['ecommerce'],
				})
			}
			else {
				window.dataLayer && window.dataLayer.push({
					event,
					payload,
				})
			}
		}
		else {
			console.log(`analytic event ${event} with payload`, payload) // eslint-disable-line no-console
		}
	},
}
export default tracker
