import { get, flow } from 'lodash'
import l from '@libs/lang'
import { getDate } from '@utils/time-utils'
import { getQueryFromString } from '@utils/common/searchQuery.js'
import getHumanizedConsist from '@utils/common/getHumanizedConsist.js'
import {
	currentSearchQuerySelector,
	currentOfferIdSelector,
	citiesSelector,
	byCurrentOfferId,
} from '@store/state/appState/selectors'
import {
	flightFaresFromOfferSelector,
	flightConsistFromOfferSelector,
	faresTotalPriceResult,
} from '@store/state/domainData/selectors'
import paymentDetailsSelector from '@molecules/CaPaymentDetails/selectors/paymentDetails.js'


export const txtRouteSelector = (state) => {
	const currentSearchQuery = currentSearchQuerySelector(state)
	if (!currentSearchQuery) return null

	const { dst } = getQueryFromString(currentSearchQuery)
	const cities = citiesSelector(state)
	const morpho = get(cities, `${dst}.morpho.ru`, null)
	if (!morpho) return null

	const txtRoute = l('Путешествие {prep} {city}', {
		prep: morpho.prep.in,
		city: morpho.acc,
	})

	return txtRoute
}

export const txtRouteDatesSelector = (state) => {
	const currentSearchQuery = currentSearchQuerySelector(state)
	if (!currentSearchQuery) return null

	const {
		outboundDate: outboundDateIso,
		inboundDate: inboundDateIso,
	} = getQueryFromString(currentSearchQuery)
	const outboundDate = getDate(outboundDateIso)
	const inboundDate = getDate(inboundDateIso)
	const txtOutboundDateMonth = l.date(outboundDate, 'longMonth')
	const txtOutboundDay = l.date(outboundDate, 'shortWeekday')
	const txtOutboundDate = `${txtOutboundDateMonth}, ${txtOutboundDay}`
	let txtInboundDate = ''
	if (inboundDateIso) {
		const txtInboundDateMonth = l.date(inboundDate, 'longMonth')
		const txtInboundDay = l.date(inboundDate, 'shortWeekday')
		txtInboundDate = ` - ${txtInboundDateMonth}, ${txtInboundDay}`
	}
	return `${txtOutboundDate}${txtInboundDate}`
}

export const ticketsPriceSelector = flow(
	byCurrentOfferId(flightFaresFromOfferSelector),
	faresTotalPriceResult
)

export const totalPriceSelector = (state) => {
	const ticketsPrice = ticketsPriceSelector(state)
	if (!ticketsPrice) return null

	const details = paymentDetailsSelector(state)
	const { price } = details.find(({ type }) => type === 'total')
	return price
}

const getDetailItemPrice = ({ amount, price }) => (
	amount * price
)
const byDetailItemType = (acc, { type, amount, price }) => ({
	...acc,
	type,
	amount: acc.amount + amount,
	price: acc.price + getDetailItemPrice({ amount, price }),
})
const getDetailsText = (details, consist) => {
	const flightDetails = details.find(({ type }) => type === 'flightTicket')
	const smsDetails = details.find(({ type }) => type === 'smsInfo')
	const flightItem = {
		title: l('Авиабилеты'),
		items: [ {
			text: getHumanizedConsist(consist),
			price: getDetailItemPrice(flightDetails),
		}, smsDetails && {
			text: l('SMS уведомление об изменении времени вылета рейса'),
			price: getDetailItemPrice(smsDetails),
		} ].filter(Boolean),
	}
	const customInsDetails = details
		.filter(({ type }) => type === 'customPackageInsurance')
		.reduce(byDetailItemType, { amount: 0, price: 0 })
	const travelInsDetails = details
		.filter(({ type }) => type === 'travelInsurance')
		.reduce(byDetailItemType, { amount: 0, price: 0 })
	const insItem = {
		title: l('Страховка'),
		items: [ customInsDetails.amount && {
			text: l('Тройная защита x{amount}', {
				amount: customInsDetails.amount,
			}),
			price: customInsDetails.price,
		}, travelInsDetails.amount && {
			text: l('Медицинская страховка x{amount}', {
				amount: travelInsDetails.amount,
			}),
			price: travelInsDetails.price,
		} ].filter(Boolean),
	}

	return [
		flightItem,
		insItem.items.length > 0 && insItem,
	].filter(Boolean)
}

export const detailsSelector = (state) => {
	const currentOfferId = currentOfferIdSelector(state)
	if (!currentOfferId) return []

	const details = paymentDetailsSelector(state)
	const consist = byCurrentOfferId(flightConsistFromOfferSelector)(state)
	return getDetailsText(details, consist)
}
