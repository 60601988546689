import { compose } from 'recompose'

import { injectCQ } from './comp-decorators.js'


export const createEnhancer = (options = {}) => {
	const { cq } = options
	const decorators = []

	if (cq) decorators.push(injectCQ(cq))

	return compose(...decorators)
}

