import { get } from 'lodash'
import { call, put } from 'redux-saga/effects'

import feofan from '@libs/feofan'
import info from '@utils/info'
import { OPERATION_STATUSES } from '@libs/foma/types'

import { makeCancellable, withError } from '../utils'


export const cancellableGetOrder = makeCancellable(feofan.getOrder)

export default function* handleGetOrder (evtChannel, meta) {

	const { orderId } = meta

	const optsGetOrder = {
		orderId,
	}

	try {
		const resData = yield call(cancellableGetOrder, optsGetOrder)
		yield put(evtChannel, resData)

		return resData.status
	}
	catch (err) {
		info('cant get order', err)
		const status = get(err, 'data.status', OPERATION_STATUSES.ERROR)
		yield put(evtChannel, withError({ ...err.data, status }))

		return status
	}
}
