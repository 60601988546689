import jsonStringify from 'json-stable-stringify'


export const parse = (pseudoOfferId) => {
	try {
		const { enabledCounts, products, routeInfo } = JSON.parse(atob(pseudoOfferId))
		return { enabledCounts, products, routeInfo }
	}
	catch (e) {
		return null
	}
}

export const isPseudo = (offerId) => (parse(offerId) !== null)

export const stringify = (payload) => btoa(jsonStringify(payload))
