import l from '@libs/lang'
import $ from './styles/index.js'

import icoLogout from './images/icon-exit.svg'


const AccountMenu = (props) => {
	const { email, items, onLogoutClick } = props

	const txtLogout = l('Выход')

	const logout = (
		<div className={$.logout} onClick={onLogoutClick}>
			<span className={$.logoutText}>{txtLogout}</span>
			<img src={icoLogout}/>
		</div>
	)

	const toMenuItem = (item, key) => (
		<span key={key} className={$.menuItem} onClick={item.onClick}>
			{item.value}
		</span>
	)

	const menuList = items.map(toMenuItem)

	return (
		<div className={$.container}>
			<span className={$.email}>{email}</span>
			<div className={$.list}>{menuList}</div>
			<div className={$.footer}>{logout}</div>
		</div>
	)
}

export default AccountMenu
