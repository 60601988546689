import {
	isConsistMismatchSelector,
	isInvalidThaiTravelSelector,
	isInvalidMexicaTravelSelector,
} from '@store/state/selectors'
import { orderDataSelector } from './orderData.js'


const isOrderFormValidSelector = (state) => (
	!isConsistMismatchSelector(state)
		&& !isInvalidThaiTravelSelector(state)
		&& !isInvalidMexicaTravelSelector(state)
		&& Boolean(orderDataSelector(state))
)

export default isOrderFormValidSelector
