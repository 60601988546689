import { select, call, put, spawn } from 'redux-saga/effects'
import { batchActions } from 'redux-batched-actions'
import loadData from '@store/sagas/apiService'
import { LOAD_TYPE, OPERATION_STATUSES } from '@libs/foma/types'
import {
	currentOfferIdSelector,
	currentSearchQuerySelector,
	byCurrentOfferId,
	byCurrentSearchQuery,
} from '@store/state/appState/selectors'
import {
	setCurrentSearchQuery,
	setCurrentOfferId,
	setChangeConsistStatus,
} from '@store/state/appState/actions'
import { addForm, removeForm } from '@store/state/uiState/forms/actions'
import { PASSENGERS_FORM } from '@store/state/uiState/forms/types'
import isOnlyFlightEnabledOfferSelector from './selectors/isOnlyFlightEnabledOffer.js'
import offerIdByConsistSelector, { getNewQuery } from './selectors/offerIdByConsist.js'
import getOnlyFlightEnabledOffer from './helpers/getOnlyFlightEnabledOffer.js'


export const enhancedIsOnlyFlightSelector = byCurrentOfferId(isOnlyFlightEnabledOfferSelector)
export const enhancedOfferIdByConsistSelector = byCurrentSearchQuery(offerIdByConsistSelector)

export default function* handleChangeConsist ({ payload }) {
	const { consist: newConsist, isAdd, formIndex } = payload
	const currentOfferId = yield select(currentOfferIdSelector)
	const isOnlyFlightEnabledOffer = yield select(enhancedIsOnlyFlightSelector)
	let offerId = isOnlyFlightEnabledOffer
		? currentOfferId
		: yield call(getOnlyFlightEnabledOffer, currentOfferId)
	const newOfferId = yield select(enhancedOfferIdByConsistSelector, offerId, newConsist)
	let opStatus = OPERATION_STATUSES.AVAIL
	if (newOfferId) {
		const searchQuery = yield select(currentSearchQuerySelector)
		const newQuery = yield call(getNewQuery, searchQuery, newConsist)
		const actions = yield call(batchActions, [
			setCurrentSearchQuery(newQuery),
			setCurrentOfferId(newOfferId),
		])
		yield put(actions)
	}
	else {
		const loadOptions = {
			type: LOAD_TYPE.REBUILD_OFFER,
			updateSearchQuery: true,
			meta: { consist: newConsist, offerId },
		}
		opStatus = yield call(loadData, loadOptions)
	}
	yield put(setChangeConsistStatus(opStatus))

	if (opStatus === OPERATION_STATUSES.AVAIL) {
		// check offer in background process
		const newCurrentOfferId = yield select(currentOfferIdSelector)
		const optsCheckOffer = {
			type: LOAD_TYPE.CHECK_OFFER,
			meta: {
				offerId: newCurrentOfferId,
			},
		}
		yield spawn(loadData, optsCheckOffer)

		const action = isAdd ? addForm : removeForm
		yield put(action(PASSENGERS_FORM.NAME, formIndex))
	}
}
