import { select, call } from 'redux-saga/effects'
import loadData from '@store/sagas/apiService'
import { LOAD_TYPE } from '@libs/foma/types'
import { byCurrentOfferId } from '@store/state/appState/selectors'

import offerIdByFaresSelector from '../selectors/offerIdByFares.js'
import flightEnabledCountsSelector from '../selectors/flightEnabledCounts.js'


export const enhancedFlightEnabledCountsSelector = byCurrentOfferId(flightEnabledCountsSelector)
export const enhancedOfferIdByFaresSelector = byCurrentOfferId(offerIdByFaresSelector)

export default function* getOnlyFlightEnabledOffer (currentOfferId) {
	// TODO: handle offer with accommodation
	const enabledCounts = yield select(enhancedFlightEnabledCountsSelector)
	let newOfferId = yield select(enhancedOfferIdByFaresSelector, enabledCounts)
	if (!newOfferId) {
		const loadOptions = {
			type: LOAD_TYPE.RECONFIGURE_OFFER,
			withoutChangingCurrentOffer: true,
			meta: { offerId: currentOfferId, enabledCounts },
		}
		yield call(loadData, loadOptions)
		newOfferId = yield select(enhancedOfferIdByFaresSelector, enabledCounts)
	}
	return newOfferId || currentOfferId
}
