import {
	FETCH_CALENDAR_PRICES,
	FETCH_TOP_PRICES_RESOLVE,
	FETCH_PRICES_BY_MONTH_RESOLVE,
	FETCH_PRICES_BY_DAY_RESOLVE,
} from '@store/state/types'


export const fetchCalendarPrices = ({ stayLength, startDate, count }) => ({
	type: FETCH_CALENDAR_PRICES,
	payload: {
		startDate,
		stayLength,
		count,
	},
})

export const makeFetchResolveActionCreator = (type) => (prices, src, dst, stayLength) => ({
	type,
	payload: {
		prices,
		src,
		dst,
		stayLength,
	},
})

export const fetchTopPricesResolve = makeFetchResolveActionCreator(FETCH_TOP_PRICES_RESOLVE)
export const fetchPricesByMonthResolve = makeFetchResolveActionCreator(FETCH_PRICES_BY_MONTH_RESOLVE)
export const fetchPricesByDayResolve = makeFetchResolveActionCreator(FETCH_PRICES_BY_DAY_RESOLVE)
