import { CANCEL } from 'redux-saga'


export const getFrontUrl = () => encodeURIComponent(window.location.href)

export const withError = (data = {}) => ({
	...data,
	__hasError: true,
})

// handler() must return { promise, cancelFn } object
export const makeCancellable = (handler) => (...args) => {
	const { promise, cancelFn } = handler(...args)
	promise[CANCEL] = cancelFn

	return promise
}
