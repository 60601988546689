import request from 'superagent'
import confy from '@utils/confy'
import getResponseHandler from './responseHandler.js'
import { OPERATION_STATUSES } from './statuses.js'


const ERROR_STATUSES = [
	OPERATION_STATUSES.ERROR,
]
const OK_STATUSES = [
	OPERATION_STATUSES.SUCCESS,
]

export default function ({ orderId, email, phone, personalData }) {
	const url = `${confy.get('API.cl.savePassengers')}`.replace(':orderId', orderId)

	const req = request
		.post(url)
		.send({
			email,
			phone,
			'personal_data': personalData,
		})

	const executor = (resolve, reject) => {
		const handler = getResponseHandler({
			resolve,
			reject,
			omitData: true,
			okStatuses: OK_STATUSES,
			errStatuses: ERROR_STATUSES,
			requestType: 'POST /savePassengers',
		})

		req.end(handler)
	}

	return {
		promise: new Promise(executor),
		cancelFn: () => req.abort(),
	}
}
