import { geoRequest as request } from '@utils/request'
import confy from '@utils/confy'
import store from 'store'
import expirePlugin from 'store/plugins/expire'


store.addPlugin(expirePlugin)

const getStoreKey = (term) => (`LOCATIONS_BY_TERM_TERM/${term}`)

const DEFAULT_DURATION = 14 * 24 * 60 * 60 * 1000 // TODO: fine-tune this
const getExpireTime = (duration = DEFAULT_DURATION) => (
	new Date().getTime() + duration
)

const getCache = (term) => (
	store.get(getStoreKey(term))
)
const setCache = (term, content) => {
	store.set(getStoreKey(term), content, getExpireTime())
}

const lookupByIp = () => new Promise((resolve, reject) => {

	const url = confy.get('API.geo.lookupByIp')

	request
		.get(url)
		.end((err, res) => {
			if (err) {
				reject(err)
			}
			if (res && res.body && res.body.length) {
				resolve(res.body[0])
			}
			reject()
		})

})
const fetchSuggestions = (term) => new Promise((resolve, reject) => {

	const fromCache = getCache(term)

	if (fromCache) {
		resolve(fromCache)
		return
	}

	const url = confy.get('API.geo.locations')

	request
		.get(url)
		.query({ term })
		.end((err, res) => {
			if (err) {
				reject(err)
				return
			}

			if (res && res.body) {
				setCache(term, res.body)
				resolve(res.body)
			}
		})
})

export default {
	fetchSuggestions,
	lookupByIp,
}
