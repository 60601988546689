import getOffer from './getOffer.js'
import rebuildOffer from './rebuildOffer.js'
import checkOffer from './checkOffer.js'
import getExtendedOffer from './getExtendedOffer.js'
import reconfigureOffer from './reconfigureOffer.js'
import getOrder from './getOrder.js'
import getOrderStatus from './getOrderStatus.js'
import createOrder from './createOrder.js'
import book from './book.js'
import getAccountData from './getAccountData'
import getHotelExt from './getHotelExt.js'
import search from './search.js'
import createOffer from './createOffer.js'
import savePassengers from './savePassengers.js'
import createSbpPayment from './createSbpPayment.js'
import checkSbpPayment from './checkSbpPayment.js'
import createTinkoffPayment from './createTinkoffPayment.js'
import checkTinkoffPayment from './checkTinkoffPayment.js'
import sendEvent from './sendEvent.js'
import * as statuses from './statuses.js'
import { getTopPrices, getPricesByMonth, getPricesByDay } from './getPrices.js'
import OfferError from './offerError.js'

/* Feofan
 * Lib that provides methods for working with offer backend API
 */

export default {
	getOffer,
	rebuildOffer,
	checkOffer,
	getExtendedOffer,
	reconfigureOffer,
	getOrder,
	getOrderStatus,
	createOrder,
	book,
	getAccountData,
	getHotelExt,
	search,
	createOffer,
	getTopPrices,
	getPricesByMonth,
	getPricesByDay,
	sendEvent,
	savePassengers,
	createSbpPayment,
	checkSbpPayment,
	createTinkoffPayment,
	checkTinkoffPayment,
	OfferError,
	...statuses,
}
