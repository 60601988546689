import IntlMessageFormat from 'intl-messageformat'
import { assignIn } from 'lodash'
import bowser from 'bowser'

import customFormat from './customFormat'

// Дичь из файла /intl-messageformat/dist/locale-data/ru.js
// Потому что он не умеет CommonJS
// russian locale
IntlMessageFormat.__addLocaleData({"locale":"ru","pluralRuleFunction":function (n,ord){var s=String(n).split("."),i=s[0],v0=!s[1],i10=i.slice(-1),i100=i.slice(-2);if(ord)return"other";return v0&&i10==1&&i100!=11?"one":v0&&(i10>=2&&i10<=4)&&(i100<12||i100>14)?"few":v0&&i10==0||v0&&(i10>=5&&i10<=9)||v0&&(i100>=11&&i100<=14)?"many":"other"}}); // eslint-disable-line
IntlMessageFormat.__addLocaleData({"locale":"ru-BY","parentLocale":"ru"}); // eslint-disable-line
IntlMessageFormat.__addLocaleData({"locale":"ru-KG","parentLocale":"ru"}); // eslint-disable-line
IntlMessageFormat.__addLocaleData({"locale":"ru-KZ","parentLocale":"ru"}); // eslint-disable-line
IntlMessageFormat.__addLocaleData({"locale":"ru-MD","parentLocale":"ru"}); // eslint-disable-line
IntlMessageFormat.__addLocaleData({"locale":"ru-UA","parentLocale":"ru"}); // eslint-disable-line

// ukraine locale
IntlMessageFormat.__addLocaleData({"locale":"uk","pluralRuleFunction":function (n,ord){var s=String(n).split("."),i=s[0],v0=!s[1],t0=Number(s[0])==n,n10=t0&&s[0].slice(-1),n100=t0&&s[0].slice(-2),i10=i.slice(-1),i100=i.slice(-2);if(ord)return n10==3&&n100!=13?"few":"other";return v0&&i10==1&&i100!=11?"one":v0&&(i10>=2&&i10<=4)&&(i100<12||i100>14)?"few":v0&&i10==0||v0&&(i10>=5&&i10<=9)||v0&&(i100>=11&&i100<=14)?"many":"other"}}); // eslint-disable-line


const lang = 'ru-RU'
const parentLocale = 'ru'
const isWindowsFallback = ((bowser.windows && bowser.osversion < 10) || bowser.windowsphone)

/*===== KOSTYL FOR USERS WHO HAVE NO TIME ZONE CHANGES FOR RUSSIA IN THEIR OS =====*/
const toUTCAndBack = (date) => {
	const args = [
		date.getFullYear(),
		date.getMonth(),
		date.getDate(),
		date.getHours(),
		date.getMinutes(),
		date.getSeconds(),
	]

	return new Date(Date.UTC(...args))
}

const convertDates = (values) => Object.keys(values).reduce((prev, key) => {
	const value = values[key]
	const isDate = (value instanceof Date)

	return {
		...prev,
		[key]: isDate ? toUTCAndBack(value) : value,
	}
}, {})


const l = (message, values, type, format = {}) => {
	if (values) {
		const newFormat = type
			? {
				...customFormat,
				[type]: {
					...customFormat[type],
					...format,
				},
			}
			: customFormat
		const msg = new IntlMessageFormat(message, lang, newFormat)
		return msg.format(convertDates(values))
	}

	return message
}

const select = (values, ...params) => {
	const value = values[lang]
	return (typeof value === 'function') ? value(params) : value
}

const useWindowsSymbol = (val, hasShort) => {
	const msg = new IntlMessageFormat('{val, number, currency}', lang, {
		number: {
			currency: {
				maximumFractionDigits: hasShort ? 0 : 2,
				minimumFractionDigits: hasShort ? 0 : 2,
			},
		},
	})
	const formatValue = msg.format({ val })
	return `${formatValue} руб.`
}

/*===== Posible types: undefined (default), percent ======
percent — is used to format values which are percentages
*/
const number = (val, type) => {

	let msg = type ? `{val, number, ${type}}` : '{val, number}'

	return l(msg, { val })
}


/*===== Posible types: short (default), full ======
short — is used to format currency without fractionals
full — is used to format currency with fractionals
*/
const currency = (val, currency = 'RUB', type = 'short') => {
	const hasShort = type === 'short'
	if (isWindowsFallback && currency === 'RUB') return useWindowsSymbol(val, currency, hasShort)
	const msg = new IntlMessageFormat('{val, number, currency}', lang, {
		number: {
			currency: {
				currency,
				style: 'currency',
				maximumFractionDigits: hasShort ? 0 : 2,
				minimumFractionDigits: hasShort ? 0 : 2,
			},
		},
	})

	return msg.format({ val })
}

/*===== Posible types: short (default), medium, long, full ======
short — is used to format dates in the shortest possible way
medium — is used to format dates with short textual representation of the month
long — is used to format dates with long textual representation of the month
full — is used to format dates with the most detail
*/
const date = (val, type = 'short', format) => (
	l(`{val, date, ${type}}`, { val }, 'date', format)
)

/*===== Posible types: short (default), medium, long, full ======
short — is used to format times with hours and minutes
medium — is used to format times with hours, minutes, and seconds
long — is used to format times with hours, minutes, seconds, and timezone
full — is the same as long
*/
const time = (() => {
	const cache = {}

	return (val, type = 'short') => {
		if (!cache[type]) cache[type] = {}

		const time = val.getTime()
		const cachePoint = cache[type]

		if (!cachePoint[time]) {
			cachePoint[time] = l(`{val, time, ${type}}`, { val })
		}

		return cachePoint[time]
	}
})()

const shortMonth = (val) => {
	let shortedMonth = date(val, 'shortMonth')
	const splittedShortMont = shortedMonth.split(' ')
	if (splittedShortMont[1].length > 3) {
		shortedMonth = `${splittedShortMont[0]} ${splittedShortMont[1].slice(0, 3)}`
	}

	return shortedMonth
}

const phone = (val) => {
	const phoneLenght = val.length
	const countryCode = val.slice(0, 1)
	if (countryCode === '7' && phoneLenght === 11) {
		const providerCode = val.slice(1, 4)
		const number = [ val.slice(4, 7), val.slice(7, 9), val.slice(9, 11) ].join('-')
		return `+${countryCode} ${providerCode} ${number}`
	}

	return val
}


assignIn(l, {
	lang,
	parentLocale,

	select,
	number,
	currency,
	date,
	time,
	shortMonth,
	phone,
})

export default l
