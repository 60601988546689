import { connectRouter } from 'connected-react-router'
import { checkOverlaidPage } from '@utils/router'
import history from '@store/history'
import { LOCATION_CHANGE } from '@store/state/types'


const initialState = {
	location: history.location,
	prevLocation: { pathname: '/' },
}

export default (history) => {
	const routerReducer = connectRouter(history)
	return (state = initialState, action) => {
		switch (action.type) {
			case LOCATION_CHANGE: {
				let nextState = routerReducer(state, action)
				if (nextState !== state) {
					const { location, prevLocation } = nextState
					const realPrevLocation = state.location
					nextState = {
						...nextState,
						prevLocation: checkOverlaidPage(realPrevLocation) || realPrevLocation.key === location.key
							? prevLocation
							: realPrevLocation,
					}
				}
				return nextState
			}
			default: return state
		}
	}
}
