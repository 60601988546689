import {
	travelRegExp,
	nationalRegExp,
	birthCertificateRegExp,
	trashRegExp,
} from '@store/state/uiState/forms/validators/regExps.js'


const getForeignAndUnknownDocSeriesNum = (num) => {
	let series = ''
	let number = ''
	const splittedNum = num.trim().split(' ')
	if (splittedNum.length > 1) {
		series = splittedNum[0]
		number = splittedNum.slice(1).join('')
	}
	else {
		const startLetters = num.match(/^[a-zA-Z]+/)
		if (startLetters) {
			series = startLetters[0]
			number = num.split(/^[a-zA-Z]+/)[1]
		}
		else {
			series = num.slice(0, 2)
			number = num.slice(2)
		}
	}

	return { series, number }
}

const DOC_TYPES_WITH_EXP_DATE = [ 'travel-passport' ]

export const isDocExpDateApplicable = (type) => (DOC_TYPES_WITH_EXP_DATE.includes(type))

export const getDocSeries = (num, type, citizenshipCode) => {
	const numWithoutTrash = num.replace(trashRegExp, '')
	switch (type) {
		case 'travel-passport':
			if (citizenshipCode === 'RUS') {
				let numDigits = numWithoutTrash.match(/\d/g)
				numDigits = numDigits && numDigits.join('')
				return numDigits.match(travelRegExp.series)[0]
			}
			else {
				const { series } = getForeignAndUnknownDocSeriesNum(num)
				return series
			}
		case 'national-passport':
			return numWithoutTrash.match(nationalRegExp.series)[0]
		case 'birth-certificate':
			return numWithoutTrash.match(birthCertificateRegExp.series)[0]
		default:
			const { series } = getForeignAndUnknownDocSeriesNum(num)
			return series
	}
}

export const getDocNumber = (num, type, citizenshipCode) => {
	const numWithoutTrash = num.replace(trashRegExp, '')
	switch (type) {
		case 'travel-passport':
			if (citizenshipCode === 'RUS') {
				let numDigits = numWithoutTrash.match(/\d/g)
				numDigits = numDigits && numDigits.join('')
				return numDigits.match(travelRegExp.number)[0]
			}
			else {
				const { number } = getForeignAndUnknownDocSeriesNum(num)
				return number
			}
		case 'national-passport':
			return numWithoutTrash.match(nationalRegExp.number)[0]
		case 'birth-certificate':
			return numWithoutTrash.match(birthCertificateRegExp.number)[0]
		default:
			const { number } = getForeignAndUnknownDocSeriesNum(num)
			return number
	}
}
