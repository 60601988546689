import {
	SEARCH_CLICK,
	TICKET_DETAILS_CLICK,
	BUY_TICKET_CLICK,
	ADD_UPSALE,
	REMOVE_UPSALE,
	CLICK_ACCEPT_TERMS,
	REDIRECT_TO_PARTNER,
	ON_ELEMENT_CLICK,
	USER_SESSION_DATA_RESOLVED,
	GET_OFFER_RESOLVED_WITH_STATUS,
	SELECT_PAYMENT_METHOD,
} from '@store/state/types'


export const searchClick = (payload) => ({
	type: SEARCH_CLICK,
	payload,
})

export const ticketDetailsClick = (overlaidProduct) => ({
	type: TICKET_DETAILS_CLICK,
	meta: { overlaidProduct },
})
export const buyTicketClick = (isPartner, product) => ({
	type: BUY_TICKET_CLICK,
	meta: { isPartner, product },
})
export const addUpsale = (serviceType, source) => ({
	type: ADD_UPSALE,
	meta: { serviceType, source },
})
export const removeUpsale = (serviceType, source) => ({
	type: REMOVE_UPSALE,
	meta: { serviceType, source },
})
export const clickAcceptTerms = () => ({
	type: CLICK_ACCEPT_TERMS,
})
export const selectPaymentMethod = () => ({
	type: SELECT_PAYMENT_METHOD,
})

export const redirectToPartner = () => ({
	type: REDIRECT_TO_PARTNER,
})

export const onElementClick = (elementKey) => ({
	type: ON_ELEMENT_CLICK,
	meta: { elementKey },
})

export const userSessionDataResolve = (sessionData) => ({
	type: USER_SESSION_DATA_RESOLVED,
	payload: sessionData,
})

export const getOfferResolvedWithStatus = (status) => ({
	type: GET_OFFER_RESOLVED_WITH_STATUS,
	payload: status,
})
