import { flattenDeep, get, head, last } from 'lodash'
import l from '@libs/lang'


const COUNTRY_VISA_WARN_WHITELIST = [ 'RU' ]

const getPointInfo = (point, geoData) => {
	const cityMetro = point.airport.metro
	const cityMorpho = geoData.cities[cityMetro]
	const airportMorpho = geoData.airports[point.airport.iata]
	const airportMorphoIata = airportMorpho && airportMorpho.iata
	const airportMorphoTitle = airportMorpho && airportMorpho.title
	return {
		dateTime: point.time,
		city: get(cityMorpho, `morpho.${l.parentLocale}.nom`, point.city),
		airport: {
			iata: airportMorphoIata || point.airport.iata,
			name: airportMorphoTitle || point.airport.title,
 			country: point.airport.country,
		},
		airportCount: cityMorpho && cityMorpho.airportCount,
	}
}

const getSegmentItem = (segment, prevSegment, nextSegment, direction, ticketGroupId, geoData) => {
	const prevSegDst = prevSegment && prevSegment.arrive.airport.iata
	const nextSegSrc = nextSegment && nextSegment.depart.airport.iata
	const curSegDst = segment.arrive.airport.iata
	const curSegSrc = segment.depart.airport.iata

	const departDate = Date.parse(segment.depart.time.split('T')[0])
	const arriveDate = Date.parse(segment.arrive.time.split('T')[0])
	const prevSegArriveDate = prevSegment && Date.parse(prevSegment.arrive.time.split('T')[0])
	const departure = {
		flightId: `${segment.airline.iata} ${segment.flight}`,
		isChangingAirport: prevSegDst && prevSegDst !== curSegSrc,
		edgeType: !prevSegment ? direction : null,
		isRenderData: !prevSegment || (prevSegArriveDate !== departDate),
		...getPointInfo(segment.depart, geoData),
	}

	const arrival = {
		isChangingAirport: nextSegSrc && nextSegSrc !== curSegDst,
		edgeType: !nextSegment ? direction : null,
		isRenderData: departDate !== arriveDate,
		...getPointInfo(segment.arrive, geoData),
	}

	return {
		isSegment: true,
		props: {
			departure,
			arrival,
			airline: segment.airline,
			durationMins: segment['travel_time'],
			aircraftType: segment.aircraft,
			ticketGroupId,
			baggage: segment.baggage,
			flightType: segment.type,
		},
	}
}

const getTransferItem = (segment, nextSegment, ticketGroupId, srcAndDstCountryCodes) => {
	const arriveTime = new Date(segment.arrive.time)
	const departTime = new Date(nextSegment.depart.time)

	const countryCode = get(segment, 'arrive.airport.country_code')

	return {
		isSegment: false,
		props: {
			// determine if transfer country is same as src of dst
			isThirdCountry: !srcAndDstCountryCodes.includes(countryCode),
			city: segment.arrive.city,
			cityMetro: segment.arrive.airport.metro,
			durationMins: Math.floor((departTime - arriveTime) / 60000),
			isChangingAirport: segment.arrive.airport.iata !== nextSegment.depart.airport.iata,
			baggage: segment.baggage,
			ticketGroupId,
		},
	}
}

export default function (segments, direction, faresIdsSet, geoData) {

	// needed to determine if a transfer (пересадка) will be
	// in the same country as source country or destination country.
	const srcAndDstCountryCodes = [
		get(head(segments), 'depart.airport.country_code'),
		get(last(segments), 'arrive.airport.country_code'),
		...COUNTRY_VISA_WARN_WHITELIST,
	]

	const items = segments.map((segment, i) => {
		const nextSegment = i < segments.length - 1 ? segments[i + 1] : null
		const prevSegment = i > 0 ? segments[i - 1] : null

		let ticketGroupId = null
		let transferTicketGroup = null
		if (faresIdsSet.length > 1) {
			const { fareId } = segment
			const nextSegFareId = nextSegment && nextSegment.fareId
			ticketGroupId = faresIdsSet.indexOf(fareId)
			transferTicketGroup = fareId === nextSegFareId ? ticketGroupId : null
		}

		if (i < segments.length - 1) {
			return [
				getSegmentItem(segment, prevSegment, nextSegment, direction, ticketGroupId, geoData),
				getTransferItem(segment, nextSegment, transferTicketGroup, srcAndDstCountryCodes),
			]
		}
		else {
			return getSegmentItem(segment, prevSegment, nextSegment, direction, ticketGroupId, geoData)
		}
	})

	return flattenDeep(items)
}
