import store from 'store'
import { call, takeEvery, select, put } from 'redux-saga/effects'
import {
	ACCEPT_QUIZ_ANSWER,
} from '@store/state/types'
import { initQuizes } from '@store/state/appState/actions'
import { quizesSelector } from '@store/state/appState/selectors'


export const QUIZES_KEY = '__quizes-ca'

// TODO: should be test, but later, cause it's prototype
function* rehydrateQuizes () {
	const quizes = yield call([ store, 'get' ], QUIZES_KEY)
	yield put(initQuizes(quizes || {}))
}

function* acceptQuizAnswerWatcher () {
	const quizes = yield select(quizesSelector)
	yield call([ store, 'set' ], QUIZES_KEY, quizes)
}

export default function* () {
	yield call(rehydrateQuizes)
	yield takeEvery(ACCEPT_QUIZ_ANSWER, acceptQuizAnswerWatcher)
}
