import {
	isConsistMismatchSelector,
	isInvalidThaiTravelSelector,
	isInvalidMexicaTravelSelector,
} from '@store/state/selectors'
import { orderSbpDataSelector } from './orderData.js'


const isSbpOrderFormValidSelector = (state) => (
	!isConsistMismatchSelector(state)
		&& !isInvalidThaiTravelSelector(state)
		&& !isInvalidMexicaTravelSelector(state)
		&& Boolean(orderSbpDataSelector(state))
)

export default isSbpOrderFormValidSelector
