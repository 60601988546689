import { get, map, values } from 'lodash'
import { createSelector } from 'reselect'
import { getDayTime } from '@utils/flight-calc'
import { byCurrentSearchQuery, byCurrentOfferId } from '@store/state/appState/selectors'
import { getProducts, getFares, flightFaresFromOfferSelector } from '@store/state/domainData/selectors'
import ticketInfoByProductIdSelector from './ticketInfoByProductId.js'


const getStartTime = ({ calculatedFlights }) => (new Date(get(calculatedFlights, '0.transferStart', 0)).getTime())

const MS_IN_MIN = 60 * 1000
const toFilterable = ({ baggageWeight, calculatedFlights }) => {
	const swtchsCount = Math.max(...calculatedFlights.map(({ segments }) => (segments.length - 1)))
	const swtchsDuration = Math.max(...calculatedFlights.map(({ swtchsDuration }) => (swtchsDuration))) / MS_IN_MIN
	const travelTime = Math.max(...calculatedFlights.map(({ totalTime }) => (totalTime))) / MS_IN_MIN
	const hasBaggage = baggageWeight !== 0

	const [ outboundTime, inboundTime ] = calculatedFlights
		.map(({ transferStart, transferEnd }) => ({
			depart: getDayTime(transferStart),
			arrive: getDayTime(transferEnd),
		}))

	return {
		swtchsCount,
		hasBaggage,
		inboundTime,
		outboundTime,
		swtchsDuration,
		travelTime,
	}
}

const byPriceAndStartTime = (a, b) => {
	const pricesDiff = a.price - b.price
	const timesDiff = a.startTime - b.startTime

	if (!pricesDiff && !timesDiff) {
		// can you imagine? there could be tickets with same depart time and price
		return a.filterable.hasBaggage - b.filterable.hasBaggage
	}
	if (!pricesDiff) {
		return timesDiff
	}
	return pricesDiff
}

const toExtendedProduct = (products) => (info, productId) => {
	const [ type, fareIds ] = products[productId]
	return {
		type,
		fareIds,
		filterable: toFilterable(info),
		startTime: getStartTime(info),
		price: info.price,
	}
}

const getKeyFromFares = (fares, keyName) => (fares.map((fare) => fare[keyName]).sort().join())

const searchSelector = createSelector(
	byCurrentSearchQuery(ticketInfoByProductIdSelector),
	byCurrentSearchQuery(getProducts),
	byCurrentSearchQuery(getFares),
	byCurrentOfferId(flightFaresFromOfferSelector),
	(ticketInfoByProductId, products, faresDict, offerFlightFares) => {
		const items = map(
			ticketInfoByProductId,
			toExtendedProduct(products)
		)

		// get currently selected offers flight fares
		const offerFlightFaresArr = values(offerFlightFares)
		// calculate uniqKey and idsKey first
		const selectedUniqKey = getKeyFromFares(offerFlightFaresArr, '_key')
		const selectedIdsKey = getKeyFromFares(offerFlightFaresArr, 'id')

		// this hashmap will contain only uniq items
		// without currently selected one and without items that look like the selected one
		let filteredMap = {}
		// we will store the really selected one here instead
		let selected = null

		// loop over items
		for (let i = 0; i < items.length; i++) {
			const item = items[i]
			const { fareIds } = item
			const fares = fareIds.map((id) => (faresDict[id]))
			// calculate uniqKey and idsKey of each
			const uniqKey = getKeyFromFares(fares, '_key')
			const idsKey = getKeyFromFares(fares, 'id')

			const isSelected = idsKey === selectedIdsKey
			const isLikeSelected = selectedUniqKey === uniqKey
			const isPresent = filteredMap[uniqKey] !== undefined
			const isCurrentCheaper = isPresent && filteredMap[uniqKey].price > item.price

			// if current item is absolutely the same as selected, store it in selected
			// and do not add it to the hashmap, because we will do that later
			if (isSelected) selected = item
			// add deduped items to the hashmap
			else if (isCurrentCheaper || (!isPresent && !isLikeSelected)) {
				filteredMap[uniqKey] = item
			}
		}

		// right now filteredMap contains deduped items without the selected item
		// and items that look like selected

		const filtered = values(filteredMap) // make it an array
		if (selected) filtered.push(selected) // and add the selected one if present

		return filtered.sort(byPriceAndStartTime) // sorting is for the late dinner
	}
)

export default searchSelector
