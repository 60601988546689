import globalEnv from '../switchers/globalEnv.js'

import API from './api.js'
import CONTACTS from './contacts.js'
import REQUISITES from './requisites.js'
import COMMON from './common.js'


export default {
	ENV: globalEnv(),
	API,
	CONTACTS,
	REQUISITES,
	COMMON,
}
