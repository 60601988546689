import { get } from 'lodash'
import { createSelector } from 'reselect'
import { makePropGetter as makeAppStatePropGetter, createPropGetterMaker } from './common'


const getGeoState = makeAppStatePropGetter('geo')
const makePropGetter = createPropGetterMaker(getGeoState)

export const geoSelector = getGeoState
export const fetchedLocationsTermsSelector = makePropGetter('fetchedLocationsTerms')
const defaultSuggestions = []
const suggestionsSelector = makePropGetter('suggestions')
export const makeFilteredSuggestionsSelector = () => (state, value) => {
	if (!value) return defaultSuggestions
	const [ term ] = value.toLowerCase().split(':')
	const suggestions = suggestionsSelector(state)
	return get(suggestions, term, defaultSuggestions)
}
export const airportsSelector = makePropGetter('airports')
export const citiesSelector = makePropGetter('cities')

export const metrosByIdsResult = (cities) => (
	Object.values(cities).reduce((acc, { id, iata }) => ({
		...acc,
		[id]: iata,
	}), {})
)
export const metrosByIdsSelector = createSelector(citiesSelector, metrosByIdsResult)

export const localityStringByMetroResult = (cities) => (
	Object.values(cities).reduce((acc, { id, iata, title, kind = 'locality' }) => ({
		...acc,
		[iata]: `${title}:${kind}:${id}`,
	}), {})
)
export const localityStringByMetroSelector = createSelector(citiesSelector, localityStringByMetroResult)
