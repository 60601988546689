import { get } from 'lodash'
import { createSelector } from 'reselect'
import {
	outboundFlightFromOfferSelector,
	outboundFlightFromProductSelector,
	inboundFlightFromOfferSelector,
	inboundFlightFromProductSelector,
	flightConsistFromOfferSelector,
	flightConsistFromProductSelector,
	airportsFromOfferSelector,
	airportsFromProductSelector,
	flightFaresFromOfferSelector,
	flightFaresFromProductSelector,
	getAirlines,
	serviceByFareIdSelector,
	sortedSegmentsByFareIdSelector,
} from '@store/state/domainData/selectors'
import { byCurrentSearchQuery } from '@store/state/appState/selectors'
import { ternarySelectorCreator } from './helper.js'


export const fareBySegmentIdResult = (fares, segmentsByFareId) => (
	Object.keys(fares).reduce((acc, fareId) => {
		const segments = segmentsByFareId[fareId]

		const fareBySegmentId = segments.reduce((prev, segment) => ({
			...prev,
			[segment.id]: fares[fareId],
		}), {})

		return { ...acc, ...fareBySegmentId }
	}, {})
)

export const fareBySegmentIdSelector = createSelector(
	ternarySelectorCreator(flightFaresFromOfferSelector, flightFaresFromProductSelector),
	byCurrentSearchQuery(sortedSegmentsByFareIdSelector),
	fareBySegmentIdResult
)


const DEFAULT_CHECKED_BAGGAGE_WEIGHT = 20
const DEFAULT_HAND_BAGGAGE_WEIGHT = 5

function shapeBaggage (baggage, kind = 'checked') {
	if (!baggage || !baggage.included) {
		return []
	}
	else {
		const defaultWeight = kind === 'checked' ? DEFAULT_CHECKED_BAGGAGE_WEIGHT : DEFAULT_HAND_BAGGAGE_WEIGHT

		return Array(baggage.included).fill(baggage.weight || defaultWeight)
	}
}

const getShapedBaggage = ({ checked, hand, checked_unavail: checkedUnavail }) => ({
	hasCheckedOnFlight: !checkedUnavail,
	checked: shapeBaggage(checked, 'checked'),
	unchecked: shapeBaggage(hand, 'hand'),
})

const localDate = (date, timeZone) => {
	const localeString = date.toLocaleString('ru-RU', { timeZone, hour12: false })
	const [ day, month, year ] = localeString.split(',')[0].split('.').map((p) => parseInt(p, 10))
	return new Date(year, month - 1, day)
}

const daysToDepart = (segment, airports) => {
	const departTime = new Date(segment.depart.time)
	const departDate = new Date(departTime.getFullYear(), departTime.getMonth(), departTime.getDate())
	const currentDate = localDate(new Date(), airports[segment.depart.airport].timezone)
	const diffTime = departDate - currentDate
	const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24))
	return diffDays >= 0 ? diffDays : 0
}

const segmentHiddenType = (service, segment, fare, airports) => {
	const type = get(service, 'subtype', '').split(':')[1]
	if (type !== 'charter') return type

	try {
		// SU flights from BG if they depart in 5 days are regulars, else are charter, 
		// but we hide it until a user click pay
		if (segment.airline === 'SU' && fare.supplier === 'bg' && daysToDepart(segment, airports) <= 4) {
			return 'regular'
		}
		else {
			return type
		}
	}
	catch (error) {
		return type
	}
}

const segmentType = (service, segment, fare) => {
	const type = get(service, 'subtype', '').split(':')[1]
	if (type !== 'charter') return type

	if (segment.airline === 'SU' && fare.supplier === 'bg') {
		return 'regular'
	}
	else {
		return type
	}
}

const toCustomSegment = (airlines, airports, consist, fareBySegmentId, serviceByFareId) => (segment = {}) => {
	const {
		airline: airlineId,
		arrive = {},
		depart = {},
	} = segment
	const fare = fareBySegmentId[segment.id]
	const service = serviceByFareId[fare.id]

	const baggage = get(fare, 'details.baggage', {})
	const airline = airlines[airlineId] || {}
	const arriveAirport = airports[arrive.airport] || {}
	const departAirport = airports[depart.airport] || {}

	const shapedBaggage = getShapedBaggage(baggage)
	const cabinClass = get(fare, 'details.seats_class', '')

	return {
		...segment,
		fareId: fare.id,
		airline,
		arrive: { ...segment.arrive, airport: arriveAirport },
		depart: { ...segment.depart, airport: departAirport },
		consist,
		baggage: shapedBaggage,
		'cabin_class': cabinClass,
		type: segmentType(service, segment, fare),
		hiddenType: segmentHiddenType(service, segment, fare, airports),
	}
}

const selector = (...args) => {
	const [
		outboundFlight,
		inboundFlight,
		airlines,
		airports,
		consist,
		fareBySegmentId,
		serviceByFareId,
	] = args

	const toCustomArgs = [ airlines, airports, consist, fareBySegmentId, serviceByFareId ]

	const hasInboundFlight = inboundFlight.length > 0

	const customOutboundFlight = {
		segments: outboundFlight.map(toCustomSegment(...toCustomArgs)),
	}

	const flights = [ customOutboundFlight ]

	const customInboundFlight = {
		segments: inboundFlight.map(toCustomSegment(...toCustomArgs)),
	}

	if (hasInboundFlight) flights.push(customInboundFlight)

	return flights
}

export default createSelector(
	ternarySelectorCreator(outboundFlightFromOfferSelector, outboundFlightFromProductSelector),
	ternarySelectorCreator(inboundFlightFromOfferSelector, inboundFlightFromProductSelector),
	byCurrentSearchQuery(getAirlines),
	ternarySelectorCreator(airportsFromOfferSelector, airportsFromProductSelector),
	ternarySelectorCreator(flightConsistFromOfferSelector, flightConsistFromProductSelector),
	fareBySegmentIdSelector,
	byCurrentSearchQuery(serviceByFareIdSelector),
	selector
)

