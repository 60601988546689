import { all, fork } from 'redux-saga/effects'

import searchBarListeners from './searchBar'
import serpListeners from './serp'
import checkoutListeners from './checkout'
import searchProcessListeners from './searchProcess'
import commonListeners from './common'


export default function* acitonListeners (analyticEventChan) {
	yield all([
		fork(searchBarListeners, analyticEventChan),
		fork(serpListeners, analyticEventChan),
		fork(checkoutListeners, analyticEventChan),
		fork(searchProcessListeners, analyticEventChan),
		fork(commonListeners, analyticEventChan),
	])
}
