import {
	SET_ACCOMMODATION_DATES,
	SEARCH_AVAILABLE_ROOMS,
	LOAD_HOTEL_REVIEWS,
	RUN_SEARCH,
} from '@store/state/types'


export const setAccommodationDates = (dates) => ({
	type: SET_ACCOMMODATION_DATES,
	payload: dates,
})

export const searchAvailableRooms = ({ hotelId, checkin, checkout }) => ({
	type: SEARCH_AVAILABLE_ROOMS,
	payload: { hotelId, checkin, checkout },
})

export const loadHotelReviews = (hotelId) => ({
	type: LOAD_HOTEL_REVIEWS,
	payload: hotelId,
})

export const runSearch = (params) => ({
	type: RUN_SEARCH,
	payload: params,
})
