import { pickBy } from 'lodash'
import { createSelector } from 'reselect'
import { PRODUCT_TYPES } from '@libs/foma/types'


const defaultDomainData = { data: {}, meta: {}, timestamp: null }
const domainDataSelector = (state, query) => (
	state.app.domainData[query] || defaultDomainData
)

export const timestampSelector = (state, query) => domainDataSelector(state, query).timestamp
export const metaItemSelector = (state, query, item) => (domainDataSelector(state, query).meta || {})[item]
export const dataItemSelector = (state, query, item) => domainDataSelector(state, query).data[item]


// meta items selectors
const makeMetaItemGetter = (item) => (state, query) => metaItemSelector(state, query, item)
export const getRouteInfo = makeMetaItemGetter('route_info')
export const getScheduledProducts = makeMetaItemGetter('scheduled_products')
export const get3dsRequest = makeMetaItemGetter('3ds_request')

// data items selectors
const makeDataItemsGetter = (item) => (state, query) => dataItemSelector(state, query, item)
export const getActivities = makeDataItemsGetter('activities')
export const getAgreements = makeDataItemsGetter('agreements')
export const getAirlines = makeDataItemsGetter('airlines')
export const getAirports = makeDataItemsGetter('airports')
export const getBrands = makeDataItemsGetter('brands')
export const getFares = makeDataItemsGetter('fares')
export const getHotels = makeDataItemsGetter('hotels')
export const getOffers = makeDataItemsGetter('offers')
export const getOrders = makeDataItemsGetter('orders')
export const getPackages = makeDataItemsGetter('packages')
export const getProducts = makeDataItemsGetter('products')
export const getSegments = makeDataItemsGetter('segments')
export const getServices = makeDataItemsGetter('services')
export const getPassengers = makeDataItemsGetter('passengers')
export const getContacts = makeDataItemsGetter('contacts')

// data item by id selectors
const makeItemByIdSelector = (getter) => createSelector(
	[ getter, (_, __, key) => key ],
	(items = {}, key) => items[key]
)
export const offerByIdSelector = makeItemByIdSelector(getOffers)
export const productByIdSelector = makeItemByIdSelector(getProducts)

export const flightsProductsSelector = createSelector(
	getProducts,
	(products) => pickBy(products, ([ type ]) => (PRODUCT_TYPES.FLIGHTS.includes(type)))
)
