export const SET_GEO_DATA = 'SET_GEO_DATA'
export const SET_DOMAIN_DATA = 'SET_DOMAIN_DATA'
export const SET_TIMESTAMP = 'SET_TIMESTAMP'
export const CLEAR_STORE = 'CLEAR_STORE'
export const CLEAR_ORDER = 'CLEAR_ORDER'

export const SET_CURRENT_QUERY = 'SET_CURRENT_QUERY'
export const SET_CURRENT_OFFER_ID = 'SET_CURRENT_OFFER_ID'
export const ADD_LOADING_EVENT = 'ADD_LOADING_EVENT'
export const REMOVE_LOADING_EVENT = 'REMOVE_LOADING_EVENT'
export const REMOVE_ALL_LOADING_EVENTS = 'REMOVE_ALL_LOADING_EVENTS'
export const ADD_QR_LOADING_EVENT = 'ADD_QR_LOADING_EVENT'
export const REMOVE_QR_LOADING_EVENT = 'REMOVE_QR_LOADING_EVENT'

export const SET_SHOPFRONT_DIALOG = 'SET_SHOPFRONT_DIALOG'
export const RUN_SEARCH = 'RUN_SEARCH'

// ACCOUNT DATA
export const SET_ACCOUNT_DATA_STATUS = 'SET_ACCOUNT_DATA_STATUS'
export const LOAD_ACCOUNT_DATA = 'LOAD_ACCOUNT_DATA'

export const SET_FORM_FIELD_VALUE = 'SET_FORM_FIELD_VALUE'
export const SET_FORM_FIELD_CHECKED = 'SET_FORM_FIELD_CHECKED'
export const CLEAR_FORM = 'CLEAR_FORM'
export const INIT_FORMS_LIST = 'INIT_FORMS_LIST'
export const ADD_FORM = 'ADD_FORM'
export const REMOVE_FORM = 'REMOVE_FORM'
export const PERSIST_FORM = 'PERSIST_FORM'
export const REHYDRATE_FORM = 'REHYDRATE_FORM'

export const SEND_SUPPORT_FEEDBACK = 'SEND_SUPPORT_FEEDBACK'

export const SEND_FEEDBACK = 'SEND_FEEDBACK'
export const SET_SEND_FEEDBACK_STATUS = 'SET_SEND_FEEDBACK_STATUS'
export const FEEDBACK_FORM_PREFILL = 'FEEDBACK_FORM_PREFILL'

export const SET_IS_SEARCHBAR_HIGHLIGHTED = 'SET_IS_SEARCHBAR_HIGHLIGHTED'
export const SEARCH = 'SEARCH'

export const SET_USER_DATA = 'SET_USER_DATA'

export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const SET_LOGIN_STATUS = 'SET_LOGIN_STATUS'
export const RECOVER_PASSWORD = 'RECOVER_PASSWORD'
export const SET_RECOVER_PASSWORD_STATUS = 'SET_RECOVER_PASSWORD_STATUS'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const SET_RESET_PASSWORD_STATUS = 'SET_RESET_PASSWORD_STATUS'

export const FOCUS = 'FOCUS'
export const HANDLE_FOCUS_EVT = 'HANDLE_FOCUS_EVT'
export const HANDLE_BLUR_EVT = 'HANDLE_BLUR_EVT'

export const BOOK = 'BOOK'
export const GENERATE_QR = 'GENERATE_QR'
export const BUY = 'BUY'
export const SHOW_QR = 'SHOW_QR'
export const SHOW_TINKOFF_QR = 'SHOW_TINKOFF_QR'
export const ACTIVATE_SBP_PAYMENT = 'ACTIVATE_SBP_PAYMENT'
export const DEACTIVATE_SBP_PAYMENT = 'DEACTIVATE_SBP_PAYMENT'
export const ACTIVATE_TINKOFF_PAYMENT = 'ACTIVATE_TINKOFF_PAYMENT'
export const DEACTIVATE_TINKOFF_PAYMENT = 'DEACTIVATE_TINKOFF_PAYMENT'
export const SET_BOOK_STATUS = 'SET_BOOK_STATUS'
export const SET_OFFER_OP_STATUS = 'SET_OFFER_OP_STATUS'
export const GO_TO_INVALID_FORM_FIELD = 'GO_TO_INVALID_FORM_FIELD'

export const SET_FARE_VARIANTS = 'SET_FARE_VARIANTS'

export const SET_UPSALE_DIALOG = 'SET_UPSALE_DIALOG'
export const RECONFIGURE_OFFER = 'RECONFIGURE_OFFER'
export const SET_OFFER_RECONFIGURE_STATUS = 'SET_OFFER_RECONFIGURE_STATUS'
export const BUILD_OFFER = 'BUILD_OFFER'
export const CHANGE_CONSIST = 'CHANGE_CONSIST'
export const PRELOAD_CONSIST = 'PRELOAD_CONSIST'
export const SET_CHANGE_CONSIST_STATUS = 'SET_CHANGE_CONSIST_STATUS'

export const FETCH_SUGGESTIONS = 'FETCH_SUGGESTIONS'
export const FETCH_SUGGESTIONS_RESOLVE = 'FETCH_SUGGESTIONS_RESOLVE'

export const SET_ACCOMMODATION_DATES = 'SET_ACCOMMODATION_DATES'
export const SEARCH_AVAILABLE_ROOMS = 'SEARCH_AVAILABLE_ROOMS'
export const LOAD_HOTEL_REVIEWS = 'LOAD_HOTEL_REVIEWS'

export const CLEAR_FILTERS = 'CLEAR_FILTERS'
export const RESET_FILTERS = 'RESET_FILTERS'
export const UPDATE_FILTER = 'UPDATE_FILTER'
export const APPLY_FILTERS = 'APPLY_FILTERS'
export const APPLY_QUERY_FILTERS = 'APPLY_QUERY_FILTERS'
export const SET_PAGE_NUM = 'SET_PAGE_NUM'

export const SET_REDIRECT_TO_CABINET_STATUS = 'SET_REDIRECT_TO_CABINET_STATUS'

export const SET_IS_CC_FORM_MANUAL = 'SET_IS_CC_FORM_MANUAL'
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD'

export const ACCEPT_QUIZ_ANSWER = 'ACCEPT_QUIZ_ANSWER'
export const INIT_QUIZES = 'INIT_QUIZES'

export const ACCEPT_QUIZ_ON_SUCCESS_ANSWER = 'ACCEPT_QUIZ_ON_SUCCESS_ANSWER'
export const INIT_SUCCESS_QUIZES = 'INIT_SUCCESS_QUIZES'

export const SET_PREVIOUS_PRICE = 'SET_PREVIOUS_PRICE'

export { LOCATION_CHANGE } from 'connected-react-router'

export const SET_CHANNEL = 'SET_CHANNEL'

export const SEARCH_CLICK = 'SEARCH_CLICK'

// TODO: fake transfer, should be removed after test
export const SEARCH_TRANSFERS = 'SEARCH_TRANSFERS'
export const SELECT_TRANSFER = 'SELECT_TRANSFER'

export const FETCH_CALENDAR_PRICES = 'FETCH_CALENDAR_PRICES'
export const FETCH_TOP_PRICES_RESOLVE = 'FETCH_TOP_PRICES_RESOLVE'
export const FETCH_PRICES_BY_MONTH_RESOLVE = 'FETCH_PRICES_BY_MONTH_RESOLVE'
export const FETCH_PRICES_BY_DAY_RESOLVE = 'FETCH_PRICES_BY_DAY_RESOLVE'
export const TICKET_DETAILS_CLICK = 'TICKET_DETAILS_CLICK'
export const BUY_TICKET_CLICK = 'BUY_TICKET_CLICK'
export const ADD_UPSALE = 'ADD_UPSALE'
export const REMOVE_UPSALE = 'REMOVE_UPSALE'
export const CLICK_ACCEPT_TERMS = 'CLICK_ACCEPT_TERMS'
export const SELECT_PAYMENT_METHOD = 'SELECT_PAYMENT_METHOD'
export const REDIRECT_TO_PARTNER = 'REDIRECT_TO_PARTNER'
export const ON_ELEMENT_CLICK = 'ON_ELEMENT_CLICK'
export const LOGROCKET_SESSION_URL_RESOLVED = 'LOGROCKET_SESSION_URL_RESOLVED'
export const USER_SESSION_DATA_RESOLVED = 'USER_SESSION_DATA_RESOLVED'
export const GET_OFFER_RESOLVED_WITH_STATUS = 'GET_OFFER_RESOLVED_WITH_STATUS'
