import jsonStringify from 'json-stable-stringify'
import md5 from 'js-md5'


const MD5 = (obj) => md5(jsonStringify(obj))

const getFareKey = (fare = {}) => {
	const { variant_id: variantId, variants = {} } = fare
	const variantedFare = variants[variantId] || fare

	const {
		cancellation_policy: {
			refundable,
		} = {},
		details: {
			baggage: {
				checked: {
					included: checkedIncluded,
					weight: checkedWeight = 0,
				} = {},
				hand: {
					included: handIncluded,
					weight: handWeight = 0,
				} = {},
			} = {},
			consist = [],
			board,
			meal: { included: mealIncluded } = {},
			seats_class: seatsClass,
			ptc,
		} = {},
		payment_schedule: {
			send_cc: sendCc,
			type: paymentType,
		} = {},
		service,
	} = variantedFare

	return [
		refundable,
		checkedIncluded,
		checkedWeight,
		handIncluded,
		handWeight,
		consist,
		board,
		mealIncluded,
		seatsClass,
		ptc,
		sendCc,
		paymentType,
		service,
	].join(':')
}

export default {
	MD5,
	getFareKey,
}
