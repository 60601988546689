import React from 'react'
import PropTypes from 'prop-types'

import { getNextItemSetter } from './utils'


const DATA_KEY = 'key'
export const DATA_ATTR_KEY = `data-${DATA_KEY}`

const createOptionsList = (setterPropsGetters) => (
	class OptionsList extends React.Component {
		static propTypes = {
			children: PropTypes.func.isRequired,
			dataList: PropTypes.array.isRequired,
			onEnter: PropTypes.func,
		}

		state = {
			hovered: null,
		}

		_hoverItem = (hovered) => this.setState({ hovered })

		_handleArrowDown = () => {
			const { dataList } = this.props
			const { hovered } = this.state
			const setterProps = setterPropsGetters.getDown(dataList, hovered)
			const setNextItem = getNextItemSetter({
				...setterProps,
				onNext: this._hoverItem,
			})
			setNextItem(hovered)
		}

		_handleArrowUp = () => {
			const { dataList } = this.props
			const { hovered } = this.state
			const setterProps = setterPropsGetters.getUp(dataList, hovered)
			const setNextItem = getNextItemSetter({
				...setterProps,
				onNext: this._hoverItem,
			})
			setNextItem(hovered)
		}

		_handleArrowRight = () => {
			if (!setterPropsGetters.getRight) return
			const { dataList } = this.props
			const { hovered } = this.state
			const setterProps = setterPropsGetters.getRight(dataList, hovered)
			const setNextItem = getNextItemSetter({
				...setterProps,
				onNext: this._hoverItem,
			})
			setNextItem(hovered)
		}

		_handleArrowLeft = () => {
			if (!setterPropsGetters.getLeft) return
			const { dataList } = this.props
			const { hovered } = this.state
			const setterProps = setterPropsGetters.getLeft(dataList, hovered)
			const setNextItem = getNextItemSetter({
				...setterProps,
				onNext: this._hoverItem,
			})
			setNextItem(hovered)
		}

		_onKeyDown = (e) => {
			switch (e.key) {
				case 'Enter': {
					e.preventDefault()
					const { onEnter } = this.props
					onEnter && onEnter(this.state.hovered)
					break
				}
				case 'ArrowDown': {
					e.preventDefault()
					this._handleArrowDown()
					break
				}
				case 'ArrowUp': {
					e.preventDefault()
					this._handleArrowUp()
					break
				}
				case 'ArrowRight': {
					e.preventDefault()
					this._handleArrowRight()
					break
				}
				case 'ArrowLeft': {
					e.preventDefault()
					this._handleArrowLeft()
					break
				}
				default: break
			}
		}

		_onMouseEnter = ({ target }) => {
			const el = target.closest(`[${DATA_ATTR_KEY}]`)
			const dataKey = JSON.parse(el.dataset[DATA_KEY])
			this._hoverItem(dataKey)
		}

		_clearHover = () => this._hoverItem(null)

		render () {
			return this.props.children(
				this.state,
				this._onKeyDown,
				this._onMouseEnter,
				this._clearHover
			)
		}
	}
)

export default createOptionsList
