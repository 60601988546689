import { flow } from 'lodash'
import { call, select, takeEvery, put, fork, all } from 'redux-saga/effects'

import { USER_ROLE } from '@libs/foma/types'
import { DEFAULT_CHANNEL } from '@libs/channel-mom'
import { setChannel } from '@store/state/appState/actions'
import { SET_CHANNEL, SET_USER_DATA } from '@store/state/types'
import { locationQuerySelector } from '@store/state/appState/selectors'

import watchChannelChange from './watchChannelChange.js'
import { setLs, getLs } from './utils.js'


const CHANNEL_BY_USER_ROLE = {
	[USER_ROLE.AGENT]: 'b2b',
}
export const setNonEmptyUserDataActionMatcher = ({ type, payload }) => (type === SET_USER_DATA && Boolean(payload))
export const getChannelByRole = (role) => (CHANNEL_BY_USER_ROLE[role] || null)

export function* userChannelWorker ({ payload: userData }) {
	const { role } = userData
	const channelByRole = yield call(getChannelByRole, role)
	if (channelByRole) yield put(setChannel(channelByRole))
}


const QS_KEY = 'ref'

export const channelFromQsSelector = flow(
	locationQuerySelector,
	(params) => (params[QS_KEY] || null)
)

export function* initialize () {

	const { fromQs, fromLs } = yield all({
		fromQs: select(channelFromQsSelector),
		fromLs: call(getLs),
	})

	const channel = fromQs || fromLs || DEFAULT_CHANNEL

	yield put(setChannel(channel))
}

export function* persistChannelWorker ({ payload: channel }) {
	yield call(setLs, channel)
}

export default function* channelPersistance () {
	yield takeEvery(SET_CHANNEL, persistChannelWorker)
	yield call(initialize)
	yield takeEvery(setNonEmptyUserDataActionMatcher, userChannelWorker)
	yield fork(watchChannelChange)
}
