import { createSelector } from 'reselect'
import {
	getDate,
	getRangeInDays,
} from '@utils/time-utils'
import { searchBarSelectors } from '@store/state/uiState/forms/selectors'
import {
	topPricesSelector as allTopPricesSelector,
	pricesByMonthSelector as allPricesByMonthSelector,
	pricesByDaySelector as allPricesByDaySelector,
} from '@store/state/appState/selectors'


const { srcSelector, dstSelector, outboundDateSelector, inboundDateSelector } = searchBarSelectors

const makePricesSelector = (pricesSelector) => createSelector(
	srcSelector,
	dstSelector,
	outboundDateSelector,
	inboundDateSelector,
	pricesSelector,
	(src, dst, outboundDateIso, inboundDateIso, prices) => {

		const outboundDate = getDate(outboundDateIso)

		const stayLength = inboundDateIso
			? getRangeInDays(outboundDate, getDate(inboundDateIso))
			: null
		const key = `${src}/${dst}/${stayLength || 'NOTHING'}`

		return prices[key] || {}
	}
)

export const topPricesSelector = makePricesSelector(allTopPricesSelector)
export const pricesByMonthSelector = makePricesSelector(allPricesByMonthSelector)
export const pricesByDaySelector = makePricesSelector(allPricesByDaySelector)
