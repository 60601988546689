import { first, mapValues, values, pickBy, invertBy } from 'lodash'
import { createSelector } from 'reselect'
import {
	getFares,
	getServices,
	getActivities,
	getSegments,
	getHotels,
} from './core.js'
import {
	getServiceFromFare,
	getActivitiesFromService,
	getHotelFromActivity,
} from './itemsRelation.js'
import { getSortedSegmentsFromActivities } from './segments.js'
import { accommodationFaresSelector } from './servicesFares.js'


export const serviceByFareIdSelector = createSelector(
	[ getServices, getFares ],
	(services, fares) => mapValues(fares, (fare) => getServiceFromFare(services, fare))
)

export const activitiesByFareIdSelector = createSelector(
	[ getActivities, serviceByFareIdSelector ],
	(activities, serviceByFareId) => mapValues(
		serviceByFareId,
		(service) => getActivitiesFromService(activities, service)
	)
)

export const sortedSegmentsByFareIdSelector = createSelector(
	[ getSegments, activitiesByFareIdSelector ],
	(segments, activitiesByFareId) => mapValues(
		activitiesByFareId,
		(activities) => getSortedSegmentsFromActivities(segments, values(activities))
	)
)

export const hotelsByFareIdSelector = createSelector(
	[ getHotels, accommodationFaresSelector, activitiesByFareIdSelector ],
	(hotels, accommodationFares, activitiesByFareId) => mapValues(
		pickBy(activitiesByFareId, (_, fareId) => (accommodationFares[fareId])),
		// activities <-> hotel has one-by-one relation
		// so we can use first element of acitivites array
		(activities) => getHotelFromActivity(hotels, first(values(activities)))
	)
)

export const faresByHotelIdSelector = createSelector(
	[ getFares, hotelsByFareIdSelector ],
	(fares, hotelsByFareId) => {
		const fareIdsByHotelId = invertBy(hotelsByFareId, ({ id }) => id)
		return mapValues(fareIdsByHotelId, (ids) => ids.reduce((acc, id) => ({ ...acc, [id]: fares[id] }), {}))
	}
)
