import qs from 'qs'
import confy from '@utils/confy'
import getResponseHandler from './responseHandler.js'
import { OPERATION_STATUSES } from './statuses.js'


// TODO: set error statuses for search ws stream
const ERROR_STATUSES = [
	OPERATION_STATUSES.ERROR,
	OPERATION_STATUSES.UNAVAIL,
]

const OK_STATUSES = [
	OPERATION_STATUSES.SUCCESS,
	OPERATION_STATUSES.IN_PROGRESS,
]

const requiredHotelFields = [
	'ru',
	'room_groups',
	'amenity_ids',
	'images',
	'latitude',
	'longitude',
	'rating',
	'stars',
	'thumbnail',
]

const getStreamURL = (queryObject) => {
	let url = confy.get('API.cl.search')
	const queryString = qs.stringify(queryObject)

	if (url.startsWith('/')) { // handle relative paths that start with /
		const { location } = window
		const { host } = location
		const protocol = (location.protocol === 'https:') ? 'wss:' : 'ws:'

		url = `${protocol}//${host}${url}`
	}

	return `${url}?${queryString}`
}

export default function (meta, emitter) {
	const {
		outboundDate,
		inboundDate,
		serviceTypes,
		hotels = [],
		...queryParams
	} = meta
	const queryObject = {
		'depart_date': outboundDate,
		'return_date': inboundDate,
		'build_offers': 0,
		'service_types': serviceTypes.join(','),
		hotels: hotels.join(','),
		...queryParams,
		'hotel_fields': requiredHotelFields.join(','),
	}
	const url = getStreamURL(queryObject)

	let socket = null

	const onPromiseEnd = (args) => {
		socket.close()
		return args
	}

	const executor = (resolve, reject) => {
		const handler = getResponseHandler({
			resolve: emitter,
			reject: emitter,
			omitData: true,
			okStatuses: OK_STATUSES,
			errStatuses: ERROR_STATUSES,
			requestType: 'WS /search',
		})
		const onMsg = (event) => {
			const data = JSON.parse(event.data)
			handler({}, { body: data })
			if (data.status === OPERATION_STATUSES.SUCCESS) {
				resolve(data.status)
			}
		}

		const onClose = (event) => {
			if (event.wasClean) {
				const status = OPERATION_STATUSES.SUCCESS
				resolve(status)
				emitter({ status })
			}
			else {
				handler(event, {})
			}
		}

		socket = new WebSocket(url)
		socket.addEventListener('error', emitter)
		socket.addEventListener('close', onClose)
		socket.addEventListener('message', onMsg)
	}

	return {
		promise: new Promise(executor).then(onPromiseEnd, onPromiseEnd),
		cancelFn: () => socket.close(),
	}
}
