import { createSelector } from 'reselect'
import fastDeepEqual from 'fast-deep-equal'
import { currentSearchQuerySelector } from '@store/state/appState/selectors'
import { searchBarSelectors } from '@store/state/uiState/forms/selectors'


const getIsSearchButtonEnabled = (searchQuery, ...values) => !fastDeepEqual(
	searchQuery,
	{ src: values.src,
		dst: values.dst,
		outboundDate: values.outboundDate,
		inboundDate: values.inboundDate,
		adults: values.adults,
		childern: values.children,
		infants: values.infants,
	}
)
export const isSearchButtonEnabledSelector = createSelector(
	currentSearchQuerySelector,
	searchBarSelectors.srcSelector,
	searchBarSelectors.dstSelector,
	searchBarSelectors.outboundDateSelector,
	searchBarSelectors.inboundDateSelector,
	searchBarSelectors.adultsSelector,
	searchBarSelectors.childrenSelector,
	searchBarSelectors.infantsSelector,
	getIsSearchButtonEnabled
)
