import PropTypes from 'prop-types'

import { Count as CountBtn } from 'core-components/Button'

import defaultStyles from './styles/counter.js'


const noOp = () => {}

const Counter = ({ title, value, isDecActive, isIncActive, classNames = {}, onDecrement, onIncrement }) => (
	<div className={classNames.counterContainer || defaultStyles.counterContainer}>
		<div className={classNames.title || defaultStyles.title}>{title}</div>
		<div className={classNames.counter || defaultStyles.counter}>
			<CountBtn type="dec" isActive={isDecActive} onClick={isDecActive ? onDecrement : noOp}/>
			<div className={classNames.value || defaultStyles.value}>{value}</div>
			<CountBtn type="inc" isActive={isIncActive} onClick={isIncActive ? onIncrement : noOp}/>
		</div>
	</div>
)

Counter.propTypes = {
	title: PropTypes.node.isRequired,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]).isRequired,
	isDecActive: PropTypes.bool.isRequired,
	isIncActive: PropTypes.bool.isRequired,
	classNames: PropTypes.object,
	onDecrement: PropTypes.func.isRequired,
	onIncrement: PropTypes.func.isRequired,
}

export default Counter
