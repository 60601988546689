import { get } from 'lodash'
import {
	FETCH_TOP_PRICES_RESOLVE,
	FETCH_PRICES_BY_MONTH_RESOLVE,
	FETCH_PRICES_BY_DAY_RESOLVE,
} from '@store/state/types'


const initialState = {
	top: [],
	byMonth: [],
	byDay: [],
}

// price object
// { date: DATE_STRING, isCharter: BOOLEAN, price: FLOAT }

const makeKeyByReducer = (prevPrices) => (acc, priceObj) => ({
	...acc,
	[priceObj.date]: {
		...priceObj,
		price: priceObj.price || get(prevPrices, `${priceObj.date}.price`, null),
	},
})
const getNewPrices = (prevPrices, prices, key) => ({
	...prevPrices,
	[key]: {
		...prevPrices[key],
		...prices.reduce(makeKeyByReducer(prevPrices[key]), {}),
	},
})

export default function reducer (state = initialState, { type, payload }) {
	switch (type) {
		case FETCH_TOP_PRICES_RESOLVE: {
			const { prices, src, dst, stayLength } = payload
			const key = `${src}/${dst}/${stayLength || 'NOTHING'}`
			return {
				...state,
				top: getNewPrices(state.top, prices, key),
			}
		}
		case FETCH_PRICES_BY_MONTH_RESOLVE: {
			const { prices, src, dst, stayLength } = payload
			const key = `${src}/${dst}/${stayLength || 'NOTHING'}`
			return {
				...state,
				byMonth: getNewPrices(state.byMonth, prices, key),
			}
		}
		case FETCH_PRICES_BY_DAY_RESOLVE: {
			const { prices, src, dst, stayLength } = payload
			const key = `${src}/${dst}/${stayLength || 'NOTHING'}`
			return {
				...state,
				byDay: getNewPrices(state.byDay, prices, key),
			}
		}
		default: return state
	}
}
