import { flow, mapValues, values } from 'lodash'
import { createSelector } from 'reselect'
import { getYearDiff, getDate } from '@utils/time-utils'
import { getCitizenshipCode } from '@utils/citizenship'
import { isDocExpDateApplicable } from '@utils/common/docTools'
import { getPassengerCategoryByAge, CATEGORIES } from '@utils/common/paxAge.js'
import { byCurrentOfferId } from '@store/state/appState/selectors'
import {
	flightBaseDateSelector,
	isInternationalFlightFromOffer,
} from '@store/state/domainData/selectors'
import {
	getCanPaxUseNationalPassport,
	getShouldPaxUseBirthCert,
	checkForRussianDocument,
	travelRegExp,
	nationalRegExp,
	birthCertificateRegExp,
	trashRegExp,
} from '../../validators'
import {
	documentExpDateSelector,
	citizenshipSelector,
	birthdateSelector,
	documentNumSelector,
	birthdateByFormIndexSelector,
	documentExpDateByFormIndexSelector,
	documentNumByFormIndexSelector,
	citizenshipByFormIndexSelector,
} from './values.js'
import { PASSENGERS_FORM } from '../../types'
import { deepEqCreateSelector, getFormsState } from '../core.js'


export const DEFAULT_CITIZENSHIP_VALUE = 'Россия'
export const getCitizenshipWithDefault = (value) => value || DEFAULT_CITIZENSHIP_VALUE
export const citizenshipWithDefaultSelector = flow(
	citizenshipSelector,
	getCitizenshipWithDefault
)

// pax age
export const getPassengerAge = (birthdateStr, baseDateIsoStr) => {
	if (!birthdateStr) return null
	const birthdateIsoStr = birthdateStr.split('.').reverse().join('-')
	const birthdateDate = getDate(birthdateIsoStr)
	const baseDate = getDate(baseDateIsoStr)
	return getYearDiff(birthdateDate, baseDate)
}
export const passengerAgeSelector = createSelector(
	birthdateSelector,
	byCurrentOfferId(flightBaseDateSelector),
	getPassengerAge
)
export const passengerAgeByFormIndexSelector = createSelector(
	birthdateByFormIndexSelector,
	byCurrentOfferId(flightBaseDateSelector),
	(valueByFormIndex, baseDate) => mapValues(valueByFormIndex, (value) => (
		getPassengerAge(value, baseDate)
	))
)

// document type
export const getDocumentType = (isInternationalFlight, documentNum, citizenship, passengerAge) => {
	const canPaxUseNationalPassport = getCanPaxUseNationalPassport(passengerAge)
	const shouldPaxUseBirthCert = getShouldPaxUseBirthCert(passengerAge)
	const isEmptyCitizenship = !citizenship
	const citizenshipCode = getCitizenshipCode(citizenship)
	const clearedDocumentNum = documentNum && documentNum.replace(trashRegExp, '')
	const isRussianDocument = checkForRussianDocument(clearedDocumentNum)
	const isForeignDocument = !isRussianDocument && clearedDocumentNum
	let documentType = 'common'

	const isTravelPassport = [
		isInternationalFlight,
		((!isEmptyCitizenship && citizenshipCode !== 'RUS') || (isForeignDocument && isEmptyCitizenship)),
		travelRegExp.full.test(clearedDocumentNum),
	].some(Boolean)
	const isNationalPassport = [
		nationalRegExp.full.test(clearedDocumentNum) && (canPaxUseNationalPassport || !passengerAge),
	].some(Boolean)
	const isBirthCertificate = [
		birthCertificateRegExp.full.test(clearedDocumentNum) && (shouldPaxUseBirthCert || !passengerAge),
	].some(Boolean)

	if (isTravelPassport) documentType = 'travel-passport' // TODO: where should be store string constant ?
	else if (isNationalPassport) documentType = 'national-passport'
	else if (isBirthCertificate) documentType = 'birth-certificate'

	return documentType
}
export const documentTypeSelector = createSelector(
	byCurrentOfferId(isInternationalFlightFromOffer),
	documentNumSelector,
	citizenshipSelector,
	passengerAgeSelector,
	getDocumentType
)
export const documentTypeByFormIndexSelector = createSelector(
	byCurrentOfferId(isInternationalFlightFromOffer),
	documentNumByFormIndexSelector,
	citizenshipByFormIndexSelector,
	passengerAgeByFormIndexSelector,
	(isInternationalFlight, docNumByIndex, citizenshipByIndex, paxAgeByIndex) => mapValues(
		docNumByIndex,
		(docNum, index) => getDocumentType(
			isInternationalFlight,
			docNum,
			citizenshipByIndex[index],
			paxAgeByIndex[index]
		)
	)
)

// fallback expiry date value for documents that do not have expiry date
const DEFAULT_DOCUMENT_EXP_DATE = `01.01.${new Date().getFullYear() + 10}`
const getDocExpDateWithDefault = (value, docType) => (
	isDocExpDateApplicable(docType) ? value : DEFAULT_DOCUMENT_EXP_DATE
)
export const documentExpDateWithDefaultSelector = createSelector(
	documentExpDateSelector,
	documentTypeSelector,
	getDocExpDateWithDefault
)
export const documentExpDateWithDefaultByFormIndexSelector = createSelector(
	documentExpDateByFormIndexSelector,
	documentTypeByFormIndexSelector,
	(valueByIndex, docTypeByIndex) => mapValues(
		valueByIndex,
		(value, index) => getDocExpDateWithDefault(value, docTypeByIndex[index])
	)
)

// document tips type
export const getDocumentTipsType = (citizenshipCode, documentType) => {
	if (citizenshipCode !== 'RUS') return 'foreign'
	else if (documentType === 'travel-passport') return 'rusTravel'
	return 'rusNational'
}
export const documentTipsTypeSelector = createSelector(
	flow(citizenshipWithDefaultSelector, getCitizenshipCode),
	documentTypeSelector,
	getDocumentTipsType
)

// check for russian document
export const getIsRussianDocument = (value) => {
	const clearedValue = value && value.replace(trashRegExp, '')
	return checkForRussianDocument(clearedValue)
}
export const isRussianDocumentSelector = createSelector(
	documentNumSelector,
	getIsRussianDocument
)

// passengers consist by forms
const initial = Object.freeze({
	[CATEGORIES.ADULT]: 0,
	[CATEGORIES.CHILD]: 0,
	[CATEGORIES.INFANT]: 0,
})
export const getPaxConsist = (paxAgeByFormIndex) => {
	const consistByCategory = values(paxAgeByFormIndex).reduce((acc, age) => {
		if (isNaN(age)) return acc
		const category = getPassengerCategoryByAge(age)
		return {
			...acc,
			[category]: acc[category] + 1,
		}
	}, initial)
	return [
		consistByCategory[CATEGORIES.ADULT],
		consistByCategory[CATEGORIES.CHILD],
		consistByCategory[CATEGORIES.INFANT],
	]
}
export const paxConsistSelector = createSelector(
	passengerAgeByFormIndexSelector,
	getPaxConsist
)

const getPaxKeysList = (state) => {
	const passengerForms = getFormsState(state)[PASSENGERS_FORM.NAME]
	return Object.keys(passengerForms)
}
export const paxKeysListSelector = deepEqCreateSelector(
	getPaxKeysList,
	(keysList) => keysList
)
