import { flow } from 'lodash'

import { LOAD_TYPE } from '@libs/foma/types'
import { loadingEventsSelector } from '@store/state/appState/selectors'


const hasLength = (arr) => (arr.length > 0)

export const bookingEvents = flow(
	loadingEventsSelector,
	(loadingEvents) => loadingEvents.filter(({ type }) => (type === LOAD_TYPE.BOOK))
)

const blockingEventTypes = [
	LOAD_TYPE.OFFER,
	LOAD_TYPE.CHECK_OFFER,
]
export const blockingEvents = flow(
	loadingEventsSelector,
	(events) => events.filter(({ type }) => blockingEventTypes.includes(type))
)

export const isBooking = flow(bookingEvents, hasLength)
export const isBlocked = flow(blockingEvents, hasLength)

export const isSerpLoading = flow(
	loadingEventsSelector,
	(loadingEvents = []) => loadingEvents.some(({ type, meta, hideEvent }) => (
		type === LOAD_TYPE.SEARCH && !Boolean(meta.hotels) && !hideEvent
	))
)

