import cn from 'classnames'

import styles from '../styles/rawBtn.js'


const noOp = () => {}

const RawButton = ({ classNames, disabled, onClick, children, setRef, ...props }) => {
	const btnClass = cn(classNames, styles.default) // override default styles with classnames

	const propsBtn = {
		...props,
		ref: setRef,
		className: btnClass,
		disabled,
		onClick: disabled ? noOp : onClick,
	}

	return (
		<button {...propsBtn}>
			{children}
		</button>
	)
}

export default RawButton
