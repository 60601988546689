import { call, all, takeEvery, select, put, fork } from 'redux-saga/effects'
import { HANDLE_FOCUS_EVT, HANDLE_BLUR_EVT, FOCUS } from '@store/state/types'
import { activeElementSelector } from '@store/state/appState/selectors'
import { focusElement, clearFocus } from '@store/state/appState/actions'


export function* focusEvtHandler ({ payload: evt }) {
	let { target: elem } = evt
	let focusElemKey = null

	while (elem !== null && focusElemKey === null) {
		focusElemKey = yield call([ elem, 'getAttribute' ], 'data-focus')
		elem = elem.parentElement
	}
	focusElemKey = focusElemKey || 'unmanaged'

	const activeElement = yield select(activeElementSelector)
	if (activeElement !== focusElemKey) yield put(focusElement(focusElemKey))
}

export function* blurEvtHandler ({ payload: evt }) {
	const { relatedTarget } = evt
	const activeElement = yield select(activeElementSelector)
	if (relatedTarget === null && activeElement !== null) yield put(clearFocus())
}

export function* focusActionHandler (tmpActiveElement, { payload: focusElemKey }) {
	if (tmpActiveElement.elem === focusElemKey) return

	tmpActiveElement.elem = focusElemKey
	if (focusElemKey === null) yield call([ document.activeElement, 'blur' ])
	else {
		const elem = yield call([ document, 'querySelector' ], `[data-focus="${focusElemKey}"]`)
		if (elem) yield call([ elem, 'focus' ])
	}
}

export function* focusBlurTask () {
	let tmpActiveElement = { elem: null }
	yield takeEvery(FOCUS, focusActionHandler, tmpActiveElement)
}

export default function* focusWorker () {
	yield all([
		takeEvery(HANDLE_FOCUS_EVT, focusEvtHandler),
		takeEvery(HANDLE_BLUR_EVT, blurEvtHandler),
		fork(focusBlurTask),
	])
}
