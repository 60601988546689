import { connect } from 'react-redux'
import cx from 'classnames'
import { push } from 'connected-react-router'
import { matchPath } from 'react-router-dom'
import { createEnhancer } from '@utils/decoract'
import { toCheckout, PATHS } from '@utils/router'
import {
	locationSelector,
	currentOfferIdSelector,
} from '@store/state/appState/selectors'
import { onElementClick } from '@store/state/appState/actions'

import Btn from './views/btn.js'
import Details from './views/details.js'

import styles from './styles'
import {
	txtRouteSelector,
	txtRouteDatesSelector,
	detailsSelector,
	totalPriceSelector,
} from './selectors'


const cq = {
	sm: {
		maxWidth: 61,
	},
}
const enhancer = createEnhancer({ cq })
const noOp = () => {}

class Cart extends React.Component {
	state = { isDetailsOpened: false }
	containerNode = null

	_closeDetails = () => this.setState({ isDetailsOpened: false })
	_toggleDetails = () => this.setState((state) => ({
		...state,
		isDetailsOpened: !state.isDetailsOpened,
	}), () => {
		this.props.onElementClick('cart')
	})

	componentDidMount () {
		document.addEventListener('click', this._handleDocumentClick)
	}

	componentWillUnmount () {
		document.removeEventListener('click', this._handleDocumentClick)
	}

	_setContainerNodeRef = (n) => this.containerNode = n
	_handleDocumentClick = (e) => {
		if (this.state.isDetailsOpened && this.containerNode && !this.containerNode.contains(e.target)) {
			this._closeDetails()
		}
	}

	render () {
		const {
			cq,
			txtRoute,
			txtRouteDates,
			details,
			totalPrice,
			onMakeOrderClick,
		} = this.props
		const { sm } = cq

		const propsBtn = {
			totalPrice,
			withText: !sm,
			isActive: this.state.isDetailsOpened,
			onClick: totalPrice ? this._toggleDetails : noOp,
		}
		const btn = (<Btn {...propsBtn}/>)

		const propsDetails = {
			txtTitle: txtRoute,
			txtCaption: txtRouteDates,
			totalPrice,
			details,
			onMakeOrderClick,
		}
		const detailsComp = (
			<div className={styles.detailsWrapper}>
				<div className={styles.details}>
					<Details {...propsDetails}/>
				</div>
			</div>
		)

		const containerClass = cx({
			[styles.container]: !sm,
			[styles.containerSm]: sm,
		})

		return (
			<div className={containerClass} ref={this._setContainerNodeRef}>
				{btn}
				{this.state.isDetailsOpened && detailsComp}
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	const currentOfferId = currentOfferIdSelector(state)
	const location = locationSelector(state)
	const isCheckout = Boolean(matchPath(location.pathname, {
		path: PATHS.CHECKOUT,
		exact: true,
	}))
	return {
		txtRoute: txtRouteSelector(state),
		txtRouteDates: txtRouteDatesSelector(state),
		details: detailsSelector(state),
		totalPrice: totalPriceSelector(state),
		isCheckout,
		currentOfferId,
	}
}
const mapDispatchToProps = {
	pushToCheckout: (offerId) => push(toCheckout(offerId)),
	onElementClick,
}
const mergeProps = ({ isCheckout, currentOfferId, ...stateProps }, { pushToCheckout, ...dispatchProps }, ownProps) => ({
	...stateProps,
	...ownProps,
	...dispatchProps,
	onMakeOrderClick: !isCheckout
		? () => {
			dispatchProps.onElementClick('cartMakerOrder')
			pushToCheckout(currentOfferId)
		}
		: noOp,
})
const connector = connect(mapStateToProps, mapDispatchToProps, mergeProps)

export default connector(enhancer(Cart))
