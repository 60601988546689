import cn from 'classnames'


export default class Input extends React.Component {
	componentDidMount () {
		const { noTrack, value } = this.props
		this.inputNode.value = value

		if (noTrack) {
			this.inputNode.setAttribute('data-do-not-track', '')
			this.inputNode.setAttribute('_lr-hide', '')
		}
	}

	componentDidUpdate () {
		const { value, isFocused } = this.props
		const oldLen = this.inputNode.value.length
		const oldCursor = this.inputNode.selectionEnd
		this.inputNode.value = value
		// TODO: need better approach for new cursor position calculation
		// in case with deleting chars by Backspace and Delete
		const newCursor = Math.max(0, value.length - oldLen + oldCursor)

		if (document.activeElement === this.inputNode) {
			this.inputNode.selectionStart = this.inputNode.selectionEnd = newCursor
		}
		if (isFocused) this.inputNode.focus()
	}

	render () {
		const { isFocused, dataFocus, noTrack, autoComplete, spellcheck, className, ...props } = this.props

		const classes = cn({
			[className]: true,
			'_lr-hide': noTrack,
		})
		const propsInput = {
			...props,
			className: classes,
			'data-focus': dataFocus,
			value: undefined,
			ref: (n) => { this.inputNode = n },
			autoComplete,
			spellcheck,
		}
		return <input {...propsInput}/>
	}
}
