import { get } from 'lodash'
import { delay, put, takeEvery, select, call, all } from 'redux-saga/effects'
import {
	getDate,
	subtractDays,
	subtractMonths,
	getRangeInDays,
	toIsoString,
	SEC,
} from '@utils/time-utils'
import { LOAD_TYPE } from '@libs/foma/types'
import {
	serpParamsSelector,
} from '@store/state/appState/selectors'
import {
	fetchPricesByDayResolve,
} from '@store/state/appState/actions'
import { SET_CURRENT_QUERY, FETCH_CALENDAR_PRICES, REMOVE_LOADING_EVENT } from '@store/state/types'
import { pricesByDaySelector } from '@store/state/selectors'

import pricesWithCheapestTicketSelector from './selectors/pricesWithCheapestTicket.js'
import {
	loadTopPrices,
	loadPricesByMonth,
	loadPricesByDay,
} from './pricesLoaders.js'


const START_DATE_OFFSET = 10
const START_DATE_MONTH_OFFSET = 6
const MONTHS_LOOKUP_COUNT = START_DATE_MONTH_OFFSET * 2
const DEFAULT_LOOKUP_DATES_COUNT = 60

const getDatesParams = (...args) => {
	const [
		outboundDateIso,
		inboundDateIso,
		explicitStartDate,
		explicitStayLength,
		datesCount = DEFAULT_LOOKUP_DATES_COUNT,
	] = args

	const outboundDate = getDate(outboundDateIso)
	const inboundDate = inboundDateIso && getDate(inboundDateIso)

	let startDateByMonth = subtractMonths(outboundDate, START_DATE_MONTH_OFFSET)
	let startDate = explicitStartDate || subtractDays(outboundDate, START_DATE_OFFSET)
	const minDate = new Date()
	if (startDate < minDate) startDate = minDate
	if (startDateByMonth < minDate) startDateByMonth = minDate
	const startDateIso = toIsoString(startDate)
	const startDateByMonthIso = toIsoString(new Date(startDateByMonth.setDate(1)))

	const stayLength = explicitStayLength
		|| (inboundDate ? getRangeInDays(outboundDate, inboundDate) : null)

	return {
		startDate: startDateIso,
		startDateByMonth: startDateByMonthIso,
		stayLength,
		datesCount,
	}
}

const TIME_TO_WAIT_DB_MS = 2 * SEC
function* getCalendarPricesWorker ({ type, payload }) {
	const serpParams = yield select(serpParamsSelector)
	if (!serpParams) return
	const {
		src,
		dst,
		outboundDate,
		inboundDate,
	} = serpParams

	const {
		startDate,
		startDateByMonth,
		stayLength,
		datesCount,
	} = yield call(
		getDatesParams,
		outboundDate, inboundDate, payload.startDate, payload.stayLength, payload.count
	)

	const isEndOfSearch = type === REMOVE_LOADING_EVENT
	if (isEndOfSearch) {
		const pricesByDay = (yield select(pricesByDaySelector)) || {}
		const priceFromCalendarState = get(pricesByDay, `${outboundDate}.price`) || Infinity
		const prices = yield select(pricesWithCheapestTicketSelector)
		if (!prices) return

		const priceFromTicketsSearch = prices[0].price
		if (priceFromCalendarState <= priceFromTicketsSearch) return

		yield put(fetchPricesByDayResolve(prices, src, dst, stayLength))
		yield delay(TIME_TO_WAIT_DB_MS)
	}

	yield all([
		call(loadTopPrices, startDate, stayLength, src, dst, datesCount, isEndOfSearch),
		call(loadPricesByMonth, startDateByMonth, stayLength, src, dst, MONTHS_LOOKUP_COUNT, isEndOfSearch),
		call(loadPricesByDay, startDate, stayLength, src, dst, datesCount, isEndOfSearch),
	])
}

const getCalendarPricesPattern = ({ type, payload }) => (
	type === FETCH_CALENDAR_PRICES
	|| (type === SET_CURRENT_QUERY && payload) // change query and start of search
	|| (type === REMOVE_LOADING_EVENT && payload.type === LOAD_TYPE.SEARCH) // end of search action
)

export default function* () {
	yield takeEvery(getCalendarPricesPattern, getCalendarPricesWorker)
}
