import { get } from 'lodash'
import { call, put } from 'redux-saga/effects'

import feofan from '@libs/feofan'
import info from '@utils/info'
import { OPERATION_STATUSES } from '@libs/foma/types'

import { makeCancellable, withError } from '../utils'


export const cancellableGetHotelExt = makeCancellable(feofan.getHotelExt)


export default function* handleHotelExt (evtChannel, meta) {
	const { id } = meta
	const optsHotelExt = { id }

	try {
		const resData = yield call(cancellableGetHotelExt, optsHotelExt)
		yield put(evtChannel, resData)

		return resData.status
	}
	catch (err) {
		info('cant get hotel\'s extend info', err)
		const status = get(err, 'data.status', OPERATION_STATUSES.ERROR)
		yield put(evtChannel, withError({ ...err.data, status }))

		return status
	}
}
