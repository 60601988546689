import { first, last } from 'lodash'
import { createSelector } from 'reselect'

import { searchTransferSelectors } from '@store/state/uiState/forms/selectors'
import { byCurrentOfferId, fakeSelectedTransferSelector } from '@store/state/appState/selectors'
import {
	outboundFlightFromOfferSelector,
	inboundFlightFromOfferSelector,
	airportsFromOfferSelector,
	flightConsistFromOfferSelector,
} from '@store/state/domainData/selectors'

import { LOCAL_TRANSFER, getDirectionList, vehicleListByMetro } from '../data'


export const arriveDataSelector = createSelector(
	byCurrentOfferId(outboundFlightFromOfferSelector),
	(outboundFlightSegments) => last(outboundFlightSegments).arrive
)
export const departDataSelector = createSelector(
	byCurrentOfferId(inboundFlightFromOfferSelector),
	(inboundFlightSegments) => (inboundFlightSegments.length > 0
		? first(inboundFlightSegments).depart
		: null
	)
)
const makeAirportsDataSelector = (locationDataSelector) => createSelector(
	locationDataSelector,
	byCurrentOfferId(airportsFromOfferSelector),
	(data, airports) => data && airports[data.airport]
)
export const arriveAirportsDataSelector = makeAirportsDataSelector(arriveDataSelector)
export const departAirportDataSelector = makeAirportsDataSelector(departDataSelector)
const isRTSelector = (state) => Boolean(departDataSelector(state))
const paxCountSelector = (state) => {
	const consist = byCurrentOfferId(flightConsistFromOfferSelector)(state) || []
	return consist.reduce((acc, count) => (acc + count), 0)
}

export const selectedDirectionSelector = (state) => (
	searchTransferSelectors.directionSelector(state) || LOCAL_TRANSFER.THERE
)

export const vehicleListSelector = createSelector(
	selectedDirectionSelector,
	paxCountSelector,
	arriveAirportsDataSelector,
	(selectedDirection, flightPaxCount, { metro }) => (vehicleListByMetro[metro] || []).map((vehicle) => ({
		...vehicle,
		isRT: selectedDirection === LOCAL_TRANSFER.ROUND_TRIP,
		vehicleCount: Math.ceil(flightPaxCount / vehicle.paxCount),
		price: Math.ceil(flightPaxCount / vehicle.paxCount) * vehicle.price,
	}))
)

const minVehiclePriceSelector = (state) => {
	const prices = vehicleListSelector(state).map(({ price }) => price)
	return Math.min(...prices)
}
export const directionListSelector = createSelector(
	minVehiclePriceSelector,
	isRTSelector,
	getDirectionList
)

export const transferPriceSelector = (state) => {
	const isRT = selectedDirectionSelector(state) === LOCAL_TRANSFER.ROUND_TRIP
	const multi = isRT ? 2 : 1
	const vehicleList = vehicleListSelector(state)
	const selectedVehicleId = fakeSelectedTransferSelector(state)
	const vehicle = vehicleList.find(({ id }) => id === selectedVehicleId)
	return vehicle && vehicle.price * multi
}

export const cancellationDateSelector = (state) => (
	selectedDirectionSelector(state) === LOCAL_TRANSFER.THERE
		? arriveDataSelector(state).time
		: departDataSelector(state).time
)

export const fakeTransferFareSelector = (state) => {
	const price = transferPriceSelector(state)
	return {
		brands: { 'intui': { 'legal_entity': 'EasyUpTur LLP' } },
		fareCounts: { 'transfer-fare-id': [ 1, 1 ] },
		fare: {
			details: {
				consist: [],
			},
			'payment_schedule': { type: 'post-pay' },
			price: { buyer: price },
			id: 'transfer-fare-id',
			supplier: 'intui',
		},
	}
}

export const getSearchParams = (props) => {
	const {
		arriveSrc,
		arriveDst,
		departSrc,
		departDst,
		arriveDate,
		departDate,
		selectedDirection,
	} = props

	return {
		arriveSrc: selectedDirection === LOCAL_TRANSFER.BACK ? null : arriveSrc,
		arriveDst: selectedDirection === LOCAL_TRANSFER.BACK ? null : arriveDst,
		departSrc: selectedDirection === LOCAL_TRANSFER.THERE ? null : departSrc,
		departDst: selectedDirection === LOCAL_TRANSFER.THERE ? null : departDst,
		arriveDate: selectedDirection === LOCAL_TRANSFER.BACK ? null : arriveDate,
		departDate: selectedDirection === LOCAL_TRANSFER.THERE ? null : departDate,
	}
}

export const hasTransfersSelector = (state) => vehicleListSelector(state).length > 0
