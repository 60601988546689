import qs from 'qs'
import { flow } from 'lodash'
import { makePropGetter as makeAppStatePropGetter, createPropGetterMaker } from './common'


const getRouterState = makeAppStatePropGetter('router')
const makePropGetter = createPropGetterMaker(getRouterState)

export const routerDataSelector = getRouterState
export const locationSelector = makePropGetter('location')
export const locationQuerySelector = flow(
	locationSelector,
	({ search }) => qs.parse(search.slice(1)),
)
