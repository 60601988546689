import { get } from 'lodash'
import deepEqual from 'fast-deep-equal'
import { push, replace } from 'connected-react-router'
import { delay, cancelled, race, all, spawn, call, put, take, select, cancel, fork } from 'redux-saga/effects'

import { SEC } from '@utils/time-utils'
import { isValidRouteParams, getSearchParams } from '@utils/common/searchQuery'
import { toIndex, toSerp } from '@utils/router'
import { RUN_SEARCH, SET_CURRENT_QUERY } from '@store/state/types'
import {
	currentSearchQuerySelector,
	localityStringByMetroSelector,
} from '@store/state/appState/selectors'
import { setShopfrontDialog } from '@store/state/appState/actions'

import { reqCitiesByMetros } from '@store/sagas/geoData'
import { initSearchBarParams } from '@store/sagas/searchBar'

import { search } from '../../helpers/search.js'
import dialogWorker from './dialogWorker.js'
import { ACCOUNT_DATA_SEARCH_QUERY } from '../cabinet'


export const SEARCH_TIMEOUT_MS = 45 * SEC

export function* metroSerpHandler (params) {
	const { src: srcMetro, dst: dstMetro, ...restParams } = params

	yield call(reqCitiesByMetros, [ srcMetro, dstMetro ])
	const localityByMetro = yield select(localityStringByMetroSelector)

	const paramsWithLocalityStrings = {
		src: localityByMetro[srcMetro],
		dst: localityByMetro[dstMetro],
		...restParams,
	}

	yield put(replace(toSerp(paramsWithLocalityStrings)))
	// need to fork as replace actions are not handled by the router
	yield fork(serpHandler, paramsWithLocalityStrings)
}

export default function* serpHandler (initialParams, query, chan) {
	let params = initialParams
	let searchTask = null
	let dialogTask = null
	let isForceRefresh = false

	if (!isValidRouteParams(params)) {
		// react-router-redux middleware drops the action for some reason
		yield delay(0)
		yield put(push(toIndex()))
		return
	}

	const currentQuery = yield select(currentSearchQuerySelector)

	if (currentQuery === ACCOUNT_DATA_SEARCH_QUERY) {
		yield take(SET_CURRENT_QUERY)
	}

	try {
		while (true) {

			const searchParams = yield call(getSearchParams, params)

			const tasks = yield all({
				searchT: (searchTask && !isForceRefresh) ? searchTask : spawn(search, searchParams, isForceRefresh),
				dialogT: dialogTask || fork(dialogWorker),
				searchBarInitializer: call(initSearchBarParams, searchParams),
			})
			searchTask = tasks.searchT
			dialogTask = tasks.dialogT
			isForceRefresh = false

			const { runAction, newData, searchTimedOut } = yield race({
				runAction: take(RUN_SEARCH),
				newData: take(chan),
				searchTimedOut: delay(SEARCH_TIMEOUT_MS),
			})
			if (runAction || (newData && !deepEqual(params, newData.params))) {
				isForceRefresh = Boolean(runAction)
				params = get(runAction, 'payload') || newData.params
				yield all([
					put(setShopfrontDialog(null)),
					cancel([ searchTask, dialogTask ]),
				])
				searchTask = null
				dialogTask = null
			}
			if (searchTimedOut) {
				yield all([
					put(setShopfrontDialog(null)),
					cancel([ searchTask, dialogTask ]),
				])
				searchTask = null
				dialogTask = null
				const { runAction, newData } = yield race({
					runAction: take(RUN_SEARCH),
					newData: take(chan),
				})
				if (runAction || (newData && !deepEqual(params, newData.params))) {
					isForceRefresh = Boolean(runAction)
					params = get(runAction, 'payload') || newData.params
				}
			}
		}
	}
	finally {
		if (yield cancelled() && searchTask) {
			yield cancel(searchTask)
		}
	}
}
