import { getDate } from '@utils/time-utils'


export const INBOUND_DATE_PLACEHOLDER = '_'

const isLocalityStringValid = (localityString = '') => (localityString.split(':').length === 3)

export const composeLocalityString = ({ title, kind = 'locality', id }) => (
	`${title}:${kind}:${id}`
)

export const parseLocalityString = (localityString = '') => {
	const [ title, kind, id ] = localityString.split(':')

	return {
		title,
		kind,
		id: parseInt(id, 10),
	}
}

const DATE_RE = /\d{4}-\d{2}-\d{2}/

export const getSearchParams = (routeParams = {}) => {
	const { src, dst, outboundDate, inboundDate, adults, children, infants } = routeParams

	return {
		src,
		dst,
		outboundDate,
		inboundDate: inboundDate === INBOUND_DATE_PLACEHOLDER ? '' : inboundDate,
		adults: parseInt(adults, 10),
		children: parseInt(children, 10),
		infants: parseInt(infants, 10),
	}
}

export const isValidRouteParams = (routeParams) => {
	const { src, dst, outboundDate, inboundDate, adults, children, infants } = routeParams

	return isLocalityStringValid(src)
		&& isLocalityStringValid(dst)
		&& DATE_RE.test(outboundDate)
		&& (inboundDate === INBOUND_DATE_PLACEHOLDER
			|| (DATE_RE.test(inboundDate) && getDate(inboundDate) > getDate(outboundDate))
		)
		&& adults > 0
		&& !isNaN(children)
		&& !isNaN(infants)
}

export const getQueryFromString = (str) => {
	const [ src, dst, outboundDate, inboundDate, adults, children, infants ] = str.split('/')
	return {
		src,
		dst,
		outboundDate,
		inboundDate: inboundDate === INBOUND_DATE_PLACEHOLDER ? '' : inboundDate,
		adults: parseInt(adults, 10),
		children: parseInt(children, 10),
		infants: parseInt(infants, 10),
	}
}

const unexpectedSearchQueryError = 'Expect object - { src, dst, outboundDate, inboundDate, adults, children, infants }'

export const getStringFromQuery = (searchQuery = {}) => {
	const hasAllProperties = searchQuery.hasOwnProperty('src')
		&& searchQuery.hasOwnProperty('dst')
		&& searchQuery.hasOwnProperty('outboundDate')
		&& searchQuery.hasOwnProperty('inboundDate')
		&& searchQuery.hasOwnProperty('adults')
		&& searchQuery.hasOwnProperty('children')
		&& searchQuery.hasOwnProperty('infants')

	if (!hasAllProperties) throw new Error(unexpectedSearchQueryError)

	const { src, dst, outboundDate, inboundDate, adults, children, infants } = searchQuery
	const searchQueryArr = [ src, dst, outboundDate, inboundDate || INBOUND_DATE_PLACEHOLDER, adults, children, infants ]
	return searchQueryArr.join('/')
}
