import { get, keyBy } from 'lodash'
import { combineReducers } from 'redux'
import hashLib from '@libs/object-hash'

import { SET_DOMAIN_DATA } from '@store/state/types.js'


export const getCommonItemReducer = (item, key) => (state = {}, { type, payload }) => {
	switch (type) {
		case SET_DOMAIN_DATA: {
			const itemsArr = get(payload, `response.data.${item}`, [])
			if (itemsArr.length <= 0) return state
			const itemsByKey = keyBy(itemsArr, key)
			return {
				...state,
				...itemsByKey,
			}
		}
		default: return state
	}
}

export const faresReducer = (state = {}, { type, payload }) => {
	switch (type) {
		case SET_DOMAIN_DATA: {

			const faresArr = get(payload, 'response.data.fares', [])
			if (faresArr.length <= 0) return state

			// we could .map then do the keyBy thing,
			// but this works a bit faster
			let faresByKey = {}
			for (let i = 0; i < faresArr.length; i++) {
				const fare = faresArr[i]
				const _key = hashLib.getFareKey(fare)
				faresByKey[fare.id] = { ...fare, _key }
			}

			return {
				...state,
				...faresByKey,
			}
		}
		default: return state
	}
}

export const offersReducer = (state = {}, { type, payload }) => {
	switch (type) {
		case SET_DOMAIN_DATA: {
			const fares = get(payload, 'response.data.fares', [])
			const offers = get(payload, 'response.data.offers', [])
				.map((offer) => ({
					...offer,
					'fare_variants': offer.fares.reduce((acc, fareId) => {
						const fare = fares.find(({ id }) => (id === fareId)) || {}
						const variantId = fare['variant_id']
						return variantId ? { ...acc, [fareId]: variantId } : acc
					}, {}),
				}))
			if (offers.length <= 0) return state
			const itemsByKey = keyBy(offers, 'id')
			return {
				...state,
				...itemsByKey,
			}
		}
		default: return state
	}
}

export const productsReducer = (state = {}, { type, payload }) => {
	switch (type) {
		case SET_DOMAIN_DATA: {
			const products = get(payload, 'response.data.products', [])
			if (products.length <= 0) return state
			const itemsByKey = keyBy(products, ([ _, fareIds = [] ]) => fareIds.join(':'))
			return {
				...state,
				...itemsByKey,
			}
		}
		default: return state
	}
}

export default combineReducers({
	activities: getCommonItemReducer('activities', 'id'),
	agreements: getCommonItemReducer('agreements', 'id'),
	airlines: getCommonItemReducer('airlines', 'iata'),
	airports: getCommonItemReducer('airports', 'iata'),
	brands: getCommonItemReducer('brands', 'id'),
	contacts: getCommonItemReducer('contacts', 'id'),
	fares: faresReducer,
	hotels: getCommonItemReducer('hotels', 'id'),
	offers: offersReducer,
	orders: getCommonItemReducer('orders', 'id'),
	packages: getCommonItemReducer('packages', 'id'),
	passengers: getCommonItemReducer('passengers', 'id'),
	products: productsReducer,
	segments: getCommonItemReducer('segments', 'id'),
	services: getCommonItemReducer('services', 'id'),
})
