import { get, setWith } from 'lodash'
import { combineReducers } from 'redux'

import { SET_DOMAIN_DATA, SET_TIMESTAMP, CLEAR_STORE, CLEAR_ORDER } from '@store/state/types.js'
import dataReducer from './dataReducers.js'


const customPick = (obj, paths) => {
	const pathsLen = paths.length
	const result = {}
	for (let i = 0; i < pathsLen; i++) {
		const path = paths[i]
		const value = get(obj, path)
		setWith(result, path, value, Object)
	}
	return result
}

const initialState = {}

const metaReducer = (state = {}, { type, payload }) => {
	switch (type) {
		case SET_DOMAIN_DATA: {
			const meta = get(payload, 'response.meta', {})
			return {
				...state,
				...meta,
			}
		}
		default: return state
	}
}

const timestampReducer = (state = null, { type, payload }) => {
	switch (type) {
		case SET_TIMESTAMP: {
			const { timestamp } = payload
			return timestamp
		}
		default: return state
	}
}

const combinedReducer = combineReducers({
	data: dataReducer,
	meta: metaReducer,
	timestamp: timestampReducer,
})

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case SET_DOMAIN_DATA:
		case SET_TIMESTAMP: {
			const { searchQuery } = payload
			return {
				...state,
				[searchQuery]: combinedReducer(state[searchQuery], { type, payload }),
			}
		}
		case CLEAR_STORE: {
			const { searchQuery, keysToKeep } = payload
			return {
				...state,
				[searchQuery]: {
					data: {},
					...customPick(state[searchQuery], keysToKeep),
				},
			}
		}
		case CLEAR_ORDER: {
			const { searchQuery } = payload
			if (searchQuery) {
				return {
					...state,
					[searchQuery]: {
						...state[searchQuery],
						...{ data: {
							...state[searchQuery].data,
							...{ orders: {} },
						} },
					},
				}
			}
			else {
				return state
			}
		}
		default: return state
	}
}

export default reducer
