import { geoSelector } from '@store/state/appState/selectors'

import customFlightsSelector from './customFlights.js'
import getFlightProps from '../helpers/getFlightProps.js'
import getFaresIdsSet from '../helpers/getFaresIdsSet.js'


const isSegment = ({ isSegment }) => isSegment

const getSegmentsCount = (flights, geoData) => {
	const [ outboundFlight, inboundFlight ] = flights
	const faresIdsSet = getFaresIdsSet(flights)
	const { items: outboundParts } = getFlightProps(outboundFlight, 'outbound', faresIdsSet, geoData)
	const { items: inboundParts = [] } = inboundFlight
		? getFlightProps(inboundFlight, 'inbound', faresIdsSet, geoData)
		: {}
	return {
		outboundSegmentsCount: outboundParts.filter(isSegment).length,
		inboundSegmentsCount: inboundParts.filter(isSegment).length,
	}
}

const segmentsCountSelector = (state) => {
	const geoData = geoSelector(state)
	const flights = customFlightsSelector(state)
	return getSegmentsCount(flights, geoData)
}

export default segmentsCountSelector
