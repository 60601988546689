import l from '@libs/lang'
import { makeCX } from '@utils/taffy'

import LoadingSpinner from '@atoms/LoadingSpinner'

import $ from '../styles/tabItems'


const cx = makeCX()

const TabItem = (props) => {
	const { isActive, classNames, onClick, children } = props

	const tabItemClass = cx({
		[$.tabItem]: true,
		[$.tabItemActive]: isActive,
		[classNames]: true,
	})

	return (
		<li className={tabItemClass} onClick={onClick}>
			{children}
		</li>
	)
}

export const TicketsItem = (props) => {
	const { count, isLoading, isActive, onClick } = props
	const txtTitle = l('Авиабилеты')
	const txtCount = l('{count}', { count })

	const caption = isLoading
		? (<div className={$.spinner}><LoadingSpinner strokeWidth={4}/></div>)
		: <span className={$.count}>{txtCount}</span>

	return (
		<TabItem isActive={isActive} classNames={$.ticketsItem} onClick={onClick}>
			{txtTitle}
			{isActive && caption}
		</TabItem>
	)
}

export const HotelsItem = (props) => {
	const { count, isLoading, isActive, onClick } = props
	const txtTitle = l('Отели')
	const txtCount = l('{count}', { count })

	const caption = isLoading
		? (<div className={$.spinner}><LoadingSpinner strokeWidth={4}/></div>)
		: <span className={$.count}>{txtCount}</span>

	return (
		<TabItem isActive={isActive} classNames={$.hotelsItem} onClick={onClick}>
			{txtTitle}
			{isActive && caption}
		</TabItem>
	)
}

export const CartItem = (props) => {
	const { ticketsPrice = 0, hotelsPrice = 0, isActive, onClick } = props
	const totalPrice = ticketsPrice + hotelsPrice
	const txtTitle = l('Оформление заказа')
	const txtPrice = l.currency(totalPrice)

	const caption = totalPrice > 0 && <span className={$.count}>{txtPrice}</span>

	return (
		<TabItem isActive={isActive} classNames={$.cartItem} onClick={onClick}>
			{txtTitle}
			{isActive && caption}
		</TabItem>
	)
}
