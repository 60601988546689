import { connect } from 'react-redux'
import cx from 'classnames'
import { push as pushAction } from 'connected-react-router'

import l from '@libs/lang'
import { createEnhancer } from '@utils/decoract'
import { USER_ROLE } from '@libs/foma/types'
import { toCabinet, _setWindowLocation } from '@utils/router'

import { userDataSelector } from '@store/state/appState/selectors'
import { logout as logoutAction } from '@store/state/appState/actions'

import LoginBtn from '@atoms/LoginBtn'
import AccountBtn from '@atoms/AccountBtn'
import AccountMenu from '@atoms/AccountMenu'
import TelegramBtn from '@atoms/TelegramHeaderBtn'

import $ from './styles/index.js'


const cq = {
	sm: {
		maxWidth: 50,
	},
}
const enhancer = createEnhancer({ cq })

export class AccountLogin extends React.Component {
	state = { isAccountMenuOpened: false }
	containerNode = null

	// eslint-disable-next-line react/no-deprecated
	componentWillMount () {
		document.addEventListener('click', this._handleDocumentClick)
	}

	componentWillUnmount () {
		document.removeEventListener('click', this._handleDocumentClick)
	}

	_openAccountMenu = () => this.setState({ isAccountMenuOpened: true })
	_closeAccountMenu = () => this.setState({ isAccountMenuOpened: false })

	_handleDocumentClick = (e) => {
		if (this.state.isAccountMenuOpened && this.containerNode && !this.containerNode.contains(e.target)) {
			this._closeAccountMenu()
		}
	}

	_menuItemClick = (onClick) => (e) => {
		onClick && onClick(e)
		this._closeAccountMenu()
	}

	_handleToCabinetClick = () => {
		const { push, userData } = this.props

		if (userData.role === USER_ROLE.AGENT) {
			_setWindowLocation(toCabinet())
		}
		else {
			push(toCabinet())
		}
	}

	_logout = () => {
		this.props.logout()
		this._closeAccountMenu()
	}

	render () {
		const {
			isMobile,
			userData,
			cq,
		} = this.props
		const { sm } = cq

		const { isAccountMenuOpened } = this.state

		const propsAccountBtn = {
			withText: false,
			onBtnClick: isAccountMenuOpened ? this._closeAccountMenu : this._openAccountMenu,
		}
		const propsLoginBtn = {
			withText: !isMobile,
		}
		const isLoggedIn = userData
		const btn = isLoggedIn
			? <AccountBtn {...propsAccountBtn}/>
			: <LoginBtn {...propsLoginBtn}/>
		const propsTelegramBtn = {
			withText: true,
			enabledAb: !isMobile,
			isMobile: isMobile,
		}
		const telegramBtn = isLoggedIn
			? <TelegramBtn {...propsTelegramBtn}/>
			: null

		const propsAccountMenu = {
			email: userData && userData.email,
			items: [ {
				value: l('Заказы'),
				onClick: this._menuItemClick(this._handleToCabinetClick),
			} ],
			onLogoutClick: this._logout,
		}
		const accountMenu = (
			<div className={$.menuWrapper}>
				<div className={$.menu}>
					<AccountMenu {...propsAccountMenu}/>
				</div>
			</div>
		)

		const containerClass = cx({
			[$.container]: !sm,
			[$.containerSm]: sm,
		})

		const btnWrapperClass = cx({
			[$.btnWrapperSm]: isMobile,
		})

		return (
			<div className={containerClass} ref={(n) => (this.containerNode = n)}>
				<div className={$.subContainer}>
					<div className={$.telegramBtnWrapper}>{telegramBtn}</div>
					<div className={btnWrapperClass}>{btn}</div>
				</div>
				{userData && isAccountMenuOpened ? accountMenu : null}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	userData: userDataSelector(state),
})
const mapDispatchToProps = {
	push: pushAction,
	logout: logoutAction,
}
const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(enhancer(AccountLogin))
