import { matchPath } from 'react-router-dom'
import PATHS from './paths'


const overlaidPaths = [
	PATHS.ADVANTAGES,
	PATHS.CONTACTS,
	PATHS.LOGIN,
	PATHS.PASSRESET,
	PATHS.PASSRECOVERY,
]

export default function (location) {
	return overlaidPaths.some((path) => matchPath(location.pathname, { path, exact: true }))
}
