import { createSelector } from 'reselect'
import {
	getAirports,
} from './core.js'
import {
	getAirportsFromSegment,
} from './itemsRelation.js'
import {
	sortedSegmentsFromOfferSelector,
	sortedSegmentsFromProductSelector,
} from './segments.js'


const makeAirportsSelector = (segmentsSelector) => createSelector(
	[ getAirports, segmentsSelector ],
	(airports, segments) => segments.reduce((acc, segment) => ({
		...acc,
		...getAirportsFromSegment(airports, segment),
	}), {})
)

export const airportsFromOfferSelector = makeAirportsSelector(sortedSegmentsFromOfferSelector)
export const airportsFromProductSelector = makeAirportsSelector(sortedSegmentsFromProductSelector)
