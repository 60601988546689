import { intersection, last } from 'lodash'
import { createSelector } from 'reselect'

import {
	outboundFlightFromOfferSelector,
} from '@store/state/domainData/selectors'
import { byCurrentOfferId } from '@store/state/appState/selectors'


const AIRPORTS_NOT_ALLOWED_BIRTH_CERT = [ 'LWN', 'EVN' ]

export const isProhibitBirthCertCountryCheck = (outboundFlight) => (
	intersection([ last(outboundFlight).arrive.airport ], AIRPORTS_NOT_ALLOWED_BIRTH_CERT).length > 0
)

export const isProhibitBirthCertCountry = createSelector(
	byCurrentOfferId(outboundFlightFromOfferSelector),
	isProhibitBirthCertCountryCheck
)
