import { takeEvery, select, put } from 'redux-saga/effects'
import { FOCUS } from '@store/state/types'
import {
	CUSTOMER_FORM,
	PASSENGERS_FORM,
	CREDIT_CARD_FORM,
} from '@store/state/uiState/forms/types'
import { parseFormFieldFocusKey } from '@store/focusMiddleware.js'
import { setBookStatus } from '@store/state/appState/actions'
import { OPERATION_STATUSES } from '@libs/foma/types'
import emptyFieldsCountSelector from './selectors/emptyFieldsCount.js'


export const MAX_EMPTY_FIELDS_COUNT = 1

export const neededFormNames = [
	CUSTOMER_FORM,
	PASSENGERS_FORM,
	CREDIT_CARD_FORM,
].map(({ NAME }) => (NAME))

export const takenActions = ({ type, payload }) => {
	if (type === FOCUS && payload) {
		const { formName } = parseFormFieldFocusKey(payload)
		return neededFormNames.includes(formName)
	}
	return false
}

export function* resetBookStatus (action) {
	const emptyFieldsCount = yield select(emptyFieldsCountSelector)

	if (emptyFieldsCount === MAX_EMPTY_FIELDS_COUNT) {
		yield put(setBookStatus(OPERATION_STATUSES.NONE))
	}
}

export default function* watchFocus () {
	yield takeEvery(takenActions, resetBookStatus)
}
