import { call, select, put, all } from 'redux-saga/effects'
import loadData from '@store/sagas/apiService'
import { LOAD_TYPE, OPERATION_STATUSES, REDIRECT_TO_CABINET_STATUS } from '@libs/foma/types'
import redirectBySubmitForm from '@utils/common/redirectBySubmitForm.js'
import submitFormToIframe from '@utils/common/submitFormToIframe.js'
import { setBookStatus, goToInvalidFormField, setRedirectToCabinetStatus } from '@store/state/appState/actions'
import {
	byCurrentOfferId,
	byCurrentSearchQuery,
	currentOfferIdSelector,
} from '@store/state/appState/selectors'
import { firstInvalidFieldSelector } from '@store/state/uiState/forms/selectors'
import {
	orderFromOfferIdSelector,
	offerByIdSelector,
	get3dsRequest,
} from '@store/state/domainData/selectors'
import { orderDataSelector } from './selectors/orderData.js'
import isOrderFormValidSelector from './selectors/isOrderFormValid.js'


export const BOOKING_REQUEST_TIMEOUT_MS = 300000
export const orderByCurrentOfferIdSelector = byCurrentOfferId(orderFromOfferIdSelector)
export const offerByCurrentOfferIdSelector = byCurrentOfferId(offerByIdSelector)
export const meta3dsByCurrentSearchQuerySelector = byCurrentSearchQuery(get3dsRequest)


export default function* bookWorker () {
	const orderData = yield select(orderDataSelector)

	if (!orderData) {
		const fieldPath = yield select(firstInvalidFieldSelector)
		yield put(goToInvalidFormField(fieldPath))
		return
	}

	const isOrderFormValid = yield select(isOrderFormValidSelector)
	if (!isOrderFormValid) return

	const [
		offerId,
		order,
		offer,
	] = yield all([
		select(currentOfferIdSelector),
		select(orderByCurrentOfferIdSelector),
		select(offerByCurrentOfferIdSelector),
	])

	yield put(setBookStatus(OPERATION_STATUSES.LOADING))
	yield put(setRedirectToCabinetStatus(REDIRECT_TO_CABINET_STATUS.NONE))

	const bookData = {
		type: LOAD_TYPE.BOOK,
		meta: {
			offerId,
			maybeOrderId: order ? order.id : null,
			bookingData: orderData,
			timeoutMs: BOOKING_REQUEST_TIMEOUT_MS,
			offerPrice: offer ? offer.price : null,
		},
	}
	let opStatus = yield call(loadData, bookData, [ OPERATION_STATUSES.PAYMENT_3DS_V2_REQUIRED ])

	if (opStatus === OPERATION_STATUSES.PAYMENT_3DS_V2_REQUIRED) {
		const meta3ds = yield select(meta3dsByCurrentSearchQuerySelector)
		yield call(submitFormToIframe, document, meta3ds)

		bookData.meta.bookingData['card_data'].sync = false
		bookData.meta.isBlock3DS = true
		bookData.meta.maybeOrderId = meta3ds.id
		opStatus = yield call(loadData, bookData)
	}

	if (opStatus === OPERATION_STATUSES.PAYMENT_3DS_REQUIRED) {
		try {
			const meta3ds = yield select(meta3dsByCurrentSearchQuerySelector)
			return yield call(redirectBySubmitForm, document, meta3ds)
		}
		catch (e) {
			window.Raven && window.Raven.captureException(e, { extra: { 
				opStatus: opStatus,
				orderId: order ? order.id : null,
			} })
			throw e
		}
	}

	yield put(setBookStatus(opStatus))
}
