import { connect } from 'react-redux'
import { NavLink, matchPath } from 'react-router-dom'
import { withRouter } from 'react-router'
import cx from 'classnames'

import l from '@libs/lang'
import { createEnhancer } from '@utils/decoract'
import { OPERATION_STATUSES } from '@libs/foma/types'
import { toSerp, PATHS } from '@utils/router'
import TABS from '@utils/serp-tabs'
import {
	serpParamsSelector,
	offerOpStatusSelector,
} from '@store/state/appState/selectors'
import { checkBookSelector } from '@store/state/selectors'

import { Scrollbars } from 'react-custom-scrollbars'

import styles from '../styles/tabs.js'


const cq = {
	lg: {
		minWidth: 1140,
	},
	md: {
		maxWidth: 1139,
		minWidth: 768,
	},
	sm: {
		maxWidth: 767,
	},
}
const enhancer = createEnhancer({ cq })

const Item = ({ isActive, disabled, children }) => (
	<button className={isActive ? styles.activeItem : styles.item} disabled={disabled}>
		{children}
	</button>
)

const Tabs = ({ isSuccess, cq, serpUrl }) => {
	const { lg, md, sm } = cq
	const txtSearchResults = l('Результаты поиска')
	const searchResults = (
		<NavLink to={serpUrl} className={styles.searchItem}>
			{txtSearchResults}
		</NavLink>
	)
	const txtCheckout = l('Бронирование и оплата')
	const txtSuccessPage = l('Ваш перелет')

	const containerClass = cx({
		[styles.container]: lg,
		[styles.containerMd]: md,
		[styles.containerSm]: sm,
	})

	const propsWrapper = {
		autoHeight: true,
	}

	return (
		<div className={containerClass}>
			<div className={styles.wrapper}>
				<Scrollbars {...propsWrapper}>
					<div className={styles.btnsWrapper}>
						<Item disabled={isSuccess}>{searchResults}</Item>
						<Item isActive={!isSuccess} disabled={isSuccess}>{txtCheckout}</Item>
						<Item isActive={isSuccess} disabled={!isSuccess}>{txtSuccessPage}</Item>
					</div>
				</Scrollbars>
			</div>
		</div>
	)
}

const mapStateToProps = (state, props) => {
	const { params: matchedParams } = props.match
	const isSerp = matchPath(props.location, { path: PATHS.SERP, exact: true })
	const params = isSerp ? matchedParams : serpParamsSelector(state)

	const offerOpStatus = offerOpStatusSelector(state)
	const { isSuccess: isBookSuccess } = checkBookSelector(state)
	const isLoading = offerOpStatus === OPERATION_STATUSES.NONE
		|| offerOpStatus === OPERATION_STATUSES.LOADING

	return {
		serpUrl: toSerp({ ...params, tab: TABS.TICKETS }),
		isSuccess: !isLoading && isBookSuccess,
	}
}
const connector = connect(mapStateToProps)

export default withRouter(connector(enhancer(Tabs)))
