import { take, put } from 'redux-saga/effects'
import { matchPath } from 'react-router'
import { PATHS } from '@utils/router'
import { SET_FORM_FIELD_VALUE, LOCATION_CHANGE } from '@store/state/types'
import {
	CREDIT_CARD_FORM,
} from '@store/state/uiState/forms/types'


const EVENT_NAME = 'CA_START_TO_FILL_CREDIT_CARD'

const setPanPattern = ({ type, payload }) => (
	type === SET_FORM_FIELD_VALUE
	&& payload.formName === CREDIT_CARD_FORM.NAME
	&& payload.fieldName === CREDIT_CARD_FORM.FIELDS.PAN
)
const checkoutTransitionPattern = ({ type, payload }) => (
	type === LOCATION_CHANGE
	&& payload.action !== 'REPLACE'
	&& Boolean(matchPath(payload.location.pathname, PATHS.CHECKOUT))
	&& !payload.location.search.includes('order_id')
	&& !payload.location.search.includes('book_by_redirect')
)
export default function* (analyticEventChan) {
	while (true) {
		yield take(setPanPattern)
		yield put(analyticEventChan, { name: EVENT_NAME })
		yield take(checkoutTransitionPattern)
	}
}

