import { createSelector } from 'reselect'
import { toIsoString } from '@utils/time-utils'
import ticketsSearchSelector from '@organisms/TicketsSerp/selectors/search'


export default createSelector(
	ticketsSearchSelector,
	([ cheapestTicket ]) => {

		if (!cheapestTicket) return null

		const { startTime, price } = cheapestTicket
		const startTimeDate = new Date(startTime)
		return [ {
			date: toIsoString(startTimeDate),
			price,
		} ]
	}
)
