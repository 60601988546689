import { PASSENGERS_FORM } from '../../types'
import { makeValueByFormIndexSelector } from '../core.js'


// field values
const makeValueFromIndexGetter = (valueByFormIndexSelector) => (state, formIndex) => {
	const valueByFormIndex = valueByFormIndexSelector(state)
	return valueByFormIndex[formIndex]
}
// lastName
export const lastNameByFormIndexSelector = makeValueByFormIndexSelector({
	formName: PASSENGERS_FORM.NAME,
	fieldName: PASSENGERS_FORM.FIELDS.LAST_NAME,
})
export const lastNameSelector = makeValueFromIndexGetter(lastNameByFormIndexSelector)
// firstName
export const firstNameByFormIndexSelector = makeValueByFormIndexSelector({
	formName: PASSENGERS_FORM.NAME,
	fieldName: PASSENGERS_FORM.FIELDS.FIRST_NAME,
})
export const firstNameSelector = makeValueFromIndexGetter(firstNameByFormIndexSelector)
// middleName
export const middleNameByFormIndexSelector = makeValueByFormIndexSelector({
	formName: PASSENGERS_FORM.NAME,
	fieldName: PASSENGERS_FORM.FIELDS.MIDDLE_NAME,
})
export const middleNameSelector = makeValueFromIndexGetter(middleNameByFormIndexSelector)
// gender
export const genderByFormIndexSelector = makeValueByFormIndexSelector({
	formName: PASSENGERS_FORM.NAME,
	fieldName: PASSENGERS_FORM.FIELDS.GENDER,
})
export const genderSelector = makeValueFromIndexGetter(genderByFormIndexSelector)
// birthdate
export const birthdateByFormIndexSelector = makeValueByFormIndexSelector({
	formName: PASSENGERS_FORM.NAME,
	fieldName: PASSENGERS_FORM.FIELDS.BIRTHDATE,
})
export const birthdateSelector = makeValueFromIndexGetter(birthdateByFormIndexSelector)
// citizenship
export const citizenshipByFormIndexSelector = makeValueByFormIndexSelector({
	formName: PASSENGERS_FORM.NAME,
	fieldName: PASSENGERS_FORM.FIELDS.CITIZENSHIP,
})
export const citizenshipSelector = makeValueFromIndexGetter(citizenshipByFormIndexSelector)
// document number
export const documentNumByFormIndexSelector = makeValueByFormIndexSelector({
	formName: PASSENGERS_FORM.NAME,
	fieldName: PASSENGERS_FORM.FIELDS.DOCUMENT_NUM,
})
export const documentNumSelector = makeValueFromIndexGetter(documentNumByFormIndexSelector)
// document expiration date
export const documentExpDateByFormIndexSelector = makeValueByFormIndexSelector({
	formName: PASSENGERS_FORM.NAME,
	fieldName: PASSENGERS_FORM.FIELDS.DOCUMENT_EXP_DATE,
})
export const documentExpDateSelector = makeValueFromIndexGetter(documentExpDateByFormIndexSelector)
