import { createSelector } from 'reselect'
import { ARRIVAL_SMS_FORM } from '../types.js'
import { makeValueSelector, makeInvalidSelector } from './core.js'
import {
	validatePhone,
} from '../validators'

import {
	smsArrivalFaresFromOfferSelector,
	makeAreFaresEnabledSelector,
} from '@store/state/domainData/selectors'
import { byCurrentOfferId } from '@store/state/appState/selectors'


const isUpsaleAcceptedSelector = byCurrentOfferId(
	makeAreFaresEnabledSelector(smsArrivalFaresFromOfferSelector)
)

// fields values
export const phoneSelector = makeValueSelector({
	formName: ARRIVAL_SMS_FORM.NAME,
	fieldName: ARRIVAL_SMS_FORM.FIELDS.PHONE,
})

// validators
export const isPhoneValidSelector = createSelector(
	[ phoneSelector, isUpsaleAcceptedSelector ],
	(value, isAccepted) => (
		isAccepted ? validatePhone(value) : true
	)
)

export const isFormValidSelector = isPhoneValidSelector

// errors fields
const makeEmptySelector = (valueSelector) => createSelector(
	valueSelector,
	isUpsaleAcceptedSelector,
	(value, isAccepted) => (
		isAccepted ? (value === '') : false
	)
)

export const errorFieldsSelectors = [ {
	formName: ARRIVAL_SMS_FORM.NAME,
	fieldName: ARRIVAL_SMS_FORM.FIELDS.PHONE,
	emptySelector: makeEmptySelector(phoneSelector),
	invalidSelector: makeInvalidSelector(
		phoneSelector,
		isPhoneValidSelector
	),
} ]
