import {
	CLEAR_FILTERS,
	RESET_FILTERS,
	UPDATE_FILTER,
	APPLY_FILTERS,
	APPLY_QUERY_FILTERS,
	SET_PAGE_NUM,
} from '@store/state/types'


export const clearFilters = () => ({
	type: CLEAR_FILTERS,
})

export const resetFilters = (serpType, filters) => ({
	type: RESET_FILTERS,
	payload: { serpType, filters },
})

export const updateFilter = (serpType, filter) => ({
	type: UPDATE_FILTER,
	payload: { serpType, filter },
})

export const applyFilters = (serpType, filters) => ({
	type: APPLY_FILTERS,
	payload: { serpType, filters },
})

export const applyQueryFilters = (serpType, filters) => ({
	type: APPLY_QUERY_FILTERS,
	payload: { serpType, filters },
})

export const setPageNum = (serpType, pageNum) => ({
	type: SET_PAGE_NUM,
	payload: { serpType, pageNum },
})

