import {
	FETCH_SUGGESTIONS,
	FETCH_SUGGESTIONS_RESOLVE,
	SET_GEO_DATA,
} from '@store/state/types'


export const fetchSuggestions = (fieldName, title) => ({
	type: FETCH_SUGGESTIONS,
	payload: {
		fieldName,
		title,
	},
})

export const fetchSuggestionsResolve = (fieldName, title, suggestions) => ({
	type: FETCH_SUGGESTIONS_RESOLVE,
	payload: {
		fieldName,
		title,
		suggestions,
	},
})

export const setGeoData = (payload) => ({ type: SET_GEO_DATA, payload })
