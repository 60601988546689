import { FLIGHT_CHECK_IN_FORM } from '../types.js'
import { makeValueSelector } from './core.js'


// fields values
export const rowSelector = makeValueSelector({
	formName: FLIGHT_CHECK_IN_FORM.NAME,
	fieldName: FLIGHT_CHECK_IN_FORM.FIELDS.ROW,
})
export const seatSelector = makeValueSelector({
	formName: FLIGHT_CHECK_IN_FORM.NAME,
	fieldName: FLIGHT_CHECK_IN_FORM.FIELDS.SEAT,
})

// validators
export const isRowValidSelector = () => (true)
export const isSeatValidSelector = () => (true)

export const isFormValidSelector = () => (true)
