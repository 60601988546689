import { flow } from 'lodash'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import accountDataFetcher, { RESET_PASSWORD_STATUS } from '@libs/account-data-fetcher'
import { RESET_PASSWORD } from '@store/state/types'
import { resetPasswordSelectors } from '@store/state/uiState/forms/selectors'
import * as actions from '@store/state/appState/actions'
import { locationQuerySelector } from '@store/state/appState/selectors'


export const tokenSelector = flow(
	locationQuerySelector,
	({ reset_password_token: token }) => token
)

export function* resetPasswordHandler () {

	const password = yield select(resetPasswordSelectors.passwordSelector)
	const passwordConfirm = yield select(resetPasswordSelectors.passwordConfirmSelector)
	const token = yield select(tokenSelector)

	try {
		yield call(accountDataFetcher.resetPassword, { password, passwordConfirm, token })
		yield put(actions.setResetPasswordStatus(RESET_PASSWORD_STATUS.SUCCESS))
	}
	catch (e) {
		yield put(actions.setResetPasswordStatus(RESET_PASSWORD_STATUS.ERROR))
	}
}

export default function* watchResetPassword () {
	yield takeEvery(RESET_PASSWORD, resetPasswordHandler)
}
