import RawBtn from './rawBtn.js'

import styles from '../styles/rightChev.js'
import icoChevLeft from '../images/icon-chev-left.svg'


export default ({ children, classNames, ...props }) => (
	<RawBtn classNames={[ styles.chev, classNames ]} {...props}>
		<div className={styles.content}>
			{children}
			<img src={icoChevLeft}/>
		</div>
	</RawBtn>
)
