import request from 'superagent'
import confy from '@utils/confy'
import getResponseHandler from './responseHandler.js'
import { OPERATION_STATUSES } from './statuses.js'


const ERROR_STATUSES = [
	OPERATION_STATUSES.INVALID,
	OPERATION_STATUSES.UNAVAIL,
	OPERATION_STATUSES.ERROR,
]
const OK_STATUSES = [
	OPERATION_STATUSES.SUCCESS,
]

export default function ({ orderId }) {
	const req = request.get(`${confy.get('API.cl.order')}/${orderId}/status`)

	const executor = (resolve, reject) => {
		const handler = getResponseHandler({
			resolve,
			reject,
			okStatuses: OK_STATUSES,
			errStatuses: ERROR_STATUSES,
			requestType: 'GET /orderStatus',
		})

		req.end(handler)
	}

	return {
		promise: new Promise(executor),
		cancelFn: () => req.abort(),
	}
}
