import { omit, uniqWith, mapValues, flatten, mapKeys, find, reject } from 'lodash'
import { createSelector } from 'reselect'
import { PRODUCT_TYPES } from '@libs/foma/types'
import { byCurrentOfferId } from '@store/state/appState/selectors'
import {
	flightFaresFromOfferSelector,
	enabledAccommodationFareIdSelector,
	offerByIdSelector,
} from '@store/state/domainData/selectors'


const flightFareIdsSelector = createSelector(
	byCurrentOfferId(flightFaresFromOfferSelector),
	(fares) => Object.keys(fares)
)
const accommodationFareIdsSelector = createSelector(
	byCurrentOfferId(enabledAccommodationFareIdSelector),
	(fareId) => (fareId ? [ fareId ] : [])
)
const enabledFareIdsByProductSelector = createSelector(
	flightFareIdsSelector,
	accommodationFareIdsSelector,
	(flightFareIds, accommodationFareIds) => ({
		...PRODUCT_TYPES.FLIGHTS.reduce((acc, type) => ({ ...acc, [type]: flightFareIds }), {}),
		[PRODUCT_TYPES.ACCOMMODATIONS]: accommodationFareIds,
	})
)

const GENERIC_FLIGHTS_TYPE = 'generic-flight'

const getGenericType = (type) => (
	PRODUCT_TYPES.FLIGHTS.includes(type)
		? GENERIC_FLIGHTS_TYPE
		: type
)

const areTypesEq = (a, b) => (getGenericType(a) === getGenericType(b))

const ENABLED_COUNT = 1

const toEnabledCounts = (acc, fareId) => ({ ...acc, [fareId]: ENABLED_COUNT })
export const getNewBuildOfferData = (offer = {}, enabledFareIdsByProduct, { replacedProducts, removedProducts }) => {
	const {
		fare_counts: fareCounts = {},
		products: currentProducts = [],
	} = offer

	const replacedProductTypes = uniqWith(
		replacedProducts.map(([ type ]) => getGenericType(type)), (a, b) => (a === b)
	)

	const enabledFareIdsByGenericProduct = mapKeys(enabledFareIdsByProduct, (_, type) => getGenericType(type))
	const currentProductsEnabledFareIds = flatten(
		replacedProductTypes.map((type) => enabledFareIdsByGenericProduct[type])
	)
	const removedProductsFareIds = removedProducts.reduce((acc, [ _, fareIds ]) => ([ ...acc, ...fareIds ]), [])

	const currentEnabledCounts = mapValues(fareCounts, ([ _, count ]) => count)
	const enabledCounts = {
		...omit(currentEnabledCounts, [ ...currentProductsEnabledFareIds, ...removedProductsFareIds ]),
		...replacedProducts.reduce((acc, [ _, fareIds ]) => ({ ...acc, ...fareIds.reduce(toEnabledCounts, {}) }), {}),
	}

	const currentOmittedProducts = currentProducts.filter(([ type ]) => (
		!Boolean(replacedProducts.find(([ replacedProductType ]) => areTypesEq(type, replacedProductType)))
		&& !Boolean(removedProducts.find(([ removedProductType ]) => areTypesEq(type, removedProductType)))
	))
	const products = [
		...currentOmittedProducts,
		...replacedProducts,
	]

	// request new insurances for new offer, because of they depends on flights
	const insurances = (find(products, (product) => product[0] === 'insurances') || [])[1] || []

	return {
		enabledCounts: omit(enabledCounts, insurances),
		products: reject(products, (product) => product[0] === 'insurances'),
	}
}

const buildOfferDataSelector = createSelector(
	byCurrentOfferId(offerByIdSelector),
	enabledFareIdsByProductSelector,
	(_, props) => props,
	getNewBuildOfferData
)

export default buildOfferDataSelector
