import { memoize } from 'lodash'
import { geoRequest as request } from '@utils/request'
import confy from '@utils/confy'


const morphoMap = {}
// TODO:
// * drop morphoMap off
// * remove unused methods

const getMorpho = (iata) => (morphoMap[iata])

const reqAirport = (id) => {
	const url = confy.get('API.geo.localities')
	const executor = (resolve, reject) => {
		request
			.get(`${url}?kind=locality&id=${id}`)
			.end((err, res) => {
				if (err || !res || (res && (!res.ok || !res.body || !res.body.length))) {
					resolve()
				}
				else {
					resolve()
				}
			})
	}

	return new Promise(executor)
}

const reqLocality = memoize((id) => {
	const url = confy.get('API.geo.localities')
	const executor = (resolve, reject) => {
		request
			.get(`${url}?kind=locality&id=${id}`)
			.end((err, res) => {
				if (err || !res || (res && (!res.ok || !res.body || !res.body.length))) {
					resolve()
				}
				else {
					resolve(res.body)
				}
			})
	}

	return new Promise(executor)
})

const reqCities = memoize((metros) => {
	const url = confy.get('API.geo.localities')
	const executor = (resolve, reject) => {
		request
			.get(`${url}?iatas=${metros.join(',')}`)
			.end((err, res) => {
				if (err || !res || (res && (!res.ok || !res.body || !res.body.length))) {
					resolve()
				}
				else {
					resolve(res.body)
				}
			})
	}

	return new Promise(executor)
}, (metros) => (metros.join(',')))

const sendReq = (iata) => new Promise((resolve, reject) => {

	const url = confy.get('API.geo.localities')

	const maybeMorpho = getMorpho(iata)

	if (maybeMorpho && maybeMorpho.airports) {
		resolve()
		return
	}
	else if (maybeMorpho) {
		resolve(reqAirport(maybeMorpho.id))
		return
	}

	request
		.get(`${url}/${iata}`)
		.end((err, res) => {
			if (err || !res || (res && (!res.ok || !res.body || !res.body.morpho))) {
				resolve()
			}
			else {
				resolve(reqAirport(res.body.id))
			}
		})
})

const requestMorpho = (iata) => {
	if (typeof iata === 'string') {
		return sendReq(iata)
	}
	else {
		return Promise.all(iata.map(sendReq))
	}
}


export default {
	getMorpho,
	requestMorpho,
	reqLocality,
	reqCities,
}
