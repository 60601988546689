import { LOGIN_FORM } from '../types'
import { makeValueSelector } from './core.js'


export const loginSelector = makeValueSelector({
	formName: LOGIN_FORM.NAME,
	fieldName: LOGIN_FORM.FIELDS.LOGIN,
})
export const passwordSelector = makeValueSelector({
	formName: LOGIN_FORM.NAME,
	fieldName: LOGIN_FORM.FIELDS.PASSWORD,
})

export const isLoginValidSelector = (state) => Boolean(loginSelector(state))
export const isPasswordValidSelector = (state) => Boolean(passwordSelector(state))

export const isFormValidSelector = (state) => (
	isLoginValidSelector(state) && isPasswordValidSelector(state)
)
