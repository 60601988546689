import $ from './styles/index.js'


const LoadingSpinner = ({ strokeWidth = 2 }) => (
	<div className={$.loader}>
		<svg className={$.circular} viewBox="25 25 50 50">
			<circle className={$.path} cx="50" cy="50" r="20" fill="none" strokeWidth={strokeWidth} strokeMiterlimit="10"/>
		</svg>
	</div>
)


export default LoadingSpinner
