import { push } from 'connected-react-router'
import { take, put, select, all } from 'redux-saga/effects'
import { DIALOGS } from '@utils/serp-tabs'
import { toCheckout } from '@utils/router'
import { toCheckoutQueryStringSelector } from '@store/state/selectors'
import { setShopfrontDialog } from '@store/state/appState/actions'
import { currentOfferIdSelector, byCurrentSearchQuery } from '@store/state/appState/selectors'
import { isAccomodationAwaitedSelector } from '@store/state/domainData/selectors'
import setOfferIdActionMatcher from '../../helpers/setOfferIdActionMatcher.js'

import {
	hasAccommodationSelector,
	hasTicketsSelector,
} from './selectors'


export const enhancedIsAccomodationAwaitedSelector = byCurrentSearchQuery(isAccomodationAwaitedSelector)

export default function* dialogWorker () {
	while (true) {
		const hadAccommodation = yield select(hasAccommodationSelector)

		yield take(setOfferIdActionMatcher)
		const [ hasAccommodation, hasTicket, isAccomodationAwaited, currentOfferId ] = yield all([
			select(hasAccommodationSelector),
			select(hasTicketsSelector),
			select(enhancedIsAccomodationAwaitedSelector),
			select(currentOfferIdSelector),
		])

		if (hasTicket && !isAccomodationAwaited) {
			const searchQuery = yield select(toCheckoutQueryStringSelector)
			yield put(push(toCheckout(currentOfferId, searchQuery)))
		}
		else if (hasTicket && hasAccommodation) {
			yield put(setShopfrontDialog(DIALOGS.TOUR_COMPLETE))
		}
		else if (!hasTicket && hasAccommodation) {
			yield put(setShopfrontDialog(DIALOGS.HOTEL_ADDED))
		}
		else if (hasTicket && !hasAccommodation && !hadAccommodation) {
			yield put(setShopfrontDialog(DIALOGS.TICKET_ADDED))
		}
	}
}
