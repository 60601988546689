import { uniqBy, last, head, sortBy } from 'lodash'
import { getDate } from '@utils/time-utils'


export const MORNING = 'MORNING'
export const DAYTIME = 'DAYTIME'
export const EVENING = 'EVENING'
export const NIGHT   = 'NIGHT'


const timesOfDay = [
	{ dayTime: MORNING, range: [ 6, 12 ] },
	{ dayTime: DAYTIME, range: [ 12, 17 ] },
	{ dayTime: EVENING, range: [ 17, 23 ] },
	{ dayTime: NIGHT, range: [ 23, 6 ] },
]

const getTime = ({ time = '' }) => getDate(time)

// todo: refactor
const isBetween = (value, [ min, max ]) => (value >= min && value < max)

export const getDayTime = (date) => {
	const hours = date.getHours() + (date.getMinutes() / 60)

	return timesOfDay.reduce((prev, { dayTime, range }) => {
		if (isBetween(hours, range)) {
			return dayTime
		}

		return prev
	}, NIGHT)
}

/*
	дает объект из дней, часов, минут из временной дельты в миллисекундах
	вспомогательный метод
*/
export const getDhm = (delta) => {
	let d = delta / 1000
	const r = {}      // result
	const s = {       // structure
		d: 86400,
		h: 3600,
		m: 60,
	}

	Object.keys(s).forEach((key) => {
		r[key] = Math.floor(d / s[key])
		d -= r[key] * s[key]
	})

	return {
		...r,
		delta: d,
	}
}

const minToMs = (mins) => (mins * 60 * 1000)

/*
	по массиву сегментов полета считает общее время маршрута,
	складывая время в пути (дается в минутах), время пересадки
*/
export const getTotalTime = (segments) => (
	segments.reduce((prevDuration, segment, index, sgmnts) => {

		const nextSegment = sgmnts[index + 1]
		const duration = prevDuration + minToMs(segment['travel_time'])

		if (!nextSegment) {
			return duration
		}

		const swtchDuration = getTime(nextSegment.depart) - getTime(segment.arrive)

		return duration + swtchDuration
	}, 0)
)

/*
	по массиву сегментов полета считает даные, помогающие
	отобразить карточку с маршрутом перелета
*/
export const calculateRoute = (segments) => {

	const uid = segments.map(({ id }) => (id)).join('/')

	const firstSegment = head(segments) || {}
	const lastSegment = last(segments) || {}
	const [ start = {}, end = {} ] = [ firstSegment.depart, lastSegment.arrive ]
	const [ transferStart, transferEnd ] = [ getTime(start), getTime(end) ]
	const totalTime = getTotalTime(segments)

	const parts = segments.reduce((parts, segment, index, sgmnts) => {

		const nextSegment = sgmnts[index + 1]
		const arriveTime = getTime(segment.arrive)
		const duration = minToMs(segment['travel_time'])

		const seg = {
			partType: 'segment',
			width: duration / totalTime,
			duration,
			...segment,
		}

		if (!nextSegment) {
			return [ ...parts, seg ]
		}

		const nextDepartTime = getTime(nextSegment.depart)

		return [
			...parts,
			seg,
			{
				partType: 'switch',
				id: segment.arrive.airport.iata,
				airport: segment.arrive.airport,
				width: (nextDepartTime - arriveTime) / totalTime,
				duration: (nextDepartTime - arriveTime),
			},
		]
	}, [])

	const swtchsDuration = parts
		.filter(({ partType }) => (partType === 'switch'))
		.reduce((sum, { duration }) => (sum + duration), 0)

	return {
		totalTime,
		parts,
		start,
		end,
		transferStart,
		transferEnd,
		segments,
		swtchsDuration,
		uid,
	}
}


/*
	получает все уникальные авиалинии по сегментам полета
*/
export const getAirlines = (segments) => {
	const sorted = sortBy([ ...segments ], (segment) => (-1 * segment.travel_time))

	return uniqBy(sorted.map(({ airline }) => airline), 'iata')
}


/*
	получает минимальный вес багажа по сегментам
*/
export const getBaggageWeight = (segments, type = 'checked') => (
	Math.min(...segments.map(({ baggage }) => (
		baggage[type].length ? Math.min(...baggage[type]) : 0
	)))
)

