import { createStore, applyMiddleware, combineReducers } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import { connectRouter, routerMiddleware as createRouterMiddleware } from 'connected-react-router'
import { enableBatching } from 'redux-batched-actions'
import logger from 'redux-logger'

import confy from '@utils/confy'
import makeAnaliticsStore from '@utils/analiticsStore'

import createAppReducer from './state/index.js'
import history from './history.js'
import createFocusMiddleware, {
	createFormFieldFocusKeyGetter,
	parseFormFieldFocusKey,
} from './focusMiddleware.js'
import { handleFocusEvent, handleBlurEvent } from './state/appState/actions'
import { activeElementSelector } from './state/appState/selectors'
import { FOCUS } from './state/types'


const sagaMiddleware = createSagaMiddleware()
const routerMiddleware = createRouterMiddleware(history)
const focusMiddleware = createFocusMiddleware(handleFocusEvent, handleBlurEvent, activeElementSelector, FOCUS)

const middleware = [ routerMiddleware, focusMiddleware, sagaMiddleware ]

if (confy.get('COMMON.reduxLogger.enabled') === 1) middleware.push(logger)

const composeEnhancer = composeWithDevTools({
	maxAge: 150,
})

const enhancer = composeEnhancer(
	applyMiddleware(...middleware)
)

export {
	history,
	createFormFieldFocusKeyGetter,
	parseFormFieldFocusKey,
}


export default (initialState = {}) => {
	const rootReducer = combineReducers({
		app: createAppReducer(history),
		router: connectRouter(history), // Note: The key MUST be router, see connected-react-router docs
	})

	const store = createStore(enableBatching(rootReducer), initialState, enhancer)
	makeAnaliticsStore(store)

	return {
		...store,
		runSaga: (rootSaga) => sagaMiddleware.run(rootSaga),
	}
}
