import { takeLatest, call } from 'redux-saga/effects'

import supportFetcher from '@libs/support-fetcher'
import { SEND_SUPPORT_FEEDBACK } from '@store/state/types'


export function* sendSupportFeedbackWorker ({ payload: feedbackData }) {
	yield call(supportFetcher.sendFeedback_v2, feedbackData)
}

export default function* sendSupportFeedbackWatcher () {
	yield takeLatest(SEND_SUPPORT_FEEDBACK, sendSupportFeedbackWorker)
}
