import PropTypes from 'prop-types'
import cn from 'classnames'

import l from '@libs/lang'

import CounterGroup from 'core-components/CounterGroup'
import Dropdown from 'core-components/Dropdown'
import { PrimaryOrangeSm as PrimaryOrangeSmBtn } from 'core-components/Button'

import styles from './styles/consistSelector.js'
import icoDown from './images/icon-down-menu.svg'
import icoClose from './images/icon-close.svg'


const txtAdults = l('Взрослые')
const txtAdultsCaption = l('12 лет и старше')
const txtChildren = l('Дети')
const txtChildrenCaption = l('от 2 до 12 лет')
const txtInfants = l('Младенцы')
const txtInfantsCaption = l('до 2 лет')
const txtTitle = l('Выберите количество пассажиров')
const txtInfantsInfo = l('Младенцы летят без места')
const txtConsistSelector = l('Пассажиры')
const txtApply = l('Применить')
const MIN_ADULTS = 1
const MIN_CHILDREN = 0
const MIN_INFANTS = 0

const countersConfig = [ {
	key: 'adults',
	title: (
		<div className={styles.counterTitle}>
			<span>{txtAdults}</span>
			<span className={styles.caption}>{txtAdultsCaption}</span>
		</div>
	),
	isIncActive: () => true,
	isDecActive: (value) => value > MIN_ADULTS,
	onIncrement: (value, onValueChange) => () => onValueChange(value + 1),
	onDecrement: (value, onValueChange) => () => onValueChange(value - 1),
}, {
	key: 'children',
	title: (
		<div className={styles.counterTitle}>
			<span>{txtChildren}</span>
			<span className={styles.caption}>{txtChildrenCaption}</span>
		</div>
	),
	isIncActive: () => true,
	isDecActive: (value) => value > MIN_CHILDREN,
	onIncrement: (value, onValueChange) => () => onValueChange(value + 1),
	onDecrement: (value, onValueChange) => () => onValueChange(value - 1),
}, {
	key: 'infants',
	title: (
		<div className={styles.counterTitle}>
			<span>{txtInfants}</span>
			<span className={styles.caption}>{txtInfantsCaption}</span>
		</div>
	),
	isIncActive: () => true,
	isDecActive: (value) => value > MIN_INFANTS,
	onIncrement: (value, onValueChange) => () => onValueChange(value + 1),
	onDecrement: (value, onValueChange) => () => onValueChange(value - 1),
} ]

class ConsistSelector extends React.Component {
	_getCounters = () => countersConfig.map((config) => {
		const { value, onValueChange } = this.props[config.key]
		return {
			...config,
			value,
			isIncActive: config.isIncActive(value),
			isDecActive: config.isDecActive(value),
			onIncrement: config.onIncrement(value, onValueChange),
			onDecrement: config.onDecrement(value, onValueChange),
		}
	})

	_renderHeader = (hidePopover) => (
		<div className={styles.headerPopover}>
			{txtConsistSelector}
			<img src={icoClose} alt="close" onClick={hidePopover}/>
		</div>
	)

	_renderFooter = (hidePopover) => (
		<PrimaryOrangeSmBtn classNames={styles.footerPopover} onClick={hidePopover}>
			{txtApply}
		</PrimaryOrangeSmBtn>
	)

	_renderPopover = (setPopoverRef, hidePopover) => {
		const { isOverlaidPicker } = this.props
		const propsCounterGroup = {
			setRef: setPopoverRef,
			classNames: styles,
			header: txtTitle,
			footer: txtInfantsInfo,
			counters: this._getCounters(),
		}
		return (
			<div className={styles.popover}>
				{isOverlaidPicker && this._renderHeader(hidePopover)}
				<CounterGroup {...propsCounterGroup}/>
				{isOverlaidPicker && this._renderFooter(hidePopover)}
			</div>
		)
	}

	_renderInput = (showPopover, hidePopover, isShownPopover) => {
		const { style, adults, children, infants } = this.props
		const txtConsist = l(`{consistCount, plural,
			one {{consistCount} человек}
			few {{consistCount} человека}
			many {{consistCount} человек}
		}`, { consistCount: adults.value + children.value + infants.value })
		const togglePopover = isShownPopover ? hidePopover : showPopover
		const propsField = {
			style,
			className: cn({
				[styles.field]: true,
				[styles.activeField]: isShownPopover,
			}),
			onClick: togglePopover,
		}
		return (
			<span {...propsField}>
				{txtConsist}
				<img src={icoDown}/>
			</span>
		)
	}

	render () {
		const { isOverlaidPicker, onFocus, onBlur } = this.props
		const propsDropdown = {
			renderInput: this._renderInput,
			renderPopover: this._renderPopover,
			isHiddenOnBlur: true,
			isOverlaidPopover: isOverlaidPicker,
			popoverWidth: '',
			onFocus,
			onBlur,
		}
		return (<Dropdown {...propsDropdown}/>)
	}
}

const consistPropTypesShape = {
	value: PropTypes.number.isRequired,
	onValueChange: PropTypes.func.isRequired,
}

ConsistSelector.propTypes = {
	adults: PropTypes.shape(consistPropTypesShape).isRequired,
	children: PropTypes.shape(consistPropTypesShape).isRequired,
	infants: PropTypes.shape(consistPropTypesShape).isRequired,
	style: PropTypes.object,
}

export default ConsistSelector
