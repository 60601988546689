const getHandler = ({ dispatch }, handler) => ({ target, relatedTarget }) => (
	dispatch(handler({ target, relatedTarget }))
)

export const createFormFieldFocusKeyGetter = (formName) => (fieldName, formIndex = '') => (
	[ formIndex, formName, fieldName ].join('/')
)
export const parseFormFieldFocusKey = (name) => {
	const [ formIndex, formName, fieldName ] = name.split('/')
	return { formIndex, formName, fieldName }
}

export default function createFocusMiddleware (handleFocus, handleBlur, activeElementSelector, focusType) {
	return function focusMiddleware (store) {
		const { documentElement } = document
		documentElement.addEventListener('focus', getHandler(store, handleFocus), true)
		documentElement.addEventListener('focusout', getHandler(store, handleBlur))

		return (next) => (action) => {
			if (action.type === focusType) {
				const prevActiveElem = activeElementSelector(store.getState())
				const enhancedFocusAction = { ...action, meta: { prevActiveElem } }
				return next(enhancedFocusAction)
			}
			return next(action)
		}
	}
}
