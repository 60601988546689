import { flow } from 'lodash'
import { SEARCH_TRANSFER_FORM } from '../types'
import { makeValueSelector } from './core.js'


// values
export const arriveDstSelector = makeValueSelector({
	formName: SEARCH_TRANSFER_FORM.NAME,
	fieldName: SEARCH_TRANSFER_FORM.FIELDS.ARRIVE_DST,
})
export const departSrcSelector = makeValueSelector({
	formName: SEARCH_TRANSFER_FORM.NAME,
	fieldName: SEARCH_TRANSFER_FORM.FIELDS.DEPART_SRC,
})
export const directionSelector = makeValueSelector({
	formName: SEARCH_TRANSFER_FORM.NAME,
	fieldName: SEARCH_TRANSFER_FORM.FIELDS.DIRECTION,
})

// validators
export const isArriveDstValidSelector = flow(arriveDstSelector, Boolean)
export const isDepartSrcValidSelector = flow(departSrcSelector, Boolean)
export const isFormValidSelector = (state) => (
	isArriveDstValidSelector(state)
	|| isDepartSrcValidSelector(state)
)
