import React from 'react'


class DelayedSwitch extends React.PureComponent {
	constructor (props) {
		super(props)
		this.state = {
			condition: props.condition,
		}
	}

	componentWillReceiveProps (nextProps) {
		const { delayOut, delayIn, condition } = nextProps
		const { condition: prevCondition } = this.props

		if (condition !== prevCondition) {

			const isOut = !condition && prevCondition

			setTimeout(() => {
				this.setState(() => ({ condition }))
			}, isOut ? delayOut : delayIn)
		}
	}

	render () {
		const { inContent, outContent } = this.props
		const { condition } = this.state

		return condition ? inContent : outContent
	}
}

export default DelayedSwitch
