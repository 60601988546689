/* DEPRECATED! SHOULD BE REMOVED */
import classNames from 'classnames/bind'


export const makeCX = ($) => classNames.bind($)
export const cx = makeCX()

// Soft Grid
export const sg = (pt) => (8 * pt)

// Пустышка для выдачи значений переменных, которые в будущем должны быть зависимы от темы
export const tm = (prop, val) => (val)

// layouts helper
export { layouts } from './layouts.js'

export { baseCSSDecorator, createWrapperDecorator } from './storybull-decorators.js'
