import request from 'superagent'
import confy from '@utils/confy'
import { INBOUND_DATE_PLACEHOLDER } from '@utils/common/searchQuery'


const composeUrl = (pattern, params) => (
	Object.keys(params).reduce((prev, key) => (
		prev.replace(`:${key}`, params[key])
	), pattern)
)

export const track = ({ searchQuery, offerId }) => {

	const url = confy.get('API.tracker.s')

	const { src, dst, outboundDate, inboundDate, adults, children, infants } = searchQuery

	const params = {
		id: offerId,
		depart: outboundDate,
		return: inboundDate || INBOUND_DATE_PLACEHOLDER,
		channel: confy.get('API.cl.channel'),
		src,
		dst,
		adults,
		children,
		infants,
	}

	const composed = composeUrl(url, params)

	const executor = (resolve, reject) => {
		request
			.get(composed)
			.withCredentials()
			.end((err, res) => {
				resolve()
			})
	}

	return new Promise(executor)
}
