import React, { PureComponent } from 'react'
import Popper from 'popper.js'
import TransitionGroup from 'react-transition-group/TransitionGroup'
import CSSTransition from 'react-transition-group/CSSTransition'
import $ from './styles/TooltipTemplate'


export const ENTER_TIMEOUT = 250
export const EXIT_TIMEOUT = 180

const transitionGroupProps = {
	component: 'div',
}
const cssTransitionProps = {
	classNames: 'fade',
	timeout: {
		enter: ENTER_TIMEOUT,
		exit: EXIT_TIMEOUT,
	},
}


class TooltipTemplate extends PureComponent {

	_popper = null

	initPopper = () => {
		const { props, divRef, arrowRef } = this
		const { referenceElement, placement } = props

		if (!divRef) return

		this._popper = new Popper(referenceElement, divRef, {
			placement,
			// eventsEnabled: false,
			modifiers: {
				arrow: {
					element: arrowRef,
				},
				flip: {
					behavior: [ 'left', 'bottom', 'top', 'right' ],
				},
				preventOverflow: {
					boundariesElement: document.body,
				},
			},
		})
	}

	componentDidUpdate (prevProps) {

		const { isVisible } = this.props
		const { isVisible: wasVisible } = prevProps

		if (isVisible && !wasVisible) this.initPopper()
		if (isVisible && wasVisible && this._popper) this._popper.scheduleUpdate()
	}

	setDivRef = (ref) => {
		this.divRef = ref
		if (!ref && this._popper) this._popper.destroy()
	}

	setArrowRef = (ref) => {
		this.arrowRef = ref
	}

	render () {
		const { content, isVisible, placement, onMouseEnter, onMouseLeave, styles } = this.props
		const $$ = styles || $

		const propsContainer = {
			ref: this.setDivRef,
			className: $$[placement],
			onMouseEnter,
			onMouseLeave,
		}

		return (
			<TransitionGroup {...transitionGroupProps}>
				{isVisible && (
					<CSSTransition {...cssTransitionProps}>
						<div {...propsContainer}>
							<div>
								{content}
							</div>
							<span ref={this.setArrowRef} className={$$.arrow}/>
						</div>
					</CSSTransition>
				)}
			</TransitionGroup>
		)
	}
}


export default TooltipTemplate
