import { EXTENDED_SERVICES } from '@libs/foma/types'
import {
	offerByIdSelector,
	serviceByFareIdSelector,
} from '@store/state/domainData/selectors'


export const isOnlyFlightEnabledOfferResult = (fareCounts, serviceByFareId) => (
	Object.keys(fareCounts).reduce((acc, fareId) => {
		const { type } = serviceByFareId[fareId]
		const [ min, enabledCount ] = fareCounts[fareId]
		return acc && (type === EXTENDED_SERVICES.TRANSFER || min === enabledCount)
	}, true)
)

const isOnlyFlightEnabledOfferSelector = (state, searchQuery, offerId) => {
	const { fare_counts: fareCounts } = offerByIdSelector(state, searchQuery, offerId)
	const serviceByFareId = serviceByFareIdSelector(state, searchQuery)
	return isOnlyFlightEnabledOfferResult(fareCounts, serviceByFareId)
}

export default isOnlyFlightEnabledOfferSelector
