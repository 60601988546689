import { call, put } from 'redux-saga/effects'
import loadData from '@store/sagas/apiService'
import { LOAD_TYPE, OPERATION_STATUSES } from '@libs/foma/types'
import { setBookStatus } from '@store/state/appState/actions'


export const BOOKING_REQUEST_TIMEOUT_MS = 300000

export default function* book3dsWorker ({ payload }) {

	const { orderId } = payload
	yield put(setBookStatus(OPERATION_STATUSES.LOADING))

	const bookData = {
		type: LOAD_TYPE.BOOK,
		meta: {
			maybeOrderId: orderId,
			timeoutMs: BOOKING_REQUEST_TIMEOUT_MS,
			isConfirm: true,
		},
	}

	const opStatus = yield call(loadData, bookData)
	yield put(setBookStatus(opStatus))
}
