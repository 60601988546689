import SERP_TABS from '@utils/serp-tabs'
import {
	CLEAR_FILTERS,
	RESET_FILTERS,
	UPDATE_FILTER,
	APPLY_FILTERS,
	APPLY_QUERY_FILTERS,
	SET_PAGE_NUM,
} from '@store/state/types'


export const initialState = {
	[SERP_TABS.TICKETS]: {
		current: [],
		applied: [],
		pageNum: 0,
	},
	[SERP_TABS.HOTELS]: {
		current: [],
		applied: [],
		pageNum: 0,
	},
}

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case CLEAR_FILTERS: {
			return initialState
		}
		case RESET_FILTERS: {
			const { filters, serpType } = payload
			return {
				...state,
				[serpType]: {
					...state[serpType],
					current: state[serpType].current.map((filter) => {
						const resettedFilter = filters.find(({ id }) => (id === filter.id))
						return resettedFilter || filter
					}),
					applied: state[serpType].applied.map((filter) => {
						const resettedFilter = filters.find(({ id }) => (id === filter.id))
						return resettedFilter || filter
					}),
				},
			}
		}
		case UPDATE_FILTER: {
			const { filter, serpType } = payload
			const serpState = state[serpType]
			const hasCurrentFilter = serpState.current
				.findIndex(({ id }) => (id === filter.id)) > -1
			return {
				...state,
				[serpType]: {
					...serpState,
					current: hasCurrentFilter
						? serpState.current.map((_filter) => (
							_filter.id === filter.id
								? { ..._filter, values: filter.values }
								: _filter
						))
						: [ ...serpState.current, filter ],
				},
			}
		}
		case APPLY_FILTERS: {
			const { filters, serpType } = payload
			return {
				...state,
				[serpType]: {
					...state[serpType],
					applied: filters,
				},
			}
		}
		case APPLY_QUERY_FILTERS: {
			const { filters, serpType } = payload
			return {
				...state,
				[serpType]: {
					...state[serpType],
					applied: filters,
					current: filters.map((filter) => ({ id: filter.id, values: filter.values })),
				},
			}
		}
		case SET_PAGE_NUM: {
			const { serpType, pageNum } = payload
			return {
				...state,
				[serpType]: {
					...state[serpType],
					pageNum,
				},
			}
		}
		default: return state
	}
}

export default reducer
