import { find, flow, isEmpty } from 'lodash'
import { createSelector } from 'reselect'
import { PRODUCT_TYPES } from '@libs/foma/types'
import { accommodationFaresFromOfferSelector } from './servicesFares.js'
import { offerByIdSelector, getHotels, getScheduledProducts } from './core.js'


const ENABLED_COUNT_OFFSET = 1

const enabledAccommodationFareResult = (fares, { fare_counts: fareCounts } = {}) => (
	fareCounts ?  find(fares, ({ id }) => fareCounts[id][ENABLED_COUNT_OFFSET] > 0) : null
)

export const enabledAccommodationFareSelector = createSelector(
	[ accommodationFaresFromOfferSelector, offerByIdSelector ],
	enabledAccommodationFareResult
)
export const enabledAccommodationFareIdSelector = flow(
	enabledAccommodationFareSelector,
	(fare) => (fare ? fare.id : null),
)
export const isAccomodationAwaitedResult = (hotels, scheduledProducts = []) => (
	!isEmpty(hotels) || scheduledProducts.includes(PRODUCT_TYPES.ACCOMMODATIONS)
)
export const isAccomodationAwaitedSelector = (state, query) => {
	const hotels = getHotels(state, query)
	const scheduledProducts = getScheduledProducts(state, query)
	return isAccomodationAwaitedResult(hotels, scheduledProducts)
}
