export const checkIntl = async (runApp) => {
	if (!window.Intl) {
		try {
			await import('intl')
			await Promise.all([
				import('intl/locale-data/jsonp/en.js'),
				import('intl/locale-data/jsonp/ru.js'),
			])
		}
		catch (err) {
			/* eslint-disable no-console */
			console.error('Intl not loaded: ', err)
			console.error('Load component without intl')
			/* eslint-enable no-console */
		}
		finally {
			runApp()
		}
	}
	else {
		runApp()
	}
}

// Element.prototype.closest
// https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Polyfill

if (!Element.prototype.matches)
	Element.prototype.matches = Element.prototype.msMatchesSelector
		|| Element.prototype.webkitMatchesSelector

if (!Element.prototype.closest)
	Element.prototype.closest = function (s) {
		let el = this
		if (!document.documentElement.contains(el)) return null
		do {
			if (el.matches(s)) return el
			el = el.parentElement || el.parentNode
		} while (el !== null && el.nodeType === 1)
		return null
	}
