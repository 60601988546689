export const MAX_AGE_FOR_CHILD = 12
export const MAX_AGE_FOR_INFANT = 2


export const CATEGORIES = Object.freeze({
	ADULT: 'adult',
	CHILD: 'child',
	INFANT: 'infant',
})

export const getPassengerCategoryByAge = (age) => {

	if (age < 0) return null

	if (age >= MAX_AGE_FOR_CHILD) {
		return CATEGORIES.ADULT
	}
	else if (age >= MAX_AGE_FOR_INFANT && age < MAX_AGE_FOR_CHILD) {
		return CATEGORIES.CHILD
	}

	return CATEGORIES.INFANT
}
