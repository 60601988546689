import { all, fork } from 'redux-saga/effects'
import { matchPath } from 'react-router'
import { PATHS } from '@utils/router'
import {
	LOCATION_CHANGE,
	ON_ELEMENT_CLICK,
	SET_FORM_FIELD_VALUE,
	RECOVER_PASSWORD,
	USER_SESSION_DATA_RESOLVED,
} from '@store/state/types'
import { LOGIN_FORM } from '@store/state/uiState/forms/types'
import { getSimpleActionToEvent, getFormFillActionToEvent } from '../helpers/actionListenerCreators'


const getLocationOpenedConfig = (eventName, path) => ({
	eventName,
	pattern: ({ type, payload }) => (
		type === LOCATION_CHANGE
		&& payload.action !== 'REPLACE'
		&& Boolean(matchPath(payload.location.pathname, path))
	),
})

const advantagesOpenedConfig = getLocationOpenedConfig(
	'CA_ADVANTAGES_OPENED',
	PATHS.ADVANTAGES
)
const contactsOpenedConfig = getLocationOpenedConfig(
	'CA_CONTACTS_OPENED',
	PATHS.CONTACTS
)
const loginOpenedConfig = getLocationOpenedConfig(
	'CA_LOGIN_OPENED',
	PATHS.LOGIN
)
const passwordRecoveryOpenedConfig = getLocationOpenedConfig(
	'CA_PASS_RECOVERY_OPENED',
	PATHS.PASSRECOVERY
)
const historyOpenedConfig = getLocationOpenedConfig(
	'CA_HISTORY_OPENED',
	PATHS.HISTORY
)
const faqOpenedConfig = getLocationOpenedConfig(
	'CA_FAQ_OPENED',
	PATHS.FAQ
)
const aboutOpenedConfig = getLocationOpenedConfig(
	'CA_ABOUT_OPENED',
	PATHS.ABOUT
)

const onBankRequisitesClick = {
	eventName: 'CA_ON_BANK_REQS_CLICK',
	pattern: ({ type, meta }) => (
		type === ON_ELEMENT_CLICK
		&& meta.elementKey === 'Банковские реквизиты'
	),
}
const recoverPasswordConfig = {
	eventName: 'CA_RECOVER_PASSWORD',
	pattern: RECOVER_PASSWORD,
}
const onUserTermClickConfig = {
	eventName: 'CA_USER_TERMS_CLICK',
	pattern: ({ type, meta }) => (
		type === ON_ELEMENT_CLICK
		&& meta.elementKey === 'user-term'
	),
}
const indexOpenedConfig = {
	eventName: 'CA_INDEX_OPENED',
	pattern: ({ type, payload }) => (
		type === LOCATION_CHANGE
		&& payload.action !== 'REPLACE'
		&& Boolean(matchPath(payload.location.pathname, {
			path: PATHS.INDEX,
			exact: true,
		}))
	),
}
const userDataResolvedConfig = {
	eventName: 'CA_USER_DATA_RESOLVED',
	pattern: USER_SESSION_DATA_RESOLVED,
	getEventPayload: ({ payload }) => (payload),
}

const simpleEventConfigList = [
	indexOpenedConfig,
	advantagesOpenedConfig,
	contactsOpenedConfig,
	loginOpenedConfig,
	passwordRecoveryOpenedConfig,
	historyOpenedConfig,
	faqOpenedConfig,
	aboutOpenedConfig,
	onBankRequisitesClick,
	recoverPasswordConfig,
	onUserTermClickConfig,
	userDataResolvedConfig,
]
const simpleEventListeners = simpleEventConfigList.map(getSimpleActionToEvent)


const setLoginEmailConfig = {
	eventName: 'CA_SET_LOGIN_EMAIL_INPUT',
	pattern: ({ type, payload }) => (
		type === SET_FORM_FIELD_VALUE
		&& payload.formName === LOGIN_FORM.NAME
		&& payload.fieldName === LOGIN_FORM.FIELDS.LOGIN
	),
}
const setLoginPasswordConfig = {
	eventName: 'CA_SET_LOGIN_PASSWORD_INPUT',
	pattern: ({ type, payload }) => (
		type === SET_FORM_FIELD_VALUE
		&& payload.formName === LOGIN_FORM.NAME
		&& payload.fieldName === LOGIN_FORM.FIELDS.PASSWORD
	),
}

const formEventConfigList = [
	setLoginEmailConfig,
	setLoginPasswordConfig,
]
const formEventListeners = formEventConfigList.map(getFormFillActionToEvent)

const listeners = [
	...simpleEventListeners,
	...formEventListeners,
]
export default function* commonListeners (analyticEventChan) {
	yield all(listeners.map((listener) => fork(listener, analyticEventChan)))
}
