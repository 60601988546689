import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import l from '@libs/lang'
import { createEnhancer } from '@utils/decoract'
import confy from '@utils/confy'
import { makeCX } from '@utils/taffy'
import getWorktime from '@utils/worktime'
import { userDataSelector } from '@store/state/appState/selectors'
import { toContacts, toHistory, toFaq, toAbout } from '@utils/router'
import { onElementClick } from '@store/state/appState/actions'
import TelegramBtn from '@atoms/TelegramFooterBtn'

import $ from './styles/primaryFooter.js'
import imgMc from './images/mastercard.svg'
import imgVisa from './images/visa.svg'
import imgMir from './images/mir.svg'


const cx = makeCX()

const cq = {
	t767: {
		maxWidth: 767,
	},
	f768t969: {
		maxWidth: 969,
		minWidth: 768,
	},
	f970: {
		minWidth: 970,
	},
}

const enhancer = createEnhancer({ cq })

const legalEntity = confy.get('COMMON.clickavia.legalEntity')
const { phone, email, foundationYear, title } = confy.get(`CONTACTS.${legalEntity}`)

const PrimaryFooter = ({ cq, onElementClick, userData }) => {

	const isLoggedIn = userData
	const { t767, f768t969, f970 } = cq

	const containerClass = cx({
		[$.container]: f970,
		[$.containerMd]: f768t969,
		[$.containerSm]: t767,
	})

	const rowClass = t767 ? $.rowSm : $.row
	const wrapperClass = t767 ? $.wrapperSm : $.wrapper

	const txtCompany = l('Компания')

	const txtContactInfo = l('Контактная информация')
	const txtHistory = l('История компании')
	const txtFaq = l('Вопросы и ответы')
	const txtAboutCompany = l('О компании')
	const txtUserTerms = l('Пользовательское соглашение')
	const hrefUserTerms = confy.get('COMMON.clickavia.agreements.DEPERSONALIZED').link
	const policyText = l('Политика конфиденциальности и обработки персональных данных')
	const policyUrl = confy.get('COMMON.documents.policy')

	const worktime = getWorktime()
	const onUserTermClick = () => onElementClick('user-term')
	const propsTelegramBtn = { withText: true }

	return (
		<div className={wrapperClass}>
			<div className={containerClass}>
				<div className={rowClass}>
					<div className={$.col}>
						<h4>{txtCompany}</h4>
						<ul className={$.nav}>
							<li>
								<Link className={$.link} to={toContacts()}>{txtContactInfo}</Link>
							</li>
							<li>
								<Link className={$.link} to={toHistory()}>{txtHistory}</Link>
							</li>
							<li>
								<Link className={$.link} to={toFaq()}>{txtFaq}</Link>
							</li>
							<li>
								<Link className={$.link} to={toAbout()}>{txtAboutCompany}</Link>
							</li>
							<li>
								<a className={$.link} href={hrefUserTerms} target="_blank" onClick={onUserTermClick}>
									{txtUserTerms}
								</a>
							</li>
							<li>
								<a className={$.link} href={policyUrl} target="_blank">
									{policyText}
								</a>
							</li>
						</ul>
					</div>
					<div className={$.colMid}>
						{isLoggedIn && (<div className={$.tgBtnWrapper}>
							<TelegramBtn {...propsTelegramBtn}/>
						</div>)}
						{!worktime && <p className={$.phone}>{phone}</p>}
						<p className={$.email}><a href={`mailto:${email}`}>{email}</a></p>
						<p className={$.copyright}>© {foundationYear}-{(new Date()).getFullYear()} {title}</p>
						<p className={$.payments}>
							<img src={imgVisa} alt="visa"/>
							<img src={imgMc} alt="mastercard"/>
							<img src={imgMir} alt="МИР"/>
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => ({
	userData: userDataSelector(state),
})
export default connect(mapStateToProps, { onElementClick })(enhancer(PrimaryFooter))
