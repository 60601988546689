import locale from '../switchers/locale.js'
import { LEGAL_ENTITIES } from '../switchers/legalEntity.js'


export default {
	[LEGAL_ENTITIES.TRAVELAB_AGENT]: locale({
		default: {
			phone: '+7 (495) 646-22-66',
			email: 'help@clickavia.ru',
			qualityEmail: 'qc@clickavia.ru',
			foundationYear: 2008,
		},
		'ru-RU': {
			title: 'ООО «Тревелаб Агент»',
		},
		'en-US': {
			title: 'Travelab Agent ltd.',
		},
	}),
	[LEGAL_ENTITIES.CLICKAVIA_TRAVEL]: locale({
		default: {
			phone: '+7 (495) 646-22-66',
			email: 'help@clickavia.ru',
			qualityEmail: 'qc@clickavia.ru',
			foundationYear: 2008,
			title: 'ООО «Клик Авиа Тревел»',
		},
	}),
	[LEGAL_ENTITIES.FLY_CHARTERZ]: locale({
		default: {
			phone: '+7 (495) 646-22-66',
			email: 'help@clickavia.ru',
			qualityEmail: 'qc@clickavia.ru',
			foundationYear: 2008,
			title: 'ООО «Флай Чартерз»',
		},
	}),
	[LEGAL_ENTITIES.TRAVEL_GO]: locale({
		default: {
			phone: '+7 (495) 646-22-66',
			email: 'help@clickavia.ru',
			qualityEmail: 'qc@clickavia.ru',
			foundationYear: 2008,
			title: 'TravelGo OÜ',
		},
	}),
	[LEGAL_ENTITIES.DEPERSONALIZED]: locale({
		default: {
			phone: '+7 (495) 646-22-66',
			email: 'help@clickavia.ru',
			qualityEmail: 'qc@clickavia.ru',
			foundationYear: 2008,
			title: 'Clickavia',
		},
	}),
}
