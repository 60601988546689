import { get, flatten, find } from 'lodash'
import { getDate } from '@utils/time-utils'
import { getStringFromQuery } from '@utils/common/searchQuery'


const findInArrByKey = (arr = [], key, value) => find(arr, (item) => (
	item[key] === value
))

const getOnlyDate = (dateTime) => (dateTime.split('T')[0])

const byTime = ({ arrive }, { depart }) => (getDate(arrive.time) - getDate(depart.time))

const _checkQueryShape = (query) => (
	query.src && query.dst && query.outboundDate
	&& query.adults !== undefined && query.children !== undefined && query.infants !== undefined
)

const attemptSearchInfo = (searchInfo) => {
	const {
		consist = [ 1, 0, 0 ],
		depart_date: outboundDate,
		return_date: inboundDate,
		src = {},
		dst = {},
	} = searchInfo

	const [ adults, children, infants ] = consist

	return {
		src: src.iata,
		dst: dst.iata,
		outboundDate,
		inboundDate,
		adults,
		children,
		infants,
	}
}

const getSearchQuery = (response = {}) => {
	const { data, meta = {} } = response
	const {
		route_info: routeInfo = [],
		search_info: searchInfo = {},
	} = meta
	const [ route = [] ] = routeInfo
	const [ src, dst ] = route

	if (!src || !dst) return null

	const { fares: offerFares = [] } = get(data, 'offers.0', {})
	const offerSegments = flatten(offerFares.map((fareId) => {
		const { fares = [], services = [], activities = [], segments = [] } = data
		const fare = findInArrByKey(fares, 'id', fareId) || {}
		const service = findInArrByKey(services, 'id', fare.service) || {}
		if (service.type === 'transfer') {
			const fareSegments = (service.activities || []).map((activityId) => {
				const { segments: segmentsIds = [] } = findInArrByKey(activities, 'id', activityId) || {}
				const actSegments = segmentsIds
					.map((segmentId) => findInArrByKey(segments, 'id', segmentId))
					.filter(Boolean)
				return flatten(actSegments)
			})
			return flatten(fareSegments)
		}

		return false
	}).filter(Boolean)).sort(byTime)
	const outboundFirstSegment = find(offerSegments, (segment) => {
		const { airport: iata = '' } = segment.depart || {}
		const { metro = '' } = findInArrByKey(data.airports, 'iata', iata) || {}
		return metro === src
	})
	const inboundFirstSegment = find(offerSegments, (segment) => {
		const { airport: iata = '' } = segment.depart || {}
		const { metro = '' } = findInArrByKey(data.airports, 'iata', iata) || {}
		return metro === dst
	})
	const outboundDateTimeStr = get(outboundFirstSegment, 'depart.time', '')
	const inboundDateTimeStr = get(inboundFirstSegment, 'depart.time', '')

	const flightFare = find((data.fares || []), (fare = {}) => {
		const service = findInArrByKey(data.services, 'id', fare.service) || {}
		return service.type === 'transfer' && offerFares.some((id) => id === fare.id)
	})
	const [ adults, children, infants ] = get(flightFare, 'details.consist', [])

	const query = {
		src,
		dst,
		outboundDate: getOnlyDate(outboundDateTimeStr),
		inboundDate: getOnlyDate(inboundDateTimeStr),
		adults,
		children,
		infants,
	}

	if (_checkQueryShape(query)) return getStringFromQuery(query)

	const fromSearchInfo = attemptSearchInfo(searchInfo)

	return _checkQueryShape(fromSearchInfo)
		? getStringFromQuery(fromSearchInfo)
		: null
}

export default getSearchQuery
