export {
	toSerp,
	toCheckout,
	toIndex,
	toContacts,
	toAdvantages,
	toLogin,
	toPassReset,
	toPassRecovery,
	toAbout,
	toFaq,
	toHistory,
	toCabinet,

	toTelegramCcBot,
	toExternalLogin,
	toExternalCabinet,
} from './pathMakers.js'
export { default as PATHS } from './paths.js'
export { default as checkOverlaidPage } from './overlaidPages.js'

export const _setWindowLocation = (location) => {
	window.location = location
}

export const openNewWindow = (location) => {
	window.open(location, '_blank')
}

