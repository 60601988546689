import { toPairs, countBy } from 'lodash'
import { geoSelector } from '@store/state/appState/selectors'

import customFlightsSelector from './customFlights.js'
import getFlightProps from '../helpers/getFlightProps.js'
import getFaresIdsSet from '../helpers/getFaresIdsSet.js'


const isSegment = ({ isSegment }) => isSegment
const getAirlineTitleCountTupleSorter = (airlineList) => ([ titleA, countA ], [ titleB, countB ]) => (
	countA === countB
		? airlineList.indexOf(titleA) - airlineList.indexOf(titleB)
		: countB - countA
)

const getDominantAirline = (flights, geoData) => {
	const [ outboundFlight, inboundFlight ] = flights
	const faresIdsSet = getFaresIdsSet(flights)
	const { items: outboundParts } = getFlightProps(outboundFlight, 'outbound', faresIdsSet, geoData)
	const { items: inboundParts = [] } = inboundFlight
		? getFlightProps(inboundFlight, 'inbound', faresIdsSet, geoData)
		: {}
	const airlines = [ ...outboundParts, ...inboundParts ]
		.filter(isSegment)
		.map(({ props }) => props.airline.title)
	const [ [ dominantAirline ] ] = toPairs(countBy(airlines)).sort(getAirlineTitleCountTupleSorter(airlines))
	return dominantAirline
}

const dominantAirlineSelector = (state) => {
	const geoData = geoSelector(state)
	const flights = customFlightsSelector(state)
	return getDominantAirline(flights, geoData)
}

export default dominantAirlineSelector
