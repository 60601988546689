import { all, fork, select } from 'redux-saga/effects'
import { SET_FORM_FIELD_VALUE, SEARCH_CLICK } from '@store/state/types'
import { SEARCH_BAR_FORM } from '@store/state/uiState/forms/types'
import {
	searchBarSelectors,
} from '@store/state/uiState/forms/selectors'

import getSearchString from '../helpers/getSearchString'
import { getSimpleActionToEvent, getFormFillActionToEvent } from '../helpers/actionListenerCreators'


const {
	adultsSelector,
	childrenSelector,
	infantsSelector,
} = searchBarSelectors

const setSrcConfig = {
	eventName: 'CA_SET_FLIGHT_SRC_INPUT',
	pattern: ({ type, payload }) => (
		type === SET_FORM_FIELD_VALUE
		&& payload.formName === SEARCH_BAR_FORM.NAME
		&& payload.fieldName === SEARCH_BAR_FORM.FIELDS.SRC
	),
	getEventPayload: ({ payload }) => ({
		flightSrcValue: payload.value,
	}),
}
const setDstConfig = {
	eventName: 'CA_SET_FLIGHT_DST_INPUT',
	pattern: ({ type, payload }) => (
		type === SET_FORM_FIELD_VALUE
		&& payload.formName === SEARCH_BAR_FORM.NAME
		&& payload.fieldName === SEARCH_BAR_FORM.FIELDS.DST
	),
	getEventPayload: ({ payload }) => ({
		flightDstValue: payload.value,
	}),
}
const setOutboundDateConfig = {
	eventName: 'CA_SET_FLIGHT_OUTBOUND_DATE_INPUT',
	pattern: ({ type, payload }) => (
		type === SET_FORM_FIELD_VALUE
		&& payload.formName === SEARCH_BAR_FORM.NAME
		&& payload.fieldName === SEARCH_BAR_FORM.FIELDS.OUTBOUND_DATE
	),
	getEventPayload: ({ payload }) => ({
		flightOutboundDateValue: payload.value,
	}),
}
const setInboundDateConfig = {
	eventName: 'CA_SET_FLIGHT_INBOUND_DATE_INPUT',
	pattern: ({ type, payload }) => (
		type === SET_FORM_FIELD_VALUE
		&& payload.formName === SEARCH_BAR_FORM.NAME
		&& payload.fieldName === SEARCH_BAR_FORM.FIELDS.INBOUND_DATE
	),
	getEventPayload: ({ payload }) => ({
		flightInboundDateValue: payload.value,
	}),
}
const setFlightConsistConfig = {
	eventName: 'CA_SET_FLIGHT_CONSIST_INPUT',
	pattern: ({ type, payload }) => (
		type === SET_FORM_FIELD_VALUE
		&& payload.formName === SEARCH_BAR_FORM.NAME
		&& [
			SEARCH_BAR_FORM.FIELDS.ADULTS,
			SEARCH_BAR_FORM.FIELDS.CHILDREN,
			SEARCH_BAR_FORM.FIELDS.INFANTS,
		].includes(payload.fieldName)
	),
	getEventPayload: function* () {
		const adults = yield select(adultsSelector)
		const children = yield select(childrenSelector)
		const infants = yield select(infantsSelector)
		return {
			consist: [ adults, children, infants ],
			paxCount: adults + children + infants,
		}
	},
}

const searchBtnClickConfig = {
	eventName: 'CA_ON_SEARCH_BTN_CLICK',
	pattern: SEARCH_CLICK,
	getEventPayload: ({ payload }) => ({
		searchString: getSearchString(payload),
	}),
}

const formEventConfigList = [
	setSrcConfig,
	setDstConfig,
	setOutboundDateConfig,
	setInboundDateConfig,
	setFlightConsistConfig,
]
const simpleEventConfigList = [
	searchBtnClickConfig,
]
const formEventListeners = formEventConfigList.map(getFormFillActionToEvent)
const simpleEventListeners = simpleEventConfigList.map(getSimpleActionToEvent)

const listeners = [
	...formEventListeners,
	...simpleEventListeners,
]
export default function* searchBarListeners (analyticEventChan) {
	yield all(listeners.map((listener) => fork(listener, analyticEventChan)))
}
