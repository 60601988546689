import { get } from 'lodash'
import { createSelector } from 'reselect'
import { getQueryFromString } from '@utils/common/searchQuery.js'
import {
	outboundFlightFromOfferSelector,
	inboundFlightFromOfferSelector,
	flightConsistFromOfferSelector,
	flightFaresFromOfferSelector,
} from '@store/state/domainData/selectors'
import {
	byCurrentOfferId,
	currentSearchQuerySelector,
	citiesSelector,
} from '@store/state/appState/selectors'


const routeDataResult = (outboundFlight, inboundFlight, consist, flightFares, queryStr, cities) => {
	const [ firstSegment ] = outboundFlight
	const query = getQueryFromString(queryStr)

	const src = get(cities, `${query.src}.morpho.ru.nom`, '')
	const dst = get(cities, `${query.dst}.morpho.ru.nom`, '')

	return {
		src,
		dst,
		outboundDateIso: get(firstSegment, 'depart.time', query.outboundDate),
		inboundDateIso: get(inboundFlight, '0.depart.time', query.inboundDate),
		consist: consist || [ query.adults, query.children, query.infants ],
		cabinClass: get(Object.values(flightFares), '0.details.seats_class', ''),
	}
}

export const routeDataSelector = createSelector([
	byCurrentOfferId(outboundFlightFromOfferSelector),
	byCurrentOfferId(inboundFlightFromOfferSelector),
	byCurrentOfferId(flightConsistFromOfferSelector),
	byCurrentOfferId(flightFaresFromOfferSelector),
	currentSearchQuerySelector,
	citiesSelector,
], routeDataResult)

