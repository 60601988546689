export const getFlatNextProps = (dataList) => ({
	initial: 0,
	getComparableEdge: () => dataList.length - 1,
	getSettableEdge: () => 0,
	comparator: (cur, edge) => (cur >= edge),
	transition: (cur) => (cur + 1),
})

export const getFlatPrevProps = (dataList) => ({
	initial: dataList.length - 1,
	getComparableEdge: () => 0,
	getSettableEdge: () => dataList.length - 1,
	comparator: (cur, edge) => (cur <= edge),
	transition: (cur) => (cur - 1),
})


