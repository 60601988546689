import { takeEvery, select, takeLatest, all, call, put } from 'redux-saga/effects'
import loadData from '@store/sagas/apiService'
import { LOAD_TYPE, EXTENDED_SERVICES } from '@libs/foma/types'
import { SEARCH_AVAILABLE_ROOMS, LOAD_HOTEL_REVIEWS } from '@store/state/types'
import { setAccommodationDates } from '@store/state/appState/actions'
import { serpParamsSelector } from '@store/state/appState/selectors'
import { hasAccommodationForQuerySelector, isLoadedReviewsSelector } from './selectors.js'


export function* searchRooms (query) {
	const {
		outboundDate: checkin,
		inboundDate: checkout,
		hotels,
	} = query
	const [ hotelId ] = hotels
	const hasAccommodation = yield select(hasAccommodationForQuerySelector, checkin, checkout, hotelId)
	if (hasAccommodation) return

	const optsSearch = {
		type: LOAD_TYPE.SEARCH,
		cancelPrev: false,
		hideEvent: true,
		meta: query,
	}

	return yield call(loadData, optsSearch)
}

export function* searchRoomsHandler ({ payload }) {
	const { hotelId, checkin, checkout } = payload
	yield put(setAccommodationDates({ checkin, checkout }))
	const serpUrlParams = yield select(serpParamsSelector)
	const query = {
		...serpUrlParams,
		outboundDate: checkin,
		inboundDate: checkout,
		serviceTypes: [ EXTENDED_SERVICES.ACCOMMODATION ],
		hotels: [ hotelId ],
	}
	// search rooms for selected hotel
	yield call(searchRooms, query)
}

export function* loadHotelReviewsHandler ({ payload: hotelId }) {
	const isLoadedReviews = yield select(isLoadedReviewsSelector, hotelId)
	if (!isLoadedReviews) {
		const optsHotelExt = {
			type: LOAD_TYPE.HOTEL_EXT,
			withoutChangingCurrentOffer: true,
			meta: { id: hotelId },
		}
		yield call(loadData, optsHotelExt)
	}
}

export default function* accommodationExtrInfoSaga () {
	yield all([
		takeLatest(SEARCH_AVAILABLE_ROOMS, searchRoomsHandler),
		takeEvery(LOAD_HOTEL_REVIEWS, loadHotelReviewsHandler),
	])
}
