import { get, sortBy, values, uniqWith } from 'lodash'
import { createSelector } from 'reselect'
import {
	flightFaresFromOfferSelector,
	flightFaresFromProductSelector,
	outboundFlightFromOfferSelector,
	outboundFlightFromProductSelector,
	inboundFlightFromOfferSelector,
	inboundFlightFromProductSelector,
} from '@store/state/domainData/selectors'
import { fareBySegmentIdSelector } from './customFlights.js'
import { ternarySelectorCreator } from './helper.js'


const DEFAULT_CHECKED_BAGGAGE_WEIGHT = 20
const DEFAULT_HAND_BAGGAGE_WEIGHT = 5

const getFareCondition = (activeVariantPrice) => (fare) => {
	const { refundable, conditions = [] } = fare['cancellation_policy']
	const policies = get(fare, 'policies', {})
	const checkedBaggage = get(fare, 'details.baggage.checked', {})
	const handBaggage = get(fare, 'details.baggage.hand', {})
	const checkedBaggageIncludedCount = checkedBaggage.included
	const checkedBaggageWeight = checkedBaggage.weight || DEFAULT_CHECKED_BAGGAGE_WEIGHT
	const handBaggageIncldedCount = handBaggage.included
	const handBaggageWeight = handBaggage.weight || DEFAULT_HAND_BAGGAGE_WEIGHT

	const baggage = checkedBaggageWeight * checkedBaggageIncludedCount
	const handBag = handBaggageWeight * handBaggageIncldedCount

	const farePrice = get(fare, 'price.buyer', 0)
	const priceDiff = activeVariantPrice ? farePrice - activeVariantPrice : undefined

	const isExchange = conditions.length > 0
		? conditions.every(({ change }) => (change && change.before))
		: false

	const refundablePolicies = get(policies, 'refund', {})
	const excahngablePolicies = get(policies, 'change', {})
	const isRefundable = 'allowed' in refundablePolicies ? refundablePolicies['allowed'] : refundable
	const isExchangable = 'allowed' in excahngablePolicies ? excahngablePolicies['allowed'] : isExchange
	const isRefundSubjectToCharge = 'allowed' in refundablePolicies && isRefundable ? refundablePolicies['price'] : null
	const isExchangeSubjectToCharge = 'allowed' in excahngablePolicies && isExchangable ?
		excahngablePolicies['price'] : null

	return {
		isActive: false,
		isExchange: isExchangable,
		isReturn: isRefundable,
		isRefundSubjectToCharge,
		isExchangeSubjectToCharge,
		baggage,
		handBag,
		fareId: fare.id,
		variantId: fare['variant_id'],
		priceDiff,
		price: farePrice,
	}
}

const toVariants = (flightFare) => {
	const activeVariant = {
		...getFareCondition()(flightFare),
		isActive: true,
	}
	const activeVariantPrice = get(flightFare, 'price.buyer', undefined)

	const variants = values(flightFare.variants).map(getFareCondition(activeVariantPrice))

	const sortedVariants = sortBy([ activeVariant ].concat(variants), [ 'price', 'baggage', 'handBag' ])

	// TODO: delete when back solves problem w/ duplicate variants after reconfigure
	const uniqVars = uniqWith(sortedVariants, (a, b) => (a.variantId === b.variantId))

	return uniqVars
}


const byFlightRoute = (outboundFlight, inboundFlight, fareBySegmentId) => ({ id: a }, { id: b }) => {
	const aOutboundIdx = outboundFlight.findIndex(({ id }) => (a === get(fareBySegmentId, `${id}.id`)))
	const aInboundIdx = inboundFlight.findIndex(({ id }) => (a === get(fareBySegmentId, `${id}.id`)))
	const bOutboundIdx = outboundFlight.findIndex(({ id }) => (b === get(fareBySegmentId, `${id}.id`)))
	const bInboundIdx = inboundFlight.findIndex(({ id }) => (b === get(fareBySegmentId, `${id}.id`)))

	if (aOutboundIdx !== -1 && bInboundIdx !== -1) return -1
	else if (aInboundIdx !== -1 && bOutboundIdx !== -1) return 1
	else if (aOutboundIdx !== -1 && bOutboundIdx !== -1) return aOutboundIdx - bOutboundIdx
	else if (aInboundIdx !== -1 && bOutboundIdx !== -1) return aInboundIdx - bInboundIdx
	else return 0
}


export const flightFaresVariantsResult = (flightFares, outboundFlight, inboundFlight, fareBySegmentId) => (
	values(flightFares)
		.sort(byFlightRoute(outboundFlight, inboundFlight, fareBySegmentId))
		.map(toVariants)
)

const flightFaresVariantsSelector = createSelector(
	ternarySelectorCreator(flightFaresFromOfferSelector, flightFaresFromProductSelector),
	ternarySelectorCreator(outboundFlightFromOfferSelector, outboundFlightFromProductSelector),
	ternarySelectorCreator(inboundFlightFromOfferSelector, inboundFlightFromProductSelector),
	fareBySegmentIdSelector,
	flightFaresVariantsResult
)

export default flightFaresVariantsSelector
