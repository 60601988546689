import { select, take, put } from 'redux-saga/effects'

import { byCurrentOfferId, channelSelector } from '@store/state/appState/selectors'
import { setChannel } from '@store/state/appState/actions'
import { offerByIdSelector } from '@store/state/domainData/selectors'
import setOfferIdActionMatcher from '@store/sagas/router/helpers/setOfferIdActionMatcher'
import { DEFAULT_CHANNEL, parseChannel } from '@libs/channel-mom'


// I have no idea what I'm doing.
// Do not ever do like this!
export const channelChanged = (channel) => ({
	type: 'CHANNEL_CHANGED',
	payload: parseChannel(channel),
})

export const currentOfferSelector = byCurrentOfferId(offerByIdSelector)

export default function* watchChannelChange () {

	const currentChannel = yield select(channelSelector)

	if (currentChannel !== DEFAULT_CHANNEL) {
		yield put(channelChanged(currentChannel)) // analytics action
		return
	}

	yield take(setOfferIdActionMatcher)
	const offer = yield select(currentOfferSelector)
	const { partner: offerChannel } = offer || {}

	const isChanged = offerChannel !== currentChannel

	if (offerChannel && isChanged) {
		yield put(setChannel(offerChannel))
		yield put(channelChanged(offerChannel)) // analytics action
	}
}

