import { select, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import {
	currentSearchQuerySelector,
} from '@store/state/appState/selectors'
import { toCheckout } from '@utils/router'
import { setCurrentOfferId } from '@store/state/appState/actions'
import { setDomainData } from '@store/state/domainData/actions'
import offerIdByProductsSelector from './selectors/offerIdByProducts.js'
import resWithPseudoOfferSelector from './selectors/resWithPseudoOffer.js'
import newBuildOfferDataSelector from './selectors/buildOfferData.js'


export default function* handleBuildOffer ({ payload }) {
	const { withTransition } = payload
	const { enabledCounts, products = [] } = yield select(newBuildOfferDataSelector, payload)
	if (products.length <= 0) {
		// TODO: should current offer id be setted to null or try to handle error
		yield put(setCurrentOfferId(null))
		return
	}

	const query = yield select(currentSearchQuerySelector)
	let offerId = yield select(offerIdByProductsSelector, query, enabledCounts, products)
	if (!offerId) {
		const resWithPseudoOffer = yield select(resWithPseudoOfferSelector, query, enabledCounts, products)
		yield put(setDomainData(resWithPseudoOffer, query))
		offerId = resWithPseudoOffer.data.offers[0].id
	}
	yield put(setCurrentOfferId(offerId))
	if (withTransition) {
		yield put(push(toCheckout(offerId)))
	}
}

