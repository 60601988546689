import { combineReducers } from 'redux'

import domainDataReducer from './domainData/reducers'
import appStateReducer from './appState/reducers'
import uiStateReducer from './uiState/reducers'


export default (history) => combineReducers({
	domainData: domainDataReducer,
	appState: appStateReducer(history),
	uiState: uiStateReducer,
})
