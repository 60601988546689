import { call, select, all, put } from 'redux-saga/effects'
import { flatten, keyBy } from 'lodash'

import { fetchAirports } from '@libs/airports-fetcher'
import citiesMorpho from '@libs/citiesMorpho'
import {
	metrosByIdsSelector,
	airportsSelector,
	citiesSelector,
} from '@store/state/appState/selectors'
import { setGeoData } from '@store/state/appState/actions'


export const toMap = (entity) => keyBy(entity, ({ iata }) => (iata))

export function* reqCitiesByMetros (metros = []) {
	const metrosMap = yield select(citiesSelector)
	const missingMetros = metros.filter((metro) => (!metrosMap.hasOwnProperty(metro)))
	if (!missingMetros.length) return

	const citiesList = yield call(citiesMorpho.reqCities, missingMetros)
	const cities = toMap(citiesList)

	yield put(setGeoData({ cities }))
}

export function* reqLocalitiesByIds (ids = []) {
	const metrosByIdsMap = yield select(metrosByIdsSelector)
	const missingIds = ids.filter((id) => (!metrosByIdsMap.hasOwnProperty(id)))

	if (!missingIds.length) return

	const response = yield all(missingIds.map((id) => call(citiesMorpho.reqLocality, id)))
	const metros = flatten(response).map(({ iata }) => (iata))
	yield call(reqCitiesByMetros, metros)
}

export function* reqAirports (iatas = []) {

	const [ citiesMap, airportsMap ] = yield all([
		select(citiesSelector),
		select(airportsSelector),
	])

	const missingIatas = iatas.filter((iata) => (!airportsMap.hasOwnProperty(iata)))

	if (!missingIatas.length) return

	const { cities = [], airports = [] } = yield call(fetchAirports, missingIatas)
	// fetchAirports returns all cities related to given airports,
	// so we may already have some cities in the store.
	// if we filter existing cities off we may win a bit
	const filteredCities = cities.filter(({ iata }) => (!citiesMap.hasOwnProperty(iata)))

	yield put(setGeoData({
		cities: toMap(filteredCities),
		airports: toMap(airports),
	}))
}
