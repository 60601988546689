import { delay, takeLatest, call, takeEvery, put } from 'redux-saga/effects'


const defaultChecker = () => (true)
const defaultPayloadGetter = () => ({})

export const getSimpleActionToEvent = (args) => {
	const {
		pattern,
		eventName,
		getEventPayload = defaultPayloadGetter,
		checkStateConditions = defaultChecker,
		debounce,
	} = args
	function* watcher (analyticEventChan, action) {
		if (debounce) yield delay(debounce)

		const isPassedCondition = yield call(checkStateConditions, action)
		if (!isPassedCondition) return
		const payload = yield call(getEventPayload, action)
		yield put(analyticEventChan, { name: eventName, payload })
	}

	const takeEffect = debounce ? takeLatest : takeEvery
	return function* actionListener (analyticEventChan) {
		yield takeEffect(pattern, watcher, analyticEventChan)
	}
}

const DEBOUNCE_TIME_MS = 500
export const getFormFillActionToEvent = ({ debounce = DEBOUNCE_TIME_MS, ...otherArgs }) => {
	const args = {
		...otherArgs,
		debounce,
	}
	return getSimpleActionToEvent(args)
}
