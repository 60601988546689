import { find, zipObject, zip, pickBy, mapValues } from 'lodash'
import store from 'store'
import { takeEvery, select, call, all, put } from 'redux-saga/effects'
import { PERSIST_FORM, REHYDRATE_FORM, SET_USER_DATA } from '@store/state/types'
import * as actions from '@store/state/uiState/forms/actions'
import * as formShapes from '@store/state/uiState/forms/types'
import { makeValueSelector } from '@store/state/uiState/forms/selectors'
import formsValidators from './formsValidatorSelectors.js'


export const getIsValidFieldSelectorFactory = (formName) => (fieldName) => (
	formsValidators[formName][fieldName]
)
export const validField = ([ _, isValid ]) => (isValid)
export const toValue = ([ value ]) => (value)
export const getKey = (formName, formIndex) => (formIndex
	? `${formName}-${formIndex}`
	: formName
)
export const formShapeByNameFactory = (formName) => ({ NAME: name }) => (
	formName === name
)

export function* handleRehydrateForm ({ type, payload }) {
	const { formName, formIndex } = payload
	const key = yield call(getKey, formName, formIndex)
	const valueByFieldName = yield call([ store, 'get' ], key)
	if (valueByFieldName) {
		const fieldNames = Object.keys(valueByFieldName)
		yield all(fieldNames.map((fieldName) => put(actions.setFormFieldValue({
			formName,
			formIndex,
			fieldName,
			value: valueByFieldName[fieldName],
		}))))
	}
}

export function* handlePersistForm ({ type, payload }) {
	const { formName, formIndex } = payload
	const formShapeByName = yield call(formShapeByNameFactory, formName)
	const formShape = yield call(find, formShapes, formShapeByName)

	const formFieldNames = Object.values(formShape.FIELDS)
	const valueSelectors = yield all(formFieldNames.map((fieldName) => call(
		makeValueSelector,
		{ formName, fieldName }
	)))
	const values = yield all(valueSelectors.map((selector) => select(selector, formIndex)))
	const isValidFieldSelectorByFieldName = yield call(getIsValidFieldSelectorFactory, formName)
	const isValidValues = yield all(formFieldNames.map((fieldName) => (
		select(isValidFieldSelectorByFieldName(fieldName), formIndex)
	)))
	const fieldValuesWithValid = yield call(zip, values, isValidValues)
	const valueWithValidByName = yield call(zipObject, formFieldNames, fieldValuesWithValid)
	const onlyValidValueByName = yield call(pickBy, valueWithValidByName, validField)
	const validValueByName = yield call(mapValues, onlyValidValueByName, toValue)

	const key = yield call(getKey, formName, formIndex)
	yield call([ store, 'set' ], key, validValueByName)
}

export function* handleSetUserData ({ payload: userData }) {
	const { email, phone } = userData
	if (email)  {
		yield put(actions.setFormFieldValue({
			value: email,
			formName: formShapes.CUSTOMER_FORM.NAME,
			fieldName: formShapes.CUSTOMER_FORM.FIELDS.EMAIL,
		}))
	}
	if (phone) {
		yield put(actions.setFormFieldValue({
			value: phone,
			formName: formShapes.CUSTOMER_FORM.NAME,
			fieldName: formShapes.CUSTOMER_FORM.FIELDS.PHONE,
		}))
	}
	yield put(actions.persistForm({
		formName: formShapes.CUSTOMER_FORM.NAME,
	}))
}

export default function* () {
	yield takeEvery(PERSIST_FORM, handlePersistForm)
	yield takeEvery(REHYDRATE_FORM, handleRehydrateForm)
	yield takeEvery(SET_USER_DATA, handleSetUserData)
}
