import PropTypes from 'prop-types'

import Counter from './counter.js'

import defaultStyles from './styles'


/* counter object shape
 * title - "text or component"
 * value
 * isIncActive
 * isDecActive
 * onIncrement
 * onDecrement
**/

const CounterGroup = (props) => {
	const {
		setRef,
		classNames = {},
		header,
		footer,
		counters,
	} = props
	const styles = { ...defaultStyles, ...classNames }
	return (
		<div ref={setRef} className={styles.groupContainer}>
			{header && (
				<div className={styles.header}>
					{header}
				</div>
			)}
			<div className={styles.counters}>
				{counters.map((propsCounter, i) => (
					<div key={i} className={styles.counterWrapper}>
						<Counter {...propsCounter} classNames={classNames}/>
					</div>
				))}
			</div>
			{footer && (
				<div className={styles.footer}>
					{footer}
				</div>
			)}
		</div>
	)
}

CounterGroup.propTypes = {
	classNames: PropTypes.object,
	header: PropTypes.node,
	footer: PropTypes.node,
	counters: PropTypes.arrayOf(PropTypes.shape(Counter.propTypes)).isRequired,
}

export default CounterGroup
