import { createSelector } from 'reselect'
import { flow } from 'lodash'


export const getAppState = (state) => (state.app.appState)
export const createPropGetterMaker = (stateGetter) => (propName) => flow(
	stateGetter,
	(state) => state[propName]
)
export const makePropGetter = createPropGetterMaker(getAppState)

export const currentSearchQuerySelector = makePropGetter('currentQuery')
export const currentOfferIdSelector = makePropGetter('currentOfferId')
export const loadingEventsSelector = makePropGetter('loadingEvents')
export const activeElementSelector = makePropGetter('activeElement')
export const userDataSelector = makePropGetter('userData')
export const shopfrontDialogSelector = makePropGetter('shopfrontDialog')
export const channelSelector = makePropGetter('channel')
export const isCCFormManualModeSelector = makePropGetter('isCCFormManualMode')
export const paymentMethodSelector = makePropGetter('paymentMethod')
export const sbpPaymentSelector = makePropGetter('sbpPayment')
export const tinkoffPaymentSelector = makePropGetter('tinkoffPayment')
export const smsInfoAddedSourceSelector = makePropGetter('smsInfoAddedSource')
export const isSearchbarHighlightedSelector = makePropGetter('isSearchbarHighlighted')
export const upsaleDialogSelector = makePropGetter('upsaleDialog')

export const userCCListSelector = createSelector(
	[ userDataSelector ],
	(userData) => {
		if (userData && userData.cards && userData.cards.length > 0) {
			return userData.cards
		}

		return null
	}
)

export const accommodationDatesSelector = flow(
	getAppState,
	({ accommodationCheckin, accommodationCheckout }) => ({
		checkin: accommodationCheckin,
		checkout: accommodationCheckout,
	})
)
export const quizesSelector = makePropGetter('quizes')
export const previousProductPriceSelector = makePropGetter('previousProductPrice')

// TODO: check isIdArray flag is necessary?
export const byCurrentOfferId = (selector, isIdArray = false) => (state, ...args) => {
	const query = currentSearchQuerySelector(state)
	const offerId = currentOfferIdSelector(state)
	const key = isIdArray ? [ offerId ] : offerId
	return selector(state, query, key, ...args)
}

export const byCurrentSearchQuery = (selector) => (state, ...args) => {
	const query = currentSearchQuerySelector(state)
	return selector(state, query, ...args)
}

// TODO: fake transfer, should be removed after test
export const fakeSelectedTransferSelector = makePropGetter('fakeSelectedTransfer')
