import { getDate } from '@utils/time-utils'
import IIDetector from '@utils/ii-detector'

import DayPicker, { DateUtils } from 'core-components/DayPicker'

import styles from './styles'


const formatDate = (date) => {
	const [ dateOnly ] = date.toISOString().split('T')
	return dateOnly
}
const noOp = () => {}

class DayPickerAdapter extends React.Component {
	_isUnavailableDate = (date) => {
		const isPast = DateUtils.isPastDay(date)
		const { isEndDateFocused, startDate: startDateIso } = this.props
		const startDate = startDateIso && getDate(startDateIso)
		const isBeforeStartDate = isEndDateFocused && startDate && DateUtils.isDayBefore(date, startDate)
		return isPast || isBeforeStartDate
	}

	_isSelectedStartDate = (date) => {
		if (this._isUnavailableDate(date)) return false
		const { startDate: startDateIso } = this.props
		const startDate = getDate(startDateIso)
		return DateUtils.isSameDay(date, startDate)
	}

	_isSelectedEndDate = (date) => {
		const { endDate: endDateIso, startDate } = this.props
		const isUnavailableDate = this._isUnavailableDate(date)
			|| DateUtils.isSameDay(date, getDate(startDate))
		if (isUnavailableDate) return false
		const endDate = getDate(endDateIso)
		return DateUtils.isSameDay(date, endDate)
	}

	_isInRangeDate = (date) => {
		const { startDate: startDateIso, endDate: endDateIso } = this.props
		const startDate = getDate(startDateIso)
		const endDate = getDate(endDateIso)
		return DateUtils.isDayBefore(startDate, endDate)
			&& DateUtils.isDayBetween(date, startDate, endDate)
	}

	_modifiers = {
		[styles.unavailableDate]: this._isUnavailableDate,
		[styles.selectedStartDate]: this._isSelectedStartDate,
		[styles.selectedEndDate]: this._isSelectedEndDate,
		[styles.rangedDate]: this._isInRangeDate,
	}

	// need time to make rerender to update props
	_defferPopoverHiding = () => setTimeout(this.props.hidePopover)

	_dayClickHandler = (date) => {
		const { startDate, isEndDateFocused, onSelect, onBlur } = this.props
		const isUnavailableDate = this._isUnavailableDate(date)
			|| (isEndDateFocused && DateUtils.isSameDay(date, getDate(startDate)))
		if (isUnavailableDate) return false
		onBlur()
		onSelect(formatDate(date))
		this._defferPopoverHiding()
	}

	_dayMouseEnterHandler = (date) => {
		const { startDate, onDateHover, isStartDateFocused, isEndDateFocused } = this.props
		const isUnavailableDate = this._isUnavailableDate(date)
			|| (isEndDateFocused && DateUtils.isSameDay(date, getDate(startDate)))
		if (isUnavailableDate) return false
		if (isStartDateFocused || isEndDateFocused) onDateHover(date.toISOString())
	}

	_closeHandler = () => {
		const { onBlur } = this.props
		onBlur()
		this._defferPopoverHiding()
	}

	_clearHandler = () => {
		const { onSelect, onBlur } = this.props
		onSelect('')
		onBlur()
		this._defferPopoverHiding()
	}

	render () {
		const {
			renderHeader,
			renderFooter,
			isOverlaidPicker,
			numberOfMonths,
			setRef,
			initialMonth,
		} = this.props
		const propsDayPicker = {
			numberOfMonths,
			setRef,
			initialMonth,
			modifiers: this._modifiers,
			onDayClick: this._dayClickHandler,
			onDayTouchStart: IIDetector.canTouch ? this._dayMouseEnterHandler : noOp,
			onDayMouseEnter: (IIDetector.canHover && !IIDetector.canTouch) ? this._dayMouseEnterHandler : noOp,
		}
		const dayPicker = (<DayPicker {...propsDayPicker}/>)
		return (
			<div className={styles.pickerWrapper}>
				{renderHeader && renderHeader(this._closeHandler, this._clearHandler, isOverlaidPicker)}
				{dayPicker}
				{renderFooter && renderFooter(this._closeHandler, this._clearHandler)}
			</div>
		)
	}
}

export { DateUtils }
export default DayPickerAdapter
