import confy from '@utils/confy'


export const DEFAULT_CHANNEL = confy.get('API.cl.channel')

export const parseChannel = (channel) => {
	if (!channel) return { brand: null, uid: null }

	const [ brand, ...rest ] = channel.split(':')

	return {
		brand,
		uid: rest.join(':'),
	}
}
