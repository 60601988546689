import { isEqual } from 'lodash'
import ResizeObserver from 'resize-observer-polyfill'
import matchQueries from 'container-query-toolkit/lib/matchQueries'


let subscribers = []

const ro = new ResizeObserver((entries) => {
	subscribers.forEach(({ cb }) => cb(entries))
})

class ContainerQueryCore {
	
	constructor (node, query, callback) {
		this.result = {}
		if (!node) return

		subscribers.push({
			node,
			cb: (entries) => {
				const entry = entries.find(({ target }) => target === node)
				if (entry) {
					const size = entry.contentRect
					const result = matchQueries(query)(size)
					if (!isEqual(this.result, result)) {
						callback(result, size)
						this.result = result
					}
				}
			},
		})
	}

	observe (element) {
		ro.observe(element)
	}

	unobserve (element) {
		subscribers = subscribers.filter(({ node }) => node !== element)
		ro.unobserve(element)
	}

}

export default ContainerQueryCore
