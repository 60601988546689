import cx from 'classnames'
import l from '@libs/lang'

import {
	Raw as RawBtn,
	RightChev as RightChevBtn,
} from 'core-components/Button'

import CrossMark from './crossMark.js'

import styles from '../styles/details.js'


const noOp = () => {}

const toDetailItem = ({ text, price, onRemove }) => (
	<div className={styles.detailItem} key={text}>
		<span className={styles.detailItemText}>{text}</span>
		<span className={styles.detailItemPrice}>{l.currency(price)}</span>
		<span className={styles.closeBtn}>
			{onRemove && <CrossMark/>}
		</span>
	</div>
)
const toDetailsGroup = ({ title, items }) => (
	<div className={styles.detailsGroup} key={title}>
		<span className={styles.groupTitle}>{title}</span>
		<div className={styles.groupItems}>
			{items.map(toDetailItem)}
		</div>
	</div>
)

const CartDetails = ({ txtTitle, txtCaption, totalPrice, details, onMakeOrderClick }) => {
	const header = (
		<div className={styles.header}>
			<span className={styles.title}>{txtTitle}</span>
			<span className={styles.caption}>{txtCaption}</span>
		</div>
	)

	const body = (
		<div className={styles.body}>
			{details.map(toDetailsGroup)}
		</div>
	)

	const txtMakeOrder = l('Оформить заказ')
	const txtPrice = l.currency(totalPrice)
	const txtTotalPrice = l('Итого: {price}', { price: txtPrice })
	const Button = onMakeOrderClick ? RightChevBtn : RawBtn
	const propsFooterBtn = {
		disabled: !onMakeOrderClick,
		classNames: cx({
			[styles.footerBtn]: true,
			[styles.disabledFooter]: !onMakeOrderClick,
		}),
		onClick: onMakeOrderClick || noOp,
	}
	const footerBtn = (
		<Button {...propsFooterBtn}>
			<span className={styles.totalPrice}>
				{txtTotalPrice}
			</span>
			{onMakeOrderClick && (
				<span className={styles.makeOrder}>
					{txtMakeOrder}
				</span>
			)}
		</Button>
	)

	return (
		<div className={styles.container}>
			{header}
			{body}
			{footerBtn}
		</div>
	)
}

export default CartDetails
