import { createSelector } from 'reselect'

import {
	byCurrentOfferId,
	userCCListSelector,
	isCCFormManualModeSelector,
} from '@store/state/appState/selectors'

import { isCardholderRequiredSelector } from '@store/state/domainData/selectors'
import { CREDIT_CARD_FORM } from '../types'
import { makeValueSelector } from './core.js'
import {
	isPanValid,
	isExpDateValid,
	isCvvValid,
	isCardholderValid,
	isCardIdValid,
} from '../validators'


// fields values
export const panSelector = makeValueSelector({
	formName: CREDIT_CARD_FORM.NAME,
	fieldName: CREDIT_CARD_FORM.FIELDS.PAN,
})
export const shouldStoreCardSelector = makeValueSelector({
	formName: CREDIT_CARD_FORM.NAME,
	fieldName: CREDIT_CARD_FORM.FIELDS.SHOULD_STORE_CARD,
})
export const cardIdSelector = makeValueSelector({
	formName: CREDIT_CARD_FORM.NAME,
	fieldName: CREDIT_CARD_FORM.FIELDS.CARD_ID,
})

export const cardIdWithDefaultSelector = createSelector(
	cardIdSelector,
	userCCListSelector,
	(fieldValue, cardsList) => {
		if (fieldValue) return fieldValue
		if (!cardsList) return null
		const defaultCard = cardsList.find((card) => (card.default === true)) || cardsList[0]

		return defaultCard.card_id
	}
)

export const expDateSelector = makeValueSelector({
	formName: CREDIT_CARD_FORM.NAME,
	fieldName: CREDIT_CARD_FORM.FIELDS.EXP_DATE,
})
export const cvvSelector = makeValueSelector({
	formName: CREDIT_CARD_FORM.NAME,
	fieldName: CREDIT_CARD_FORM.FIELDS.CVV,
})
export const cardholderSelector = makeValueSelector({
	formName: CREDIT_CARD_FORM.NAME,
	fieldName: CREDIT_CARD_FORM.FIELDS.CARD_HOLDER,
})
export const cardholderWithDefaultSelector = createSelector(
	cardholderSelector,
	byCurrentOfferId(isCardholderRequiredSelector),
	(value, isCardholderRequired) => (isCardholderRequired ? value : 'notEmpty')
)


/**
 * [Validates CC pan considering current mode of the form]
 * @param  {String}  panValue     [form`s pan value]
 * @param  {Boolean} isManualMode [form`s mode: true = manual, false = select card mode]
 * @return {Boolean}              [returns true if valid]
 */
export const isPanValidResult = (panValue, isManualMode) => {
	if (!isManualMode) return true
	return isPanValid(panValue)
}
export const isPanValidSelector = createSelector(
	panSelector,
	isCCFormManualModeSelector,
	isPanValidResult
)

/**
 * [Validates CC Exp Date considering current mode of the form]
 * @param  {String}  expDateValue [form`s expDate value: MM/YY || MM/YYYY]
 * @param  {Boolean} isManualMode [form`s mode: true = manual, false = select card mode]
 * @return {Boolean}              [returns true if valid]
 */
export const isExpDateValidResult = (expDateValue, isManualMode) => {
	if (!isManualMode) return true
	return isExpDateValid(expDateValue)
}
export const isExpDateValidSelector = createSelector(
	expDateSelector,
	isCCFormManualModeSelector,
	isExpDateValidResult
)

/**
 * [Validates CVV considering current mode of the form]
 * @param  {String}  cvvValue     [form`s CVV value]
 * @param  {Boolean} isManualMode [form`s mode: true = manual, false = select card mode]
 * @return {Boolean}              [returns true if valid]
 */
export const isCvvValidResult = (cvvValue, isManualMode) => {
	if (!isManualMode) return true
	return isCvvValid(cvvValue)
}
export const isCvvValidSelector = createSelector(
	cvvSelector,
	isCCFormManualModeSelector,
	isCvvValidResult
)

/**
 * [Validates cardholder considering current mode of the form]
 * @param  {String}  cardholderValue      [form`s cardholder value]
 * @param  {Boolean} isManualMode         [form`s mode: true = manual, false = select card mode]
 * @param  {Boolean} isCardholderRequired [represents necessity of the cardholder field]
 * @return {Boolean}                      [returns true if valid]
 */
export const isCardholderValidResult = (cardholderValue, isManualMode, isCardholderRequired) => {
	if (!isManualMode || !isCardholderRequired) return true
	return isCardholderValid(cardholderValue)
}
export const isCardholderValidSelector = createSelector(
	cardholderSelector,
	isCCFormManualModeSelector,
	byCurrentOfferId(isCardholderRequiredSelector),
	isCardholderValidResult
)

/**
 * [Validates card id value considering current mode of the form]
 * @param  {String}  cardIdValue  [forms selected card value with default value]
 * @param  {Array}  cardsList     [list of the cards from current user]
 * @param  {Boolean} isManualMode [form`s mode: true = manual, false = select card mode]
 * @return {Boolean}              [returns true if valid]
 */
export const isCardIdValidResult = (cardIdValue, cardsList, isManualMode) => {
	if (isManualMode) return true
	return isCardIdValid(cardIdValue, cardsList)
}
export const isCardIdValidSelector = createSelector(
	cardIdWithDefaultSelector,
	userCCListSelector,
	isCCFormManualModeSelector,
	isCardIdValidResult
)

export const isFormValidSelector = (state) => (
	isPanValidSelector(state)
	&& isExpDateValidSelector(state)
	&& isCvvValidSelector(state)
	&& isCardholderValidSelector(state)
	&& isCardIdValidSelector(state)
)
// custom selector creators that consider the logic of
// switching of manual mode and select card mode
const makeEmptySelector = (valueSelector) => createSelector(
	valueSelector,
	isCCFormManualModeSelector,
	(value, isManualMode) => (
		isManualMode ? (value === '') : false
	)
)

const makeInvalidSelector = (valueSelector, isValidSelector) => createSelector(
	valueSelector,
	isValidSelector,
	isCCFormManualModeSelector,
	(value, isValid, isManualMode) => (
		isManualMode
			? (value !== '' && !isValid)
			: false
	)
)
// errors fields
export const errorFieldsSelectors = [ {
	formName: CREDIT_CARD_FORM.NAME,
	fieldName: CREDIT_CARD_FORM.FIELDS.PAN,
	emptySelector: makeEmptySelector(panSelector),
	invalidSelector: makeInvalidSelector(
		panSelector,
		isPanValidSelector
	),
}, {
	formName: CREDIT_CARD_FORM.NAME,
	fieldName: CREDIT_CARD_FORM.FIELDS.EXP_DATE,
	emptySelector: makeEmptySelector(expDateSelector),
	invalidSelector: makeInvalidSelector(
		expDateSelector,
		isExpDateValidSelector
	),
}, {
	formName: CREDIT_CARD_FORM.NAME,
	fieldName: CREDIT_CARD_FORM.FIELDS.CARD_HOLDER,
	emptySelector: makeEmptySelector(cardholderWithDefaultSelector),
	invalidSelector: makeInvalidSelector(
		cardholderSelector,
		isCardholderValidSelector
	),
}, {
	formName: CREDIT_CARD_FORM.NAME,
	fieldName: CREDIT_CARD_FORM.FIELDS.CVV,
	emptySelector: makeEmptySelector(cvvSelector),
	invalidSelector: makeInvalidSelector(
		cvvSelector,
		isCvvValidSelector
	),
} ]
