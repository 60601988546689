export const CUSTOMER_FORM = Object.freeze({
	NAME: 'customer',
	FIELDS: {
		EMAIL: 'email',
		PHONE: 'phone',
	},
})

export const ARRIVAL_SMS_FORM = Object.freeze({
	NAME: 'arrivalSmsUpsale',
	FIELDS: {
		PHONE: 'phone',
	},
})

export const FLIGHT_CHECK_IN_FORM = Object.freeze({
	NAME: 'flightCheckInUpsale',
	FIELDS: {
		ROW: 'row',
		SEAT: 'seat',
	},
})

export const PASSENGERS_FORM = Object.freeze({
	NAME: 'passengers',
	FIELDS: {
		LAST_NAME: 'lastName',
		FIRST_NAME: 'firstName',
		MIDDLE_NAME: 'middleName',
		GENDER: 'gender',
		BIRTHDATE: 'birthdate',
		CITIZENSHIP: 'citizenship',
		DOCUMENT_NUM: 'documentNum',
		DOCUMENT_EXP_DATE: 'documentExpDate',
	},
})

export const FEEDBACK_FORM = Object.freeze({
	NAME: 'feedbackForm',
	FIELDS: {
		EMAIL: 'email',
		COMMENT: 'comment',
		NAME: 'name',
		SUBJECT: 'subject',
		ORDER_NUMBER: 'orderNumber',
		PERSONAL_DATA: 'personalData',
	},
})

export const CREDIT_CARD_FORM = Object.freeze({
	NAME: 'creditCard',
	FIELDS: {
		PAN: 'pan',
		EXP_DATE: 'expDate',
		CVV: 'cvv',
		CARD_HOLDER: 'cardholder',
		CARD_ID: 'cardId',
		SHOULD_STORE_CARD: 'shouldStoreCard',
	},
})

export const LOGIN_FORM = Object.freeze({
	NAME: 'loginForm',
	FIELDS: {
		LOGIN: 'login',
		PASSWORD: 'password',
	},
})

export const RESET_FORM = Object.freeze({
	NAME: 'resetForm',
	FIELDS: {
		PASSWORD: 'password',
		PASSWORD_CONFIRM: 'passwordConfirm',
	},
})

export const SEARCH_BAR_FORM = Object.freeze({
	NAME: 'searchBar',
	FIELDS: {
		SRC: 'src',
		DST: 'dst',
		OUTBOUND_DATE: 'outboundDate',
		INBOUND_DATE: 'inboundDate',
		ADULTS: 'adults',
		CHILDREN: 'children',
		INFANTS: 'infants',
	},
})

export const SEARCH_TRANSFER_FORM = Object.freeze({
	NAME: 'searchTransfer',
	FIELDS: {
		ARRIVE_DST: 'arrive_dst',
		DEPART_SRC: 'depart_src',
		DIRECTION: 'direction',
	},
})
