import { get, flow } from 'lodash'
import { createSelector } from 'reselect'
import {
	flightFaresFromOfferSelector,
	flightFaresFromProductSelector,
} from './servicesFares.js'


export const flightConsistResult = (fare = {}) => (
	get(fare, 'details.consist') || fare.consist || null
)

export const makeFlightConsistSelector = (flightFaresSelector) => createSelector(
	flightFaresSelector,
	flow(Object.values, ([ fare ]) => flightConsistResult(fare))
)

export const flightConsistFromOfferSelector =
	makeFlightConsistSelector(flightFaresFromOfferSelector)
export const flightConsistFromProductSelector =
	makeFlightConsistSelector(flightFaresFromProductSelector)
