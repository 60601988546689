import locale from '../switchers/locale.js'
import env from '../switchers/env.js'
import legalEntity, { LEGAL_ENTITIES } from '../switchers/legalEntity.js'


export default {
	clickavia: {
		legalEntity: legalEntity(),
		legalEntities: LEGAL_ENTITIES,
		agreements: {
			[LEGAL_ENTITIES.TRAVELAB_AGENT]: locale({
				default: {
					id: 'travelabAgent',
					link: 'https://www.clickavia.ru/documents/travelab_agreement.pdf',
				},
				'ru-RU': {
					title: 'публичной оферты ООО «Тревелаб Агент»',
				},
			}),
			[LEGAL_ENTITIES.CLICKAVIA_TRAVEL]: locale({
				default: {
					id: 'clickaviaTravel',
					link: 'https://www.clickavia.ru/documents/ka_agreement.pdf',
				},
				'ru-RU': {
					title: 'публичной оферты ООО «Клик Авиа Тревел»',
				},
			}),
			[LEGAL_ENTITIES.FLY_CHARTERZ]: locale({
				default: {
					id: 'flyCharterz',
					link: 'https://www.clickavia.ru/documents/fly_charterz_agreement.pdf',
				},
				'ru-RU': {
					title: 'публичной оферты ООО «Флай Чартерз»',
				},
			}),
			[LEGAL_ENTITIES.TRAVEL_GO]: locale({
				default: {
					id: 'travelGo',
					link: 'https://www.clickavia.ru/documents/travel_go_agreement.pdf',
				},
				'ru-RU': {
					title: 'публичной оферты TravelGo OÜ',
				},
			}),
			[LEGAL_ENTITIES.DEPERSONALIZED]: locale({
				default: {
					id: 'depersonalized',
					link: 'https://www.clickavia.ru/documents/agreement.pdf',
				},
				'ru-RU': {
					title: 'публичной оферты',
				},
			}),
		},
	},
	reduxLogger: {
		enabled: env({
			default: 1,
			prod: -1,
			mock: 1,
		}),
	},
	tracer: {
		verbose: env({
			default: -1,
			prod: 1,
			mock: 1,
		}),
	},
	trackingPixels: {
		wego: env({
			default: 'https://secure.wego.com/analytics/v2/conversions',
			prod: 'https://secure.wego.com/analytics/v2/conversions',
			mock: 'https://srv.wegostaging.com/analytics/v2/conversions',
		}),
	},
	googleMapApiKey: 'AIzaSyCxOMn5biAYBcfc4sc-z0vxtuUsNX2sC-w',
	somicChannelName: 'somic',
	documents: {
		policy: 'https://www.clickavia.ru/documents/policy.pdf',
	},
}
