import { createSelector } from 'reselect'
import {
	arrivalSmsUpsaleSelectors,
	flightCheckInUpsaleSelectors,
} from '@store/state/uiState/forms/selectors'

import {
	smsArrivalFaresFromOfferSelector,
	checkInFaresFromOfferSelector,
	makeAreFaresEnabledSelector,
} from '@store/state/domainData/selectors'
import { byCurrentOfferId } from '@store/state/appState/selectors'


const isSmsArrivalAcceptedSelector = byCurrentOfferId(
	makeAreFaresEnabledSelector(smsArrivalFaresFromOfferSelector)
)

const isFlightCheckInAcceptedSelector = byCurrentOfferId(
	makeAreFaresEnabledSelector(checkInFaresFromOfferSelector)
)

export const arrivalSmsUpsaleDetailsSelector = createSelector([
	isSmsArrivalAcceptedSelector,
	arrivalSmsUpsaleSelectors.phoneSelector,
], (isAccepted, phoneValue) => ( // TODO: test this
	isAccepted ? {
		phones: [ phoneValue ],
	} : null
))

export const flightCheckInUpsaleDetailsSelector = createSelector([
	isFlightCheckInAcceptedSelector,
	flightCheckInUpsaleSelectors.rowSelector,
	flightCheckInUpsaleSelectors.seatSelector,
], (isAccepted, rowValue, seatValue) => ( // TODO: test this
	isAccepted ? {
		// TODO: handle seatValue for more than one passenger
		row: rowValue,
		seat: seatValue,
	} : null
))

