import { matchPath } from 'react-router-dom'
import { take, fork, put, all, select, throttle, call } from 'redux-saga/effects'
import { getDate } from '@utils/time-utils'
import api from '@libs/api'
import { PATHS } from '@utils/router'
import { SET_FORM_FIELD_VALUE } from '@store/state/types'
import { SEARCH_BAR_FORM } from '@store/state/uiState/forms/types'
import { setFormFieldValue, rehydrateForm, persistForm } from '@store/state/uiState/forms/actions'
import { searchBarSelectors } from '@store/state/uiState/forms/selectors'
import { fetchSuggestions, fetchSuggestionsResolve } from '@store/state/appState/actions'
import {
	fetchedLocationsTermsSelector,
	routerDataSelector,
} from '@store/state/appState/selectors'


export const selectorsWrappedWithSelect = [
	searchBarSelectors.srcSelector,
	searchBarSelectors.dstSelector,
	searchBarSelectors.outboundDateSelector,
	searchBarSelectors.inboundDateSelector,
	searchBarSelectors.adultsSelector,
	searchBarSelectors.childrenSelector,
	searchBarSelectors.infantsSelector,
].map((selector) => select(selector))

export const isEqualToInitialState = ([ src, dst, outboundDate, inboundDate, adults, children, infants ]) => (
	!src && !dst && !outboundDate && !inboundDate && (adults === 1) && !children && !infants
)

export const setValue = (fieldName, value) => (
	setFormFieldValue({ formName: SEARCH_BAR_FORM.NAME, fieldName, value })
)

export function* storeParamsInLS () {
	yield put(persistForm({ formName: SEARCH_BAR_FORM.NAME }))
}

export const setOutboundDateFieldPattern = ({ type, payload = {} }) => {
	if (!payload) return false // if payload is null
	const { formName, fieldName } = payload

	return type === SET_FORM_FIELD_VALUE
		&& formName === SEARCH_BAR_FORM.NAME
		&& fieldName === SEARCH_BAR_FORM.FIELDS.OUTBOUND_DATE
}

export function* fillParamsFromLS () {
	const [ { location }, currentValues ] = yield all([
		select(routerDataSelector),
		all(selectorsWrappedWithSelect),
	])

	const isSerp = matchPath(location.pathname, { path: PATHS.SERP, exact: true })

	if (!isEqualToInitialState(currentValues) || isSerp) return

	yield put(rehydrateForm({ formName: SEARCH_BAR_FORM.NAME }))

	const { payload: { value: outboundDate } } = yield take(setOutboundDateFieldPattern)

	if (Boolean(outboundDate)) {
		const depart = getDate(outboundDate)
		const now = new Date()
		now.setHours(0, 0, 0, 0) // we want to compare only date without time

		if (depart < now) {
			yield all([
				put(setValue(SEARCH_BAR_FORM.FIELDS.OUTBOUND_DATE, '')),
				put(setValue(SEARCH_BAR_FORM.FIELDS.INBOUND_DATE, '')),
			])
		}
	}
}

// TODO: test this
export function* initSearchBarParams ({ src, dst, outboundDate, inboundDate, adults, children, infants }) {
	const [
		currentSrc,
		currentDst,
		currentOutboundDate,
		currentInboundDate,
		currentAdults,
		currentChildren,
		currentInfants,
	] = yield all(selectorsWrappedWithSelect)
	yield all([
		currentSrc !== src && put(setValue(SEARCH_BAR_FORM.FIELDS.SRC, src)),
		currentDst !== dst && put(setValue(SEARCH_BAR_FORM.FIELDS.DST, dst)),
		currentOutboundDate !== outboundDate && put(setValue(SEARCH_BAR_FORM.FIELDS.OUTBOUND_DATE, outboundDate)),
		currentInboundDate !== inboundDate && put(setValue(SEARCH_BAR_FORM.FIELDS.INBOUND_DATE, inboundDate)),
		currentAdults !== adults && put(setValue(SEARCH_BAR_FORM.FIELDS.ADULTS, adults)),
		currentChildren !== children && put(setValue(SEARCH_BAR_FORM.FIELDS.CHILDREN, children)),
		currentInfants !== infants && put(setValue(SEARCH_BAR_FORM.FIELDS.INFANTS, infants)),
	].filter(Boolean))
}


export function* handleSetLocationsFieldValue ({ payload = {} }) {
	const { fieldName, value = '' } = payload

	const [ title ] = value.toLowerCase().split(':')

	const fetchedTerms = yield select(fetchedLocationsTermsSelector)

	if (!fetchedTerms.find((term = '') => (term.toLowerCase() === title))) {
		yield put(fetchSuggestions(fieldName, title))
		const suggestions = yield call(api.fetchSuggestions, title)
		yield put(fetchSuggestionsResolve(fieldName, title, suggestions))
	}
}

export const locationsFieldsPattern = ({ type, payload = {} }) => {
	if (!payload) return false // if payload is null
	const { formName, fieldName, value } = payload

	return type === SET_FORM_FIELD_VALUE
		&& Boolean(value)
		&& formName === SEARCH_BAR_FORM.NAME
		&& (fieldName === SEARCH_BAR_FORM.FIELDS.SRC || fieldName === SEARCH_BAR_FORM.FIELDS.DST)
}

export const THROTTLE_TIME_MS = 200
export default function* () {
	yield fork(fillParamsFromLS)
	yield throttle(THROTTLE_TIME_MS, locationsFieldsPattern, handleSetLocationsFieldValue)
}
