import { createSelector } from 'reselect'

import { EXTENDED_SERVICES_SUBTYPE, EXTENDED_SERVICES } from '@libs/foma/types'
import {
	customerSelectors,
	arrivalSmsUpsaleSelectors,
	flightCheckInUpsaleSelectors,
} from '@store/state/uiState/forms/selectors'
import { byCurrentOfferId } from '@store/state/appState/selectors'
import { flightConsistFromOfferSelector } from '@store/state/domainData/selectors'
import cardDataSelector from './cardData.js'
import personalDataSelector from './personalData.js'
import {
	arrivalSmsUpsaleDetailsSelector,
	flightCheckInUpsaleDetailsSelector,
} from './serviceDetails.js'


const paxCountSelector = createSelector(
	byCurrentOfferId(flightConsistFromOfferSelector),
	(consist) => consist.reduce((acc, i) => (acc + i), 0)
)

const MIN_WINDOW_SIZE = 200
const SHARE_DIALOG_WINDOW_SIZE = 0.9

const evalBorderLength = (val, defaultVal) => Math.max.apply(
	null,
	[ MIN_WINDOW_SIZE, Math.min.apply(
		null,
		[ Math.floor((val * SHARE_DIALOG_WINDOW_SIZE) / 10) * 10, defaultVal ]
	) ]
)

const browserInfo = () => ({
	'browser_info': {
		'accept_header': 'application/json; charset=utf-8',
		'color_depth': screen.colorDepth,
		'java_enabled': navigator.javaEnabled(),
		'javascript_enabled': true,
		'language': navigator.language,
		'height': screen.height,
		'width': screen.width,
		'timezone': (new Date().getTimezoneOffset()),
		'useragent': navigator.userAgent,
		'window_height': evalBorderLength(screen.height, 500),
		'window_width': evalBorderLength(screen.width, 600),
	},
})
const getOrderData = (skipCardValidation) =>
	(...args) => {
		const [
			isCustomerFormValid,
			email,
			phone,
			cardData,
			personalData = [],
			paxCount,
			arrivalSmsUpsaleDetails,
			isArrivalSmsUpsaleFormValid,
			flightCheckInUpsaleDetails,
			isFlightCheckinUpsaleFormValid,
		] = args

		const isOrderDataComplete = (isCustomerFormValid
			&& (skipCardValidation || cardData)
			&& personalData.length === paxCount
			&& isArrivalSmsUpsaleFormValid
			&& isFlightCheckinUpsaleFormValid
		)

		if (!isOrderDataComplete) return null

		let result = {
			email,
			phone,
			'personal_data': personalData,
			'card_data': { ...(cardData || {}), ...browserInfo() },
		}

		if (arrivalSmsUpsaleDetails || flightCheckInUpsaleDetails) {
			result['service_book_details'] = {}
		}

		if (arrivalSmsUpsaleDetails) {
			result['service_book_details'][EXTENDED_SERVICES_SUBTYPE.SMS.ARRIVAL] = arrivalSmsUpsaleDetails
		}

		if (flightCheckInUpsaleDetails) {
			result['service_book_details'][EXTENDED_SERVICES.CHECK_IN] = flightCheckInUpsaleDetails
		}

		return result
	}

export const orderDataSelector = createSelector([
	customerSelectors.isFormValidSelector,
	customerSelectors.emailSelector,
	customerSelectors.phoneSelector,
	cardDataSelector,
	personalDataSelector,
	paxCountSelector,
	arrivalSmsUpsaleDetailsSelector,
	arrivalSmsUpsaleSelectors.isFormValidSelector,
	flightCheckInUpsaleDetailsSelector,
	flightCheckInUpsaleSelectors.isFormValidSelector,
], getOrderData())

export const orderSbpDataSelector = createSelector([
	customerSelectors.isFormValidSelector,
	customerSelectors.emailSelector,
	customerSelectors.phoneSelector,
	cardDataSelector,
	personalDataSelector,
	paxCountSelector,
	arrivalSmsUpsaleDetailsSelector,
	arrivalSmsUpsaleSelectors.isFormValidSelector,
	flightCheckInUpsaleDetailsSelector,
	flightCheckInUpsaleSelectors.isFormValidSelector,
], getOrderData(true))
