import { SET_CURRENT_OFFER_ID } from '@store/state/types'
import { BATCH } from 'redux-batched-actions'


// TODO: edit when redux-batched-actions is dropped-off
const setOfferIdActionMatcher = ({ type, payload }) => (
	type === SET_CURRENT_OFFER_ID || (type === BATCH && payload.some(setOfferIdActionMatcher))
)

export default setOfferIdActionMatcher
