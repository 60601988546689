import { call, select } from 'redux-saga/effects'

import feofan from '@libs/feofan'
import info from '@utils/info'
import { channelSelector } from '@store/state/appState/selectors'

import { makeCancellable } from '../utils'


export const cancellableGetTop = makeCancellable(feofan.getTopPrices)
export const cancellableGetByMonth = makeCancellable(feofan.getPricesByMonth)
export const cancellableGetByDay = makeCancellable(feofan.getPricesByDay)

const makeGetPricesHandler = (getPricesRequest) => function* handleGetPrices (meta) {
	const channel = yield select(channelSelector)

	const optsGetPrices = {
		...meta,
		channel,
	}

	try {
		const resData = yield call(getPricesRequest, optsGetPrices)
		return resData
	}
	catch (err) {
		info('cant load prices data', err)
		return null
	}
}

export const handleGetTopPrices = makeGetPricesHandler(cancellableGetTop)
export const handleGetPricesByMonth = makeGetPricesHandler(cancellableGetByMonth)
export const handleGetPricesByDay = makeGetPricesHandler(cancellableGetByDay)
