import cx from 'classnames'
import { Link } from 'react-router-dom'

import l from '@libs/lang'
import { createEnhancer } from '@utils/decoract'
import { toContacts, toAdvantages } from '@utils/router'

import HamburgerMenu from '@molecules/HamburgerMenu'
import AccountLogin from '@molecules/AccountLogin'
import Cart from '@molecules/Cart'
import Search from '@organisms/Search'

import MaybeProgressBar from './views/maybeProgressBar.js'
import Tabs from './views/tabs.js'

import styles from './styles'
import imgLogo from './images/clickavia.svg'


const cq = {
	lg: {
		minWidth: 1140,
	},
	md: {
		maxWidth: 1139,
		minWidth: 768,
	},
	sm: {
		maxWidth: 767,
	},
}
const enhancer = createEnhancer({ cq })

const Header = (props) => {
	const {
		cq,
		showSearchBar,
		isSearchEditable,
		isSearchShown = true,
		isCartShown = true,
		isTabsShown = true,
	} = props
	const { lg, md, sm } = cq

	const hamburgerMenu = (
		<div className={styles.hamburgerMenu}>
			<HamburgerMenu/>
		</div>
	)

	const propsSearch = {
		showSearchBar,
		isEditable: isSearchEditable,
	}
	const searchComp = isSearchShown && (
		<div className={styles.search}>
			<Search {...propsSearch}/>
		</div>
	)

	const cart = isCartShown && (
		<div className={styles.cart}>
			<Cart/>
		</div>
	)

	const tabs = isTabsShown && (
		<div className={styles.tabs}>
			<Tabs/>
		</div>
	)

	const txtAdvantages = l('Почему покупают у нас')
	const txtContacts = l('Контакты')
	const txtVip = l('VIP залы')
	const isMenuLinksShown = !isTabsShown && !sm
	const menuLinks = isMenuLinksShown && (
		<div className={styles.menuLinks}>
			<Link className={styles.menuItem} to={toAdvantages()}>
				{txtAdvantages}
			</Link>
			<Link className={styles.menuItem} to={toContacts()}>
				{txtContacts}
			</Link>
			<a className={styles.menuItem} href="https://lounge.clickavia.ru/vip/">
				{txtVip}
			</a>
		</div>
	)

	const containerClass = cx({
		[styles.container]: lg,
		[styles.containerMd]: md,
		[styles.containerSm]: sm,
	})

	return (
		<div className={containerClass}>
			<div className={styles.header}>
				{sm && hamburgerMenu}
				<Link to="/" className={styles.logo}>
					<img src={imgLogo} alt="clickavia"/>
				</Link>
				{isMenuLinksShown && menuLinks}
				<div className={styles.login}>
					<AccountLogin isMobile={sm}/>
				</div>
				{isCartShown && cart}
			</div>
			{isSearchShown && searchComp}
			<MaybeProgressBar cq={cq}/>
			{isTabsShown && tabs}
		</div>
	)
}

export default enhancer(Header)
