import { createSelector } from 'reselect'
import { getQueryFromString } from '@utils/common/searchQuery'
import { localityStringByMetroSelector } from './geo'
import { currentSearchQuerySelector } from './common'


export {
	currentSearchQuerySelector,
	localityStringByMetroSelector,
}
export {
	currentOfferIdSelector,
	loadingEventsSelector,
	activeElementSelector,
	userDataSelector,
	shopfrontDialogSelector,
	accommodationDatesSelector,
	quizesSelector,
	previousProductPriceSelector,
	channelSelector,
	byCurrentOfferId,
	byCurrentSearchQuery,
	userCCListSelector,
	isCCFormManualModeSelector,
	paymentMethodSelector,
	sbpPaymentSelector,
	tinkoffPaymentSelector,
	fakeSelectedTransferSelector,
	smsInfoAddedSourceSelector,
	isSearchbarHighlightedSelector,
	upsaleDialogSelector,
} from './common'
export {
	fetchedLocationsTermsSelector,
	makeFilteredSuggestionsSelector,
	airportsSelector,
	citiesSelector,
	metrosByIdsSelector,
	geoSelector,
} from './geo'
export {
	sendFeedbackStatusSelector,
	accountDataStatusSelector,
	loginStatusSelector,
	recoverPasswordStatusSelector,
	resetPasswordStatusSelector,
	offerOpStatusSelector,
	offerReconfigureStatusSelector,
	changeConsistStatusSelector,
	redirectToCabinetStatusSelector,
	bookStatusSelector,
} from './requestStatuses'
export {
	currentFiltersSelector,
	appliedFiltersSelector,
	pageNumSelector,
} from './serpFilters'
export {
	routerDataSelector,
	locationQuerySelector,
	locationSelector,
} from './router'
export {
	topPricesSelector,
	pricesByMonthSelector,
	pricesByDaySelector,
} from './pricesList'

export const serpParamsSelector = createSelector(
	currentSearchQuerySelector,
	localityStringByMetroSelector,
	(searchQueryString, localityStringByMetroMap) => {
		const { src: srcMetro, dst: dstMetro, ...rest } = getQueryFromString(searchQueryString || '')
		const src = localityStringByMetroMap[srcMetro]
		const dst = localityStringByMetroMap[dstMetro]

		return (src && dst) ? { ...rest, src, dst } : null
	}
)

