import { padStart, range } from 'lodash'
import l from '@libs/lang'
import { getDhm } from '@utils/flight-calc'


export const SEC = 1000
export const MIN = 60 * SEC
export const HOUR = 60 * MIN
export const MS_IN_DAY = 24 * HOUR
const TIME_SECTION_NUM_OF_DIGITS = 2
const MONTHS_SHORT = [
	l('янв'), l('фев'), l('мар'), l('апр'), l('мая'), l('июн'),
	l('июл'), l('авг'), l('сен'), l('окт'), l('ноя'), l('дек'),
]
const MONTHS_GENITIVE = [
	l('января'), l('февраля'), l('марта'), l('апреля'), l('мая'), l('июня'),
	l('июля'), l('августа'), l('сентября'), l('октября'), l('ноября'), l('декабря'),
]
const WEEKDAYS_SHORT = [
	l('вс'), l('пн'), l('вт'), l('ср'), l('чт'), l('пт'), l('сб'),
]

const padNumber = (number, numOfDigits) => {
	let zeroes = ''

	for (let i = 0; i < numOfDigits; i++) zeroes += '0'

	return (zeroes + number).slice(-numOfDigits)
}

const borderTimeTxtGenerator = (border) => (date) => (
	l('{border} {hours}:{minutes}', {
		border,
		hours: padNumber(date.getHours(), TIME_SECTION_NUM_OF_DIGITS),
		minutes: padNumber(date.getMinutes(), TIME_SECTION_NUM_OF_DIGITS),
	})
)

/*
	iso string -> Date object
*/
export const getDate = (isoString) => {
	// safari does not parse ISO strings well
	const [ year, month, ...rest ] = isoString.split(/[^0-9]/)
	return new Date(year, month - 1, ...rest)
}

export const toIsoString = (dateObj) => ([
	dateObj.getFullYear(),
	padStart(`${dateObj.getMonth() + 1}`, 2, '0'),
	padStart(`${dateObj.getDate()}`, 2, '0'),
].join('-'))

export const getInboundByOffset = (inboundIso, offset) => {
	const inboundDate = getDate(inboundIso)
	inboundDate.setDate(inboundDate.getDate() + offset)

	return toIsoString(inboundDate)
}

export const getYearDiff = (subtrahendDate, minuendDate) => {
	let yearDiff = minuendDate.getFullYear() - subtrahendDate.getFullYear()
	const m = minuendDate.getMonth() - subtrahendDate.getMonth()

	if (m < 0 || (m === 0 && minuendDate.getDate() < subtrahendDate.getDate())) {
		yearDiff--
	}

	return yearDiff
}

export const getRangeInDays = (startDate, endDate) => Math.ceil((endDate - startDate) / MS_IN_DAY)
export const getRangeInMonth = (startDate, endDate) => (
	startDate.getMonth() - endDate.getMonth() + (12 * (startDate.getFullYear() - endDate.getFullYear()))
)

export const getDateRangeTxt = (inDate, outDate) => {

	const daysCount = getRangeInDays(inDate, outDate)

	const lengthOfStay = l(`на {daysCount, plural,
		one {{daysCount} ночь}
		few {{daysCount} ночи}
		many {{daysCount} ночей}
	}`, { daysCount })

	const isSameMonth = inDate.getMonth() === outDate.getMonth()

	const inDayNum = inDate.getDate()

	const dateRange = l('с {in} до {out}', {
		in: isSameMonth ? inDayNum : l.date(inDate, 'shortMonth'),
		out: isSameMonth ? l.date(outDate, 'longMonth') : l.date(outDate, 'shortMonth'),
	})

	return `${lengthOfStay} ${dateRange}`
}

export const subtractDays = (date, daysCount) => (
	new Date(date.getFullYear(), date.getMonth(), date.getDate() - daysCount)
)
export const subtractMonths = (date, monthsCount) => (
	new Date(date.getFullYear(), date.getMonth() - monthsCount, 1)
)
export const getFromTimeTxt = borderTimeTxtGenerator(l('с'))
export const getToTimeTxt = borderTimeTxtGenerator(l('до'))

export const getDayDateTxt = (date, pattern = '{day} {month} {year}, {weekday}') => (
	l(pattern, {
		day: date.getDate(),
		month: MONTHS_SHORT[date.getMonth()],
		monthFull: MONTHS_GENITIVE[date.getMonth()],
		year: date.getFullYear(),
		weekday: WEEKDAYS_SHORT[date.getDay()],
	})
)

export const getGenitiveMonth = (date) => MONTHS_GENITIVE[date.getMonth() - 1]

export const hmMapper = (range) => (minutes, i) => {
	const mins = minutes || range[i]

	const txtPrefix = [ l('от'), l('до') ]

	if (mins === 0) {
		const txtZero = l('0 ч')

		return `${txtPrefix[i]} ${txtZero}`
	}

	const { d, h, m } = getDhm(mins * 60000)

	const txtD = d ? l('{d} дн', { d }) : ''
	const txtH = h ? l('{h} ч', { h }) : ''
	const txtM = m ? l('{m} мин', { m }) : ''

	return `${txtPrefix[i]} ${txtD} ${txtH} ${txtM}`
}

export const isIsoDateValid = (isoStr) => !isNaN(Date.parse(new Date(isoStr)))

export const getLastDay = (year, month) => new Date(year, month, 0).getDate()

// should return dates range
// by days - [ '2018-05-14', '2018-05-15', ... ]
// by months - [ '2018-05-01', '2018-06-01', ... ]
export const getDatesRangeArr = (start, count, isMonth = false, step = 1) => (
	range(count)
		.map((n) => {
			const date = getDate(start)
			if (isMonth) {
				date.setMonth(date.getMonth() + (n * step))
			}
			else {
				date.setDate(date.getDate() + (n * step))
			}
			return toIsoString(date)
		})
)

