import { FEEDBACK_FORM } from '../types'
import { validateEmail } from '../validators'
import { makeValueSelector, makeCheckedSelector } from './core.js'


export const emailSelector = makeValueSelector({
	fieldName: FEEDBACK_FORM.FIELDS.EMAIL,
	formName: FEEDBACK_FORM.NAME,
})
export const commentSelector = makeValueSelector({
	fieldName: FEEDBACK_FORM.FIELDS.COMMENT,
	formName: FEEDBACK_FORM.NAME,
})
export const nameSelector = makeValueSelector({
	fieldName: FEEDBACK_FORM.FIELDS.NAME,
	formName: FEEDBACK_FORM.NAME,
})
export const subjectSelector = makeValueSelector({
	fieldName: FEEDBACK_FORM.FIELDS.SUBJECT,
	formName: FEEDBACK_FORM.NAME,
})
export const orderNumberSelector = makeValueSelector({
	fieldName: FEEDBACK_FORM.FIELDS.ORDER_NUMBER,
	formName: FEEDBACK_FORM.NAME,
})
export const personalDataSelector = makeCheckedSelector({
	fieldName: FEEDBACK_FORM.FIELDS.PERSONAL_DATA,
	formName: FEEDBACK_FORM.NAME,
})

export const formValuesSelector = (state) => ({
	[FEEDBACK_FORM.FIELDS.EMAIL]: emailSelector(state),
	[FEEDBACK_FORM.FIELDS.COMMENT]: commentSelector(state),
	[FEEDBACK_FORM.FIELDS.NAME]: nameSelector(state),
	[FEEDBACK_FORM.FIELDS.SUBJECT]: subjectSelector(state),
	[FEEDBACK_FORM.FIELDS.ORDER_NUMBER]: orderNumberSelector(state),
	[FEEDBACK_FORM.FIELDS.PERSONAL_DATA]: personalDataSelector(state),
})

export const isEmailValidSelector = (state) => validateEmail(emailSelector(state))
export const isCommentValidSelector = (state) => Boolean(commentSelector(state))
export const isNameValidSelector = (state) => Boolean(nameSelector(state))
export const isSubjectValidSelector = (state) => Boolean(subjectSelector(state))
export const isPersonalDataValidSelector = (state) => Boolean(personalDataSelector(state))
export const isOrderNumberValidSelector = (state) => (true)

export const isFormValidSelector = (state) => (
	isEmailValidSelector(state)
	&& isCommentValidSelector(state)
	&& isNameValidSelector(state)
	&& isSubjectValidSelector(state)
	&& isOrderNumberValidSelector(state)
	&& isPersonalDataValidSelector(state)
)
