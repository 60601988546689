import { CANCEL } from 'redux-saga'
import { createSelector } from 'reselect'
import { flow, isEmpty } from 'lodash'
import { select, take, put, race, fork, takeEvery, call, all } from 'redux-saga/effects'

import { LOAD_TYPE, EXTENDED_SERVICES_SUBTYPE, OPERATION_STATUSES } from '@libs/foma/types'

import loadData from '@store/sagas/apiService'
import {
	BUY,
	SHOW_QR,
	SHOW_TINKOFF_QR,
	PERSIST_FORM,
	RECONFIGURE_OFFER,
	SET_OFFER_RECONFIGURE_STATUS,
	SET_OFFER_OP_STATUS,
} from '@store/state/types'
import {
	byCurrentOfferId,
	byCurrentSearchQuery,
	currentOfferIdSelector,
	sbpPaymentSelector,
	tinkoffPaymentSelector,
	currentSearchQuerySelector,
} from '@store/state/appState/selectors'
import {
	orderFromOfferIdSelector,
	offerByIdSelector,
	smsInfoFaresFromOfferSelector,
	packageCustomInsFaresFromOfferSelector,
	makeAreFaresEnabledSelector,
	flightFaresFromOfferSelector,
	serviceByFareIdSelector,
	sortedSegmentsByFareIdSelector,
} from '@store/state/domainData/selectors'
import { clearOrder } from '@store/state/domainData/actions'

import { firstInvalidFieldSelector } from '@store/state/uiState/forms/selectors'

import isOrderFormValidSelector from './selectors/isOrderFormValid.js'
import isSbpOrderFormValidSelector from './selectors/isSbpOrderFormValid.js'
import { orderSbpDataSelector } from './selectors/orderData.js'
import {
	book,
	setUpsaleDialog,
	activateSpbPayment,
	deactivateSpbPayment,
	activateTinkoffPayment,
	deactivateTinkoffPayment,
	goToInvalidFormField,
	setBookStatus,
	setPaymentMethod,
	addLoadingEvent,
	removeLoadingEvent,
	removeAllLoadingEvents,
} from '@store/state/appState/actions'

import feofan from '@libs/feofan'
import info from '@utils/info'


function* sleep (timeoutMs) {
	yield new Promise((resolve) => {
		setTimeout(() => {
			resolve(timeoutMs)
		}, timeoutMs)
	})
}

function* isHiddenCharter () {
	const fareBySegmentIdResult = (fares, segmentsByFareId) => (
		Object.keys(fares).reduce((acc, fareId) => {
			const segments = segmentsByFareId[fareId]

			const fareBySegmentId = segments.reduce((prev, segment) => ({
				...prev,
				[segment.id]: fares[fareId],
			}), {})

			return { ...acc, ...fareBySegmentId }
		}, {})
	)
	const fares = yield select(createSelector(
		byCurrentOfferId(flightFaresFromOfferSelector),
		byCurrentSearchQuery(sortedSegmentsByFareIdSelector),
		fareBySegmentIdResult
	))
	const faresList = Object.values(fares).flat()
	const services = yield select(byCurrentOfferId(serviceByFareIdSelector))

	const localDate = (date, timeZone) => {
		const localeString = date.toLocaleString('ru-RU', { timeZone, hour12: false })
		const [ day, month, year ] = localeString.split(',')[0].split('.').map((p) => parseInt(p, 10))
		return new Date(year, month - 1, day)
	}

	const daysToDepart = (segment, airports) => {
		const departTime = new Date(segment.depart.time)
		const departDate = new Date(departTime.getFullYear(), departTime.getMonth(), departTime.getDate())
		const currentDate = localDate(new Date(), airports[segment.depart.airport].timezone)
		const diffTime = departDate - currentDate
		const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24))
		return diffDays >= 0 ? diffDays : 0
	}

	const isServiceHiddenCharter = (service) => {
		try {
			// SU flights from BG if they depart in 5 days are regulars, else are charter, 
			const segment = { depart: { time: service.start.time, airport: service.start.location.iata } }
			const airports = { [service.start.location.iata]: service.start.location }
			return daysToDepart(segment, airports) > 4
		}
		catch (error) {
			return false
		}
	}

	const hasBgSuHiddenCharter = faresList.some((fare) =>
		fare.supplier === 'bg' && fare.service.startsWith('flight:charter SU') && isServiceHiddenCharter(services[fare.id])
	)
	const hasNonBgCharter = faresList.some((fare) =>
		fare.service.startsWith('flight:charter') && !(
			fare.supplier === 'bg' && fare.service.startsWith('flight:charter SU')
		)
	)
	return hasBgSuHiddenCharter && !hasNonBgCharter
}

export const makeCancellable = (handler) => (...args) => {
	const { promise, cancelFn } = handler(...args)
	promise[CANCEL] = cancelFn

	return promise
}

export const cancellableSavePassengers = makeCancellable(feofan.savePassengers)
export const cancellableCreateSbpPayment = makeCancellable(feofan.createSbpPayment)
export const cancellableCheckSbpPayment = makeCancellable(feofan.checkSbpPayment)
export const cancellableCreateTinkoffPayment = makeCancellable(feofan.createTinkoffPayment)
export const cancellableCheckTinkoffPayment = makeCancellable(feofan.checkTinkoffPayment)
export const cancellableGetOrderStatus = makeCancellable(feofan.getOrderStatus)

export const HTTP_REQUEST_TIMEOUT_MS = 300000

export const orderByCurrentOfferIdSelector = byCurrentOfferId(orderFromOfferIdSelector)
export const offerByCurrentOfferIdSelector = byCurrentOfferId(offerByIdSelector)

export const makeSetReconfigStatusPattern = (serviceType, status) => ({ type, payload }) => (
	type === SET_OFFER_RECONFIGURE_STATUS
		&& payload.status === status
		&& payload.serviceType === serviceType
)

export const upsalesList = [
	EXTENDED_SERVICES_SUBTYPE.INSURANCE.PACKAGE_CUSTOM,
	EXTENDED_SERVICES_SUBTYPE.SMS.INFO,
]

const nonEmpty = (obj) => !isEmpty(obj)

export const areFaresEnabledByServiceType = {
	[EXTENDED_SERVICES_SUBTYPE.SMS.INFO]: byCurrentOfferId(
		makeAreFaresEnabledSelector(smsInfoFaresFromOfferSelector)
	),
	[EXTENDED_SERVICES_SUBTYPE.INSURANCE.PACKAGE_CUSTOM]: byCurrentOfferId(
		makeAreFaresEnabledSelector(packageCustomInsFaresFromOfferSelector)
	),
}

export const areFaresInCurrentOfferByServiceType = {
	[EXTENDED_SERVICES_SUBTYPE.SMS.INFO]: flow(
		byCurrentOfferId(smsInfoFaresFromOfferSelector),
		nonEmpty
	),
	[EXTENDED_SERVICES_SUBTYPE.INSURANCE.PACKAGE_CUSTOM]: flow(
		byCurrentOfferId(packageCustomInsFaresFromOfferSelector),
		nonEmpty
	),
}

export const takenChangeOrderActions = ({ type }) => Boolean(
	type === PERSIST_FORM || type === RECONFIGURE_OFFER
)

export function* changeOrderDetailsWatcher () {
	while (true) {
		yield take(takenChangeOrderActions)

		yield put(deactivateSpbPayment())
		yield put(deactivateTinkoffPayment())
		const searchQuery = yield select(currentSearchQuerySelector)
		yield put(clearOrder(searchQuery))
		yield put(setPaymentMethod('card'))
	}
}

export function* tinkoffActivateWatcher () {
	while (true) {
		yield take(SHOW_TINKOFF_QR)

		const canBook = yield select(isSbpOrderFormValidSelector)

		if (canBook) {
			try {
				const [
					offerId,
				] = yield all([
					select(currentOfferIdSelector),
				])

				yield put(addLoadingEvent(offerId))
				yield put(setPaymentMethod('tinkoff'))

				const createOrderParams = {
					type: LOAD_TYPE.GENERATE_TINKOFF_PAYMENT,
					meta: {
						offerId,
						timeoutMs: HTTP_REQUEST_TIMEOUT_MS,
					},
				}
				yield call(loadData, createOrderParams)

				const order = yield select(orderByCurrentOfferIdSelector)
				const orderId = order.id

				const bookingData = yield select(orderSbpDataSelector)

				yield call(cancellableSavePassengers, { 
					orderId,
					email: bookingData.email,
					phone: bookingData.phone,
					personalData: bookingData['personal_data'],
				})

				const createPaymentResp = yield call(cancellableCreateTinkoffPayment, { orderId })
				const payment = createPaymentResp.data
				yield put(activateTinkoffPayment(payment))
				yield put(removeLoadingEvent(offerId))

				let checkPaymentResp = null
				let iter = 0
				let subIter = 0
				let waitPayment = payment
				let wasPaid = false
				const waitingTime = 60 * 10
				const recheckTime = 10
				const maxIteration = waitingTime / recheckTime

				while (waitPayment && iter < maxIteration) {
					if (subIter === 0) iter += 1
					subIter += 1
					yield sleep(1000)

					waitPayment = yield select(tinkoffPaymentSelector)
					if (waitPayment && subIter > recheckTime) {
						subIter = 0
						checkPaymentResp = yield call(cancellableCheckTinkoffPayment, { orderId, paymentId: payment.id })
						let isCharged = checkPaymentResp.data.paymentStatus === 'CHARGED'
						let isBlocked = checkPaymentResp.data.paymentStatus === 'BLOCKED'
						let isBlocking = checkPaymentResp.data.paymentStatus === 'BLOCKING'
						let isUnpaid = checkPaymentResp.data.paymentStatus === 'UNPAID'
						let isPending = checkPaymentResp.data.paymentStatus === 'PENDING_3DS'
						let isProcessing = isUnpaid || isBlocking || isPending
						if (isCharged || isBlocked) {
							yield put(setBookStatus(OPERATION_STATUSES.LOADING))

							wasPaid = true
							waitPayment = null

							let orderCheckIter = 0
							const orderCheckRepeatDelaySec = 5
							const orderCheckTimeoutSec = 5 * 60
							const maxOrderCheckIteration = orderCheckTimeoutSec / orderCheckRepeatDelaySec
							let checkBookStatus = true
							let bookStatusResp = null
							let bookStatus = null
							while (checkBookStatus && orderCheckIter < maxOrderCheckIteration) {
								orderCheckIter += 1
								bookStatusResp = yield call(cancellableGetOrderStatus, { orderId })
								bookStatus = bookStatusResp.data['last_book_ops']
								if (bookStatus) {
									checkBookStatus = false
									yield put(setBookStatus(bookStatus))
								}
								else {
									yield sleep(orderCheckRepeatDelaySec * 1000)
								}
							}
						}
						else if (!isProcessing) {
							throw new Error('Undefined payment status')
						}
					}
				}

				if (waitPayment && !wasPaid) {
					const searchQuery = yield select(currentSearchQuerySelector)
					yield put(deactivateTinkoffPayment())
					yield put(clearOrder(searchQuery))
					yield put(setPaymentMethod('card'))
					yield put(setBookStatus(OPERATION_STATUSES.TINKOFF_TIMELIMIT))
				}
			}
			catch (e) {
				info('cant log', e)
				const searchQuery = yield select(currentSearchQuerySelector)
				yield put(deactivateTinkoffPayment())
				yield put(clearOrder(searchQuery))
				yield put(setPaymentMethod('card'))
				yield put(setBookStatus(OPERATION_STATUSES.TINKOFF_ERROR))
				yield put(removeAllLoadingEvents())
			}
		}
		else {
			const orderData = yield select(orderSbpDataSelector)

			if (!orderData) {
				const fieldPath = yield select(firstInvalidFieldSelector)
				yield put(goToInvalidFormField(fieldPath))
			}
		}
	}
}

export function* sbpActivateWatcher () {
	while (true) {
		yield take(SHOW_QR)

		const canBook = yield select(isSbpOrderFormValidSelector)

		if (canBook) {
			try {
				const [
					offerId,
				] = yield all([
					select(currentOfferIdSelector),
				])

				yield put(addLoadingEvent(offerId))
				yield put(setPaymentMethod('sbp'))

				const createOrderParams = {
					type: LOAD_TYPE.GENERATE_SBP_PAYMENT,
					meta: {
						offerId,
						timeoutMs: HTTP_REQUEST_TIMEOUT_MS,
					},
				}
				yield call(loadData, createOrderParams)

				const order = yield select(orderByCurrentOfferIdSelector)
				const orderId = order.id

				const bookingData = yield select(orderSbpDataSelector)

				yield call(cancellableSavePassengers, { 
					orderId,
					email: bookingData.email,
					phone: bookingData.phone,
					personalData: bookingData['personal_data'],
				})

				const createSbpPaymentResp = yield call(cancellableCreateSbpPayment, { orderId })
				const payment = createSbpPaymentResp.data
				yield put(activateSpbPayment(payment))
				yield put(removeLoadingEvent(offerId))

				let checkSbpPaymentResp = null
				let iter = 0
				let subIter = 0
				let waitPayment = payment
				let wasPaid = false
				const waitingTime = 60 * 10
				const recheckTime = 10
				const maxIteration = waitingTime / recheckTime

				while (waitPayment && iter < maxIteration) {
					if (subIter === 0) iter += 1
					subIter += 1
					yield sleep(1000)

					waitPayment = yield select(sbpPaymentSelector)
					if (waitPayment && subIter > recheckTime) {
						subIter = 0
						checkSbpPaymentResp = yield call(cancellableCheckSbpPayment, { orderId, paymentId: payment.id })
						if (checkSbpPaymentResp.data.paymentStatus === 'CHARGED') {
							yield put(setBookStatus(OPERATION_STATUSES.LOADING))

							wasPaid = true
							waitPayment = null

							let orderCheckIter = 0
							const orderCheckRepeatDelaySec = 5
							const orderCheckTimeoutSec = 5 * 60
							const maxOrderCheckIteration = orderCheckTimeoutSec / orderCheckRepeatDelaySec
							let checkBookStatus = true
							let bookStatusResp = null
							let bookStatus = null
							while (checkBookStatus && orderCheckIter < maxOrderCheckIteration) {
								orderCheckIter += 1
								bookStatusResp = yield call(cancellableGetOrderStatus, { orderId })
								bookStatus = bookStatusResp.data['last_book_ops']
								if (bookStatus) {
									checkBookStatus = false
									yield put(setBookStatus(bookStatus))
								}
								else {
									yield sleep(orderCheckRepeatDelaySec * 1000)
								}
							}
						}
						else if (checkSbpPaymentResp.data.paymentStatus !== 'UNPAID') {
							throw new Error('Undefined payment status')
						}
					}
				}

				if (waitPayment && !wasPaid) {
					const searchQuery = yield select(currentSearchQuerySelector)
					yield put(deactivateSpbPayment())
					yield put(clearOrder(searchQuery))
					yield put(setPaymentMethod('card'))
					yield put(setBookStatus(OPERATION_STATUSES.SBP_TIMELIMIT))
				}
			}
			catch (e) {
				info('cant log', e)
				const searchQuery = yield select(currentSearchQuerySelector)
				yield put(deactivateSpbPayment())
				yield put(clearOrder(searchQuery))
				yield put(setPaymentMethod('card'))
				yield put(setBookStatus(OPERATION_STATUSES.SBP_ERROR))
				yield put(removeAllLoadingEvents())
			}
		}
		else {
			const orderData = yield select(orderSbpDataSelector)

			if (!orderData) {
				const fieldPath = yield select(firstInvalidFieldSelector)
				yield put(goToInvalidFormField(fieldPath))
			}
		}
	}
}

export function* buyWatcher (failedServices) {
	while (true) {
		yield take(BUY)

		const canBook = yield select(isOrderFormValidSelector)

		if (canBook) {
			const needToShowCharterInfo = yield isHiddenCharter()
			if (needToShowCharterInfo) {
				let serviceType = 'charter'
				yield put(setUpsaleDialog(serviceType))

				const [ acceptSuccessPattern, acceptFailPattern ] = yield all([
					call(makeSetReconfigStatusPattern, serviceType, OPERATION_STATUSES.AVAIL),
					call(makeSetReconfigStatusPattern, serviceType, OPERATION_STATUSES.NONE),
				])

				yield race({
					refuse: take(BUY),
					acceptSuccess: take(acceptSuccessPattern),
					acceptFail: take(acceptFailPattern),
				})
			}
			for (let serviceType of upsalesList) {

				const { isEnabled, isAvailable, hasFailed } = yield all({
					isEnabled: select(areFaresEnabledByServiceType[serviceType]),
					isAvailable: select(areFaresInCurrentOfferByServiceType[serviceType]),
					hasFailed: call([ failedServices, 'has' ], serviceType),
				})

				if (!isEnabled && !hasFailed && isAvailable) {
					yield put(setUpsaleDialog(serviceType))

					const [ acceptSuccessPattern, acceptFailPattern ] = yield all([
						call(makeSetReconfigStatusPattern, serviceType, OPERATION_STATUSES.AVAIL),
						call(makeSetReconfigStatusPattern, serviceType, OPERATION_STATUSES.NONE),
					])

					const { acceptFail } = yield race({
						refuse: take(BUY),
						acceptSuccess: take(acceptSuccessPattern),
						acceptFail: take(acceptFailPattern),
					})

					if (acceptFail) yield call([ failedServices, 'add' ], serviceType)
				}
			}

			yield put(setUpsaleDialog(null))
		}

		yield put(book())
	}
}

export default function* upsaleDialogWorker () {
	const failedServices = new Set()

	yield fork(buyWatcher, failedServices)
	yield fork(sbpActivateWatcher)
	yield fork(tinkoffActivateWatcher)
	yield fork(changeOrderDetailsWatcher)
	yield takeEvery(SET_OFFER_OP_STATUS, () => failedServices.clear())
}
