import { isEmpty, get } from 'lodash'
import { batchActions } from 'redux-batched-actions'
import { call, put, select, spawn } from 'redux-saga/effects'

import { LOAD_TYPE } from '@libs/foma/types'

import * as geoData from '@store/sagas/geoData'
import { setDomainData } from '@store/state/domainData/actions'
import { getSearchQuery } from '@store/state/domainData/selectors'
import {
	setCurrentSearchQuery,
	setCurrentOfferId,
} from '@store/state/appState/actions'
import { currentSearchQuerySelector, currentOfferIdSelector } from '@store/state/appState/selectors'


export const getOfferIdFromResponse = (response, currentOfferId) => (
	get(response, 'data.offers.0.id', currentOfferId)
)

export const getAirportsIatas = (response) => (
	get(response, 'data.airports', []).map(({ iata }) => (iata))
)

export default function* responseHandler (loadOptions, payload) {
	const {
		__hasError = false,
		...response
	} = payload

	if (isEmpty(loadOptions)) return

	yield spawn(geoData.reqAirports, getAirportsIatas(response))

	const { updateSearchQuery, withoutChangingCurrentOffer, type: loadType } = loadOptions

	const searchQuery = updateSearchQuery
		? yield call(getSearchQuery, response)
		: yield select(currentSearchQuerySelector)

	// TODO: handle searchQuery === null case
	if (!searchQuery) return

	yield put(setDomainData(response, searchQuery))

	if (!withoutChangingCurrentOffer && loadType !== LOAD_TYPE.SEARCH && !__hasError) {
		const currentOfferId = yield select(currentOfferIdSelector)
		const newOfferId = yield call(getOfferIdFromResponse, response, currentOfferId)
		const actions = yield call(batchActions, [
			updateSearchQuery && setCurrentSearchQuery(searchQuery),
			newOfferId && setCurrentOfferId(newOfferId),
		].filter(Boolean))
		yield put(actions)
	}
}
