import {
	byCurrentOfferId,
	byCurrentSearchQuery,
} from '@store/state/appState/selectors'


export const ternarySelectorCreator = (fromOffer, fromProduct) => (state, { fareIds } = {}) => (
	fareIds
		? byCurrentSearchQuery(fromProduct)(state, fareIds.join(':'))
		: byCurrentOfferId(fromOffer)(state)
)
