import { sortBy, uniq } from 'lodash'
import { all, fork, select } from 'redux-saga/effects'
import { matchPath } from 'react-router'
import { PATHS } from '@utils/router'
import {
	BUY_TICKET_CLICK,
	APPLY_FILTERS,
	TICKET_DETAILS_CLICK,
	SET_PAGE_NUM,
	LOCATION_CHANGE,
	ON_ELEMENT_CLICK,
} from '@store/state/types'
import { isPartnerLinkSelector } from '@organisms/CaSerpOffersList/selectors/checkoutLink'
import { getFares } from '@store/state/domainData/selectors'
import {
	byCurrentSearchQuery,
	currentSearchQuerySelector,
} from '@store/state/appState/selectors'
import { getSimpleActionToEvent } from '../helpers/actionListenerCreators'
import getFilterEventListener from './filters'


const ecommerceData = (serpParams, allFares, product, key, actionField) => {
	const iataCodes = serpParams.split('/', 3).slice(0, 2)
	const fares = product.fareIds.map((fareId) => allFares[fareId])
	const sortedFares = sortBy(
		Object.entries(fares).map(([ _, fare ]) => fare),
		(fare) => {
			let [ _, ...legs ] = fare.service.split(' ')
			return legs.map((leg) => leg.split('-')[2].slice(0, 8)).sort()[0]
		}
	)
	return {
		ecommerce: {
			currencyCode: 'RUB',
			[key]: {
				actionField,
				products: sortedFares.map((fare, idx) => {
					let [ kind, ...legs ] = fare.service.split(' ')
					let fareIataCodes = idx === 0 ? iataCodes : iataCodes.reverse()
					let quantity = fare.details.consist.reduce((a, b) => a + b, 0)
					if (!quantity) { quantity = 1 }

					return {
						id: fare.service,
						name: fareIataCodes.join('-'),
						price: fare.price.buyer / quantity,
						brand: fare.supplier,
						category: 'Flight',
						variant: legs.map((leg) => leg.split('-')[2].slice(2, 8)).join('/'),
						quantity: quantity,
						dimension1: kind.split(':')[1],
						dimension2: uniq(legs.map((leg) => leg.split('-')[0])).join('/'),
						dimension3: sortedFares.length > 1 || legs.length > 1 ? 'RT' : 'OW',
						dimension4: sortedFares.length > 1 ? 'cross' : 'non-cross',
						metric1: fare.details.baggage.checked.included,
						metric2: fare.details.baggage.checked.weight,
					}
				}),
			},
		},
	}
}

const serpOpenedConfig = {
	eventName: 'CA_SERP_OPENED',
	pattern: ({ type, payload }) => (
		type === LOCATION_CHANGE
		&& payload.action !== 'REPLACE'
		&& Boolean(matchPath(payload.location.pathname, PATHS.SERP))
	),
}
const selectOrBuyTicketClickConfig = {
	eventName: 'CA_SELECT_OR_BUY_TICKET_CLICK',
	pattern: BUY_TICKET_CLICK,
	checkStateConditions: ({ meta }) => !meta.isPartner,
}
const selectPartnerTicketClickConfig = {
	eventName: 'CA_SELECT_PARTNER_TICKET_CLICK',
	pattern: BUY_TICKET_CLICK,
	checkStateConditions: ({ meta }) => meta.isPartner,
}
const buyTicketClickConfig = {
	eventName: 'CA_BUY_TICKET_CLICK',
	pattern: BUY_TICKET_CLICK,
}
const ecAddToCardConfig = {
	eventName: 'EC_ADD_TO_CARD',
	pattern: BUY_TICKET_CLICK,
	getEventPayload: function* ({ meta }) {
		const { product } = meta
		const serpParams = yield select(currentSearchQuerySelector)
		const allFares = yield select(byCurrentSearchQuery(getFares))
		return ecommerceData(serpParams, allFares, product, 'add', {})
	},
}
const applyFiltersConfig = {
	eventName: 'CA_APPLY_FILTERS',
	pattern: APPLY_FILTERS,
}
const ticketDetailsClickConfig = {
	eventName: 'CA_TICKET_DETAILS_CLICK',
	pattern: ({ type, meta }) => (
		type === TICKET_DETAILS_CLICK
		&& meta.overlaidProduct
	),
	checkStateConditions: function* ({ meta }) {
		const { overlaidProduct: product } = meta
		const isPartnerLink = yield select(isPartnerLinkSelector, { product })
		return !isPartnerLink
	},
}
const ecClickConfig = {
	eventName: 'EC_CLICK',
	pattern: ({ type, meta }) => (
		type === TICKET_DETAILS_CLICK
			&& meta.overlaidProduct
	),
	checkStateConditions: function* ({ meta }) {
		const { overlaidProduct: product } = meta
		const isPartnerLink = yield select(isPartnerLinkSelector, { product })
		return !isPartnerLink
	},
	getEventPayload: function* ({ meta }) {
		const { overlaidProduct: product } = meta
		const serpParams = yield select(currentSearchQuerySelector)
		const allFares = yield select(byCurrentSearchQuery(getFares))
		return ecommerceData(serpParams, allFares, product, 'detail', { list: 'SERP' })
	},
}
const partnerTicketDetailsClickConfig = {
	eventName: 'CA_PARTNER_TICKET_DETAILS_CLICK',
	pattern: ({ type, meta }) => (
		type === TICKET_DETAILS_CLICK
		&& meta.overlaidProduct
	),
	checkStateConditions: function* ({ meta }) {
		const { overlaidProduct: product } = meta
		const isPartnerLink = yield select(isPartnerLinkSelector, { product })
		return isPartnerLink
	},
}
const ticketDetailsCloseClickConfig = {
	eventName: 'CA_TICKET_DETAILS_CLOSE_CLICK',
	pattern: ({ type, meta }) => (
		type === TICKET_DETAILS_CLICK
		&& !meta.overlaidProduct
	),
}
const showMoreResultsConfig = {
	eventName: 'CA_SHOW_MORE_RESULTS',
	pattern: SET_PAGE_NUM,
}
const onDateInPriceCalendarClickConfig = {
	eventName: 'CA_ON_DATE_IN_PRICE_CALENDAR_CLICK',
	pattern: ({ type, meta }) => (
		type === ON_ELEMENT_CLICK
		&& meta.elementKey === 'dateInPriceCalendar'
	),
}
const onMonthlyCalendarClick = {
	eventName: 'CA_ON_MONTHLY_CALENDAR_CLICK',
	pattern: ({ type, meta }) => (
		type === ON_ELEMENT_CLICK
		&& meta.elementKey === 'monthlyPricesCalendar'
	),
}
const onGoodPriceCalendarClick = {
	eventName: 'CA_ON_GOOD_PRICE_CALENDAR_CLICK',
	pattern: ({ type, meta }) => (
		type === ON_ELEMENT_CLICK
		&& meta.elementKey === 'goodPriceCalendar'
	),
}
const onCartClickConfig = {
	eventName: 'CA_ON_CART_CLICK',
	pattern: ({ type, meta }) => (
		type === ON_ELEMENT_CLICK
		&& meta.elementKey === 'cart'
	),
}
const onCartMakeOrderClickConfig = {
	eventName: 'CA_ON_CART_MAKE_ORDER_CLICK',
	pattern: ({ type, meta }) => (
		type === ON_ELEMENT_CLICK
		&& meta.elementKey === 'cartMakerOrder'
	),
}

const simpleEventConfigList = [
	serpOpenedConfig,
	applyFiltersConfig,
	selectOrBuyTicketClickConfig,
	selectPartnerTicketClickConfig,
	buyTicketClickConfig,
	ecAddToCardConfig,
	ticketDetailsClickConfig,
	ecClickConfig,
	partnerTicketDetailsClickConfig,
	showMoreResultsConfig,
	onDateInPriceCalendarClickConfig,
	onMonthlyCalendarClick,
	onGoodPriceCalendarClick,
	ticketDetailsCloseClickConfig,
	onCartClickConfig,
	onCartMakeOrderClickConfig,
]
const simpleEventListeners = simpleEventConfigList.map(getSimpleActionToEvent)

const transferCountOpenedFilterConfig = {
	eventName: 'CA_TRANSFER_COUNT_FILTER_OPENED',
	pattern: ({ type, meta }) => (
		type === ON_ELEMENT_CLICK
		&& meta.elementKey.includes('transfersCount')
	),
}
const travelTimeOpenedFilterConfig = {
	eventName: 'CA_TRAVEL_TIME_FILTER_OPENED',
	pattern: ({ type, meta }) => (
		type === ON_ELEMENT_CLICK
		&& meta.elementKey.includes('travelTime')
	),
}
const baggageOpenedFilterConfig = {
	eventName: 'CA_BAGGAGE_FILTER_OPENED',
	pattern: ({ type, meta }) => (
		type === ON_ELEMENT_CLICK
		&& meta.elementKey.includes('baggage')
	),
}
const flightTimeOpenedFilterConfig = {
	eventName: 'CA_FLIGHT_TIME_FILTER_OPENED',
	pattern: ({ type, meta }) => (
		type === ON_ELEMENT_CLICK
		&& meta.elementKey.includes('flightTime')
	),
}
const transfersCountFilterAppliedConfig = {
	eventName: 'CA_TRANSFER_COUNT_FILTER_APPLIED',
	pattern: ({ type, meta }) => (
		type === APPLY_FILTERS
		&& meta.openedFilter.includes('transfersCount')
	),
}
const travelTimeFilterAppliedConfig = {
	eventName: 'CA_TRAVEL_TIME_FILTER_APPLIED',
	pattern: ({ type, meta }) => (
		type === APPLY_FILTERS
		&& meta.openedFilter.includes('travelTime')
	),
}
const baggageFilterAppliedConfig = {
	eventName: 'CA_BAGGAGE_FILTER_APPLIED',
	pattern: ({ type, meta }) => (
		type === APPLY_FILTERS
		&& meta.openedFilter.includes('baggage')
	),
}
const flightTimeFilterAppliedConfig = {
	eventName: 'CA_FLIGHT_TIME_FILTER_APPLIED',
	pattern: ({ type, meta }) => (
		type === APPLY_FILTERS
		&& meta.openedFilter.includes('flightTime')
	),
}
const filterEventConfigList = [
	transferCountOpenedFilterConfig,
	travelTimeOpenedFilterConfig,
	baggageOpenedFilterConfig,
	flightTimeOpenedFilterConfig,
	transfersCountFilterAppliedConfig,
	travelTimeFilterAppliedConfig,
	baggageFilterAppliedConfig,
	flightTimeFilterAppliedConfig,
]
const filterEventListener = getFilterEventListener(filterEventConfigList)

const listeners = [
	...simpleEventListeners,
	filterEventListener,
]
export default function* serpListeners (analyticEventChan) {
	yield all(listeners.map((listener) => fork(listener, analyticEventChan)))
}
