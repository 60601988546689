import { mapValues, values, isPlainObject } from 'lodash'
import { createSelector } from 'reselect'
import { getDocSeries, getDocNumber } from '@utils/common/docTools'
import { getPassengerCategoryByAge } from '@utils/common/paxAge'
import { getCitizenshipCode } from '@utils/citizenship'
import { byCurrentOfferId } from '@store/state/appState/selectors'
import { passengerSelectors } from '@store/state/uiState/forms/selectors'
import { isMiddleNameRequiredSelector } from '@store/state/domainData/selectors'


const {
	isFormValidByFormIndexSelector,
	lastNameByFormIndexSelector,
	firstNameByFormIndexSelector,
	middleNameByFormIndexSelector,
	genderByFormIndexSelector,
	birthdateByFormIndexSelector,
	passengerAgeByFormIndexSelector,
	documentTypeByFormIndexSelector,
	documentNumByFormIndexSelector,
	citizenshipByFormIndexSelector,
	documentExpDateWithDefaultByFormIndexSelector,
} = passengerSelectors

// DD.MM.YYYY -> YYYY-MM-DD
const dateToIso = (str) => str.split('.').reverse().join('-')
export const getPersonData = (isFormValid, ...args) => {
	if (!isFormValid) return null
	const [
		age,
		lastName,
		firstName,
		middleName,
		gender,
		birthdate,
		citizenship,
		documentNum,
		documentExpDate,
		documentType,
		isMiddleNameRequired,
	] = args
	const birthdayIso = dateToIso(birthdate)
	const citizenshipCode = getCitizenshipCode(citizenship)
	const category = getPassengerCategoryByAge(age)
	const passportNum = getDocNumber(documentNum, documentType, citizenshipCode)
	const passportSeries = getDocSeries(documentNum, documentType, citizenshipCode)
	const documentExpDateIso = dateToIso(documentExpDate)
	const shouldSendMiddleName = isMiddleNameRequired && [
		'national-passport',
		'birth-certificate',
	].indexOf(documentType) >= 0
	// build structure for API request payload
	return {
		'is_incomplete': false,
		'category': category,
		'gender': gender,
		'birthday': birthdayIso,
		'citizenship': citizenshipCode,
		'first_name': firstName,
		'last_name': lastName,
		'middle_name': shouldSendMiddleName ? middleName : '',
		'passport_number': passportNum,
		'passport_series': passportSeries,
		'passport_type': documentType,
		'passport_validity': documentExpDateIso,
	}
}

export const getPersonalData = (isFormValidByIndex, ...valuesByIndex) => {
	const personalDataByFormIndex = mapValues(isFormValidByIndex, (isFormValid, index) => (
		getPersonData(isFormValid, ...valuesByIndex.map((value) => (
			isPlainObject(value) ? value[index] : value)) // get value by index for object only
		)
	))
	return values(personalDataByFormIndex).filter(Boolean)
}

export default createSelector(
	isFormValidByFormIndexSelector,
	passengerAgeByFormIndexSelector,
	lastNameByFormIndexSelector,
	firstNameByFormIndexSelector,
	middleNameByFormIndexSelector,
	genderByFormIndexSelector,
	birthdateByFormIndexSelector,
	citizenshipByFormIndexSelector,
	documentNumByFormIndexSelector,
	documentExpDateWithDefaultByFormIndexSelector,
	documentTypeByFormIndexSelector,
	byCurrentOfferId(isMiddleNameRequiredSelector),
	getPersonalData
)
