import request from 'superagent'
import confy from '@utils/confy'


const getUserId = () => {

	const url = confy.get('API.tracker.sync')

	const executor = (resolve, reject) => {
		request
			.get(url)
			.accept('json')
			.withCredentials()
			.end((err, res) => {

				if (res && res.body) {
					resolve(res.body)
					return
				}

				resolve(null)
			})
	}

	return new Promise(executor)
}

export default getUserId
