import l from '@libs/lang'

import { toExternalCabinet } from '@utils/router'
import $ from './styles/index.js'
import icoUser from './images/icon-user-1.svg'


const AccountBtn = (props) => {
	const {
		withText,
	} = props

	const txtOrders = l('Мои заказы')

	return (
		<a className={$.container} href={toExternalCabinet()}>
			<img src={icoUser}/>
			{withText ? <span className={$.text}>{txtOrders}</span> : null}
		</a>
	)
}

export default AccountBtn
