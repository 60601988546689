import cn from 'classnames'

import Dropdown from 'core-components/Dropdown'
import DateInput from './dateInput.js'
import DayPicker, { DateUtils } from './dayPickerAdapter.js'

import styles from './styles'


class DateRangePicker extends React.Component {
	_renderDayPicker = (setPopoverRef, hidePopover) => {
		const {
			renderClearButton,
			isOverlaidPicker,
			...restProps
		} = this.props
		const propsDayPicker = {
			...restProps,
			isOverlaidPicker,
			numberOfMonths: isOverlaidPicker ? 1 : 2,
			setRef: setPopoverRef,
			renderFooter: renderClearButton,
			hidePopover,
		}
		return (
			<div className={styles.pickerWrapper}>
				<DayPicker {...propsDayPicker}/>
			</div>
		)
	}

	_renderInputs = (showPopover) => {
		const {
			startDate,
			endDate,
			isStartDateFocused,
			isEndDateFocused,
			startDatePlaceholder,
			endDatePlaceholder,
			isStartDateValid,
			isEndDateValid,
			classNames,
			onStartDateInputFocus,
			onEndDateInputFocus,
		} = this.props
		const propsStartDateInput = {
			value: startDate,
			placeholder: startDatePlaceholder,
			placeholderColor: isStartDateValid ? '#878787' : '#d50000',
			isFocused: isStartDateFocused,
			readOnly: true,
			containerClass: cn({
				[classNames.startDateInput]: true,
				[classNames.invalidStartDateInput]: !isStartDateValid,
			}),
			showPopover,
			focus: onStartDateInputFocus,
		}
		const startDateInput = (<DateInput {...propsStartDateInput}/>)
		const propsEndDateInput = {
			value: endDate,
			placeholder: endDatePlaceholder,
			placeholderColor: isEndDateValid ? '#878787' : '#d50000',
			isFocused: isEndDateFocused,
			readOnly: true,
			containerClass: cn({
				[classNames.endDateInput]: true,
				[classNames.invalidEndDateInput]: !isEndDateValid,
			}),
			showPopover,
			focus: onEndDateInputFocus,
		}
		const endDateInput = (<DateInput {...propsEndDateInput}/>)
		return (
			<div className={classNames.inputs}>
				{startDateInput}
				{endDateInput}
			</div>
		)
	}

	render () {
		const { isOverlaidPicker, onBlur } = this.props
		const propsDropdown = {
			popoverWidth: isOverlaidPicker ? '' : 'auto',
			isOverlaidPopover: isOverlaidPicker,
			isHiddenOnBlur: true,
			renderInput: this._renderInputs,
			renderPopover: this._renderDayPicker,
			onBlur,
		}
		return (<Dropdown {...propsDropdown}/>)
	}
}

export { DateUtils }
export default DateRangePicker
