import PrimaryOrange from './primaryOrange.js'

import styles from '../styles/primaryOrangeSm.js'


export default ({ children, classNames, ...props }) => (
	<PrimaryOrange {...props} classNames={[ styles.primaryOrangeSm, classNames ]}>
		{children}
	</PrimaryOrange>
)
