import { all, fork, select, call } from 'redux-saga/effects'
import { LOAD_TYPE } from '@libs/foma/types'
import {
	ADD_LOADING_EVENT,
	REMOVE_LOADING_EVENT,
} from '@store/state/types'
import searchedFlightsSelector from '@organisms/TicketsSerp/selectors/search'
import { getSimpleActionToEvent } from '../../helpers/actionListenerCreators'
import getSearchString from '../../helpers/getSearchString'

import firstSearchResultsListener from './firstSearchResults'


const startSearchConfig = {
	eventName: 'CA_START_SEARCH',
	pattern: ({ type, payload }) => (
		type === ADD_LOADING_EVENT
		&& payload.type === LOAD_TYPE.SEARCH
	),
	getEventPayload: ({ payload }) => ({
		searchString: getSearchString(payload.meta),
	}),
}
const finishSearchConfig = {
	eventName: 'CA_FINISH_SEARCH',
	pattern: ({ type, payload }) => (
		type === REMOVE_LOADING_EVENT
		&& payload.type === LOAD_TYPE.SEARCH
	),
	getEventPayload: function* ({ payload }) {
		const searchString = yield call(getSearchString, payload.meta)
		const products = yield select(searchedFlightsSelector)
		return { searchString, ticketSearchResultsCount: products.length }
	},
}
const flightsNotFoundConfig = {
	eventName: 'CA_FLIGHTS_NOT_FOUND',
	pattern: ({ type, payload }) => (
		type === REMOVE_LOADING_EVENT
		&& payload.type === LOAD_TYPE.SEARCH
	),
	checkStateConditions: function* () {
		const products = yield select(searchedFlightsSelector)
		return products.length <= 0
	},
	getEventPayload: ({ payload }) => ({
		searchString: getSearchString(payload.meta),
	}),
}

const simpleEventConfigList = [
	startSearchConfig,
	finishSearchConfig,
	flightsNotFoundConfig,
]
const simpleEventListeners = simpleEventConfigList.map(getSimpleActionToEvent)

const listeners = [
	...simpleEventListeners,
	firstSearchResultsListener,
]

export default function* searchProcessListeners (analyticEventChan) {
	yield all(listeners.map((listener) => fork(listener, analyticEventChan)))
}
