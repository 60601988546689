import { select, call, put } from 'redux-saga/effects'
import { createSelector } from 'reselect'

import env from '@utils/env'
import redirectBySubmitForm from '@utils/common/redirectBySubmitForm.js'
import {
	redirectToPartner as redirectToPartnerAction,
} from '@store/state/appState/actions'
import {
	byCurrentOfferId,
} from '@store/state/appState/selectors'
import {
	flightFaresFromOfferSelector,
} from '@store/state/domainData/selectors'


export const redirectParamsSelector = createSelector(
	[ byCurrentOfferId(flightFaresFromOfferSelector) ],
	(fares) => {
		const fare = Object.values(fares).find(({ book_by_redirect: params }) => Boolean(params))
		return fare && fare['book_by_redirect']
	}
)

const getPromisifiedTimeout = (cb, timeoutMs) => new Promise((resolve, reject) => {
	setTimeout(() => {
		resolve()
		cb()
	}, timeoutMs)
})

export const TIMEOUT_MS = 3000
export const getRedirectMethod = ({ method, url, params }) => {
	if (method === 'GET') {
		return () => getPromisifiedTimeout(() => {
			window.location.href = url
		}, TIMEOUT_MS)
	}
	else if (method === 'POST') {
		return () => getPromisifiedTimeout(() => {
			redirectBySubmitForm(document, { method, url, params }, 'params')
		}, TIMEOUT_MS)
	}
}

export const loadAviasalesPixel = ({ clickId, gateId }) => new Promise((resolve, reject) => {
	const img = document.createElement('img')
	img.id = 'pixel'
	img.width = 0
	img.height = 0
	img.src = `//yasen.aviasales.ru/adaptors/pixel_click.png?click_id=${clickId}&gate_id=${gateId}`
	document.body.appendChild(img)
	img.addEventListener('load', resolve)
})

export default function* redirectToPartner () {
	const redirectParams = yield select(redirectParamsSelector)
	const redirect = yield call(getRedirectMethod, redirectParams)
	if (env.isProd) {
		yield call(loadAviasalesPixel, redirectParams['click_id'], redirectParams['gate_id'])
	}
	yield call(redirect)
	yield put(redirectToPartnerAction())
}
