import { isPlainObject } from 'lodash'
import { INIT_FORMS_LIST, ADD_FORM, REMOVE_FORM } from '@store/state/types'
import createSingleFormReducer from './singleFormReducerCreator.js'


export default function (name, formInitialState) {
	const singleFormReducer = createSingleFormReducer(name, formInitialState)
	return (state = {}, action = {}) => {
		const { type, payload = {} } = action
		if (!isPlainObject(payload)) return state
		const { formIndex, formName, indexes = [] } = payload
		if (formName !== name || (indexes.length <= 0 && !formIndex)) return state

		switch (type) {
			case INIT_FORMS_LIST: {
				const addedForms = indexes
					.filter((index) => (state[index] === undefined))
					.reduce((acc, index) => ({
						...acc,
						[index]: singleFormReducer(undefined, { type: 'INIT' }),
					}), {})
				return {
					...state,
					...addedForms,
				}
			}
			case ADD_FORM: {
				return {
					...state,
					[formIndex]: singleFormReducer(undefined, { type: 'INIT' }),
				}
			}
			case REMOVE_FORM: {
				const stateKeys = Object.keys(state)
				if (!stateKeys.includes(formIndex)) return state
				return stateKeys
					.reduce((newState, key) => (key === formIndex
						? newState
						: { ...newState, [key]: state[key] }
					), {})
			}
			default: {
				const stateKeys = Object.keys(state)
				if (!stateKeys.includes(formIndex)) return state
				return {
					...state,
					[formIndex]: singleFormReducer(state[formIndex], action),
				}
			}
		}
	}
}
