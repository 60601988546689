import cn from 'classnames'

import Input from './raw.js'

import styles from '../styles/floatinLabel.js'


const FloatingLabel = ({ placeholder, placeholderColor = '#878787', children, ...props }) => {
	const propsInput = {
		// some browsers still do autocompletion,
		// even though input has autocomplete="off" attr.
		// type="search" seems to fix the issue.
		type: 'search',
		autoComplete: 'off',
		spellcheck: 'off',
		className: styles.input,
		...props,
	}
	const input = children || (<Input {...propsInput}/>)

	const placeholderStyle = {
		color: placeholderColor,
	}
	const placeholderComp = (
		<span className={styles.placeholder} style={placeholderStyle}>
			{placeholder}
		</span>
	)

	const containerClass = cn({
		[styles.container]: !props.value,
		[styles.focusedContainer]: props.value,
	})

	return (
		<div className={containerClass}>
			<label>
				{input}
				{placeholderComp}
			</label>
		</div>
		
	)
}

export default FloatingLabel
