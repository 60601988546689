const romanNum = new RegExp(/(?=[MDCLXVI])(M{0,3})(C[DM]|D?C{0,3})(X[LC]|L?X{0,3})(I[VX]|V?I{0,3})/)
const cyrillicChars = new RegExp(/[\u0410-\u044F]{2}/)
const birthCertSeriesRegExp = new RegExp(`^${romanNum.source}${cyrillicChars.source}`)
const birthCertNumberRegExp = new RegExp(/\d{6}$/)

export const birthCertificateRegExp = {
	series: birthCertSeriesRegExp,
	number: birthCertNumberRegExp,
	full: new RegExp(birthCertSeriesRegExp.source + birthCertNumberRegExp.source),
}

const nationalSeriesRegExp = new RegExp(/^\d{4}/)
const nationalNumberRegExp = new RegExp(/\d{6}$/)

export const nationalRegExp = {
	series: nationalSeriesRegExp,
	number: nationalNumberRegExp,
	full: new RegExp(`${nationalSeriesRegExp.source}\\D*${nationalNumberRegExp.source}`),
}

const travelSeriesRegExp = new RegExp(/^\d{2}/)
const travelNumberRegExp = new RegExp(/\d{7}$/)

export const travelRegExp = {
	series: travelSeriesRegExp,
	number: travelNumberRegExp,
	full: new RegExp(`${travelSeriesRegExp.source}\\D*${travelNumberRegExp.source}`),
}

export const trashRegExp = new RegExp(/[\s-#№]/g)
export const lettersOnlyRegExp = /^\D*$/
export const latinCharRegExp = /\w/
export const cyrillicCharRegExp = /[А-Яа-я]/
export const dateFormatRegExp = /^\d{2}\.\d{2}\.\d{4}$/

// RegExp from HTML5 spec https://www.w3.org/TR/html5/forms.html#valid-e-mail-address
// with little changes to don't pass addresses like (name@host)
// eslint-disable-next-line
export const emailRegExp = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-.]{0,61}[a-zA-Z0-9])\.{1}?(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/

export const cvvRegExp = /^\d{3,4}$/

export const locationRegExp = /^.+:locality:\d+$/
export const searchBarDateFormatRegExp = /^\d{4}-\d{2}-\d{2}$/

export const latinCharAndSpaceRegExp = /^[a-zA-z\s]*$/
