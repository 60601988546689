import qs from 'qs'
import { createSelector } from 'reselect'

import {
	byCurrentOfferId,
} from '@store/state/appState/selectors'
import {
	flightFaresFromOfferSelector,
	flightFaresFromProductSelector,
} from '@store/state/domainData/selectors'


const getToCheckoutSearch = (flightFares) => {
	const isBookByRedirect = Object.values(flightFares).some(({ book_by_redirect: flag }) => flag)
	const queryString = isBookByRedirect ? qs.stringify({
		'book_by_redirect': isBookByRedirect,
	}) : ''
	return `?${queryString}`
}

export const toCheckoutQueryStringSelectorFromProduct = createSelector(
	[ flightFaresFromProductSelector ],
	getToCheckoutSearch
)

export default createSelector(
	[ byCurrentOfferId(flightFaresFromOfferSelector) ],
	getToCheckoutSearch
)

