import cx from 'classnames'

import { Raw as Button } from 'core-components/Button'

import styles from '../styles/btn.js'


const Btn = ({ isOpened, onClick }) => {
	const propsBtn = {
		classNames: cx(styles.wrapper, {
			[styles.opened]: isOpened,
			[styles.normal]: !isOpened,
		}),
		onClick,
	}

	return (
		<Button {...propsBtn}>
			<span/>
			<span/>
			<span/>
		</Button>
	)
}

export default Btn
