import React from 'react'
import PropTypes from 'prop-types'


class Toggler extends React.Component {
	state = {
		isOn: false,
	}

	_on = () => this.setState({ isOn: true })
	_off = () => this.setState({ isOn: false })
	_toggle = () => this.setState((state) => ({ isOn: !state.isOn }))

	render () {
		return this.props.children(
			this.state,
			this._toggle,
			this._on,
			this._off,
		)
	}
}

Toggler.propTypes = {
	children: PropTypes.func.isRequired,
}

export default Toggler
