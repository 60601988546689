import {
	SET_ACCOUNT_DATA_STATUS,
	LOAD_ACCOUNT_DATA,
	LOGIN,
	LOGOUT,
	SET_LOGIN_STATUS,
	RECOVER_PASSWORD,
	SET_RECOVER_PASSWORD_STATUS,
	RESET_PASSWORD,
	SET_RESET_PASSWORD_STATUS,
	SET_USER_DATA,
	SET_REDIRECT_TO_CABINET_STATUS,
} from '@store/state/types'


export const setAccountDataStatus = (status) => ({
	type: SET_ACCOUNT_DATA_STATUS,
	payload: status,
})

export const loadAccountData = () => ({
	type: LOAD_ACCOUNT_DATA,
})

export const login = () => ({
	type: LOGIN,
})

export const logout = () => ({
	type: LOGOUT,
})

export const setLoginStatus = (status) => ({
	type: SET_LOGIN_STATUS,
	payload: status,
})

export const recoverPassword = () => ({
	type: RECOVER_PASSWORD,
})

export const setRecoverPasswordStatus = (status) => ({
	type: SET_RECOVER_PASSWORD_STATUS,
	payload: status,
})

export const resetPassword = () => ({
	type: RESET_PASSWORD,
})

export const setResetPasswordStatus = (status) => ({
	type: SET_RESET_PASSWORD_STATUS,
	payload: status,
})

export const setUserData = (userData) => ({
	type: SET_USER_DATA,
	payload: userData,
})

export const setRedirectToCabinetStatus = (status) => ({
	type: SET_REDIRECT_TO_CABINET_STATUS,
	payload: status,
})
