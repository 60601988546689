import { mapValues } from 'lodash'
import { createSelector } from 'reselect'
import {
	getAirlines,
	getAirports,
	outboundFlightByProductIdSelector,
	inboundFlightByProductIdSelector,
	flightFaresByProductIdSelector,
	faresTotalPriceResult,
} from '@store/state/domainData/selectors'
import {
	getExtendedSegments,
	getTicketsInfo,
} from '@organisms/CaSerpOffer/selectors/ticketInfo.js'


const flightsByProductIdSelector = createSelector(
	outboundFlightByProductIdSelector,
	inboundFlightByProductIdSelector,
	(outboundByProductId, inboundByProductId) => mapValues(outboundByProductId, (v, id) => ({
		outboundFlight: v,
		inboundFlight: inboundByProductId[id],
	}))
)

const extendedSegmentsByProductIdSelector = createSelector(
	flightsByProductIdSelector,
	getAirlines,
	getAirports,
	(flightsByProductId, airlines, airports) => (
		mapValues(flightsByProductId, ({ outboundFlight, inboundFlight }) => (
			getExtendedSegments(outboundFlight, inboundFlight, airlines, airports)
		))
	)
)

const ticketInfoByProductIdSelector = createSelector(
	extendedSegmentsByProductIdSelector,
	flightFaresByProductIdSelector,
	(extSegmentsByProductId, faresByProductId) => (
		mapValues(extSegmentsByProductId, (extSegs, id) => (
			getTicketsInfo(faresTotalPriceResult(faresByProductId[id]), extSegs, faresByProductId[id])
		))
	)
)

export default ticketInfoByProductIdSelector
