import 'normalize.css'
import './styles/index.css'


const TAB_KEYCODE = 9
const TABBING_MARKER = 'user-is-tabbing'


const BaseCSS = ({ children }) => (<div>{children}</div>)

function handleTabDown (e) {
	if (e.keyCode === TAB_KEYCODE) {
		document.body.classList.add(TABBING_MARKER)

		window.removeEventListener('keydown', handleTabDown)
		window.addEventListener('mousedown', handleMouseDown)
	}
}

function handleMouseDown () {
	document.body.classList.remove(TABBING_MARKER)

	window.removeEventListener('mousedown', handleMouseDown)
	window.addEventListener('keydown', handleTabDown)
}

window.addEventListener('keydown', handleTabDown)

export default BaseCSS
