import l from '@libs/lang'

import DayPicker, { LocaleUtils } from 'react-day-picker'

import styles from './styles'


const firstDayOfWeek = {
	'ru-RU': 1,
}
const sundayStartedMonth = (date) => `2018-07-${date.length <= 1 ? `0${date}` : date}`
const getWeekdayByIndex = (i, type = 'short') => (
	l.date(new Date(sundayStartedMonth(`${i + 1}`)), 'weekdayFormat', {
		weekdayFormat: { weekday: type },
	})
)
const weekdays = new Array(7).fill(null).map((_, i) => ({
	short: getWeekdayByIndex(i, 'short'),
	long: getWeekdayByIndex(i, 'long'),
}))
const defaultFirstDayOfWeek = 1
const localeUtils = {
	...LocaleUtils,
	formatWeekdayLong: (index) => weekdays[index].long,
	formatWeekdayShort: (index) => weekdays[index].short,
	getFirstDayOfWeek: (locale = 'ru-RU') => (firstDayOfWeek[locale] || defaultFirstDayOfWeek),
	formatMonthTitle: (d) => (
		<React.Fragment>
			<b>{l.date(d, 'longMonth', { longMonth: { month: 'long' } })}</b>&nbsp;{d.getFullYear()}
		</React.Fragment>
	),
}

const DayPickerWrapper = ({ initialMonth, setRef, ...props }) => {
	const nowMonth = new Date()
	const firstRenderMonth = initialMonth || nowMonth
	const propsDayPicker = {
		ref: setRef,
		locale: l.lang,
		fromMonth: nowMonth,
		initialMonth: firstRenderMonth,
		localeUtils,
		...props,
		classNames: styles,
	}
	return (<DayPicker {...propsDayPicker}/>)
}

export { DateUtils } from 'react-day-picker'
export default DayPickerWrapper
