import { flow, some, get, last, find, first } from 'lodash'
import { createSelector } from 'reselect'
import {
	outboundFlightFromOfferSelector,
	outboundFlightFromProductSelector,
	inboundFlightFromOfferSelector,
	inboundFlightFromProductSelector,
	flightConsistFromOfferSelector,
	flightConsistFromProductSelector,
	faresTotalPriceResult,
	flightFaresFromOfferSelector,
	flightFaresFromProductSelector,
	ticketsTypeFromOfferSelector,
	ticketsTypeFromProductSelector,
	airportsFromOfferSelector,
	airportsFromProductSelector,
} from '@store/state/domainData/selectors'
import flightFaresVariantsSelector from './flightFaresVariants.js'
import { ternarySelectorCreator } from './helper.js'


export const productTypeSelector = ternarySelectorCreator(ticketsTypeFromOfferSelector, ticketsTypeFromProductSelector)

export const passengersCountSelector = flow(
	ternarySelectorCreator(flightConsistFromOfferSelector, flightConsistFromProductSelector),
	(consist) => (consist.reduce((a, i) => (a + i), 0))
)

export const baggageConsistSelector = flow(
	ternarySelectorCreator(flightConsistFromOfferSelector, flightConsistFromProductSelector),
	([ adults, children ]) => (adults + children)
)

export const suppliersCountSelector = flow(
	ternarySelectorCreator(flightFaresFromOfferSelector, flightFaresFromProductSelector),
	(fares) => (Object.keys(fares).length)
)

export const ticketsSelector = createSelector(
	flightFaresVariantsSelector,
	(tickets) => (
		tickets.map(([ { isExchange, isReturn, isRefundSubjectToCharge, isExchangeSubjectToCharge } ]) => ({
			isRefundable: isReturn,
			isChangeble: isExchange,
			isRefundSubjectToCharge,
			isExchangeSubjectToCharge,
		}))
	),
)

export const baggageReRegWarnFnSelector = flow(
	ternarySelectorCreator(flightFaresFromOfferSelector, flightFaresFromProductSelector),
	(fares) => {
		const findFareFn = (flight) => find(Object.values(fares), (fare) =>
			fare.service.includes(flight.props.departure.flightId.replace(' ', '-'))
		)
		return (prevFlight, nextFlight) => {
			try {
				if (!nextFlight || !prevFlight) return false

				const prevFlightFare = findFareFn(prevFlight)
				const nextFlightFare = findFareFn(nextFlight)
				if (nextFlightFare.id !== prevFlightFare.id) return true

				return some(Object.values(fares), (fare) =>
					some((fare.details['recheck_before'] || []), (segment) =>
						segment.includes(nextFlight.props.departure.flightId.replace(' ', '-'))
					)
				)
			}
			catch (_err) {
				return false
			}
		}
	}
)

export const baggageReRegWarnSelector = flow(
	ternarySelectorCreator(flightFaresFromOfferSelector, flightFaresFromProductSelector),
	(fares) => {
		// these suppliers build custom hub-crosses
		// so a passenger will probably have to re-register
		// his baggage in transfer point
		const SUPPLIERS_WITH_BAGGAGE_RE_REG_WARN = [ 'kiwi', 'kiwi_full', 'kiwi_ogo', 'kiwi_tgo' ]

		return some(fares, ({ supplier, recheck }) => (
			recheck || SUPPLIERS_WITH_BAGGAGE_RE_REG_WARN.includes(supplier))
		)
	}
)

export const priceSelector = flow(
	ternarySelectorCreator(flightFaresFromOfferSelector, flightFaresFromProductSelector),
	faresTotalPriceResult
)

export const faresWithVarsCountSelector = flow(
	flightFaresVariantsSelector,
	(faresVars) => faresVars.filter((fareVars) => fareVars.length > 1).length
)

const CHARTER_FLIGHT = 'charter'
const GOA_AIRPORT = 'GOI'

export const isIndianVisaNeededSelector = createSelector(
	productTypeSelector,
	ternarySelectorCreator(outboundFlightFromOfferSelector, outboundFlightFromProductSelector),
	(ticketsType, outboundFlight) => {
		const isCharterFlight = ticketsType === CHARTER_FLIGHT

		const departSegment = first(outboundFlight)
		const departAirport = get(departSegment, 'depart.airport', '')
		const arriveSegment = last(outboundFlight)
		const arriveAirport = get(arriveSegment, 'arrive.airport', '')
		const isGoaDestination = departAirport === GOA_AIRPORT || arriveAirport === GOA_AIRPORT

		return isCharterFlight && isGoaDestination
	}
)


const THAI_COUNTRY_CODE = 'TH'
const EGYPT_COUNTRY_CODE = 'EG'
const RU_COUNTRY_CODE = 'RU'
const AE_COUNTRY_CODE = 'AE'
const BANNED_AIRLINES = [ 'RL', 'I4', 'N4', 'ZF', 'F7' ]

export const showUaeWarnSelector = createSelector(
	ternarySelectorCreator(airportsFromOfferSelector, airportsFromProductSelector),
	ternarySelectorCreator(outboundFlightFromOfferSelector, outboundFlightFromProductSelector),
	ternarySelectorCreator(inboundFlightFromOfferSelector, inboundFlightFromProductSelector),
	(airports, outboundFlight, inboundFlight) => inboundFlight.length === 0 && outboundFlight.some((segment) => {

		const srcAirport = get(segment, 'depart.airport', '')
		const dstAirport = get(segment, 'arrive.airport', '')
		const srcCountry = get(airports, `${srcAirport}.country_code`, '')
		const dstCountry = get(airports, `${dstAirport}.country_code`, '')

		return srcCountry !== AE_COUNTRY_CODE && dstCountry === AE_COUNTRY_CODE
	})
)

export const showThaiCharterWarn = createSelector(
	ternarySelectorCreator(airportsFromOfferSelector, airportsFromProductSelector),
	ternarySelectorCreator(outboundFlightFromOfferSelector, outboundFlightFromProductSelector),
	ternarySelectorCreator(inboundFlightFromOfferSelector, inboundFlightFromProductSelector),
	(airports, outboundFlight, inboundFlight) => {

		const hasBannedSegment = outboundFlight.some((segment) => {

			const srcAirport = get(segment, 'depart.airport', '')
			const dstAirport = get(segment, 'arrive.airport', '')
			const srcCountry = get(airports, `${srcAirport}.country_code`, '')
			const dstCountry = get(airports, `${dstAirport}.country_code`, '')

			const isFromThai = srcCountry === THAI_COUNTRY_CODE
			const isToRussia = dstCountry === RU_COUNTRY_CODE
			const isFromRussia = srcCountry === RU_COUNTRY_CODE
			const isToThai = dstCountry === THAI_COUNTRY_CODE
			const isBannedAirline = BANNED_AIRLINES.includes(segment.airline)

			return ((isFromThai && isToRussia) || (isFromRussia && isToThai)) && isBannedAirline
		})

		return hasBannedSegment && !inboundFlight.length
	}
)

export const showEgyptCharterWarnSelector = createSelector(
	ternarySelectorCreator(airportsFromOfferSelector, airportsFromProductSelector),
	productTypeSelector,
	ternarySelectorCreator(outboundFlightFromOfferSelector, outboundFlightFromProductSelector),
	ternarySelectorCreator(inboundFlightFromOfferSelector, inboundFlightFromProductSelector),
	(airports, ticketsType, outboundFlight, inboundFlight) => {

		const isCharterFlight = ticketsType === CHARTER_FLIGHT
		const hasWarnSegment = outboundFlight.some((segment) => {

			const srcAirport = get(segment, 'depart.airport', '')
			const dstAirport = get(segment, 'arrive.airport', '')
			const srcCountry = get(airports, `${srcAirport}.country_code`, '')
			const dstCountry = get(airports, `${dstAirport}.country_code`, '')

			const isFromEgypt = srcCountry === EGYPT_COUNTRY_CODE
			const isToRussia = dstCountry === RU_COUNTRY_CODE

			return (isFromEgypt && isToRussia)
		})

		return isCharterFlight && hasWarnSegment && !inboundFlight.length
	}
)
