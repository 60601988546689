import request from 'superagent'
import confy from '@utils/confy'
import getResponseHandler from './responseHandler.js'
import { OPERATION_STATUSES } from './statuses.js'


const ERROR_STATUSES = [
	OPERATION_STATUSES.INVALID,
	OPERATION_STATUSES.VALIDATION_ERROR,
	OPERATION_STATUSES.PRICE_CHANGED,
	OPERATION_STATUSES.UNAVAIL,
]
const OK_STATUSES = [
	OPERATION_STATUSES.AVAIL,
]

export default function ({ offerId, frontUrl, channel }) {
	const url = `${confy.get('API.cl.order')}`
	const data = {
		offer_id: offerId, // eslint-disable-line camelcase
		url: frontUrl,
		channel,
	}

	const req = request
		.post(url)
		.send(data)

	const executor = (resolve, reject) => {
		const handler = getResponseHandler({
			resolve,
			reject,
			okStatuses: OK_STATUSES,
			errStatuses: ERROR_STATUSES,
			requestType: 'POST /order',
		})

		req.end(handler)
	}

	return {
		promise: new Promise(executor),
		cancelFn: () => req.abort(),
	}
}
