import cx from 'classnames'
import l from '@libs/lang'

import { Raw as Button } from 'core-components/Button'

import styles from '../styles/btn.js'
import imgEmtpyCart from '../images/empty-cart.svg'
import imgFullCart from '../images/full-cart.svg'


const noOp = () => {}

const CartBtn = ({ totalPrice, withText, isActive, onClick = noOp }) => {
	const txtEmpty = l('В заказе нет услуг')
	const txtPrice = l.currency(totalPrice)
	const txtNotEmpty = l('Сумма {price}', { price: txtPrice })
	const text = (
		<span className={styles.textContainer}>
			{!totalPrice ? txtEmpty : txtNotEmpty}
		</span>
	)

	const imgSrc = !totalPrice ? imgEmtpyCart : imgFullCart
	const imgAlt = !totalPrice ? 'empty-cart' : 'not-emtpy-cart'
	const image = (
		<div className={styles.imgContainer}>
			<img src={imgSrc} alt={imgAlt}/>
		</div>
	)

	const containerClass = cx({
		[styles.container]: true,
		[styles.active]: isActive,
	})

	return (
		<Button classNames={containerClass} onClick={onClick} disabled={!totalPrice}>
			{image}
			{withText && text}
		</Button>
	)
}

export default CartBtn
