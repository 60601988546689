import { get } from 'lodash'
import { call, put, select } from 'redux-saga/effects'

import feofan from '@libs/feofan'
import info from '@utils/info'
import { channelSelector } from '@store/state/appState/selectors'
import { OPERATION_STATUSES } from '@libs/foma/types'

import { makeCancellable, withError } from '../utils'


export const cancellableRebuildOffer = makeCancellable(feofan.rebuildOffer)

export default function* handleRebuildOffer (evtChannel, meta) {

	const { consist, offerId } = meta
	const [ adults, children, infants ] = consist
	const channel = yield select(channelSelector)

	const optsRebuild = {
		id: offerId,
		channel,
		consist: { adults, children, infants },
	}

	try {
		const resData = yield call(cancellableRebuildOffer, optsRebuild)
		yield put(evtChannel, resData)

		return resData.status
	}
	catch (err) {
		info('cant rebuild offer for new consist', err)
		const status = get(err, 'data.status', OPERATION_STATUSES.ERROR)
		yield put(evtChannel, withError({ ...err.data, status }))

		return status
	}
}

