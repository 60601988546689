import { createEnhancer } from '@utils/decoract'

import SecondaryFooter from './SecondaryFooter.jsx'
import PrimaryFooter from './PrimaryFooter.jsx'
import $ from './styles/index.js'


const cq = {
	t767: {
		maxWidth: 767,
	},
}

const enhancer = createEnhancer({ cq })

const CaFooter = ({ cq, showSecondary = false, ...props }) => (
	<div className={cq.t767 ? $.containerSm : $.container}>
		<PrimaryFooter {...props}/>
		{showSecondary && <SecondaryFooter {...props}/>}
	</div>
)

export default enhancer(CaFooter)
