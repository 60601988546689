import { takeEvery, all, fork } from 'redux-saga/effects'
import { BOOK, SET_BOOK_STATUS } from '@store/state/types'

import bookWorker from './bookWorker.js'
import book3dsWorker from './book3dsWorker.js'
import bookSuccessWorker from './bookSuccessWorker.js'
import upsaleDialogWorker from './upsaleDialogWorker.js'


export const takenBookActions = ({ type, payload = {} }) => Boolean(
	type === BOOK && !payload.isConfirm
)

export const takenBook3dsActions = ({ type, payload = {} }) => Boolean(
	type === BOOK && payload.isConfirm && payload.orderId
)

export default function* bookWatcher () {
	yield all([
		fork(upsaleDialogWorker),
		takeEvery(takenBookActions, bookWorker),
		takeEvery(takenBook3dsActions, book3dsWorker),
		takeEvery(SET_BOOK_STATUS, bookSuccessWorker),
	])
}
