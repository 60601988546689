export default {
	METRO_SERP: '/:src/:dst/:outboundDate/:inboundDate/:adults/:children/:infants',
	SERP: '/serp-page/:src/:dst/:outboundDate/:inboundDate/:adults/:children/:infants/:tab?',
	TICKETS: '/serp-page/:src/:dst/:outboundDate/:inboundDate/:adults/:children/:infants/tickets',
	HOTELS: '/serp-page/:src/:dst/:outboundDate/:inboundDate/:adults/:children/:infants/hotels',
	INDEX: '/',
	CHECKOUT: '/offer/:offerId',
	ADVANTAGES: '/advantages',
	CONTACTS: '/contacts',
	HISTORY: '/history',
	LOGIN: '/login',
	CABINET_ORDER: '/cabinet/:orderSupportId',
	CABINET: '/cabinet/:orderSupportId?',
	ABOUT: '/about',
	FAQ: '/faq/:questionId?',
	PASSRESET: '/password_reset',
	PASSRECOVERY: '/password_recovery',

	EXTERNAL_LOGIN: 'https://www.clickavia.ru/login',
	EXTERNAL_CABINET: 'https://www.clickavia.ru/cabinet/:orderSupportId?',
	TELEGRAM_CC_BOT: 'http://www.clickavia.ru/rd/telegram_cc_bot?channel=:channel',
}
