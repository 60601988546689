import { flow } from 'lodash'
import { createSelector } from 'reselect'
import { SEARCH_BAR_FORM } from '../types'
import { makeValueSelector } from './core.js'
import {
	isValueValid,
	isOutboundDateValid,
	isInboundDateValid,
	isAdultsValid,
	isChildrenValid,
	isInfantsValid,
} from '../validators'


// values
export const srcSelector = makeValueSelector({
	formName: SEARCH_BAR_FORM.NAME,
	fieldName: SEARCH_BAR_FORM.FIELDS.SRC,
})
export const dstSelector = makeValueSelector({
	formName: SEARCH_BAR_FORM.NAME,
	fieldName: SEARCH_BAR_FORM.FIELDS.DST,
})
export const outboundDateSelector = makeValueSelector({
	formName: SEARCH_BAR_FORM.NAME,
	fieldName: SEARCH_BAR_FORM.FIELDS.OUTBOUND_DATE,
})
export const inboundDateSelector = makeValueSelector({
	formName: SEARCH_BAR_FORM.NAME,
	fieldName: SEARCH_BAR_FORM.FIELDS.INBOUND_DATE,
})
export const adultsSelector = makeValueSelector({
	formName: SEARCH_BAR_FORM.NAME,
	fieldName: SEARCH_BAR_FORM.FIELDS.ADULTS,
})
export const childrenSelector = makeValueSelector({
	formName: SEARCH_BAR_FORM.NAME,
	fieldName: SEARCH_BAR_FORM.FIELDS.CHILDREN,
})
export const infantsSelector = makeValueSelector({
	formName: SEARCH_BAR_FORM.NAME,
	fieldName: SEARCH_BAR_FORM.FIELDS.INFANTS,
})

// validators
export const isSrcValidSelector = flow(srcSelector, isValueValid)
export const isDstValidSelector = flow(dstSelector, isValueValid)
export const isOutboundDateValidSelector = flow(outboundDateSelector, isOutboundDateValid)
export const isInboundDateValidSelector = createSelector(
	inboundDateSelector,
	outboundDateSelector,
	isInboundDateValid
)
export const isAdultsValidSelector = flow(adultsSelector, isAdultsValid)
export const isChildrenValidSelector = flow(childrenSelector, isChildrenValid)
export const isInfantsValidSelector = flow(infantsSelector, isInfantsValid)
export const isFormValidSelector = (state) => (
	isSrcValidSelector(state)
	&& isDstValidSelector(state)
	&& isOutboundDateValidSelector(state)
	&& isInboundDateValidSelector(state)
	&& isAdultsValidSelector(state)
	&& isChildrenValidSelector(state)
	&& isInfantsValidSelector(state)
)
