import { createSelector } from 'reselect'
import { flow } from 'lodash'
import { PRODUCT_TYPES } from '@libs/foma/types'

import {
	citiesSelector,
	byCurrentOfferId,
	byCurrentSearchQuery,
} from '@store/state/appState/selectors'
import {
	enabledAccommodationFareSelector,
	faresTotalPriceResult,
	flightFaresFromOfferSelector,
	getHotels,
	flightsProductsSelector,
	routeInfoSelector,
	getScheduledProducts,
	getFares,
} from '@store/state/domainData/selectors'


const objectPropsLength = (obj) => (obj ? Object.keys(obj).length : null)

export const hotelsPriceSelector = flow(
	byCurrentOfferId(enabledAccommodationFareSelector),
	(fare) => (fare ? { [fare.id]: fare } : {}),
	faresTotalPriceResult
)

export const ticketsPriceSelector = flow(
	byCurrentOfferId(flightFaresFromOfferSelector),
	faresTotalPriceResult
)

export const hotelsCountSelector = flow(
	byCurrentSearchQuery(getHotels),
	objectPropsLength
)


export const ticketsCountSelector = createSelector(
	[ byCurrentSearchQuery(flightsProductsSelector), byCurrentSearchQuery(getFares) ],
	(products, fares) => {
		const keysSet = new Set()

		for (let productId in products) {
			const [ _, fareIds ] = products[productId]
			const joinedKeys = fareIds.map((fareId) => (fares[fareId]._key)).sort().join()
			keysSet.add(joinedKeys)
		}

		return keysSet.size
	}
)

export const isRTSelector = flow(
	byCurrentSearchQuery(routeInfoSelector),
	(routeInfo) => (routeInfo.length > 1)
)

export const isAccomodationAwaitedSelector = (state) => {
	const hotelsCount = hotelsCountSelector(state)
	const scheduledProducts = byCurrentSearchQuery(getScheduledProducts)(state) || []

	return hotelsCount > 0 || scheduledProducts.includes(PRODUCT_TYPES.ACCOMMODATIONS)
}

export const dstMorphoSelector = (state) => {
	const cities = citiesSelector(state)
	const [ outboundRoute = [] ] = byCurrentSearchQuery(routeInfoSelector)(state)
	const [ _, dstMetro ] = outboundRoute

	const { morpho, title } = cities[dstMetro] || {}

	return { morpho, title }
}
