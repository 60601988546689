import {
	SET_FORM_FIELD_VALUE,
	SET_FORM_FIELD_CHECKED,
	CLEAR_FORM
} from '@store/state/types'


export default function (name, initialState = {}) {
	return (state = initialState, { type, payload }) => {
		switch (type) {
			case SET_FORM_FIELD_VALUE: {
				const { formName, fieldName, value } = payload
				if (formName !== name) return state
				return {
					...state,
					[fieldName]: value,
				}
			}
			case SET_FORM_FIELD_CHECKED: {
				const { formName, fieldName, checked } = payload
				if (formName !== name) return state
				return {
					...state,
					[fieldName]: checked,
				}
			}
			case CLEAR_FORM: {
				const { formName } = payload
				if (formName !== name) return state
				return initialState
			}
			default: return state
		}
	}
}
