import l from '@libs/lang'

import { toTelegramCcBot } from '@utils/router'
import $ from './styles/index.js'
import icoTelegram from './images/telegram.svg'


const TelegramHeaderBtn = (props) => {
	const {
		withText,
		enabledAb,
		isMobile,
	} = props

	const selectedAbVariant = 'B'
	const abVariant = enabledAb ? selectedAbVariant : 'A'
	const yourOrders = l('Ваши заказы')
	const controlYourOrders = l('Управляйте заказами')
	const txt = abVariant === 'A' ? yourOrders : controlYourOrders

	const contatinerClass = isMobile ? $.containerSm : $.container

	return (
		<a className={contatinerClass} target="_blank" href={toTelegramCcBot(`header_${abVariant}`)}>
			<img src={icoTelegram}/>
			{withText ? <span className={$.text}>{txt}</span> : null}
		</a>
	)
}

export default TelegramHeaderBtn
