import qs from 'qs'
import { channel, END, buffers } from 'redux-saga'
import { put, select, fork, call, take, cancel } from 'redux-saga/effects'
import { matchPath } from 'react-router-dom'
import { PATHS } from '@utils/router'
import { routerDataSelector } from '@store/state/appState/selectors'
import history from '@store/history'
import { LOCATION_CHANGE } from '@store/state/types'
import {
	checkoutHandler,
	serpHandler,
	makeCabinetHandler,
	metroSerpHandler,
} from './handlers'
import sendFeedbackWatcher from '@store/sagas/watchSendFeedback'
import recoverPasswordWatcher from '@store/sagas/watchRecoverPassword'
import resetPasswordWatcher from '@store/sagas/watchResetPassword'


export const routeHandlers = [
	{ route: PATHS.SERP, handler: serpHandler },
	{ route: PATHS.METRO_SERP, handler: metroSerpHandler },
	{ route: PATHS.CHECKOUT, handler: checkoutHandler },
	{ route: PATHS.CABINET_ORDER, handler: makeCabinetHandler() },
	{ route: PATHS.CABINET, handler: makeCabinetHandler() },
	{ route: PATHS.CONTACTS, handler: sendFeedbackWatcher },
	{ route: PATHS.FAQ, handler: sendFeedbackWatcher },
	{ route: PATHS.PASSRECOVERY, handler: recoverPasswordWatcher },
	{ route: PATHS.PASSRESET, handler: resetPasswordWatcher },
]

export const matchPaths = (location) => {
	for (let { route, handler } of routeHandlers) {
		const match = matchPath(location.pathname, route)
		if (match && match.isExact) {
			return {
				handler,
				params: match.params,
				query: qs.parse(location.search.slice(1)),
			}
		}
	}
	return null
}

export function* routerTakeLatest () {
	let lastHandlerTask = null
	let lastOverlaidHandlerTask = null
	let lastHandler = null
	let lastChan = null
	while (true) {
		yield take(LOCATION_CHANGE)
		const { location } = yield select(routerDataSelector)
		const matched = yield call(matchPaths, location)
		if (history.action !== 'REPLACE') {
			const state = location.state || {}
			const isSameLocation = matched && matched.handler === lastHandler
			if (lastOverlaidHandlerTask) {
				yield cancel(lastOverlaidHandlerTask)
				lastOverlaidHandlerTask = null
			}
			if (!state.isOverlaid && !isSameLocation && lastHandlerTask) {
				yield cancel(lastHandlerTask)
				lastHandlerTask = null
				lastHandler = null
				yield put(lastChan, END)
				lastChan = null
			}
			if (matched && !isSameLocation) {
				const { handler, params, query } = matched
				if (state.isOverlaid) {
					lastOverlaidHandlerTask = yield fork(handler, params, query, lastChan)
				}
				else {
					lastChan = yield call(channel, buffers.sliding())
					lastHandlerTask = yield fork(handler, params, query, lastChan)
					lastHandler = handler
				}
			}
			else if (matched) {
				yield put(lastChan, { params: matched.params, query: matched.query, state })
			}
		}
	}
}

export default function* () {
	yield fork(routerTakeLatest)
}
