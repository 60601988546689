import store from 'store'
import { select, call, put } from 'redux-saga/effects'
import * as somic from '@tracer/sociomantic'
import {
	setPreviousPrice,
} from '@store/state/appState/actions'
import {
	locationQuerySelector,
} from '@store/state/appState/selectors'


export default function* restorePreviousPriceWorker () {
	const { product_id: productId } = yield select(locationQuerySelector)
	if (!productId) return
	const prevPriceLsKey = yield call(somic.getLsPriceKey, productId)
	const previousPrice = yield call([ store, 'get' ], prevPriceLsKey)
	if (previousPrice) yield put(setPreviousPrice(previousPrice))
}

