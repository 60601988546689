import { put, takeEvery } from 'redux-saga/effects'

import { FOCUS } from '@store/state/types'
import { parseFormFieldFocusKey } from '@store/focusMiddleware'
import { persistForm } from '@store/state/uiState/forms/actions'
import { PASSENGERS_FORM, CUSTOMER_FORM } from '@store/state/uiState/forms/types'


const FORMS_TO_PERSIST = [ PASSENGERS_FORM.NAME, CUSTOMER_FORM.NAME ]

export const checkoutFormsBlurActionsMatcher = ({ type, meta = {} }) => {
	if (type !== FOCUS) return false
	const { formName } = parseFormFieldFocusKey(meta.prevActiveElem || '')
	return FORMS_TO_PERSIST.includes(formName)
}

export function* handleFieldBlur ({ meta }) {
	const { formName, formIndex } = parseFormFieldFocusKey(meta.prevActiveElem || '')
	yield put(persistForm({ formName, formIndex }))
}

export default function* persistFormsWorker () {
	yield takeEvery(checkoutFormsBlurActionsMatcher, handleFieldBlur)
}
