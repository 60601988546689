import store from 'store'

import { get, set, pick } from 'lodash'
import { call, put, select } from 'redux-saga/effects'

import feofan from '@libs/feofan'
import info from '@utils/info'
import { channelSelector } from '@store/state/appState/selectors'
import { OPERATION_STATUSES, ORDER_STATUSES, PAYMENT_STATUSES } from '@libs/foma/types'

import { makeCancellable, withError, getFrontUrl } from '../utils'


export const cancellableBook = makeCancellable(feofan.book)
export const cancellableGetOrder = makeCancellable(feofan.getOrder)
export const cancellableCreateOrder = makeCancellable(feofan.createOrder)
export const cancellableRebuildOffer = makeCancellable(feofan.rebuildOffer)

const pickStatuses = (resData) => pick(resData, [
	'status',
	'meta.op_status',
	'data.orders.0.payment_status',
	'data.orders.0.status',
])

const ORDER_STATUSES_FOR_REBUILD = [
	OPERATION_STATUSES.UNAVAIL,
	OPERATION_STATUSES.INVALID,
]

export function* makeOrder (evtChannel, offerId, maybeOrderId, offerPrice) {

	const channel = yield select(channelSelector)
	const frontUrl = yield call(getFrontUrl)

	const optsReqOrder = {
		offerId,
		orderId: maybeOrderId,
		frontUrl,
		channel,
	}

	const orderFetcher = maybeOrderId
		? cancellableGetOrder
		: cancellableCreateOrder

	let orderData = null
	let needRebuild = false

	try {
		orderData = yield call(orderFetcher, optsReqOrder)
		if (get(orderData, 'data.orders.0.id', null) && get(orderData, 'meta.order_token', null)) {
			const orderId = get(orderData, 'data.orders.0.id', null)
			store.set(`ORDERS_TOKEN/${orderId}`, get(orderData, 'meta.order_token', null))
		}
		// set order data to store
		yield put(evtChannel, orderData)
	}
	catch (e) {
		const status = get(e, 'data.status', OPERATION_STATUSES.UNAVAIL)
		needRebuild = ORDER_STATUSES_FOR_REBUILD.includes(status)
		if (!needRebuild) {
			const data = { ...e.data, status }
			yield put(evtChannel, data)
			throw e
		}
	}

	if (needRebuild) {
		const optsRebuildOffer = {
			id: offerId,
			frontUrl,
			channel,
		}
		try {
			const resData = yield call(cancellableRebuildOffer, optsRebuildOffer)
			// set rebuilded offer data to store
			yield put(evtChannel, resData)
			const newOfferId = get(resData, 'data.offers.0.id', offerId)
			const newOfferPrice = get(resData, 'data.offers.0.price', null)
			const newOptsReqOrder = {
				...optsReqOrder,
				offerId: newOfferId,
			}

			orderData = yield call(orderFetcher, newOptsReqOrder)
			// set order data to store
			yield put(evtChannel, orderData)

			if (offerPrice !== newOfferPrice) {
				const err = new Error()
				set(err, 'data.status', OPERATION_STATUSES.PRICE_CHANGED)
				throw err
			}
		}
		catch (err) {
			// do nothing
			throw err
		}
	}

	const orderId = get(orderData, 'data.orders.0.id', null)

	return orderId
}

export const ATTEMPTS_FOR_UNROLL_COMPLETION = 3

export function* getUnrolledOrder (orderId) {
	let orderStatus = null
	let orderData = {}
	let retryCount = ATTEMPTS_FOR_UNROLL_COMPLETION

	do {
		orderData = yield call(cancellableGetOrder, { orderId })
		orderStatus = get(orderData, 'data.orders.0.status', null)
		retryCount--
	} while (orderStatus === ORDER_STATUSES.UNROLLING && retryCount > 0)

	info({ type: 'getOrder', data: pickStatuses(orderData) })

	return orderData
}

export default function* handleBook (evtChannel, meta) {

	const { offerId, maybeOrderId, bookingData, isConfirm, isBlock3DS, timeoutMs, offerPrice } = meta

	const channel = yield select(channelSelector)
	const frontUrl = yield call(getFrontUrl)

	let orderId = null

	if (isConfirm) {
		// confirm 3DS payment
		try {
			const optsBookConfirm = { orderId: maybeOrderId, isConfirm }
			info({ type: 'bookStart' })
			const bookingResData = yield call(cancellableBook, optsBookConfirm)

			try {
				const orderData = yield call(getUnrolledOrder, maybeOrderId)
				yield put(evtChannel, orderData)
				info({ type: 'getOrder', data: pickStatuses(orderData) })
			}
			catch (e) {
				const orderStatus = get(e, 'data.status', OPERATION_STATUSES.ERROR)
				const errorData = { ...e.data, status: orderStatus }
				yield put(evtChannel, withError(errorData))
				info({ type: 'getOrder', data: errorData })
			}

			yield put(evtChannel, bookingResData)
			info({ type: 'book', data: pickStatuses(bookingResData) })

			return bookingResData.status
		}
		catch (err) {
			info('cant confirm 3DS', err)
			const status = get(err, 'data.status', OPERATION_STATUSES.ERROR)
			window.Raven && window.Raven.captureException(new Error('3ds Error'), { extra: { 
				offerId: offerId, orderId: maybeOrderId, status: status, data: err.data,
			} })
			try {
				const orderData = yield call(cancellableGetOrder, { orderId: maybeOrderId })
				yield put(evtChannel, orderData)
				info({ type: 'getOrder', data: pickStatuses(orderData) })
			}
			catch (e) {
				const orderStatus = get(e, 'data.status', OPERATION_STATUSES.ERROR)
				const errorData = { ...e.data, status: orderStatus }
				yield put(evtChannel, withError(errorData))
				info({ type: 'getOrder', data: errorData })
				window.Raven && window.Raven.captureException(new Error('3ds Error'), { extra: {
					offerId: offerId, orderId: maybeOrderId, data: e.data,
				} })
			}
			yield put(evtChannel, withError({ ...err.data, status }))
			info({ type: 'book', data: { ...err.data } })

			return status
		}
	}
	else if (isBlock3DS) {
		try {
			const optsBlock = {
				offerId,
				orderId: maybeOrderId,
				bookingData: {
					channel,
					...bookingData,
				},
				isConfirm,
				timeoutMs,
			}

			info({ type: 'book3DSStart' })
			const bookingResData = yield call(cancellableBook, optsBlock)

			yield put(evtChannel, bookingResData)
			info({ type: 'book3DS', data: pickStatuses(bookingResData) })

			return bookingResData.status
		}
		catch (err) {
			info('cant process a 3DS scenario for booking', err)
			const status = get(err, 'data.status', OPERATION_STATUSES.ERROR)
			try {
				const orderData = yield call(cancellableGetOrder, { orderId: maybeOrderId })
				yield put(evtChannel, orderData)
				info({ type: 'getOrder', data: pickStatuses(orderData) })
			}
			catch (e) {
				const orderStatus = get(e, 'data.status', OPERATION_STATUSES.ERROR)
				const errorData = { ...e.data, status: orderStatus }
				yield put(evtChannel, withError(errorData))
				info({ type: 'getOrder', data: errorData })
			}
			yield put(evtChannel, withError({ ...err.data, status }))
			info({ type: 'book3DS', data: { ...err.data } })

			return status
		}
	}
	else {
		try {
			info({ type: 'bookStart' })
			orderId = yield call(makeOrder, evtChannel, offerId, maybeOrderId, offerPrice)

			if (!orderId) {
				return OPERATION_STATUSES.ERROR
			}

			info({ type: 'bookStart' })

			const optsBook = {
				offerId,
				orderId,
				bookingData: {
					channel,
					...bookingData,
				},
				isConfirm,
				timeoutMs,
			}
			const bookingResData = yield call(cancellableBook, optsBook)

			info({ type: 'book', data: pickStatuses(bookingResData) })

			// check order after success booking
			const optsGetOrderAfterBook = {
				orderId,
				channel,
				frontUrl,
			}
			const orderDataAfterBook = yield call(cancellableGetOrder, optsGetOrderAfterBook)
			info({ type: 'getOrder', data: pickStatuses(orderDataAfterBook) })
			// set order data to store
			yield put(evtChannel, orderDataAfterBook)
			yield put(evtChannel, bookingResData)

			return bookingResData.status
		}
		catch (err) {
			info('cant book or get order', err)
			const status = get(err, 'data.status', OPERATION_STATUSES.ERROR)
			const paymentStatus = get(err, 'data.data.orders.0.payment_status', null)
			try {
				window.Raven && window.Raven.captureException(new Error('cant book or get order'), { extra: { 
					status: status, err: err, paymentStatus: paymentStatus,
				} })
			}
			catch (e) {
				info('cant log', e)
			}
			const needUnrolling = status === OPERATION_STATUSES.UNAVAIL
				&& paymentStatus === PAYMENT_STATUSES.BLOCKED
			const hasOrderData = Boolean(paymentStatus)

			// check order while backend do unrolling
			if ((needUnrolling || !hasOrderData) && orderId) {
				try {
					const orderData = yield call(getUnrolledOrder, orderId)
					info({ type: 'getOrder', data: pickStatuses(orderData) })
					yield put(evtChannel, orderData)
				}
				catch (e) {
					const status = get(e, 'data.status', OPERATION_STATUSES.ERROR)
					const errorData = { ...e.data, status }
					yield put(evtChannel, withError(errorData))
					info({ type: 'getOrder', data: errorData })
				}
			}
			yield put(evtChannel, withError({ ...err.data, status }))
			info({ type: 'book', data: { ...err.data } })

			return status
		}
	}
}
