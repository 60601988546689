import { getDate, isIsoDateValid } from '@utils/time-utils'
import {
	locationRegExp,
	searchBarDateFormatRegExp,
} from './regExps.js'


export const isValueValid = (value) => Boolean(
	value.length && locationRegExp.test(value)
)
export const isDateValid = (value) => Boolean(
	value.length && searchBarDateFormatRegExp.test(value) && isIsoDateValid(value)
)
export const isFlightDateValid = (value, minDateValue = null) => {
	if (!isDateValid(value)) return false
	const date = getDate(value)
	const minDate = minDateValue ? getDate(minDateValue) : new Date()
	minDate.setHours(0, 0, 0, 0)
	return date >= minDate
}
export const isOutboundDateValid = isFlightDateValid
export const isInboundDateValid = (value, outboundDate) => (
	!value || isFlightDateValid(value, outboundDate)
)
export const isPaxCountValid = (value, min) => (
	typeof value === 'number' && value >= min
)
const MIN_ADULTS_COUNT = 1
export const isAdultsValid = (value) => isPaxCountValid(value, MIN_ADULTS_COUNT)
const MIN_CHILDREN_COUNT = 0
export const isChildrenValid = (value) => isPaxCountValid(value, MIN_CHILDREN_COUNT)
const MIN_INFANTS_COUNT = 0
export const isInfantsValid = (value) => isPaxCountValid(value, MIN_INFANTS_COUNT)

