import request from 'superagent'
import confy from '@utils/confy'
import getResponseHandler from './responseHandler.js'
import { OPERATION_STATUSES } from './statuses.js'


const ERROR_STATUSES = [
	OPERATION_STATUSES.UNAVAIL,
	OPERATION_STATUSES.VALIDATION_ERROR,
]
const OK_STATUSES = [
	OPERATION_STATUSES.PRICE_CHANGED,
	OPERATION_STATUSES.AVAIL,
]

export default function ({ id, frontUrl, channel }) {
	const url = `${confy.get('API.cl.offer')}/${id}/check`
	const queryObject = {
		front_url: frontUrl, // eslint-disable-line camelcase
		channel,
	}

	const req = request.get(url).query(queryObject)

	const executor = (resolve, reject) => {
		const handler = getResponseHandler({
			resolve,
			reject,
			okStatuses: OK_STATUSES,
			errStatuses: ERROR_STATUSES,
			requestType: 'GET /offer/check',
		})

		req.end(handler)
	}
	return {
		promise: new Promise(executor),
		cancelFn: () => req.abort(),
	}
}
