const removeIFrame = (document, name) => {
	if (document.body.contains(document.getElementById(name))) {
		document.getElementById(name).remove()
	}
}

const createIFrame = (document, name) => {
	removeIFrame(document, name)

	const iframe = document.createElement('iframe')
	// eslint-disable-next-line
	iframe.setAttribute('src', 'javascript:false')
	iframe.setAttribute('name', name)
	iframe.setAttribute('id', name)
	iframe.setAttribute('sandbox', 'allow-same-origin allow-scripts allow-forms')
	iframe.style.display = 'none'
	document.body.appendChild(iframe)
	return iframe
}

const DELAY = 3000

export default function submitFormToIframe (document, meta, paramsKey = 'form', iframeName = 'acl-frame') {
	createIFrame(document, iframeName)

	const form = document.createElement('form')
	form.method = 'post'
	form.action = meta.url
	form.target = iframeName
	form.style.display = 'none'

	const params = meta[paramsKey]

	Object.keys(params).forEach((paramName) => {
		const node = document.createElement('input')
		node.name = paramName
		node.value = params[paramName]

		form.appendChild(node)
	})

	document.body.appendChild(form)
	form.submit()

	return new Promise((resolve, _reject) => {
		setTimeout(() => {
			document.body.removeChild(form)
			resolve()
		}, DELAY)
	})
}
