import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { matchPath } from 'react-router-dom'
import TABS from '@utils/serp-tabs'
import {
	toSerp,
	toCheckout,
	toIndex,
	toContacts,
	toAbout,
	PATHS,
} from '@utils/router'
import {
	setShopfrontDialog,
} from '@store/state/appState/actions'
import { isSerpLoading as isSerpLoadingSelector } from '@store/state/selectors'
import {
	currentOfferIdSelector,
	serpParamsSelector,
	currentSearchQuerySelector,
	routerDataSelector,
} from '@store/state/appState/selectors'
import {
	ticketsPriceSelector,
	hotelsPriceSelector,
	ticketsCountSelector,
	hotelsCountSelector,
	isAccomodationAwaitedSelector,
} from '@ecosystems/Shopfront/selectors'


const mapStateToProps = (state) => {
	const { location } = routerDataSelector(state)
	const serpMatched = matchPath(location.pathname, { path: PATHS.SERP, exact: true })
	const checkoutMatched = matchPath(location.pathname, { path: PATHS.CHECKOUT, exact: true })
	const isSerp = Boolean(serpMatched)
	const isCheckout = Boolean(checkoutMatched)
	const isTickets = isSerp && serpMatched.params.tab === TABS.TICKETS
	const isHotels = isSerp && serpMatched.params.tab === TABS.HOTELS
	return {
		currentOfferId: currentOfferIdSelector(state),
		isCheckout,
		isTickets,
		isHotels,
		params: isSerp ? serpMatched.params : serpParamsSelector(state),
		ticketsPrice: ticketsPriceSelector(state),
		hotelsPrice: hotelsPriceSelector(state),
		hotelsCount: hotelsCountSelector(state),
		ticketsCount: ticketsCountSelector(state),
		isAccomodationAwaited: isAccomodationAwaitedSelector(state),
		isLoading: isSerpLoadingSelector(state) || !currentSearchQuerySelector(state),
	}
}
const mapDispatchToProps = (dispatch) => ({
	openDialog: (...args) => {
		dispatch(setShopfrontDialog(...args))
	},
	pushToSerp: (...args) => {
		dispatch(push(toSerp(...args)))
	},
	pushToCheckout: (...args) => {
		dispatch(push(toCheckout(...args)))
	},
	pushToIndex: () => {
		dispatch(push(toIndex()))
	},
	pushToContacts: () => {
		dispatch(push(toContacts()))
	},
	pushToAbout: () => {
		dispatch(push(toAbout()))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)

