import { matchPath } from 'react-router-dom'
import { connect } from 'react-redux'
import { PATHS } from '@utils/router'
import { isSerpLoading as isSerpLoadingSelector } from '@store/state/selectors'
import {
	byCurrentSearchQuery,
	routerDataSelector,
} from '@store/state/appState/selectors'
import { timestampSelector } from '@store/state/domainData/selectors'

import DelayedSwitch from '@core/DelayedSwitch'
import FakeProgressBar from '@molecules/FakeProgressBar/statefull'

import $ from '../styles/progressBar.js'


const OUT_DELAY = 500

class MaybeProgressBar extends React.Component {

	state = {
		lastProgress: 0,
		isMounted: true,
	}

	updateProgress = (lastProgress) => {
		this.setState((state) => ({
			...state,
			lastProgress,
		}))
	}

	componentWillReceiveProps (nextProps) {
		const willTimestampChange = nextProps.timestamp !== this.props.timestamp
		// if search query changes
		// we need to unmount progress bar, reset lastProgress, then mount back
		if (willTimestampChange) {
			this.setState((state) => ({
				...state,
				isMounted: false,
			}))
		}
	}

	componentDidUpdate (prevProps, prevState) {
		const didTimestampChange = prevProps.timestamp !== this.props.timestamp
		const didUnmount = prevState.isMounted && !this.state.isMounted

		if (didTimestampChange && didUnmount) {
			this.setState((state) => ({
				...state,
				lastProgress: 0,
				isMounted: true,
			}))
		}
	}

	render () {
		const { cq, isSerpLoading, isSerp } = this.props
		const { lg } = cq
		const { lastProgress, isMounted } = this.state

		if (!isSerp || !isMounted) return null

		return (
			<DelayedSwitch
				delayOut={OUT_DELAY}
				delayIn={0}
				inContent={(
					<div className={lg ? $.progress : $.progressSm}>
						<div>
							<FakeProgressBar
								isRunning={isSerpLoading}
								initialPercent={lastProgress}
								onUnmount={this.updateProgress}
							/>
						</div>
					</div>
				)}
				outContent={null}
				condition={isSerpLoading}
			/>
		)
	}
}


const mapStateToProps = (state) => {
	const { location } = routerDataSelector(state)

	return {
		isSerp: matchPath(location.pathname, { path: PATHS.SERP, exact: true }),
		isSerpLoading: isSerpLoadingSelector(state),
		timestamp: byCurrentSearchQuery(timestampSelector)(state),
	}
}

const connector = connect(mapStateToProps)

export default connector(MaybeProgressBar)
