import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { findDOMNode } from 'react-dom'
import PropTypes from 'prop-types'
import cn from 'classnames'

import Toggler from 'core-components/Toggler'

import styles from './styles'


const propsTransitionGroup = {
	component: null,
}
const propsCSSTransition = {
	classNames: {
		enter: styles.popoverEnter,
		enterActive: styles.popoverEnterActive,
		exit: styles.popoverExit,
		exitActive: styles.popoverExitActive,
	},
	timeout: {
		enter: 350,
		exit: 200,
	},
	enter: true,
	exit: true,
}

class Dropdown extends React.Component {
	_setContainerRef = (ref) => this._containerRef = findDOMNode(ref)
	_setPopoverRef = (ref) => this._popoverRef = findDOMNode(ref)

	_onContainerFocus = ({ target }) => {
		const { isShownOnFocused, onFocus } = this.props
		isShownOnFocused && this._showPopover()
		onFocus && onFocus()
	}

	_onContainerBlur = ({ relatedTarget }) => {
		const isInsideContainer = this._containerRef && this._containerRef.contains(relatedTarget)
		if (!isInsideContainer) {
			const { isHiddenOnBlur, onBlur } = this.props
			isHiddenOnBlur && this._hidePopover()
			onBlur && onBlur()
		}
	}

	_onContainerKeyDown = ({ target, nativeEvent }) => {
		if (target === this._popoverRef) return
		const evt = new KeyboardEvent('keydown', nativeEvent)
		this._popoverRef && this._popoverRef.dispatchEvent(evt)
	}

	_renderDropdown = (togglerState, toggle, on, off) => {
		const { renderInput, renderPopover, popoverWidth, isOverlaidPopover } = this.props
		const { isOn } = togglerState
		this._showPopover = on
		this._hidePopover = off

		const propsContainer = {
			className: styles.container,
			ref: this._setContainerRef,
			tabIndex: 0,
			onKeyDown: this._onContainerKeyDown,
			onFocus: this._onContainerFocus,
			onBlur: this._onContainerBlur,
		}

		const popoverStyle = {
			width: popoverWidth,
		}
		const input = renderInput(this._showPopover, this._hidePopover, isOn)
		const popover = renderPopover(this._setPopoverRef, this._hidePopover)
		const popoverClass = cn({
			[styles.popover]: !isOverlaidPopover,
			[styles.popoverOverlay]: isOverlaidPopover,
			[styles.activePopover]: isOn,
		})

		return (
			<div {...propsContainer}>
				{input}
				<TransitionGroup {...propsTransitionGroup}>
					{isOn && (
						<CSSTransition {...propsCSSTransition}>
							<div className={popoverClass} style={popoverStyle}>
								{popover}
							</div>
						</CSSTransition>
					)}
				</TransitionGroup>
			</div>
		)
	}

	render () {
		return (
			<Toggler>
				{this._renderDropdown}
			</Toggler>
		)
	}
}

Dropdown.propsTypes = {
	isShownOnFocused: PropTypes.bool.isRequired,
	isHiddenOnBlur: PropTypes.bool.isRequired,
	onFocus: PropTypes.func.isRequired,
	onBlur: PropTypes.func.isRequired,
	renderInput: PropTypes.func.isRequired,
	renderPopover: PropTypes.func.isRequired,
	popoverWidth: PropTypes.string.isRequired,
	isOverlaidPopover: PropTypes.bool.isRequired,
}

export default Dropdown
