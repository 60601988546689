import { makeSwitcher } from '../utils.js'


export const LEGAL_ENTITIES = {
	DEPERSONALIZED: 'DEPERSONALIZED',
	TRAVELAB_AGENT: 'TRAVELAB_AGENT',
	CLICKAVIA_TRAVEL: 'CLICKAVIA_TRAVEL',
	FLY_CHARTERZ: 'FLY_CHARTERZ',
	TRAVEL_GO: 'TRAVEL_GO',
}

const defaultLegalEntity = LEGAL_ENTITIES.CLICKAVIA_TRAVEL

export default makeSwitcher({
	options: [
		{
			label: `Current legal entity = ${defaultLegalEntity}`,
			pointer: 'default',
		},
		{
			label: LEGAL_ENTITIES.TRAVELAB_AGENT,
			pointer: 'travelabAgent',
		},
		{
			label: LEGAL_ENTITIES.CLICKAVIA_TRAVEL,
			pointer: 'clickaviaTravel',
		},
		{
			label: LEGAL_ENTITIES.FLY_CHARTERZ,
			pointer: 'flyCharterz',
		},
		{
			label: LEGAL_ENTITIES.TRAVEL_GO,
			pointer: 'travelGo',
		},
		{
			label: LEGAL_ENTITIES.DEPERSONALIZED,
			pointer: 'depersonalized',
		},
	],
	values: {
		default: defaultLegalEntity,
		travelabAgent: LEGAL_ENTITIES.TRAVELAB_AGENT,
		clickaviaTravel: LEGAL_ENTITIES.CLICKAVIA_TRAVEL,
		flyCharterz: LEGAL_ENTITIES.FLY_CHARTERZ,
		travelGo: LEGAL_ENTITIES.TRAVEL_GO,
		depersonalized: LEGAL_ENTITIES.DEPERSONALIZED,
	},
})

