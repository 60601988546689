import { isIsoDateValid, getLastDay } from '@utils/time-utils'
import luhnChk from '@utils/common/luhnAlgo.js'
import { cvvRegExp, latinCharAndSpaceRegExp } from './regExps.js'


export const isPanValid = (pan) => luhnChk(pan)

export const isExpDateValid = (value) => {
	let [ month, year = '' ] = value.split('/')
	if (year.length < 2 || year.length === 3 || year.length > 4) return false
	if (year.length === 2) year = `20${year}`

	const day = getLastDay(parseInt(year, 10), parseInt(month, 10))
	const isoStr = [ year, month, `${day}` ].join('-')

	if (!isIsoDateValid(isoStr)) return false

	return true
}

export const isCvvValid = (value) => (cvvRegExp.test(value))

export const isCardholderValid = (value) => (
	Boolean(value.length && latinCharAndSpaceRegExp.test(value))
)

export const isCardIdValid = (cardId, cardsList) => (
	Boolean(cardsList) && cardsList.some(({ card_id: id }) => (id === cardId))
)
