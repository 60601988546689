import Isvg from 'react-inlinesvg'
import l from '@libs/lang'
import { toExternalLogin } from '@utils/router'
import $ from './styles/index.js'

import icoLogin from './images/icon-login.svg'


const LoginBtn = (props) => {

	const { withText } = props

	const txtLogin = l('Войти')

	const loginText = <span className={$.loginText}>{txtLogin}</span>

	return (
		<a className={$.container} href={toExternalLogin()} id="login-btn">
			<Isvg src={icoLogin}/>
			{withText ? loginText : null}
		</a>
	)
}

export default LoginBtn
