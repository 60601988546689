import { withRouter } from 'react-router-dom'
import TABS, { DIALOGS } from '@utils/serp-tabs'

import Btn from './views/btn.js'
import Menu from './views/menu.js'

import styles from './styles'
import connector from './connector.js'


class HamburgerMenu extends React.Component {
	state = { isOpened: false }
	containerNode = null

	_closeMenu = () => this.setState({ isOpened: false })
	_toggleMenu = () => this.setState((state) => ({
		...state,
		isOpened: !state.isOpened,
	}))

	componentWillMount () {
		document.addEventListener('click', this._handleDocumentClick)
	}

	componentWillUnmount () {
		document.removeEventListener('click', this._handleDocumentClick)
	}

	_setContainerNodeRef = (n) => this.containerNode = n
	_handleDocumentClick = (e) => {
		if (this.state.isOpened && this.containerNode && !this.containerNode.contains(e.target)) {
			this._closeMenu()
		}
	}
	_withCloseMenu = (cb) => () => {
		this._closeMenu()
		cb()
	}

	render () {
		const {
			currentOfferId,
			isTickets,
			isHotels,
			isCheckout,
			params,
			ticketsCount,
			hotelsCount,
			ticketsPrice,
			hotelsPrice,
			isLoading,
			isAccomodationAwaited,
			openDialog,
			pushToSerp,
			pushToAbout,
			pushToIndex,
			pushToCheckout,
			pushToContacts,
		} = this.props

		const propsBtn = {
			isOpened: this.state.isOpened,
			onClick: this._toggleMenu,
		}
		const btn = (<Btn {...propsBtn}/>)

		let dialog = null
		if (!ticketsPrice && !hotelsPrice) dialog = DIALOGS.EMPTY_CART
		else if (!ticketsPrice) dialog = DIALOGS.CART_WITHOUT_TICKET

		const propsMenu = {
			isLoading,
			ticketsCount,
			isTickets,
			hotelsCount,
			isHotels,
			ticketsPrice,
			hotelsPrice,
			isCheckout,
			shouldRenderHotelTab: isAccomodationAwaited,
			onTicketsClick: this._withCloseMenu(() => pushToSerp({ ...params, tab: TABS.TICKETS })),
			onHotelsClick: this._withCloseMenu(() => pushToSerp({ ...params, tab: TABS.HOTELS })),
			onCartClick: this._withCloseMenu(() => (dialog ? openDialog(dialog) : pushToCheckout(currentOfferId))),
			onNewSearchClick: this._withCloseMenu(pushToIndex),
			onContactsClick: this._withCloseMenu(pushToContacts),
			onAboutClick: this._withCloseMenu(pushToAbout),
		}
		const menu = (
			<div className={styles.menuContainer}>
				<Menu {...propsMenu}/>
			</div>
		)

		return (
			<div className={styles.container} ref={this._setContainerNodeRef}>
				{btn}
				{this.state.isOpened && menu}
			</div>
		)
	}
}

export default withRouter(connector(HamburgerMenu))
