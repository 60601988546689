import {
	SET_CURRENT_QUERY,
	SET_SHOPFRONT_DIALOG,
	SET_CURRENT_OFFER_ID,
	ADD_LOADING_EVENT,
	REMOVE_LOADING_EVENT,
	ADD_QR_LOADING_EVENT,
	REMOVE_QR_LOADING_EVENT,
	REMOVE_ALL_LOADING_EVENTS,
	FOCUS,
	SET_USER_DATA,
	LOGOUT,
	SET_ACCOMMODATION_DATES,
	INIT_QUIZES,
	ACCEPT_QUIZ_ANSWER,
	SET_PREVIOUS_PRICE,
	SET_CHANNEL,
	SET_IS_CC_FORM_MANUAL,
	SET_PAYMENT_METHOD,
	SELECT_TRANSFER,
	CLEAR_STORE,
	RECONFIGURE_OFFER,
	SET_IS_SEARCHBAR_HIGHLIGHTED,
	SET_UPSALE_DIALOG,
	ACTIVATE_SBP_PAYMENT,
	DEACTIVATE_SBP_PAYMENT,
	ACTIVATE_TINKOFF_PAYMENT,
	DEACTIVATE_TINKOFF_PAYMENT,
} from '@store/state/types'

import requestStatusesReducer from './requestStatuses'
import createRouterReducer from './router'
import serpFiltersReducer from './serpFilters'
import geoReducer from './geo'
import pricesListReducer from './pricesList.js'


const initialState = {
	currentQuery: null,
	currentOfferId: null,
	loadingEvents: [],
	activeElement: null,
	userData: null,
	shopfrontDialog: null,
	accommodationCheckin: null,
	accommodationCheckout: null,
	quizes: {},
	previousProductPrice: null,
	channel: null,
	fakeSelectedTransfer: null,
	upsaleDialog: null,
	isCCFormManualMode: true,
	smsInfoAddedSource: null,
	isSearchbarHighlighted: false,
	requestStatuses: undefined,
	router: undefined,
	serpFilters: undefined,
	geo: undefined,
	pricesList: undefined,
}

const createReducer = (history) => {
	const routerReducer = createRouterReducer(history)
	return (state = initialState, action) => {
		let nextState = state
		switch (action.type) {
			case SET_CURRENT_QUERY: {
				nextState = { ...state, currentQuery: action.payload }
				break
			}
			case SET_IS_SEARCHBAR_HIGHLIGHTED: {
				nextState = { ...state, isSearchbarHighlighted: action.payload }
				break
			}
			case SET_SHOPFRONT_DIALOG: {
				nextState = { ...state, shopfrontDialog: action.payload }
				break
			}
			case SET_CURRENT_OFFER_ID: {
				nextState = { ...state, currentOfferId: action.payload }
				break
			}
			case ADD_LOADING_EVENT: {
				nextState = {
					...state,
					loadingEvents: [ ...state.loadingEvents, action.payload ],
				}
				break
			}
			case ADD_QR_LOADING_EVENT: {
				nextState = {
					...state,
					loadingEvents: [ ...state.loadingEvents, action.payload ],
				}
				break
			}
			case REMOVE_LOADING_EVENT: {
				nextState = {
					...state,
					loadingEvents: state.loadingEvents.filter((evt) => (evt !== action.payload)),
				}
				break
			}
			case REMOVE_QR_LOADING_EVENT: {
				nextState = {
					...state,
					loadingEvents: state.loadingEvents.filter((evt) => (evt !== action.payload)),
				}
				break
			}
			case REMOVE_ALL_LOADING_EVENTS: {
				nextState = {
					...state,
					loadingEvents: [],
				}
				break
			}
			case FOCUS: {
				nextState = { ...state, activeElement: action.payload }
				break
			}
			case SET_USER_DATA: {
				nextState = { ...state, userData: action.payload }
				break
			}
			case LOGOUT: {
				nextState = { ...state, userData: initialState.userData }
				break
			}
			case SET_ACCOMMODATION_DATES: {
				const { checkin, checkout } = action.payload
				nextState = {
					...state,
					accommodationCheckin: checkin,
					accommodationCheckout: checkout,
				}
				break
			}
			case INIT_QUIZES: {
				nextState = { ...state, quizes: action.payload }
				break
			}
			case ACCEPT_QUIZ_ANSWER: {
				const { quiz, answer } = action.payload
				nextState = {
					...state,
					quizes: { ...state.quizes, [quiz]: answer },
				}
				break
			}
			case SET_PREVIOUS_PRICE: {
				nextState = { ...state, previousProductPrice: action.payload }
				break
			}
			case SET_CHANNEL: {
				nextState = { ...state, channel: action.payload }
				break
			}
			case SET_IS_CC_FORM_MANUAL: {
				nextState = { ...state, isCCFormManualMode: action.payload }
				break
			}
			case SET_PAYMENT_METHOD: {
				nextState = {
					...state,
					paymentMethod: action.payload,
					tinkoffPayment: (action.payload !== 'tinkoff' ? null : state.tinkoffPayment),
					sbpPayment: (action.payload !== 'sbp' ? null : state.sbpPayment) }
				break
			}
			case ACTIVATE_SBP_PAYMENT: {
				nextState = { ...state, sbpPayment: action.payload }
				break
			}
			case DEACTIVATE_SBP_PAYMENT: {
				nextState = { ...state, sbpPayment: null }
				break
			}
			case ACTIVATE_TINKOFF_PAYMENT: {
				nextState = { ...state, tinkoffPayment: action.payload }
				break
			}
			case DEACTIVATE_TINKOFF_PAYMENT: {
				nextState = { ...state, tinkoffPayment: null }
				break
			}
			case RECONFIGURE_OFFER: {
				nextState = {
					...state,
					smsInfoAddedSource: action.payload.source,
				}
				break
			}
			// TODO: fake transfer, should be removed after test
			case SELECT_TRANSFER: {
				nextState = {
					...state,
					fakeSelectedTransfer: action.payload,
				}
				break
			}
			case SET_UPSALE_DIALOG: {
				nextState = {
					...state,
					upsaleDialog: action.payload,
				}
				break
			}
			case CLEAR_STORE: {
				nextState = {
					...state,
					fakeSelectedTransfer: null,
					currentOfferId: null,
				}
				break
			}
			default: {
				return {
					...state,
					requestStatuses: requestStatusesReducer(state.requestStatuses, action),
					router: routerReducer(state.router, action),
					serpFilters: serpFiltersReducer(state.serpFilters, action),
					geo: geoReducer(state.geo, action),
					pricesList: pricesListReducer(state.pricesList, action),
				}
			}
		}

		return {
			...nextState,
			requestStatuses: requestStatusesReducer(nextState.requestStatuses, action),
			router: routerReducer(nextState.router, action),
			serpFilters: serpFiltersReducer(nextState.serpFilters, action),
			geo: geoReducer(nextState.geo, action),
			pricesList: pricesListReducer(state.pricesList, action),
		}
	}
}

export default createReducer
