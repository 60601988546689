import {
	SEND_FEEDBACK,
	SEND_SUPPORT_FEEDBACK,
	SET_SEND_FEEDBACK_STATUS,
} from '@store/state/types'


export const sendFeedback = () => ({
	type: SEND_FEEDBACK,
})

export const sendSupportFeedback = (feedbackData) => ({
	type: SEND_SUPPORT_FEEDBACK,
	payload: feedbackData,
})

export const setSendFeedbackStatus = (status) => ({
	type: SET_SEND_FEEDBACK_STATUS,
	payload: status,
})


