import { flow } from 'lodash'
import { getDate, isIsoDateValid } from '@utils/time-utils'
import { getCitizenshipCode } from '@utils/citizenship'
import {
	travelRegExp,
	nationalRegExp,
	birthCertificateRegExp,
	trashRegExp,
	lettersOnlyRegExp,
	latinCharRegExp,
	cyrillicCharRegExp,
	dateFormatRegExp,
} from './regExps.js'


const MIN_AGE_FOR_PASSPORT = 14
const MAX_AGE_FOR_BIRTH_CERT = 16
export const getCanPaxUseNationalPassport = (age) => age >= MIN_AGE_FOR_PASSPORT
export const getShouldPaxUseBirthCert = (age) => age <= MAX_AGE_FOR_BIRTH_CERT
export const checkForRussianDocument = (value) => [
	travelRegExp.full.test(value),
	nationalRegExp.full.test(value),
	birthCertificateRegExp.full.test(value),
].some(Boolean)

export const isNameValid = (value, isInternationalFlight) => (
	Boolean(value.length) && lettersOnlyRegExp.test(value)
	&& !(latinCharRegExp.test(value) && cyrillicCharRegExp.test(value))
	&& !(isInternationalFlight && !latinCharRegExp.test(value))
)

export const isMiddleNameValid = (value, isRequired, docType) => (
	!isRequired || [
		'national-passport',
		'birth-certificate',
	].indexOf(docType) < 0 || Boolean((value || '').trim())
)

export const isGenderValid = Boolean

const dateValueToIso = (value) => (value.split('.').reverse().join('-'))
export const isDateValid = (value) => (
	Boolean(value.length) && dateFormatRegExp.test(value)
	&& isIsoDateValid(dateValueToIso(value))
)
export const isBirthdateValid = (value) => {
	if (!isDateValid(value)) return false
	const date = getDate(dateValueToIso(value))
	const minDate = new Date(1900, 0, 1)
	const maxDate = new Date()
	return date > minDate && date < maxDate
}

export const isCitizenshipValid = flow(
	getCitizenshipCode,
	Boolean
)

export const isDocumentExpDateValid = (value, flightBaseDate) => {
	if (!isDateValid(value)) return false
	const date = getDate(dateValueToIso(value))
	const minDate = getDate(flightBaseDate)
	return date > minDate
}

export const isDocumentNumValid = (
	dirtyValue,
	citizenship,
	isInternationalFlight,
	passengerAge,
	isProhibitBirthCertCountry
) => {
	const value = dirtyValue.replace(trashRegExp, '')
	const citizenshipCode = getCitizenshipCode(citizenship)
	if (!value.length) return false
	const isRussianDocument = checkForRussianDocument(value)
	const isNonRusDoc = (citizenshipCode !== 'RUS' || (!isRussianDocument && !citizenshipCode))
	if (isNonRusDoc)
	  return !cyrillicCharRegExp.test(value)
	else if (isInternationalFlight) {
		let valueDigits = value.match(/\d/g)
		valueDigits = valueDigits && valueDigits.join('')
		const isValidRusTravelDoc = citizenshipCode === 'RUS' && isInternationalFlight
			&& travelRegExp.full.test(valueDigits)
		return isValidRusTravelDoc
	}

	const canPaxUseNationalPassport = getCanPaxUseNationalPassport(passengerAge)
	const shouldPaxUseBirthCert = getShouldPaxUseBirthCert(passengerAge) && !isProhibitBirthCertCountry
	const wasAgeSet = Number.isInteger(passengerAge)

	return [
		travelRegExp.full.test(value),
		(canPaxUseNationalPassport || !wasAgeSet) && nationalRegExp.full.test(value),
		(shouldPaxUseBirthCert || !wasAgeSet) && birthCertificateRegExp.full.test(value),
	].some(Boolean)
}
