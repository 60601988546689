import { flow } from 'lodash'
import { CUSTOMER_FORM } from '../types.js'
import { makeValueSelector, makeEmptySelector, makeInvalidSelector } from './core.js'
import {
	validateEmail,
	validatePhone,
} from '../validators'


// fields values
export const emailSelector = makeValueSelector({
	formName: CUSTOMER_FORM.NAME,
	fieldName: CUSTOMER_FORM.FIELDS.EMAIL,
})
export const phoneSelector = makeValueSelector({
	formName: CUSTOMER_FORM.NAME,
	fieldName: CUSTOMER_FORM.FIELDS.PHONE,
})

// validators
export const isEmailValidSelector = flow(
	emailSelector,
	validateEmail
)
export const isPhoneValidSelector = flow(
	phoneSelector,
	validatePhone
)
export const isFormValidSelector = (state) => (
	isEmailValidSelector(state) && isPhoneValidSelector(state)
)

// errors fields
export const errorFieldsSelectors = [ {
	formName: CUSTOMER_FORM.NAME,
	fieldName: CUSTOMER_FORM.FIELDS.EMAIL,
	emptySelector: makeEmptySelector(emailSelector),
	invalidSelector: makeInvalidSelector(
		emailSelector,
		isEmailValidSelector
	),
}, {
	formName: CUSTOMER_FORM.NAME,
	fieldName: CUSTOMER_FORM.FIELDS.PHONE,
	emptySelector: makeEmptySelector(phoneSelector),
	invalidSelector: makeInvalidSelector(
		phoneSelector,
		isPhoneValidSelector
	),
} ]
