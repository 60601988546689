import { RESET_FORM } from '../types'
import { makeValueSelector } from './core.js'


//values
export const passwordSelector = makeValueSelector({
	formName: RESET_FORM.NAME,
	fieldName: RESET_FORM.FIELDS.PASSWORD,
})
export const passwordConfirmSelector = makeValueSelector({
	formName: RESET_FORM.NAME,
	fieldName: RESET_FORM.FIELDS.PASSWORD_CONFIRM,
})

// validators
export const isPasswordValidSelector = (state) => Boolean(passwordSelector(state))
export const isPasswordConfirmValidSelector = (state) => Boolean(passwordConfirmSelector(state))
export const isPassEqualSelector = (state) => (passwordSelector(state) === passwordConfirmSelector(state))
export const isFormValidSelector = (state) => (
	 isPassEqualSelector(state) && isPasswordValidSelector(state)
)
