import { isEqual } from 'lodash'

import { passengerSelectors } from '@store/state/uiState/forms/selectors'
import { flightConsistFromOfferSelector } from '@store/state/domainData/selectors'
import { byCurrentOfferId } from '@store/state/appState/selectors'


export const isConsistMismatchResult = (flightConsist, formConsist) => (
	!isEqual(flightConsist, formConsist)
)

const isConsistMismatch = (state) => {
	const flightConsist = byCurrentOfferId(flightConsistFromOfferSelector)(state)
	const formConsist = passengerSelectors.paxConsistSelector(state)

	return isConsistMismatchResult(flightConsist, formConsist)
}

export default isConsistMismatch
