import { call, put } from 'redux-saga/effects'

import accountDataFetcher from '@libs/account-data-fetcher'
import { setUserData } from '@store/state/appState/actions'


export default function* checkUserAuth () {
	try {
		const { body: userData } = yield call(accountDataFetcher.checkCurrentUser)
		yield put(setUserData(userData))
	}
	catch (e) {}
}
