import feofan from '@libs/feofan'
import { channel, END } from 'redux-saga'
import { put, call, take, fork, join } from 'redux-saga/effects'

import {
	addLoadingEvent,
	removeLoadingEvent,
} from '@store/state/appState/actions'

import responseHandler from './responseHandler.js'
import { getHandlerByType, getGettingHandlerByType } from './handlers'


export function* responseWatcher (evtChannel, loadOptions) {
	while (true) {
		const payload = yield take(evtChannel)
		yield fork(responseHandler, loadOptions, payload)
	}
}

export function* getData (options) {
	const { type, meta } = options
	const handler = yield call(getGettingHandlerByType, type)
	const data = yield call(handler, meta)
	return data
}

export function* sendEvent (options) {
	yield call(feofan.sendEvent, options)
}

export default function* loadData (loadOptions, skipCondForRemovingLoader = []) {
	let removeLoader = true
	try {
		yield put(addLoadingEvent(loadOptions))

		const evtChannel = yield call(channel)
		const watchResponseTask = yield fork(responseWatcher, evtChannel, loadOptions)

		const { type, meta } = loadOptions
		const handler = yield call(getHandlerByType, type)
		const opStatus = yield call(handler, evtChannel, meta)
		removeLoader = skipCondForRemovingLoader.indexOf(opStatus) < 0

		// END action terminates all sagas blocked on take effect
		// If the terminated Saga has still some forked tasks which are still running,
		// it will wait for all the child tasks to terminate before terminating the Task.
		yield put(evtChannel, END)
		// wait for watchResponseTask to terminate
		yield join(watchResponseTask)
		// and ONLY then return opStatus
		return opStatus
	}
	finally {
		if (removeLoader) {
			yield put(removeLoadingEvent(loadOptions))
		}
	}
}

