import { mapValues } from 'lodash'
import { EXTENDED_SERVICES } from '@libs/foma/types'
import {
	offerByIdSelector,
	serviceByFareIdSelector,
} from '@store/state/domainData/selectors'


export const flightEnabledCountsResult = (fareCounts, serviceByFareId) => (
	mapValues(fareCounts, ([ min, enabledCount ], fareId) => {
		const { type } = serviceByFareId[fareId]
		return type === EXTENDED_SERVICES.TRANSFER ? enabledCount : min
	})
)

const flightEnabledCountsSelector = (state, searchQuery, offerId) => {
	const { fare_counts: fareCounts } = offerByIdSelector(state, searchQuery, offerId)
	const serviceByFareId = serviceByFareIdSelector(state, searchQuery)
	return flightEnabledCountsResult(fareCounts, serviceByFareId)
}

export default flightEnabledCountsSelector
