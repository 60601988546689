import { values } from 'lodash'
import { createSelector } from 'reselect'
import { getFares, offerByIdSelector } from './core.js'
import { getFaresFromOffer } from './itemsRelation.js'


const makeRequiredFieldGetter = (fieldName) => (faresList) => faresList.some(({ passenger_reqs: reqs = [] }) => (
	reqs.includes(fieldName)
))

const faresFromOfferSelector = createSelector(
	[ getFares, offerByIdSelector ],
	(fares, offer) => values(getFaresFromOffer(fares, offer))
)

export const getIsCardholderRequired = makeRequiredFieldGetter('cardholder')
export const isCardholderRequiredSelector = createSelector(
	faresFromOfferSelector,
	getIsCardholderRequired
)

export const getIsMiddleNameRequired = makeRequiredFieldGetter('middle_name')
export const isMiddleNameRequiredSelector = createSelector(
	faresFromOfferSelector,
	getIsMiddleNameRequired
)
