import { EXTENDED_SERVICES_SUBTYPE } from '@libs/foma/types'


const { LOCAL_TRANSFER } = EXTENDED_SERVICES_SUBTYPE
export { LOCAL_TRANSFER }
export const getDirectionList = (minPrice, isRT) => ([ {
	key: LOCAL_TRANSFER.THERE,
	title: 'Из аэропорта',
	price: minPrice,
}, isRT && {
	key: LOCAL_TRANSFER.ROUND_TRIP,
	price: minPrice * 2,
	title: 'Туда и обратно',
}, isRT && {
	key: LOCAL_TRANSFER.BACK,
	price: minPrice,
	title: 'В аэропорт',
} ].filter(Boolean))

export const vehicleListByMetro = {
	VAR: [
		{
			id: '16200',
			name: 'Dacia Lodgy Minivan 1-4 пассажира',
			paxCount: 4,
			baggageCount: 4,
			image: 'https://www.intui.travel/content/private_cars_photo/16200_2.jpg',
			price: 845,
			isWholeVehicle: true,
		},
		{
			id: '658673',
			name: 'Индивидуальный трансфер 1-3 пассажира',
			paxCount: 3,
			baggageCount: 3,
			image: 'https://www.intui.travel/public/content/images/transfers_content/2.jpg?1523618733.447',
			price: 923,
			isWholeVehicle: true,
		},
		{
			id: '15946',
			name: 'Mercedes Vito Minibus 1-8 пассажиров',
			paxCount: 8,
			baggageCount: 8,
			image: 'https://www.intui.travel/content/private_cars_photo/15946_2.jpg',
			price: 1060,
			isWholeVehicle: true,
		},
	],
	OVB: [
		{
			id: '1229',
			name: 'Hyundai Solaris, Toyota Camry V40, Audi A3 Sedan 1-4 пассажира',
			paxCount: 4,
			baggageCount: 4,
			image: 'https://www.intui.travel/content/private_cars_photo/1229_2.jpeg',
			price: 1127,
			isWholeVehicle: true,
		},
		{
			id: '1243',
			name: 'Toyota Camry Sedan 1-4 пассажира',
			paxCount: 4,
			baggageCount: 4,
			image: 'https://www.intui.travel/content/private_cars_photo/1243_2.jpg',
			price: 1353,
			isWholeVehicle: true,
		},
		{
			id: '1246',
			name: 'Hyundai Grant Starex Minibus 1-10 пассажиров',
			paxCount: 10,
			baggageCount: 10,
			image: 'https://www.intui.travel/content/private_cars_photo/1246_2.jpg',
			price: 2818,
			isWholeVehicle: true,
		},
	],
	LED: [
		{
			id: '19149',
			name: 'Volkswagen Jetta Sedan 1-4 пассажира',
			paxCount: 4,
			baggageCount: 3,
			image: 'https://www.intui.travel/content/private_cars_photo/19149_2.png',
			price: 1211,
			isWholeVehicle: true,
		},
		{
			id: '19213',
			name: 'Skoda Octavia Sedan 1-4 пассажира',
			paxCount: 4,
			baggageCount: 4,
			image: 'https://www.intui.travel/content/private_cars_photo/19213_2.png',
			price: 1353,
			isWholeVehicle: true,
		},
		{
			id: '18815',
			name: 'Volkswagen Caravelle Minivan 1-8 пассажиров',
			paxCount: 8,
			baggageCount: 8,
			image: 'https://www.intui.travel/content/private_cars_photo/18815_2.jpg',
			price: 1634,
			isWholeVehicle: true,
		},
	],
	TIV: [
		{
			id: '18655',
			name: 'Volkswagen Pasat Sedan 1-3 пассажира',
			paxCount: 3,
			baggageCount: 3,
			image: 'https://www.intui.travel/content/private_cars_photo/18655_2.jpg',
			price: 1245,
			isWholeVehicle: true,
		},
		{
			id: '18627',
			name: 'Volkswagen Touran Minivan 1-4 пассажира',
			paxCount: 4,
			baggageCount: 4,
			image: 'https://www.intui.travel/content/private_cars_photo/18627_2.jpg',
			price: 1245,
			isWholeVehicle: true,
		},
		{
			id: '15958',
			name: 'Mercedes Viano Minivan 1-7 пассажиров',
			paxCount: 7,
			baggageCount: 7,
			image: 'https://www.intui.travel/content/private_cars_photo/15958_2.jpg',
			price: 2650,
			isWholeVehicle: true,
		},
	],
	BKK: [
		{
			id: '26',
			name: 'Трансфер автобусом-шаттлом "Классик"',
			paxCount: 1,
			baggageCount: 1,
			image: 'https://www.intui.travel/public/content/images/transfers_content/26.jpg?1523617088.1723',
			price: 673,
			isWholeVehicle: false,
		},
		{
			id: '1928',
			name: 'Toyota Innova Hatchback 1-4 пассажира',
			paxCount: 4,
			baggageCount: 4,
			image: 'https://www.intui.travel/content/private_cars_photo/1928_2.jpg',
			price: 2006,
			isWholeVehicle: true,
		},
		{
			id: '17451',
			name: 'Toyota D4D Van Minivan 1-10 пассажиров',
			paxCount: 10,
			baggageCount: 10,
			image: 'https://www.intui.travel/content/private_cars_photo/17451_2.jpg',
			price: 2532,
			isWholeVehicle: true,
		},
	],
	BCN: [
		{
			id: '976',
			name: 'Шаттл IVECO Bus 1-55 пассажиров',
			paxCount: 1,
			baggageCount: 1,
			image: 'https://www.intui.travel/content/private_cars_photo/976_2.jpg',
			price: 992,
			isWholeVehicle: false,
		},
		{
			id: '16890',
			name: 'Opel Insignia Sedan 1-4 пассажира',
			paxCount: 4,
			baggageCount: 4,
			image: 'https://www.intui.travel/content/private_cars_photo/16890_2.jpg',
			price: 3380,
			isWholeVehicle: true,
		},
		{
			id: '1431',
			name: 'Mercedes Vito / Viano or similar Minivan 1-7 пассажиров',
			paxCount: 7,
			baggageCount: 7,
			image: 'https://www.intui.travel/content/private_cars_photo/1431_2.jpg',
			price: 8652,
			isWholeVehicle: true,
		},
	],
	LCA: [
		{
			id: '970',
			name: 'Mercedes E class Sedan 1-4 пассажира',
			paxCount: 4,
			baggageCount: 4,
			image: 'https://www.intui.travel/content/private_cars_photo/970_2.jpg',
			price: 3886,
			isWholeVehicle: true,
		},
		{
			id: '684894',
			name: 'Индивидуальный трансфер 1-4 пассажира',
			paxCount: 4,
			baggageCount: 4,
			image: 'https://www.intui.travel/public/content/images/transfers_content/2.jpg?1523616662.5594',
			price: 4088,
			isWholeVehicle: true,
		},
		{
			id: '16467',
			name: 'Mercedes Vito Minivan 1-6 пассажиров',
			paxCount: 6,
			baggageCount: 6,
			image: 'https://www.intui.travel/content/private_cars_photo/16467_2.jpg',
			price: 5407,
			isWholeVehicle: true,
		},
	],
	BOJ: [
		{
			id: '16006',
			name: 'Dacia Logan or similar Sedan 1-4 пассажира',
			paxCount: 4,
			baggageCount: 4,
			image: 'https://www.intui.travel/content/private_cars_photo/16006_2.jpg',
			price: 928,
			isWholeVehicle: true,
		},
		{
			id: '990',
			name: 'Dacia Logan MCV Station wagon 1-4 пассажира',
			paxCount: 4,
			baggageCount: 4,
			image: 'https://www.intui.travel/content/private_cars_photo/990_2.jpg',
			price: 962,
			isWholeVehicle: true,
		},
		{
			id: '15960',
			name: 'Opel Zafira Minivan 1-4 пассажира',
			paxCount: 4,
			baggageCount: 4,
			image: 'https://www.intui.travel/content/private_cars_photo/15960_2.jpg',
			price: 972,
			isWholeVehicle: true,
		},
	],
	AER: [
		{
			id: '17633',
			name: 'Volkswagen Polo Sedan 1-4 пассажира',
			paxCount: 4,
			baggageCount: 4,
			image: 'https://www.intui.travel/content/private_cars_photo/17633_2.jpg',
			price: 1014,
			isWholeVehicle: true,
		},
		{
			id: '19179',
			name: 'Volsvagen Caravella Minibus 1-8 пассажиров',
			paxCount: 8,
			baggageCount: 7,
			image: 'https://www.intui.travel/content/private_cars_photo/19179_2.jpg',
			price: 1353,
			isWholeVehicle: true,
		},
		{
			id: '568',
			name: 'Skoda Octavia or Similar Sedan 1-4 пассажира',
			paxCount: 4,
			baggageCount: 4,
			image: 'https://www.intui.travel/content/private_cars_photo/568_2.jpg',
			price: 1578,
			isWholeVehicle: true,
		},
	],
	AYT: [
		{
			id: '553',
			name: 'Шаттл Isuzu Novolux Standard Bus 1-25 пассажиров',
			paxCount: 1,
			baggageCount: 1,
			image: 'https://www.intui.travel/content/private_cars_photo/553_2.jpg',
			price: 510,
			isWholeVehicle: false,
		},
		{
			id: '811',
			name: 'Шаттл Isuzu Speedy Minibus 1-25 пассажиров',
			paxCount: 1,
			baggageCount: 1,
			image: 'https://www.intui.travel/content/private_cars_photo/811_2.jpg',
			price: 848,
			isWholeVehicle: false,
		},
		{
			id: '15875',
			name: 'Ford Focus or Similar Sedan 1-3 пассажира',
			paxCount: 3,
			baggageCount: 3,
			image: 'https://www.intui.travel/content/private_cars_photo/15875_2.jpg',
			price: 1441,
			isWholeVehicle: true,
		},
	],
	EVN: [
		{
			id: '17815',
			name: 'Toyota Camry Sedan 1-3 пассажира',
			paxCount: 3,
			baggageCount: 3,
			image: 'https://www.intui.travel/content/private_cars_photo/17815_2.jpg',
			price: 1210,
			isWholeVehicle: true,
		},
		{
			id: '1424',
			name: 'Volkswagen Polo Sedan 1-3 пассажира',
			paxCount: 3,
			baggageCount: 3,
			image: 'https://www.intui.travel/content/private_cars_photo/1424_2.png',
			price: 1334,
			isWholeVehicle: true,
		},
		{
			id: '17853',
			name: 'Mercedes Viano Minivan 1-6 пассажиров',
			paxCount: 6,
			baggageCount: 6,
			image: 'https://www.intui.travel/content/private_cars_photo/17853_2.jpg',
			price: 2018,
			isWholeVehicle: true,
		},
	],
	PRG: [
		{
			id: '1522',
			name: 'Skoda Superb Sedan 1-4 пассажира',
			paxCount: 4,
			baggageCount: 4,
			image: 'https://www.intui.travel/content/private_cars_photo/1522_2.jpg',
			price: 1413,
			isWholeVehicle: true,
		},
		{
			id: 'prive-transfer-minibus',
			name: 'Private Minivan Transfer 1-3 пассажира',
			paxCount: 3,
			baggageCount: 3,
			image: 'https://www.intui.travel/public/content/images/transfers_content/47.jpg?1523615954.5033',
			price: 1461,
			isWholeVehicle: true,
		},
		{
			id: '15905',
			name: 'Volkswagen Passat Sedan 1-4 пассажира',
			paxCount: 4,
			baggageCount: 3,
			image: 'https://www.intui.travel/content/private_cars_photo/15905_2.jpg',
			price: 1644,
			isWholeVehicle: true,
		},
	],
	GOI: [
		{
			id: 'individual',
			name: 'Индивидуальный трансфер 1-3 пассажира',
			paxCount: 3,
			baggageCount: 3,
			image: 'https://www.intui.travel/public/content/images/transfers_content/2.jpg?1523616466.254',
			price: 3432,
			isWholeVehicle: true,
		},
		{
			id: 'luxury',
			name: 'Luxury A/C Car 1-2 пассажира',
			paxCount: 2,
			baggageCount: 2,
			image: 'https://www.intui.travel/public/content/images/transfers_content/40.jpg?1523616466.2542',
			price: 10211,
			isWholeVehicle: true,
		},
	],
	HKT: [
		{
			id: 'shuttle-bus',
			name: 'Трансфер автобусом-шаттлом "Классик"',
			paxCount: 1,
			baggageCount: 1,
			image: 'https://www.intui.travel/public/content/images/transfers_content/26.jpg?1523616780.8822',
			price: 630,
			isWholeVehicle: false,
		},
		{
			id: 'individual',
			name: 'Индивидуальный трансфер 1-2 пассажира',
			paxCount: 2,
			baggageCount: 2,
			image: 'https://www.intui.travel/public/content/images/transfers_content/2.jpg?1523616780.8823',
			price: 1692,
			isWholeVehicle: true,
		},
		{
			id: '1756',
			name: 'Toyota Altis Sedan 1-3 пассажира',
			paxCount: 3,
			baggageCount: 2,
			image: 'https://www.intui.travel/content/private_cars_photo/1756_2.jpg',
			price: 1934,
			isWholeVehicle: true,
		},
	],
	MOW: [
		{
			id: '1122',
			name: 'Chevrolet Cruze Hatchback 1-4 пассажира',
			paxCount: 4,
			baggageCount: 4,
			image: 'https://www.intui.travel/content/private_cars_photo/1122_2.jpg',
			price: 1917,
			isWholeVehicle: true,
		},
		{
			id: '16335',
			name: 'Hyundai Solaris Sedan 1-3 пассажира',
			paxCount: 3,
			baggageCount: 3,
			image: 'https://www.intui.travel/content/private_cars_photo/16335_2.jpg',
			price: 1940,
			isWholeVehicle: true,
		},
		{
			id: '18839',
			name: 'Volkswagen Polo Sedan 1-3 пассажира',
			paxCount: 3,
			baggageCount: 3,
			image: 'https://www.intui.travel/content/private_cars_photo/18839_2.jpg',
			price: 2010,
			isWholeVehicle: true,
		},
	],
	SKG: [
		{
			id: '17745',
			name: 'Skoda Octavia Sedan 1-4 пассажира',
			paxCount: 4,
			baggageCount: 4,
			image: 'https://www.intui.travel/content/private_cars_photo/17745_2.jpg',
			price: 1545,
			isWholeVehicle: true,
		},
	],
	DXB: [
		{
			id: 'one-transfer',
			name: 'Трансфер автобусом-шаттлом "Классик"',
			paxCount: 1,
			baggageCount: 1,
			image: 'https://www.intui.travel/public/content/images/transfers_content/26.jpg?1523617571.8921',
			price: 943,
			isWholeVehicle: false,
		},
		{
			id: '858',
			name: 'Private Car Sedan 1-3 пассажира',
			paxCount: 3,
			baggageCount: 3,
			image: 'https://www.intui.travel/content/private_cars_photo/858_2.jpg',
			price: 1827,
			isWholeVehicle: true,
		},
		{
			id: '911',
			name: 'Hyundai H1 or equivalent Minivan 1-5 пассажиров',
			paxCount: 5,
			baggageCount: 5,
			image: 'https://www.intui.travel/content/private_cars_photo/911_2.jpg',
			price: 1973,
			isWholeVehicle: true,
		},
	],
}

