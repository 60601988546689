import { values, flatten } from 'lodash'
import { createSelector } from 'reselect'
import {
	getFares,
	getServices,
	getActivities,
	getSegments,
	offerByIdSelector,
} from './core.js'
import {
	getFaresFromOffer,
	getServiceFromFare,
	getActivitiesFromService,
	getSegmentsFromActivity,
} from './itemsRelation.js'


const faresSelector = createSelector(
	[ getFares, offerByIdSelector ],
	(fares, offer) => values(getFaresFromOffer(fares, offer))
)
const servicesSelector = createSelector(
	[ getServices, faresSelector ],
	(services, fares) => fares.map((fare) => getServiceFromFare(services, fare))
)
const activitiesSelector = createSelector(
	[ getActivities, servicesSelector ],
	(activities, services) => flatten(
		services.map((service) => values(getActivitiesFromService(activities, service)))
	)
)
const segmentsSelector = createSelector(
	[ getSegments, activitiesSelector ],
	(segments, activities) => flatten(
		activities
			.map((activity) => values(getSegmentsFromActivity(segments, activity)))
			.filter(Boolean)
	)
)

const getKey = (item, k) => `data.${item}.${k}`

export const dataKeysToStoreResult = (offer, fares, services, activities, segments) => {
	if (!offer) return []
	const {
		agreements: agreementIds,
		products,
		fares: fareIds,
		id: offerId,
	} = offer
	const offerKey = getKey('offers', offerId)
	const agreementKeys = agreementIds.map((id) => getKey('agreements', id))
	const fareKeys = fareIds.map((id) => getKey('fares', id))
	const productKeys = products.map(([ _, fareIds ]) => getKey('products', fareIds.join(':')))
	const serviceKeys = services.map(({ id }) => getKey('services', id))
	const activityKeys = activities.map(({ id }) => getKey('activities', id))
	const segmentsKeys = segments.map(({ id }) => getKey('segments', id))
	const hotelKeys = activities
		.reduce((acc, { hotel }) => (!hotel ? acc : [ ...acc, getKey('hotels', hotel) ]), [])
	const airlineKeys = segments.map(({ airline }) => getKey('airlines', airline))
	const airportsKeys = segments
		.reduce((acc, { depart, arrive }) => ([
			...acc,
			getKey('airports', depart.airport), getKey('airports', arrive.airport),
		]), [])
	const brandKeys = fares.map(({ supplier }) => getKey('brands', supplier))
	return [
		offerKey,
		...productKeys,
		...brandKeys,
		...fareKeys,
		...serviceKeys,
		...activityKeys,
		...segmentsKeys,
		...airlineKeys,
		...airportsKeys,
		...hotelKeys,
		...agreementKeys,
	]
}

const dataKeysToStoreSelector = createSelector(
	offerByIdSelector,
	faresSelector,
	servicesSelector,
	activitiesSelector,
	segmentsSelector,
	dataKeysToStoreResult
)

export default dataKeysToStoreSelector
