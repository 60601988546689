import ReactDOM from 'react-dom'
import { Component } from 'react'

import { createEnhancer } from '@utils/decoract'
import { makeCX } from '@utils/taffy'

import $ from './styles'


const cq = {
	md: {
		minWidth: 768,
		maxWidth: 1139,
	},
	lg: {
		minWidth: 1140,
	},
}

const enhancer = createEnhancer({ cq })
const cx = makeCX()

const instances = new Set()
const BODY_CLASS = 'modal-open'
let modalRoot = document.getElementById('overlay-portal')

let scrollPosition = 0

const _setBodyScroll = (isScrollable) => {
	if (isScrollable) {
		scrollPosition = window.scrollY
		document.body.classList.add(BODY_CLASS)
		document.body.style.top = `${-scrollPosition}px`
	}
	else {
		document.body.classList.remove(BODY_CLASS)
		window.scrollTo(0, scrollPosition)
		document.body.style.top = ''
	}
}


// custom node in body use for render fixed popup in IE,
// cause element with "position: fixed" wrong rendering
// rendered in parent with "position: relative" and "overflow:hidden"
class OverlayWrapper extends Component {

	constructor (props) {
		super(props)
		if (!modalRoot) {
			modalRoot = document.getElementById('overlay-portal')
		}
		this.overlay = document.createElement('div')
		instances.add(this)
	}

	componentDidMount () {
		modalRoot.appendChild(this.overlay)
		// disable scroll only once (preventing page jump
		// for multiple overlays
		if (instances.size === 1) _setBodyScroll(true)
	}

	componentWillUnmount () {
		instances.delete(this)
		if (!instances.size) _setBodyScroll(false)

		modalRoot.removeChild(this.overlay)
	}

	handleClickOutside = (e) => {
		if (!this.domNode || !this.domNode.contains(e.target)) {
			this.props.onClose()
		}
	}

	setRef = (c) => {
		this.domNode = ReactDOM.findDOMNode(c)
	}

	render () {
		const { cq, ...props } = this.props

		const { lg, md } = cq

		// $$ assuming that rest props are classnames, let's call them $$
		const { children, width, height, onClose, footer, ...$$ } = props

		const wrapperStyles = { width, height }

		const wrapperClass = cx({
			[$.wrapper]: lg,
			[$.mdWrapper]: md,
			[$.smWrapper]: !lg && !md,
			[$$.wrapperClassName]: Boolean($$.wrapperClassName),
		})
		const containerClass = cx({
			[$.container]: true,
			[$$.containerClassName]: Boolean($$.containerClassName),
		})
		const contentClass = cx({
			[$.childrenWrapper]: true,
			[$$.className]: Boolean($$.className),
		})
		const closeBtnClass = cx({
			[$.closeBtn]: true,
			[$$.closeBtnClassNameSm]: !lg && !md && Boolean($$.closeBtnClassNameSm),
			[$$.closeBtnClassNameMd]: md && Boolean($$.closeBtnClassNameMd),
			[$$.closeBtnClassNameLg]: lg && Boolean($$.closeBtnClassNameLg),
		})
		const footerClass = cx({
			[$.footer]: true,
			[$$.footerClassName]: Boolean($$.footerClassName),
		})

		const content = (
			<div className={containerClass} onClick={onClose && this.handleClickOutside}>
				<div className={wrapperClass} style={wrapperStyles} ref={this.setRef}>
					<div className={contentClass}>
						{children}
					</div>
					{onClose ? <div className={closeBtnClass} onClick={onClose}/> : null}
					{footer && <div className={footerClass}>
						{footer}
					</div>}
				</div>
			</div>
		)

		return ReactDOM.createPortal(content, this.overlay)
	}
}

export default enhancer(OverlayWrapper)
