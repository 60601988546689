import request from 'superagent'
import confy from '@utils/confy'
import getResponseHandler from './responseHandler.js'
import { OPERATION_STATUSES } from './statuses.js'


const ERROR_STATUSES = [
	OPERATION_STATUSES.UNAVAIL,
]
const OK_STATUSES = [
	OPERATION_STATUSES.SUCCESS,
]

const makePricesGetter = (url) => ({ src, dst, startDate, stayLength, count, channel }) => {
	const queryObject = {
		src,
		dst,
		'start_date': startDate,
		'stay_length': stayLength,
		count,
		channel,
	}

	const req = request.get(url).query(queryObject)

	const executor = (resolve, reject) => {
		const handler = getResponseHandler({
			resolve,
			reject,
			okStatuses: OK_STATUSES,
			errStatuses: ERROR_STATUSES,
			requestType: 'GET /calendar/by_day',
		})

		req.end(handler)
	}

	return {
		promise: new Promise(executor),
		cancelFn: () => req.abort(),
	}
}

export const getTopPrices = makePricesGetter(confy.get('API.cl.topPrices'))
export const getPricesByMonth = makePricesGetter(confy.get('API.cl.pricesByMonth'))
export const getPricesByDay = makePricesGetter(confy.get('API.cl.pricesByDay'))
