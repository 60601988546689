import { flow, mapValues } from 'lodash'
import { createSelector } from 'reselect'
import {
	getRouteInfo,
	getPackages,
	offerByIdSelector,
	getOffers,
} from './core.js'
import {
	getPackageFromOffer,
} from './itemsRelation.js'


const packageFromOfferSelector = createSelector(
	[ getPackages, offerByIdSelector ],
	(packages = {}, offer = {}) => getPackageFromOffer(packages, offer)
)
export const routeInfoFromOffer = flow(
	packageFromOfferSelector,
	(pkg) => (pkg ? pkg['route_info'] : null)
)

const defaultRouteInfo = []
export const routeInfoSelector = createSelector(
	[ getRouteInfo, routeInfoFromOffer ],
	(routeInfo, routeInfoFromOffer) => (
		routeInfo || routeInfoFromOffer || defaultRouteInfo
	)
)

export const routeInfoByOfferIdSelector = createSelector(
	[ getOffers, getPackages ],
	(offers, packages) => mapValues(offers, (offer) => {
		const pkg = getPackageFromOffer(packages, offer)
		return pkg ? pkg['route_info'] : null
	})
)
