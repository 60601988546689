// TODO: uncomment when you want to use quiz
// import visaQuizData from './visa.js'
// import hotelsQuizData from './hotels.js'


// quizes that we collect
const quizesData = [
	// visaQuizData,
	// hotelsQuizData,
]

// find quiz that has no answer from user
const getQuizThatHasNoAnswer = (quizesAnswers) => (
	quizesData.find(({ id }) => !Boolean(quizesAnswers[id])) || null
)

export default getQuizThatHasNoAnswer
