import { takeEvery, select, call, put } from 'redux-saga/effects'

import accountDataFetcher, { RECOVER_PASSWORD_STATUS } from '@libs/account-data-fetcher'
import { RECOVER_PASSWORD } from '@store/state/types'
import { loginSelectors } from '@store/state/uiState/forms/selectors'
import * as actions from '@store/state/appState/actions'


export function* recoverPasswordWorker () {

	const login = yield select(loginSelectors.loginSelector)

	try {
		yield call(accountDataFetcher.recoverPassword, login)
		yield put(actions.setRecoverPasswordStatus(RECOVER_PASSWORD_STATUS.SUCCESS))
	}
	catch (e) {
		yield put(actions.setRecoverPasswordStatus(RECOVER_PASSWORD_STATUS.ERROR))
	}

}

export default function* watchLogin () {
	yield takeEvery(RECOVER_PASSWORD, recoverPasswordWorker)
}
