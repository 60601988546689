import request from 'superagent'
import confy from '@utils/confy'
import getResponseHandler from './responseHandler.js'
import { OPERATION_STATUSES } from './statuses.js'


const ERROR_STATUSES = [
	OPERATION_STATUSES.UNAVAIL,
	OPERATION_STATUSES.VALIDATION_ERROR,
	OPERATION_STATUSES.INVALID,
]
const OK_STATUSES = [
	OPERATION_STATUSES.AVAIL,
]
const TIMEOUT_MS = 60000

export default function ({ id, channel, enabledCounts, fareVariants }) {
	const url = `${confy.get('API.cl.offer')}/${id}/reconfigure`
	const data = {
		channel,
		/* eslint-disable camelcase */
		enabled_counts: enabledCounts,
		fare_variants: fareVariants,
		/* eslint-enable camelcase */
	}

	const req = request
		.post(url)
		.set('Content-Type', 'application/json')
		.send(data)
		.timeout({ response: TIMEOUT_MS })

	const executor = (resolve, reject) => {
		const handler = getResponseHandler({
			resolve,
			reject,
			okStatuses: OK_STATUSES,
			errStatuses: ERROR_STATUSES,
			requestType: 'POST /offer/reconfigure',
		})

		req.end(handler)
	}

	return {
		promise: new Promise(executor),
		cancelFn: () => req.abort(),
	}
}
