import { omit, get, values, flatten } from 'lodash'
import { createSelector } from 'reselect'
import { calculateRoute, getAirlines as getAirlinesFromFlight } from '@utils/flight-calc'
import {
	makeOutboundFlightFromProductSelector,
	makeInboundFlightFromProductSelector,
	makeFaresTotalPriceFromProductSelector,
	makeFlightFaresSelector,
	getAirlines,
	getAirports,
	productByIdSelector,
	getFaresFromProduct,
} from '@store/state/domainData/selectors'


export const getExtendedSegments = (outboundFlight, inboundFlight, airlines, airports) => (
	[ outboundFlight, inboundFlight ]
		.filter((flight) => (flight.length > 0))
		.map((segments) => segments.map((segment) => ({
			...omit(segment, 'fareId'),
			airline: airlines[segment.airline],
			arrive: {
				...segment.arrive,
				airport: airports[get(segment, 'arrive.airport', '')],
			},
			depart: {
				...segment.depart,
				airport: airports[get(segment, 'depart.airport', '')],
			},
		})))
)

const makeExtendedSegmentsSelector = () => {
	const outboundFlightFromProductSelector = makeOutboundFlightFromProductSelector()
	const inboundFlightFromProductSelector = makeInboundFlightFromProductSelector()
	return createSelector(
		outboundFlightFromProductSelector,
		inboundFlightFromProductSelector,
		getAirlines,
		getAirports,
		getExtendedSegments
	)
}

const DEFAULT_BAGGAGE_WEIGHT = {
	hand: 5,
	checked: 15,
}

const getBaggageWeight = (fares, type) => Math.min(
	...fares.map(({ details }) => {
		const { included, weight } = get(details, `baggage.${type}`) || {}
		return Boolean(included) ? weight || DEFAULT_BAGGAGE_WEIGHT[type] : 0
	})
)

export const getTicketsInfo = (price, extSegments, fares) => {
	const faresList = values(fares)
	const airlines = getAirlinesFromFlight(flatten(extSegments))
	const { dominant_color: color } = airlines
		.find((airline) => Boolean(airline.dominant_color)) || {}
	const baggageWeight = getBaggageWeight(faresList, 'checked')
	const uncheckedBaggageWeight = getBaggageWeight(faresList, 'hand')
	const calculatedFlights = extSegments.map(calculateRoute)

	return {
		price,
		color,
		baggageWeight,
		uncheckedBaggageWeight,
		calculatedFlights,
		airlines,
	}
}

export const makeTicketInfoSelector = () => {
	const extendedSegmentsSelector = makeExtendedSegmentsSelector()
	const flightFaresSelector = makeFlightFaresSelector(productByIdSelector, getFaresFromProduct)
	const faresTotalPriceFromProductSelector = makeFaresTotalPriceFromProductSelector()
	return createSelector(
		faresTotalPriceFromProductSelector,
		extendedSegmentsSelector,
		flightFaresSelector,
		getTicketsInfo
	)
}

