import { mapValues, isPlainObject, omit, flatten } from 'lodash'
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect'
import fastDeepEqual from 'fast-deep-equal'


export const deepEqCreateSelector = createSelectorCreator(defaultMemoize, fastDeepEqual)

export const getFormsState = (state) => (state.app.uiState.forms)

export const makeValueSelector = ({ formName, fieldName }) => (state, formIndex = null) => (
	formIndex
		? getFormsState(state)[formName][formIndex][fieldName]
		: getFormsState(state)[formName][fieldName]
)
export const makeCheckedSelector = ({ formName, fieldName }) => (state, formIndex = null) => {
	let val = formIndex
		? getFormsState(state)[formName][formIndex][fieldName]
		: getFormsState(state)[formName][fieldName]
	return val === undefined ? false : val
}

export const getValueByFormIndex = (state, { formName, fieldName }) => {
	const formsState = getFormsState(state)[formName]
	return mapValues(formsState, (formState) => formState[fieldName])
}
export const makeValueByFormIndexSelector = (path) => deepEqCreateSelector(
	(state) => getValueByFormIndex(state, path),
	(valueByFormIndex) => valueByFormIndex
)

const getIsEmptyField = (value) => (!value)
export const makeEmptySelector = (valueSelector) => createSelector(
	valueSelector,
	(value) => (
		isPlainObject(value) ? mapValues(value, getIsEmptyField) : getIsEmptyField(value)
	)
)
const getIsInvalidField = (value, isValid) => Boolean(value && !isValid)
const getIsInvalidFieldByIndex = (isValidByIndex) => (value, index) => (
	getIsInvalidField(value, isValidByIndex[index])
)
export const makeInvalidSelector = (valueSelector, isValidSelector) => createSelector(
	valueSelector,
	isValidSelector,
	(value, isValid) => (isPlainObject(value)
		? mapValues(value, getIsInvalidFieldByIndex(isValid))
		: getIsInvalidField(value, isValid)
	)
)

export const getErrorsFromValueByFormIndex = (valueByFormIndex, { formName, fieldName, orderBy }) => (
	Object.keys(valueByFormIndex).reduce((acc, formIndex, orderFormIndex) => ([
		...acc,
		{ formName,
			fieldName,
			value: valueByFormIndex[formIndex],
			formIndex,
			orderFormIndex,
			orderBy,
		},
	]), [])
)
const getErrorFromValue = (value, { formName, fieldName, orderBy = null }) => ({
	formName,
	fieldName,
	value,
	orderFormIndex: null,
	orderBy,
})
export const byFormIndex = (a, b) => {
	if (a.orderFormIndex === b.orderFormIndex) return a.orderBy - b.orderBy
	return a.orderFormIndex - b.orderFormIndex
}
export const makeErrorFromValuesGetter = (errorFieldsSelectors) => (...values) => flatten(
	values.map((value, index) => {
		const errorField = errorFieldsSelectors[index]
		return isPlainObject(value)
			? getErrorsFromValueByFormIndex(value, errorField)
			: getErrorFromValue(value, errorField)
	})
).filter(({ value }) => value)
	.map((error) => omit(error, 'value'))
	.sort(byFormIndex)
export const makeErrorsSelector = (errorFieldsSelectors, isFlatArray = false) => {
	const emptySelectors = errorFieldsSelectors.map(({ emptySelector }) => emptySelector)
	const invalidSelectors = errorFieldsSelectors.map(({ invalidSelector }) => invalidSelector)
	const getErrorFromValues = makeErrorFromValuesGetter(errorFieldsSelectors)
	const emptyFieldsSelector = createSelector(emptySelectors, getErrorFromValues)
	const invalidFieldsSelector = createSelector(invalidSelectors, getErrorFromValues)
	return createSelector(
		emptyFieldsSelector,
		invalidFieldsSelector,
		(emptyFields, invalidFields) => (isFlatArray
			? [ ...emptyFields, ...invalidFields ]
			: { emptyFields, invalidFields }
		)
	)
}
