import { flow, some } from 'lodash'
import { airportsFromOfferSelector } from './airports.js'


const DOMESTIC_AIRPORTS = [ 'EVN', 'LWN' ]
const DOMESTIC_COUNTRIES = [ 'RUS', 'KAZ', 'BLR', 'KGZ' ]

export const isInternationalFlightResult = (airports = {}) => (
	some(airports, ({ country, iata }) => (!DOMESTIC_COUNTRIES.includes(country) && !DOMESTIC_AIRPORTS.includes(iata)))
)

export const isInternationalFlightFromOffer = flow(
	airportsFromOfferSelector,
	isInternationalFlightResult
)
