import { makeSwitcher } from '../utils.js'


const dev = 'dev'
const prod = 'prod'
const mock = 'mock'
const stage = 'stage'

const envs = [ dev, prod, stage, mock ]
const aliasMap = { development: dev, production: prod, staging: stage, test: mock }
const getEnv = () => {
	const lowerEnv = (process.env.NODE_ENV || '').toLowerCase()
	const env = aliasMap[lowerEnv] || lowerEnv
	return envs.includes(env) ? env : dev
}

const defaultEnvironment = getEnv()

const globalEnv = makeSwitcher({
	options: [
		{
			label: `(NODE_ENV || Default) = ${defaultEnvironment}`,
			pointer: 'default',
		},
		{
			label: 'Production',
			pointer: prod,
		},
		{
			label: 'Development',
			pointer: dev,
		},
		{
			label: 'Staging',
			pointer: stage,
		},
		{
			label: 'QA / Mock',
			pointer: mock,
		},
	],
	values: {
		dev,
		prod,
		mock,
		stage,
		default: defaultEnvironment,
	},
})

export default globalEnv

export {
	dev,
	prod,
	mock,
	stage,
}
