import l from '@libs/lang'
import { createEnhancer } from '@utils/decoract'
import { makeCX } from '@utils/taffy'

import $ from './styles/secondaryFooter.js'


const cx = makeCX()

const cq = {
	t767: {
		maxWidth: 767,
	},
	f768t969: {
		maxWidth: 969,
		minWidth: 768,
	},
	f970: {
		minWidth: 970,
	},
}

const enhancer = createEnhancer({ cq })

const SecondaryFooter = ({ cq }) => {
	const { t767, f768t969, f970 } = cq

	const containerClass = cx({
		[$.container]: f970,
		[$.containerMd]: f768t969,
		[$.containerSm]: t767,
	})

	const waysClass = cx({
		[$.ways]: f970,
		[$.waysMd]: f768t969,
		[$.waysSm]: t767,
	})

	const wrapperClass = t767 ? $.wrapperSm : $.wrapper

	const txtTitle = l('Города, страны и направления')

	let ways = [
		{ text: l('Барселона'), id: 1 },
		{ text: l('Гоа (Даболим)'), id: 2 },
		{ text: l('Шарм-Эль-Шейх'), id: 3 },
		{ text: l('Пхукет'), id: 4 },
		{ text: l('Стамбул'), id: 5 },
		{ text: l('Шарм-Эль-Шейх'), id: 6 },
		{ text: l('Дубай'), id: 7 },
		{ text: l('Хургада'), id: 8 },
		{ text: l('Хургада'), id: 9 },
		{ text: l('Тенерифе'), id: 10 },
		{ text: l('Дубай'), id: 11 },
		{ text: l('Анталия'), id: 12 },
		{ text: l('Барселона'), id: 13 },
		{ text: l('Стамбул'), id: 14 },
		{ text: l('Пхукет'), id: 15 },
		{ text: l('Париж'), id: 16 },
		{ text: l('Бангкок'), id: 17 },
		{ text: l('Гоа (Даболим)'), id: 18 },
		{ text: l('Тенерифе'), id: 19 },
		{ text: l('Бангкок'), id: 20 },
		{ text: l('Париж'), id: 21 },
		{ text: l('Канкун'), id: 22 },
		{ text: l('Канкун'), id: 23 },
		{ text: l('Анталия'), id: 24 },
	]

	if (f768t969) {
		ways = ways.slice(0, 20)
	}

	return (
		<div className={wrapperClass}>
			<div className={containerClass}>
				<h4>{txtTitle}</h4>
				<ul className={waysClass}>	
					{ways.map(({ id, text }) => (
						<li key={id}><a href={`https://clickavia.ru/way/${id}`}>{text}</a></li>
					))}
				</ul>
			</div>
		</div>
	)
}

export default enhancer(SecondaryFooter)
