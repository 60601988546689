import { flow } from 'lodash'
import { connect } from 'react-redux'
import cx from 'classnames'
import l from '@libs/lang'
import { getDate } from '@utils/time-utils'
import getHumanizedConsist from '@utils/common/getHumanizedConsist.js'

import styles from './styles'
import imgEditBtn from './images/edit-btn.svg'
import {
	routeDataSelector,
} from './selectors'


const formatDate = (dateObj) => {
	let formatted = l('{date, date, shortMonth}', { date: dateObj })
	const [ date, month ] = formatted.split(' ')
	if (month.length > 3) {
		formatted = `${date} ${month.slice(0, 3)}`
	}
	return formatted
}
const getFormattedDate = flow(
	getDate,
	formatDate
)

export const RouteInfo = (props) => {
	const {
		src,
		dst,
		outboundDateIso,
		inboundDateIso,
		consist,
		cabinClass,
		sm,
		onEditRouteClick,
	} = props

	const txtRoute = l('{src} {arrow} {dst},', {
		src,
		dst,
		arrow: inboundDateIso ? '⇄' : '→',
	})

	const txtRouteDates = l(`{outbound} {hasInboundDate, select,
		true {— {inbound}}
		false {}
	}`, {
		outbound: getFormattedDate(outboundDateIso),
		inbound: inboundDateIso && getFormattedDate(inboundDateIso),
		hasInboundDate: Boolean(inboundDateIso),
	})

	const txtConsist = getHumanizedConsist(consist)
	const txtCabinClass = l(`{cabinClass, select,
		econom {, эконом}
		business {, бизнес}
		other {{cabinClass}}
	}`, { cabinClass })

	const dot = (<b> • </b>)

	const containerClass = cx({
		[styles.container]: !sm,
		[styles.containerSm]: sm,
	})

	return (
		<div className={containerClass} onClick={onEditRouteClick}>
			<span className={styles.route}>{txtRoute}</span>
			<div className={styles.secondary}>
				<div className={styles.secondaryText}>
					<span className={styles.dates}>{txtRouteDates}</span>
					<span className={styles.consist}>
						{sm || dot}{txtConsist}{txtCabinClass}
					</span>
				</div>
				{onEditRouteClick && (
					<div className={styles.editBtn}>
						<img src={imgEditBtn} alt="edit-search"/>
					</div>
				)}
			</div>
		</div>
	)
}

const mapStateToProps = (state) => ({
	...routeDataSelector(state),
})
const connector = connect(mapStateToProps)

export default connector(RouteInfo)
