import { takeEvery, all } from 'redux-saga/effects'
import {
	RECONFIGURE_OFFER,
	BUILD_OFFER,
	CHANGE_CONSIST,
	PRELOAD_CONSIST,
} from '@store/state/types'

import handleReconfigureOffer from './reconfigureOfferHandler.js'
import handleBuildOffer from './buildOfferHandler.js'
import handleChangeConsist from './changeConsistHandler.js'
import handlePreloadConsist from './preloadConsistHandler.js'


export default function* () {
	yield all([
		takeEvery(RECONFIGURE_OFFER, handleReconfigureOffer),
		takeEvery(BUILD_OFFER, handleBuildOffer),
		takeEvery(CHANGE_CONSIST, handleChangeConsist),
		takeEvery(PRELOAD_CONSIST, handlePreloadConsist),
	])
}
