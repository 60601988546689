import { mapValues } from 'lodash'
import { createSelector } from 'reselect'
import { byCurrentOfferId } from '@store/state/appState/selectors'
import { isMiddleNameRequiredSelector } from '@store/state/domainData/selectors'
import { PASSENGERS_FORM } from '../../types.js'
import { makeEmptySelector, makeInvalidSelector } from '../core.js'
import {
	lastNameByFormIndexSelector,
	firstNameByFormIndexSelector,
	middleNameByFormIndexSelector,
	genderByFormIndexSelector,
	birthdateByFormIndexSelector,
	citizenshipByFormIndexSelector,
	documentNumByFormIndexSelector,
} from './values.js'
import {
	documentExpDateWithDefaultByFormIndexSelector,
	documentTypeByFormIndexSelector,
} from './derivatives.js'
import {
	isLastNameValidByFormIndexSelector,
	isFirstNameValidByFormIndexSelector,
	isMiddleNameValidByFormIndexSelector,
	isGenderValidByFormIndexSelector,
	isBirthdateValidByFormIndexSelector,
	isCitizenshipValidByFormIndexSelector,
	isDocumentNumValidByFormIndexSelector,
	isDocumentExpDateValidByFormIndexSelector,
} from './validators.js'


const middleNameWithDefaultByFormIndexSelector = createSelector(
	middleNameByFormIndexSelector,
	byCurrentOfferId(isMiddleNameRequiredSelector),
	documentTypeByFormIndexSelector,
	(valueByIndex, isRequired, docTypeByIndex) => mapValues(
		valueByIndex,
		(value, index) => ((isRequired && docTypeByIndex[index] === 'national-passport')
			? value
			: 'notEmpty'
		)
	)
)

export const errorFieldsSelectors = [ {
	formName: PASSENGERS_FORM.NAME,
	fieldName: PASSENGERS_FORM.FIELDS.LAST_NAME,
	emptySelector: makeEmptySelector(lastNameByFormIndexSelector),
	invalidSelector: makeInvalidSelector(
		lastNameByFormIndexSelector,
		isLastNameValidByFormIndexSelector,
	),
}, {
	formName: PASSENGERS_FORM.NAME,
	fieldName: PASSENGERS_FORM.FIELDS.FIRST_NAME,
	emptySelector: makeEmptySelector(firstNameByFormIndexSelector),
	invalidSelector: makeInvalidSelector(
		firstNameByFormIndexSelector,
		isFirstNameValidByFormIndexSelector,
	),
}, {
	formName: PASSENGERS_FORM.NAME,
	fieldName: PASSENGERS_FORM.FIELDS.MIDDLE_NAME,
	emptySelector: makeEmptySelector(middleNameWithDefaultByFormIndexSelector),
	invalidSelector: makeInvalidSelector(
		middleNameByFormIndexSelector,
		isMiddleNameValidByFormIndexSelector,
	),
}, {
	formName: PASSENGERS_FORM.NAME,
	fieldName: PASSENGERS_FORM.FIELDS.GENDER,
	emptySelector: makeEmptySelector(genderByFormIndexSelector),
	invalidSelector: makeInvalidSelector(
		genderByFormIndexSelector,
		isGenderValidByFormIndexSelector,
	),
}, {
	formName: PASSENGERS_FORM.NAME,
	fieldName: PASSENGERS_FORM.FIELDS.BIRTHDATE,
	emptySelector: makeEmptySelector(birthdateByFormIndexSelector),
	invalidSelector: makeInvalidSelector(
		birthdateByFormIndexSelector,
		isBirthdateValidByFormIndexSelector,
	),
}, {
	formName: PASSENGERS_FORM.NAME,
	fieldName: PASSENGERS_FORM.FIELDS.CITIZENSHIP,
	emptySelector: makeEmptySelector(citizenshipByFormIndexSelector),
	invalidSelector: makeInvalidSelector(
		citizenshipByFormIndexSelector,
		isCitizenshipValidByFormIndexSelector,
	),
}, {
	formName: PASSENGERS_FORM.NAME,
	fieldName: PASSENGERS_FORM.FIELDS.DOCUMENT_NUM,
	emptySelector: makeEmptySelector(documentNumByFormIndexSelector),
	invalidSelector: makeInvalidSelector(
		documentNumByFormIndexSelector,
		isDocumentNumValidByFormIndexSelector,
	),
}, {
	formName: PASSENGERS_FORM.NAME,
	fieldName: PASSENGERS_FORM.FIELDS.DOCUMENT_EXP_DATE,
	emptySelector: makeEmptySelector(documentExpDateWithDefaultByFormIndexSelector),
	invalidSelector: makeInvalidSelector(
		documentExpDateWithDefaultByFormIndexSelector,
		isDocumentExpDateValidByFormIndexSelector,
	),
} ].map((field, index) => ({
	...field,
	orderBy: index,
}))
