import { sum, isEqual, flow, values } from 'lodash'
import { throttle, put, select, all } from 'redux-saga/effects'

import { PASSENGERS_FORM } from '@store/state/uiState/forms/types'
import { preloadConsist } from '@store/state/appState/actions'
import { FOCUS } from '@store/state/types'
import { parseFormFieldFocusKey } from '@store/focusMiddleware.js'
import { flightConsistFromOfferSelector } from '@store/state/domainData/selectors'

import { byCurrentOfferId } from '@store/state/appState/selectors'
import { passengerSelectors } from '@store/state/uiState/forms/selectors'


export const currentOfferConsistSelector = byCurrentOfferId(flightConsistFromOfferSelector)

export const paxFormsBlurActionsMatcher = ({ type, meta = {} }) => (
	type === FOCUS && parseFormFieldFocusKey(meta.prevActiveElem || '').formName === PASSENGERS_FORM.NAME
)

export const isAllPaxFormsValidSelector = flow(
	passengerSelectors.isFormValidByFormIndexSelector,
	(isFormValidByFormIndex) => values(isFormValidByFormIndex).every(Boolean)
)

export function* formsFullfillWorker () {
	const isAllPaxFormsValid = yield select(isAllPaxFormsValidSelector)
	if (!isAllPaxFormsValid) return

	const [ offerConsist, formsConsist ] = yield all([
		select(currentOfferConsistSelector),
		select(passengerSelectors.paxConsistSelector),
	])

	const isSameConsist = isEqual(offerConsist, formsConsist)
	const areConsistSumEq = sum(formsConsist) === sum(offerConsist)

	if (!isSameConsist && areConsistSumEq) {
		yield put(preloadConsist(formsConsist))
	}

}

export const THROTTLE_TIME_MS = 1280

export default function* watchFormsFullfill () {
	yield throttle(THROTTLE_TIME_MS, paxFormsBlurActionsMatcher, formsFullfillWorker)
}
