import l from '@libs/lang'
import { getDate } from '@utils/time-utils'

import { FloatingLabel as FloatingLabelInput } from 'core-components/Input'

import styles from './styles'
import icoCalendar from './images/icon-calendar.svg'


class DateInput extends React.Component {
	_inputFocusHandler = (e) => {
		const { showPopover, focus } = this.props
		showPopover()
		focus()
	}

	_renderFormattedDate = (dateIso) => {
		const date = dateIso && getDate(dateIso)
		const txtWeekday = date && l.date(date, 'weekdayFormat', {
			weekdayFormat: { weekday: 'short' },
		})
		const txtDayWithMonth = date && l.date(date, 'dayMonthFormat', {
			dayMonthFormat: {
				day: 'numeric',
				month: 'long',
			},
		})
		return (
			<span className={styles.inputDate}>
				{dateIso && (
					<React.Fragment>
						{txtDayWithMonth}<span>, {txtWeekday}</span>
					</React.Fragment>
				)}
			</span>
		)
	}

	render () {
		const { showPopover, focus, containerClass, value, ...restProps } = this.props
		const propsContainer = {
			className: containerClass,
			tabIndex: -1,
			onFocus: this._inputFocusHandler,
		}
		const propsInput = {
			...restProps,
			value,
			onFocus: this._inputFocusHandler,
		}
		return (
			<div {...propsContainer}>
				<FloatingLabelInput {...propsInput}>
					{this._renderFormattedDate(value)}
				</FloatingLabelInput>
				{!value && <img src={icoCalendar} alt="dateInput"/>}
			</div>
		)
	}
}

export default DateInput
