import request from 'superagent'
import confy from '@utils/confy'
import store from 'store'
import getResponseHandler from './responseHandler.js'
import { OPERATION_STATUSES } from './statuses.js'


const ERROR_STATUSES = [
	OPERATION_STATUSES.INVALID,
	OPERATION_STATUSES.VALIDATION_ERROR,
	OPERATION_STATUSES.PRICE_CHANGED,
	OPERATION_STATUSES.UNAVAIL,
]
const OK_STATUSES = [
	OPERATION_STATUSES.SUCCESS,
]

export default function ({ orderId }) {
	let url = `${confy.get('API.cl.order')}/${orderId}`
	const token = store.get(`ORDERS_TOKEN/${orderId}`)
	if (token) {
		url = `${url}?token=${token}`
	}

	const req = request.get(url)

	const executor = (resolve, reject) => {
		const handler = getResponseHandler({
			resolve,
			reject,
			okStatuses: OK_STATUSES,
			errStatuses: ERROR_STATUSES,
			requestType: 'GET /order',
		})

		req.end(handler)
	}

	return {
		promise: new Promise(executor),
		cancelFn: () => req.abort(),
	}
}
