import { values, omit } from 'lodash'
import { getFares } from '@store/state/domainData/selectors'


export const responseWithNewFaresResult = (fares, fareVariants) => {
	const newFares = Object.keys(fareVariants).reduce((acc, fareId) => {
		const fare = fares[fareId]
		const { variants, variant_id: currentVariantId } = fare
		const variantId = fareVariants[fareId]
		if (!variantId) return { ...acc, [fareId]: fare }

		const variant = variants[variantId]
		if (!variant) return { ...acc, [fareId]: fare }
		const newFare = {
			...variant,
			variants: { ...variants, [currentVariantId]: omit(fare, 'variants') },
		}
		return { ...acc, [fareId]: newFare }
	}, {})

	return { data: { fares: values(newFares) }, meta: {} }
}

const resWithNewFaresSelector = (state, searchQuery, fareVariants) => {
	const fares = getFares(state, searchQuery)
	return responseWithNewFaresResult(fares, fareVariants)
}

export default resWithNewFaresSelector
