export default (params) => {
	const {
		src,
		dst,
		outboundDate,
		inboundDate,
		adults,
		children,
		infants,
	} = params
	return `${src}/${dst}/${outboundDate}/${inboundDate || '_'}/${adults}/${children}/${infants}`
}
