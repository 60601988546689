import { take, put, select } from 'redux-saga/effects'

import { LOAD_TYPE } from '@libs/foma/types'
import { ADD_LOADING_EVENT, SET_DOMAIN_DATA } from '@store/state/types'
import searchedFlightsSelector from '@organisms/TicketsSerp/selectors/search'


const resultsCountSelector = (state) => {
	const results = searchedFlightsSelector(state)
	return results.length
}

const startSearchPattern = ({ type, payload }) => (
	type === ADD_LOADING_EVENT
	&& payload.type === LOAD_TYPE.SEARCH
)

const EVENT_NAME = 'CA_FIRST_SEARCH_RESULTS'
export default function* firstSearchResults (analyticEventChan) {
	let hasResults = false
	let isSearchStarted = false
	while (true) {
		if (!isSearchStarted) {
			yield take(startSearchPattern)
			isSearchStarted = true
		}
		else {
			yield take(SET_DOMAIN_DATA)
			const resultsCount = yield select(resultsCountSelector)
			hasResults = resultsCount > 0
			if (hasResults) {
				isSearchStarted = false
				yield put(analyticEventChan, {
					name: EVENT_NAME,
					payload: { ticketFirstSearchResultsCount: resultsCount },
				})
			}
		}
	}
}

