import { flow } from 'lodash'
import { replace, push } from 'connected-react-router'
import { take, all, takeLatest, select, put, fork, cancel, join, call, cancelled } from 'redux-saga/effects'
import {
	OPERATION_STATUSES,
	ORDER_STATUSES,
	PAYMENT_STATUSES,
	LOAD_TYPE,
} from '@libs/foma/types'
import loadData from '@store/sagas/apiService'
import { getQueryFromString } from '@utils/common/searchQuery'
import { toSerp, toCheckout } from '@utils/router'
import { isPseudo } from '@utils/common/offerId'
import { reqAirports, reqCitiesByMetros } from '@store/sagas/geoData'
import { initSearchBarParams } from '@store/sagas/searchBar'
import { LOCATION_CHANGE } from '@store/state/types'
import { checkBookSelector } from '@store/state/selectors'
import {
	setOfferOpStatus,
	book3dsConfirm,
	setCurrentOfferId,
	setBookStatus,
} from '@store/state/appState/actions'
import {
	clearStore,
} from '@store/state/domainData/actions'
import {
	airportsFromOfferSelector,
	orderFromOfferIdSelector,
} from '@store/state/domainData/selectors'
import {
	currentSearchQuerySelector,
	currentOfferIdSelector,
	serpParamsSelector,
	byCurrentOfferId,
	routerDataSelector,
	bookStatusSelector,
	offerOpStatusSelector,
} from '@store/state/appState/selectors'
import setOfferIdActionMatcher from '../../helpers/setOfferIdActionMatcher.js'

import bookWatcher from '@store/sagas/watchBook'
import resetBookStatus from '@store/sagas/resetBookStatus'
import goToInvalidFormFieldWatcher from '@store/sagas/watchGoToInvalidFormField'
import loadOfferByFormsConsist from '@store/sagas/loadOfferByFormsConsist'
import restorePreviousPriceWorker from '@store/sagas/restorePreviousPrice'
import loadOffer from './loadOffer.js'
import initForms from './initForms.js'
import persistFormsWorker from './persistForms.js'
import redirectToPartner from './redirectToPartner.js'


export const airportIatasFromOfferSelector = flow(
	byCurrentOfferId(airportsFromOfferSelector),
	Object.keys
)

export function* loadDataWorker (offerId, orderId) {
	yield put(setOfferOpStatus(OPERATION_STATUSES.LOADING))
	let loadOfferResult = { opStatus: null, bgTask: null }
	try {
		if (orderId) {
			loadOfferResult.opStatus = yield call(loadData, {
				type: LOAD_TYPE.ORDER,
				meta: { orderId },
				updateSearchQuery: true,
			})

			yield put(book3dsConfirm(orderId))
		}
		else {
			loadOfferResult = yield call(loadOffer, offerId)
		}
		yield put(setOfferOpStatus(loadOfferResult.opStatus))

		const successStatuses = [ OPERATION_STATUSES.AVAIL, OPERATION_STATUSES.SUCCESS ]
		if (successStatuses.includes(loadOfferResult.opStatus)) {
			yield call(initForms)
			const airportIatas = yield select(airportIatasFromOfferSelector)
			yield call(reqAirports, airportIatas)

			const searchParams = yield select(serpParamsSelector)
			yield call(initSearchBarParams, searchParams)
		}
		else if (!orderId) {
			// should try redirect to serp
			const currentSearchQuery = yield select(currentSearchQuerySelector)
			if (currentSearchQuery) {
				const { src, dst } = yield call(getQueryFromString, currentSearchQuery)
				yield call(reqCitiesByMetros, [ src, dst ])
				const searchParams = yield select(serpParamsSelector)
				yield put(push(toSerp(searchParams)))
			}
		}

		if (loadOfferResult.bgTask) {
			yield join(loadOfferResult.bgTask)
			const opStatus = yield call(loadOfferResult.bgTask.result)
			if (opStatus && !successStatuses.includes(opStatus)) {
				yield put(setOfferOpStatus(opStatus))
			}
		}
	}
	finally {
		const isCancelled = yield cancelled()
		if (isCancelled && loadOfferResult.bgTask) {
			yield put(setOfferOpStatus(OPERATION_STATUSES.NONE))
			yield cancel(loadOfferResult.bgTask)
		}
	}
}

export function* replaceOfferIdLocation () {
	const offerId = yield select(currentOfferIdSelector)
	const { location } = yield select(routerDataSelector)
	yield put(replace(toCheckout(offerId, location.search)))
}

export function* changeOfferIdWatcher () {
	yield takeLatest(setOfferIdActionMatcher, replaceOfferIdLocation)
}

export const enhancedOrderFromOfferIdSelector = byCurrentOfferId(orderFromOfferIdSelector)
export function* clearHandlerData () {
	yield put(setOfferOpStatus(OPERATION_STATUSES.NONE))

	const { isSuccess } = yield select(checkBookSelector)
	if (isSuccess) yield put(setBookStatus(OPERATION_STATUSES.NONE))
	const bookingStatus = yield select(bookStatusSelector)
	const {
		status: orderStatus,
		payment_status: paymentStatus,
	} = (yield select(enhancedOrderFromOfferIdSelector)) || {}
	const isBooking = bookingStatus === OPERATION_STATUSES.LOADING
	const isPriceChanged = bookingStatus === OPERATION_STATUSES.PRICE_CHANGED
		&& orderStatus === ORDER_STATUSES.AVAIL
		&& paymentStatus === PAYMENT_STATUSES.UNPAID
	const isOrderUnavail = (orderStatus === ORDER_STATUSES.UNAVAIL
		|| bookingStatus === OPERATION_STATUSES.UNAVAIL)
		&& (paymentStatus === PAYMENT_STATUSES.UNPAID || !paymentStatus)
	const offerId = yield select(currentOfferIdSelector)
	const isPseudoOffer = yield call(isPseudo, offerId)
	const offerOpStatus = yield select(offerOpStatusSelector)
	const isCreateOfferFail = isPseudoOffer && offerOpStatus === OPERATION_STATUSES.UNAVAIL

	if ((!isBooking && (isPriceChanged || isOrderUnavail)) || isCreateOfferFail) {
		yield put(setCurrentOfferId(null))
		yield put(setBookStatus(OPERATION_STATUSES.NONE))
		const currentQuery = yield select(currentSearchQuerySelector)
		yield put(clearStore(currentQuery))
	}
}

export default function* checkoutHandler (params, query) {
	try {
		const { offerId } = params
		const { order_id: orderId, book_by_redirect: isBookByRedirect } = query
		if (isBookByRedirect) {
			yield call(loadDataWorker, offerId, orderId)
			yield call(redirectToPartner)
		}
		else {
			yield all([
				fork(restorePreviousPriceWorker),
				fork(loadDataWorker, offerId, orderId),
				fork(changeOfferIdWatcher),
				fork(bookWatcher),
				fork(resetBookStatus),
				fork(goToInvalidFormFieldWatcher),
				fork(persistFormsWorker),
				fork(loadOfferByFormsConsist),
			])
		}
		while (true) {
			yield take(LOCATION_CHANGE)
		}
	}
	finally {
		if (yield cancelled()) {
			yield call(clearHandlerData)
		}
	}
}
