import OfferError from './offerError.js'
import { get } from 'lodash'
import { OPERATION_STATUSES } from '@libs/feofan/statuses'


const getResponseHandler = ({
	resolve,
	reject,
	omitData,
	okStatuses,
	errStatuses,
	requestType,
}) => (err, res) => {
	const status = get(res, 'body.status', OPERATION_STATUSES.NONE)
	const data = get(res, 'body.data', get(res, 'body.payment', null))
	const meta = get(res, 'body.meta', null)
	const isOkStatus = okStatuses.some((okStatus) => (okStatus === status))
	const isErrStatus = errStatuses.some((errStatus) => (errStatus === status))

	if (isOkStatus && data) {
		resolve({ data, meta, status })
	}
	else if (isOkStatus && omitData) {
		resolve({ data: {}, meta: meta || {}, status })
	}
	else if (isErrStatus) {
		reject(new OfferError(`${requestType} has error status`, { data, meta, status }))
	}
	else {
		reject(new OfferError(`${requestType} server error`, { res, err }))
	}
}

export default getResponseHandler
