import { get, flow, last, mapValues } from 'lodash'
import { createSelector } from 'reselect'
import {
	getAirports,
} from './core.js'
import {
	sortedSegmentsFromOfferSelector,
	sortedSegmentsFromProductSelector,
	sortedSegmentsByProductIdSelector,
	sortedSegmentsByOfferIdSelector,
	makeSortedSegmentsFromProductSelector,
} from './segments.js'
import { routeInfoSelector, routeInfoByOfferIdSelector } from './routeInfo.js'


export const getFlightFromSegments = (segments, airports, route = []) => {
	if (route.length <= 0) return []

	const [ departMetro, arriveMetro ] = route
	const firstSegIdx = segments.findIndex(({ depart = {} }) => {
		const { airport: iata } = depart
		return get(airports, `${iata}.metro`, '') === departMetro
	})
	const lastSegIdx = segments.findIndex(({ arrive = {} }) => {
		const { airport: iata } = arrive
		return get(airports, `${iata}.metro`, '') === arriveMetro
	})
	if (firstSegIdx === -1 || lastSegIdx === -1) return []

	return segments.slice(firstSegIdx, lastSegIdx + 1)
}

const makeFlightSelector = (routeGetter, segmentsSelector) => createSelector(
	[ segmentsSelector, getAirports, flow(routeInfoSelector, routeGetter) ],
	getFlightFromSegments
)

const makeFlightByRootItemIdSelector = (routeSelector, segmentsByRootItemSelector) => createSelector(
	segmentsByRootItemSelector,
	getAirports,
	routeSelector,
	(segmentsByRootItemId, airports, route) => mapValues(segmentsByRootItemId, (segments, rootId) => (
		getFlightFromSegments(segments, airports, route[rootId] || route)
	))
)

const defaultRouteInfo = []
export const getOutboundRoute = ([ route ]) => route || defaultRouteInfo
export const getInboundRoute = (routeInfo) => ((routeInfo && routeInfo.length > 1) ? last(routeInfo) : defaultRouteInfo)

export const outboundFlightFromOfferSelector = makeFlightSelector(getOutboundRoute, sortedSegmentsFromOfferSelector)
export const outboundFlightFromProductSelector = makeFlightSelector(getOutboundRoute, sortedSegmentsFromProductSelector)
export const inboundFlightFromOfferSelector = makeFlightSelector(getInboundRoute, sortedSegmentsFromOfferSelector)
export const inboundFlightFromProductSelector = makeFlightSelector(getInboundRoute, sortedSegmentsFromProductSelector)

export const makeOutboundFlightFromProductSelector = () => (
	makeFlightSelector(getOutboundRoute, makeSortedSegmentsFromProductSelector())
)
export const makeInboundFlightFromProductSelector = () => (
	makeFlightSelector(getInboundRoute, makeSortedSegmentsFromProductSelector())
)

export const outboundFlightByProductIdSelector = makeFlightByRootItemIdSelector(
	flow(routeInfoSelector, getOutboundRoute),
	sortedSegmentsByProductIdSelector
)
export const inboundFlightByProductIdSelector = makeFlightByRootItemIdSelector(
	flow(routeInfoSelector, getInboundRoute),
	sortedSegmentsByProductIdSelector
)
const makeRouteSelector = (routeGetter) => flow(
	routeInfoByOfferIdSelector,
	(routeByOffer) => mapValues(routeByOffer, routeGetter)
)
export const outboundFlightByOfferIdSelector = makeFlightByRootItemIdSelector(
	makeRouteSelector(getOutboundRoute),
	sortedSegmentsByOfferIdSelector,
)
export const inboundFlightByOfferIdSelector = makeFlightByRootItemIdSelector(
	makeRouteSelector(getInboundRoute),
	sortedSegmentsByOfferIdSelector,
)
