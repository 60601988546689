import l from '@libs/lang'


export default function ([ adults, children, infants ]) {
	const humanizedConsist = []

	if (adults > 0) humanizedConsist.push(
		l(`{count, plural,
			one {{count} взрослый}
			few {{count} взрослых}
			many {{count} взрослых}
		}`, {
			count: adults,
		})
	)
	if (children > 0) humanizedConsist.push(
		l(`{count, plural,
			one {ребенок}
			few {{count} ребенка}
			many {{count} детей}
		}`, {
			count: children,
		})
	)
	if (infants > 0) humanizedConsist.push(
		l(`{count, plural,
			=0 {}
			one {младенец}
			few {{count} младенца}
			many {{count} младенцев}
		}`, {
			count: infants,
		})
	)

	let txtConsist = ''
	let txtAndParticle = 'и'

	if (humanizedConsist.length === 3) {
		txtConsist = `${humanizedConsist[0]}, ${humanizedConsist[1]} ${txtAndParticle} ${humanizedConsist[2]}`
	}
	else {
		txtConsist = humanizedConsist.join(` ${txtAndParticle} `)
	}

	return txtConsist
}
