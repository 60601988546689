import {
	RECONFIGURE_OFFER,
	SET_OFFER_RECONFIGURE_STATUS,
	BUILD_OFFER,
	CHANGE_CONSIST,
	SET_CHANGE_CONSIST_STATUS,
	PRELOAD_CONSIST,
	BOOK,
	BUY,
	SHOW_QR,
	SHOW_TINKOFF_QR,
	ACTIVATE_SBP_PAYMENT,
	DEACTIVATE_SBP_PAYMENT,
	ACTIVATE_TINKOFF_PAYMENT,
	DEACTIVATE_TINKOFF_PAYMENT,
	SET_BOOK_STATUS,
	SET_OFFER_OP_STATUS,
	SET_UPSALE_DIALOG,
} from '@store/state/types'


export const reconfigureOffer = (serviceType, enabledCounts, fareVariants, source) => ({
	type: RECONFIGURE_OFFER,
	payload: {
		serviceType,
		enabledCounts,
		fareVariants,
		source,
	},
})

export const setOfferReconfigureStatus = (serviceType, status) => ({
	type: SET_OFFER_RECONFIGURE_STATUS,
	payload: {
		serviceType,
		status,
	},
})

export const buildOffer = (replacedProducts = [], removedProducts = [], withTransition = false) => ({
	type: BUILD_OFFER,
	payload: { replacedProducts, removedProducts, withTransition },
})

export const changeConsist = (consist, formIndex, isAdd) => ({
	type: CHANGE_CONSIST,
	payload: {
		consist,
		formIndex,
		isAdd,
	},
})

export const preloadConsist = (consist) => ({
	type: PRELOAD_CONSIST,
	payload: {
		consist,
	},
})

export const setUpsaleDialog = (serviceType) => ({
	type: SET_UPSALE_DIALOG,
	payload: serviceType,
})

export const setChangeConsistStatus = (status) => ({
	type: SET_CHANGE_CONSIST_STATUS,
	payload: status,
})

export const book = () => ({
	type: BOOK,
})

export const buy = (isInitial = false) => ({
	type: BUY,
	meta: { isInitial },
})

export const showQr = () => ({
	type: SHOW_QR,
})

export const showTinkoffQr = () => ({
	type: SHOW_TINKOFF_QR,
})

export const book3dsConfirm = (orderId) => ({
	type: BOOK,
	payload: {
		isConfirm: true,
		orderId,
	},
})

export const setBookStatus = (status) => ({
	type: SET_BOOK_STATUS,
	payload: status,
})

export const activateSpbPayment = (payment) => ({
	type: ACTIVATE_SBP_PAYMENT,
	payload: payment,
})
export const deactivateSpbPayment = () => ({
	type: DEACTIVATE_SBP_PAYMENT,
})

export const activateTinkoffPayment = (payment) => ({
	type: ACTIVATE_TINKOFF_PAYMENT,
	payload: payment,
})
export const deactivateTinkoffPayment = () => ({
	type: DEACTIVATE_TINKOFF_PAYMENT,
})

export const setOfferOpStatus = (status) => ({
	type: SET_OFFER_OP_STATUS,
	payload: status,
})
