import { isEmpty } from 'lodash'
import { take, race, delay, cancelled, call, select, fork, put } from 'redux-saga/effects'
import { BATCH } from 'redux-batched-actions'

import { LOAD_TYPE } from '@libs/foma/types'
import loadData from '@store/sagas/apiService'
import { EXPIRE_TIMEOUT } from '@libs/foma'
import { getStringFromQuery, parseLocalityString } from '@utils/common/searchQuery'

import { reqLocalitiesByIds } from '@store/sagas/geoData'
import { storeParamsInLS as storeSearchBarParamsInLS } from '@store/sagas/searchBar'
import { SET_CURRENT_QUERY } from '@store/state/types'
import {
	dataKeysToStoreSelector,
	timestampSelector,
	getHotels,
	flightsProductsSelector,
} from '@store/state/domainData/selectors'
import { setTimestamp, clearStore } from '@store/state/domainData/actions'
import {
	setCurrentSearchQuery,
	setShopfrontDialog,
	clearFilters,
	applyQueryFilters,
	setCurrentOfferId,
} from '@store/state/appState/actions'
import {
	currentSearchQuerySelector,
	byCurrentOfferId,
	byCurrentSearchQuery,
	metrosByIdsSelector,
} from '@store/state/appState/selectors'

import { DIALOGS } from '@utils/serp-tabs'


export const DEFAULT_KEPT_KEYS = [ 'meta.route_info', 'meta.scheduled_products', 'meta.search_info' ]

export const isResultsEmptySelector = (state) => (
	isEmpty(byCurrentSearchQuery(getHotels)(state))
		&& isEmpty(byCurrentSearchQuery(flightsProductsSelector)(state))
)

export const enhancedDataKeysToStoreSelector = byCurrentOfferId(dataKeysToStoreSelector)

export function* composeSearchQuery (searchParams) {
	const { src, dst, ...rest } = searchParams
	const { id: srcId } = yield call(parseLocalityString, src)
	const { id: dstId } = yield call(parseLocalityString, dst)
	yield call(reqLocalitiesByIds, [ srcId, dstId ])
	const metrosByIdsMap = yield select(metrosByIdsSelector)
	const query = {
		...rest,
		src: metrosByIdsMap[srcId],
		dst: metrosByIdsMap[dstId],
	}
	const queryString = yield call(getStringFromQuery, query)
	return queryString
}

export function* expiryWorker () {
	yield delay(EXPIRE_TIMEOUT)
	const isResultsEmpty = yield select(isResultsEmptySelector)
	if (!isResultsEmpty) yield put(setShopfrontDialog(DIALOGS.SEARCH_EXPIRED))
}

export const getTimestamp = () => (new Date().getTime())

// TODO: edit when redux-batched-actions is dropped-off
export const setCurrentQueryActionMatcher = ({ type, payload }) => (
	type === SET_CURRENT_QUERY || (type === BATCH && payload.some(setCurrentQueryActionMatcher))
)
export function* search (searchParams, forceRefresh = false) {
	const nextSearchQuery = yield call(composeSearchQuery, searchParams)
	const timestamp = yield select(timestampSelector, nextSearchQuery) // do we have results for new query ?
	const currentSearchQuery = yield select(currentSearchQuerySelector)
	const isSameQuery = currentSearchQuery === nextSearchQuery

	if (isSameQuery && timestamp && !forceRefresh) return null

	const currentTimestamp = yield select(timestampSelector, currentSearchQuery)
	if (currentSearchQuery && currentTimestamp) {
		const offerKeys = yield select(enhancedDataKeysToStoreSelector)

		const keysToKeep = isSameQuery
			? [ ...DEFAULT_KEPT_KEYS, ...offerKeys ]
			: DEFAULT_KEPT_KEYS

		yield put(clearStore(currentSearchQuery, keysToKeep))
		yield put(clearFilters())
	}

	const filterByBaggage = (new URLSearchParams(window.location.search)).get('baggage') === 'true'
	if (filterByBaggage) {
		yield put(
			applyQueryFilters('tickets', [
				{
					id: 'baggage',
					criterias: [
						{ value: false, minPrice: 0 },
						{ value: true, minPrice: 0 },
					],
					values: [ true ],
				},
			])
		)
	}

	const now = yield call(getTimestamp)
	yield put(setTimestamp(now, nextSearchQuery))
	yield put(setCurrentSearchQuery(nextSearchQuery))
	if (!isSameQuery) yield put(setCurrentOfferId(null))

	yield fork(expiryWorker)

	const optsSearch = {
		type: LOAD_TYPE.SEARCH,
		meta: searchParams,
	}

	yield call(storeSearchBarParamsInLS)
	try {
		yield race({
			loadSearch: call(loadData, optsSearch),
			changeQuery: take(setCurrentQueryActionMatcher),
		})
	}
	finally {
		if (yield cancelled()) {
			yield put(setTimestamp(null, nextSearchQuery))
		}
	}
}
