import {
	CUSTOMER_FORM,
	PASSENGERS_FORM,
	SEARCH_BAR_FORM,
} from '@store/state/uiState/forms/types'
import {
	customerSelectors,
	passengerSelectors,
	searchBarSelectors,
} from '@store/state/uiState/forms/selectors'


export default {
	[CUSTOMER_FORM.NAME]: {
		[CUSTOMER_FORM.FIELDS.EMAIL]: customerSelectors.isEmailValidSelector,
		[CUSTOMER_FORM.FIELDS.PHONE]: customerSelectors.isPhoneValidSelector,
	},
	[PASSENGERS_FORM.NAME]: {
		[PASSENGERS_FORM.FIELDS.LAST_NAME]: passengerSelectors.isLastNameValidSelector,
		[PASSENGERS_FORM.FIELDS.FIRST_NAME]: passengerSelectors.isFirstNameValidSelector,
		[PASSENGERS_FORM.FIELDS.MIDDLE_NAME]: passengerSelectors.isMiddleNameValidSelector,
		[PASSENGERS_FORM.FIELDS.GENDER]: passengerSelectors.isGenderValidSelector,
		[PASSENGERS_FORM.FIELDS.BIRTHDATE]: passengerSelectors.isBirthdateValidSelector,
		[PASSENGERS_FORM.FIELDS.CITIZENSHIP]: passengerSelectors.isCitizenshipValidSelector,
		[PASSENGERS_FORM.FIELDS.DOCUMENT_NUM]: passengerSelectors.isDocumentNumValidSelector,
		[PASSENGERS_FORM.FIELDS.DOCUMENT_EXP_DATE]: passengerSelectors.isDocumentExpDateValidSelector,
	},
	[SEARCH_BAR_FORM.NAME]: {
		[SEARCH_BAR_FORM.FIELDS.SRC]: searchBarSelectors.isSrcValidSelector,
		[SEARCH_BAR_FORM.FIELDS.DST]: searchBarSelectors.isDstValidSelector,
		[SEARCH_BAR_FORM.FIELDS.OUTBOUND_DATE]: searchBarSelectors.isOutboundDateValidSelector,
		[SEARCH_BAR_FORM.FIELDS.INBOUND_DATE]: searchBarSelectors.isInboundDateValidSelector,
		[SEARCH_BAR_FORM.FIELDS.ADULTS]: searchBarSelectors.isAdultsValidSelector,
		[SEARCH_BAR_FORM.FIELDS.CHILDREN]: searchBarSelectors.isChildrenValidSelector,
		[SEARCH_BAR_FORM.FIELDS.INFANTS]: searchBarSelectors.isInfantsValidSelector,
	},
}
