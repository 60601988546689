import { PureComponent } from 'react'

import { getTimeoutTime } from './helpers/index.js'
import $ from './styles/index.js'
import { makeCX } from '@utils/taffy'


const cx = makeCX()

const SHOW_AFTER_START_TIMEOUT = 100
const MAX_PERCENT = 100
const NOOP = () => {}


class StatefullProgressBar extends PureComponent {

	static defaultProps = {
		forceStopAt: null,
		initialPercent: 0,
		onUnmount: NOOP,
	}

	constructor (props) {
		super(props)

		this.state = {
			percent: props.initialPercent,
		}

		this.timeout = null
	}

	componentDidMount () {
		if (this.props.isRunning) {
			this.timeout = setTimeout(this.doIncrementPercent, SHOW_AFTER_START_TIMEOUT)
		}
	}

	componentWillReceiveProps ({ isRunning, forceStopAt }) {

		const {
			isRunning: wasRunning,
		} = this.props

		const finishedRunning = wasRunning && !isRunning
		const startedRunning = !wasRunning && isRunning

		if (finishedRunning) {
			clearTimeout(this.timeout)
			this.timeout = null

			this._setPercentState(forceStopAt || MAX_PERCENT)

		}
		if (startedRunning) {
			this._initialize()
			this.doIncrementPercent()
		}
	}

	componentWillUnmount () {
		this.props.onUnmount(this.state.percent)
		clearTimeout(this.timeout)
		this.timeout = null
	}

	_incrementPercentState = (by) => {
		this.setState((prevState) => ({
			...prevState,
			percent: prevState.percent + by,
		}))
	}

	_setPercentState = (percent) => {
		this.setState((prevState) => ({
			...prevState,
			percent,
		}))
	}

	_initialize = () => {
		this.setState((prevState) => ({
			...prevState,
			percent: this.props.initialPercent,
		}))
	}

	doIncrementPercent = () => {
		if (this.state.percent >= MAX_PERCENT) {
			clearTimeout(this.timeout)
			this.timeout = null
			return
		}
		this._incrementPercentState(1)
		this.timeout = setTimeout(this.doIncrementPercent, getTimeoutTime(this.state.percent))
	}

	render () {

		const { barClassName, containerClassName, theme } = this.props
		const { percent } = this.state

		// prevent bar from overflowing, just in case
		const limitedPercent = Math.min(percent, MAX_PERCENT)
		const barStyle = { ...theme, width: `${limitedPercent}%` }

		const containerClass = cx({
			[$.container]: true,
			[containerClassName]: Boolean(containerClassName),
		})
		const barClass = cx({
			[$.bar]: true,
			[barClassName]: Boolean(barClassName),
		})

		return (
			<div className={containerClass}>
				<div className={$.barWrapper}>
					<div className={barClass} style={barStyle}/>
				</div>
			</div>
		)
	}
}

export default StatefullProgressBar
