import { SET_DOMAIN_DATA, SET_TIMESTAMP, CLEAR_STORE, CLEAR_ORDER } from '@store/state/types.js'


export const setDomainData = (response, searchQuery) => ({
	type: SET_DOMAIN_DATA,
	payload: {
		response,
		searchQuery,
	},
})

export const setTimestamp = (timestamp, searchQuery) => ({
	type: SET_TIMESTAMP,
	payload: { timestamp, searchQuery },
})

export const clearStore = (searchQuery, keysToKeep = []) => ({
	type: CLEAR_STORE,
	payload: { searchQuery, keysToKeep },
})

export const clearOrder = (searchQuery) => ({
	type: CLEAR_ORDER,
	payload: { searchQuery },
})
