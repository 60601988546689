import { compose, mapProps } from 'recompose'

import { applyContainerQuery } from '@utils/cq'


export const injectCQ = (query) => compose(
	(Container) => applyContainerQuery(Container, query),
	mapProps(({ containerQuery, ...props }) => ({ cq: containerQuery, ...props }))
)

