import { wwwRequest as request } from '@utils/request'
import confy from '@utils/confy'


export const LOGIN_STATUS = Object.freeze({
	INITIAL: 'LOGIN_STATUS/INITIAL',
	LOADING: 'LOGIN_STATUS/LOADING',
	SUCCESS: 'LOGIN_STATUS/SUCCESS',
	ERROR: 'LOGIN_STATUS/ERROR',
})

export const RECOVER_PASSWORD_STATUS = Object.freeze({
	INITIAL: 'RECOVER_PASSWORD_STATUS/INITIAL',
	LOADING: 'RECOVER_PASSWORD_STATUS/LOADING',
	SUCCESS: 'RECOVER_PASSWORD_STATUS/SUCCESS',
	ERROR: 'RECOVER_PASSWORD_STATUS/ERROR',
})

export const RESET_PASSWORD_STATUS = Object.freeze({
	INITIAL: 'RESET_PASSWORD_STATUS/INITIAL',
	LOADING: 'RESET_PASSWORD_STATUS/LOADING',
	SUCCESS: 'RESET_PASSWORD_STATUS/SUCCESS',
	ERROR: 'RESET_PASSWORD_STATUS/ERROR',
})

const responseHandler = (resolve, reject) => (err, res) => {
	if (err || !res || !res.ok || !res.body) {
		reject(res || {})
	}
	else {
		const isErrorStatus = res.body.status === 'ERROR' // TODO: wait for backend support
			|| !res.body.data
		if (isErrorStatus) {
			reject(res)
		}
		else {
			resolve(res.body.data)
		}
	}
}

const getData = () => {
	const url = confy.get('API.cl.accountData.url')
	const acceptHeader = confy.get('API.cl.accountData.acceptHeader')

	const executor = (resolve, reject) => {
		request
			.get(url)
			.withCredentials()
			.accept(acceptHeader)
			.end(responseHandler(resolve, reject))
	}

	return new Promise(executor)
}

const authorize = (login, password) => {
	const url = confy.get('API.cl.login')

	const data = {
		'user[email]': login,
		'user[password]': password,
	}

	return request
		.post(url)
		.withCredentials()
		.type('form')
		.send(data)
}

const authorizeByToken = (login, token) => {
	const url = confy.get('API.cl.loginByToken')

	const data = {
		'user[email]': login,
		'user[token]': token,
	}

	const executor = (resolve, reject) => {
		request
				.post(url)
				.withCredentials()
				.type('form')
				.send(data)
				.end((err, res) => {
					if (err || !res || !res.ok) {
						reject(new Error('Login timeout'))
					}
					else if (res.statusCode === 201) {
						resolve(res.body || true)
					}
					else if (res.statusCode === 401) {
						reject(new Error('Not logged in'))
					}
					else {
						reject(res)
					}
				})
	}

	return new Promise(executor)
}

const logout = () => {
	const url = confy.get('API.cl.logout')

	const executor = (resolve, reject) => {
		request
			.del(url)
			.withCredentials()
			.end((err, res) => {
				resolve()
			})
	}

	return new Promise(executor)
}

const checkCurrentUser = () => {
	const url = confy.get('API.cl.currentUser')

	const executor = (resolve, reject) => {
		request
			.get(url)
			.withCredentials()
			.end((err, res) => {
				if (err || !res || !res.ok) {
					reject(res || {})
				}
				else if (res.statusCode === 200) {
					resolve(res)
				}
				else if (res.statusCode === 204) {
					reject(new Error('Not logged in'))
				}
				else {
					reject(res)
				}
			})
	}

	return new Promise(executor)
}

const recoverPassword = (email) => {
	const url = confy.get('API.cl.passwordRecovery')

	const data = {
		user: {
			email,
		},
	}

	return request
		.post(url)
		.withCredentials()
		.type('form')
		.send(data)
}

const resetPassword = ({ password, passwordConfirm, token }) => {
	const url = confy.get('API.cl.passwordRecovery')

	const data = {
		'user[reset_password_token]': token,
		'user[password]': password,
		'user[password_confirmation]': passwordConfirm,
	}

	return request
		.put(url)
		.withCredentials()
		.type('form')
		.send(data)
}

export default {
	getData,
	authorize,
	authorizeByToken,
	checkCurrentUser,
	logout,
	recoverPassword,
	resetPassword,
}
