import { createSelector } from 'reselect'
import { creditCardSelectors } from '@store/state/uiState/forms/selectors'
import {
	userCCListSelector,
	isCCFormManualModeSelector,
} from '@store/state/appState/selectors'


const {
	isFormValidSelector,
	panSelector,
	cvvSelector,
	expDateSelector,
	cardholderSelector,
	shouldStoreCardSelector,
	cardIdWithDefaultSelector,
} = creditCardSelectors

const DEFAULT_CARDHOLDER_NAME = ''

export const getData = (isFormValid, ...args) => {

	if (!isFormValid) return null

	const [
		pan,
		cvv,
		expDate,
		cardholder = DEFAULT_CARDHOLDER_NAME,
		isManualMode,
		shouldStoreCard,
		cardId,
		userCCList,
	] = args

	if (isManualMode) {
		let [ validMonth, validYear ] = expDate.split('/')
		if (validYear.length === 4) {
			validYear = validYear.slice(2)
		}
		return {
			cardholder,
			pan,
			cvv,
			'valid_month': validMonth,
			'valid_year': validYear,
			'save_card': shouldStoreCard,
		}
	}

	const { card_id: id, payment_gate: paymentGate } = userCCList.find(({ card_id: id }) => (id === cardId))

	return {
		'card_id': id,
		'payment_gate': paymentGate,
	}
}

export default createSelector(
	isFormValidSelector,
	panSelector,
	cvvSelector,
	expDateSelector,
	cardholderSelector,
	isCCFormManualModeSelector,
	shouldStoreCardSelector,
	cardIdWithDefaultSelector,
	userCCListSelector,
	getData
)
