import { last } from 'lodash'

import { getCitizenshipCode } from '@utils/citizenship'
import { outboundFlightFromOfferSelector } from '@store/state/domainData/selectors'
import { byCurrentOfferId } from '@store/state/appState/selectors'
import { passengerSelectors } from '@store/state/uiState/forms/selectors'


const { paxKeysListSelector, citizenshipSelector } = passengerSelectors

const CITIZENSHIP_CODES = [ 'TJK', 'UZB', 'KGZ' ]
const BANNED_COUNTRY = 'MX'

const isMexicaFlight = ({ arrive }) => (arrive['country_code'] === BANNED_COUNTRY)

export const citizenshipListSelector = (state) => (
	paxKeysListSelector(state).map((formIndex) =>
		getCitizenshipCode(citizenshipSelector(state, formIndex))
	)
)

export const isInvalidMexicaTravelResult = (outboundFlight, citizenshipList) => {
	const mexFlight = isMexicaFlight(last(outboundFlight))
	const bannedPassenger = citizenshipList.find(
		(citizenshipCode) => (CITIZENSHIP_CODES.indexOf(citizenshipCode) >= 0)
	)

	return Boolean(mexFlight && bannedPassenger)
}

const isInvalidMexicaTravel = (state) => {
	const outboundFlight = byCurrentOfferId(outboundFlightFromOfferSelector)(state)
	const citizenshipList = citizenshipListSelector(state)

	return isInvalidMexicaTravelResult(outboundFlight, citizenshipList)
}

export default isInvalidMexicaTravel
