// temporary always hide phone number until we have 24/7 support
// https://www.pivotaltracker.com/story/show/151180507
export default () => (true)

// uncomment when needed
// export default () => {
// 	const date = new Date()
// 	let hour = date.getUTCHours()
// 	//9.00 and 20.00 in UTC timezone (+0) = 6 and 17
// 	//need to add lib for great work with time
// 	//i promise to do it in spare time
// 	return hour >= 6 && hour < 17
// }
