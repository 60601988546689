import { filter, pickBy, mapValues, zipObject } from 'lodash'
import { createSelector } from 'reselect'
import { EXTENDED_SERVICES, EXTENDED_SERVICES_SUBTYPE } from '@libs/foma/types'
import {
	getFares,
	getServices,
	offerByIdSelector,
	productByIdSelector,
	flightsProductsSelector,
} from './core.js'
import {
	getFaresFromOffer,
	getFaresFromProduct,
} from './itemsRelation.js'


const ENABLED_COUNT_OFFSET = 1

export const enabledFaresSelector = (faresSelector) => createSelector(
	[ offerByIdSelector, faresSelector ],
	({ fare_counts: fareCounts }, fares) => {
		const enabledFares = filter(
			Object.values(fares),
			(fare) => fareCounts[fare.id] && fareCounts[fare.id][ENABLED_COUNT_OFFSET]
		)
		return zipObject(
			enabledFares.map((fare) => fare.id),
			enabledFares
		)
	}
)

export const makeAreFaresEnabledSelector = (faresSelector) => createSelector(
	[ offerByIdSelector, faresSelector ],
	({ fare_counts: fareCounts }, fares) => {
		const faresArr = Object.keys(fares)
		return faresArr.length && faresArr.every((id) => Boolean(
			fareCounts[id] && fareCounts[id][ENABLED_COUNT_OFFSET]
		))
	}
)

const {
	INSURANCE: INSURANCE_SUBTYPES,
	SMS: SMS_SUBTYPES,
} = EXTENDED_SERVICES_SUBTYPE

const byServiceTypeAndSubtype = (services, serviceType, serviceSubtype) => ({ service }) => (
	services[service] && services[service].type === serviceType
	&& (!serviceSubtype || services[service].subtype === serviceSubtype)
)

const createFaresSelectorMaker = (serviceType, serviceSubtype) => (
	(rootItemGetter, faresGetter) => createSelector(
		[ getFares, getServices, rootItemGetter ],
		(fares, services, rootItem) => pickBy(
			faresGetter(fares, rootItem),
			byServiceTypeAndSubtype(services, serviceType, serviceSubtype)
		)
	)
)

// flights fares
export const makeFlightFaresSelector = createFaresSelectorMaker(EXTENDED_SERVICES.TRANSFER)
export const flightFaresFromOfferSelector =
	makeFlightFaresSelector(offerByIdSelector, getFaresFromOffer)
export const flightFaresFromProductSelector =
	makeFlightFaresSelector(productByIdSelector, getFaresFromProduct)

const faresByProductIdSelector = createSelector(
	[ getFares, flightsProductsSelector ],
	(fares, products) => mapValues(products, (product) => (
		getFaresFromProduct(fares, product)
	))
)
export const flightFaresByProductIdSelector = createSelector(
	[ faresByProductIdSelector, getServices ],
	(faresByProductId, services) => mapValues(faresByProductId, (fares) => (
		pickBy(
			fares,
			byServiceTypeAndSubtype(services, EXTENDED_SERVICES.TRANSFER)
		)
	))
)

// sms arrival fares
const makeCheckInFaresSelector = createFaresSelectorMaker(EXTENDED_SERVICES.CHECK_IN)
export const checkInFaresFromOfferSelector =
	makeCheckInFaresSelector(offerByIdSelector, getFaresFromOffer)
export const checkInFaresFromProductSelector =
	makeCheckInFaresSelector(productByIdSelector, getFaresFromProduct)

// accommodations fares
const makeAccommodationFaresSelector = createFaresSelectorMaker(EXTENDED_SERVICES.ACCOMMODATION)
export const accommodationFaresFromOfferSelector =
	makeAccommodationFaresSelector(offerByIdSelector, getFaresFromOffer)
export const accommodationFaresFromProductSelector =
	makeAccommodationFaresSelector(productByIdSelector, getFaresFromProduct)
export const accommodationFaresSelector = createSelector(
	[ getFares, getServices ],
	(fares, services) => pickBy(
		fares,
		byServiceTypeAndSubtype(services, EXTENDED_SERVICES.ACCOMMODATION)
	)
)

// sms info fares
const makeSmsInfoFaresSelector = createFaresSelectorMaker(EXTENDED_SERVICES.SMS, SMS_SUBTYPES.INFO)
export const smsInfoFaresFromOfferSelector =
	makeSmsInfoFaresSelector(offerByIdSelector, getFaresFromOffer)
export const smsInfoFaresFromProductSelector =
	makeSmsInfoFaresSelector(productByIdSelector, getFaresFromProduct)

// sms arrival fares
const makeSmsArrivalFaresSelector = createFaresSelectorMaker(EXTENDED_SERVICES.SMS, SMS_SUBTYPES.ARRIVAL)
export const smsArrivalFaresFromOfferSelector =
	makeSmsArrivalFaresSelector(offerByIdSelector, getFaresFromOffer)
export const smsArrivalFaresFromProductSelector =
	makeSmsArrivalFaresSelector(productByIdSelector, getFaresFromProduct)

// insurances fares
// alfa: triple protection
const makePackageCustomInsFaresSelector = createFaresSelectorMaker(
	EXTENDED_SERVICES.INSURANCE,
	INSURANCE_SUBTYPES.PACKAGE_CUSTOM
)
export const packageCustomInsFaresFromOfferSelector =
	makePackageCustomInsFaresSelector(offerByIdSelector, getFaresFromOffer)
export const packageCustomInsFaresFromProductSelector =
	makePackageCustomInsFaresSelector(productByIdSelector, getFaresFromProduct)

// alfa: medical
const makeTravelInsFaresSelector = createFaresSelectorMaker(
	EXTENDED_SERVICES.INSURANCE,
	INSURANCE_SUBTYPES.TRAVEL
)
export const travelInsFaresFromOfferSelector =
	makeTravelInsFaresSelector(offerByIdSelector, getFaresFromOffer)
export const travelInsFaresFromProductSelector =
	makeTravelInsFaresSelector(productByIdSelector, getFaresFromProduct)

// alfa: NSP
const makeInabilityToTravelInsFaresSelector = createFaresSelectorMaker(
	EXTENDED_SERVICES.INSURANCE,
	INSURANCE_SUBTYPES.INABILITY_TO_TRAVEL
)
export const inabilityToTravelInsFaresFromOfferSelector =
	makeInabilityToTravelInsFaresSelector(offerByIdSelector, getFaresFromOffer)
export const inabilityToTravelInsFaresFromProductSelector =
	makeInabilityToTravelInsFaresSelector(productByIdSelector, getFaresFromProduct)

// insureme: anti theft
const makeAntiTheftInsFaresSelector = createFaresSelectorMaker(
	EXTENDED_SERVICES.INSURANCE,
	INSURANCE_SUBTYPES.CARD_THEFT
)
export const antiTheftInsFaresFromOfferSelector =
	makeAntiTheftInsFaresSelector(offerByIdSelector, getFaresFromOffer)

// insureme: anti theft
const makeTicketRefundInsFaresSelector = createFaresSelectorMaker(
	EXTENDED_SERVICES.INSURANCE,
	INSURANCE_SUBTYPES.TICKET_REFUND
)
export const ticketRefundInsFaresFromOfferSelector =
	makeTicketRefundInsFaresSelector(offerByIdSelector, getFaresFromOffer)

// insureme: anti covid
const makeNcovNspInsFaresSelector = createFaresSelectorMaker(
	EXTENDED_SERVICES.INSURANCE,
	INSURANCE_SUBTYPES.NCOV_NSP
)
export const ncovNspInsFaresFromOfferSelector =
	makeNcovNspInsFaresSelector(offerByIdSelector, getFaresFromOffer)

const makeNcovReturnInsFaresSelector = createFaresSelectorMaker(
	EXTENDED_SERVICES.INSURANCE,
	INSURANCE_SUBTYPES.NCOV_RETURN
)
export const ncovReturnInsFaresFromOfferSelector =
	makeNcovReturnInsFaresSelector(offerByIdSelector, getFaresFromOffer)

const makeNcovAccidentBudgetInsFaresSelector = createFaresSelectorMaker(
	EXTENDED_SERVICES.INSURANCE,
	INSURANCE_SUBTYPES.NCOV_ACCIDENT_BUDGET
)
export const ncovAccidentBudgetInsFaresFromOfferSelector =
	makeNcovAccidentBudgetInsFaresSelector(offerByIdSelector, getFaresFromOffer)

const makeNcovAccidentLuxuryInsFaresSelector = createFaresSelectorMaker(
	EXTENDED_SERVICES.INSURANCE,
	INSURANCE_SUBTYPES.NCOV_ACCIDENT_LUXURY
)
export const ncovAccidentLuxuryInsFaresFromOfferSelector =
	makeNcovAccidentLuxuryInsFaresSelector(offerByIdSelector, getFaresFromOffer)
