import store from 'store'
import request from 'superagent'
import confy from '@utils/confy'
import getResponseHandler from './responseHandler.js'
import { OPERATION_STATUSES } from './statuses.js'


const ERROR_STATUSES = [
	OPERATION_STATUSES.INVALID,
	OPERATION_STATUSES.UNAVAIL,
	OPERATION_STATUSES.VALIDATION_ERROR,
	OPERATION_STATUSES.PRICE_CHANGED,
	OPERATION_STATUSES.INVALID_CARD_DATA,
	OPERATION_STATUSES.INSUFFICIENT_FUNDS,
	OPERATION_STATUSES.PAYMENT_3DS_FAILED,
	OPERATION_STATUSES.PAYMENT_REJECTED,
	OPERATION_STATUSES.PAYMENT_ERROR,
	OPERATION_STATUSES.ERROR,
	OPERATION_STATUSES.PAYMENT_3DS_REQUIRED,
	OPERATION_STATUSES.PAYMENT_3DS_V2_REQUIRED,
]
const OK_STATUSES = [
	OPERATION_STATUSES.BOOKED,
	OPERATION_STATUSES.BOOKING,
]

export default function ({ orderId, bookingData, offerId, isConfirm, timeoutMs = 0 }) {
	const url = confy.get('API.cl.book').replace(':orderId', orderId)
	let returnUrl = confy.get('API.cl.payReturnUrl')
		.replace(':offerId', offerId)
		.replace(':orderId', orderId)
	const token = store.get(`ORDERS_TOKEN/${orderId}`)
	if (token) {
		returnUrl = `${returnUrl}&order_token=${token}`
	}

	const data = isConfirm
		? {}
		: { ...bookingData, 'return_url': returnUrl }

	const req = request
		.post(url)
		.timeout(timeoutMs)
		.send(data)

	const executor = (resolve, reject) => {
		const handler = getResponseHandler({
			resolve,
			reject,
			omitData: true,
			okStatuses: OK_STATUSES,
			errStatuses: ERROR_STATUSES,
			requestType: 'POST /book',
		})

		req.end((err, res) => {
			try {
				window.Raven && window.Raven.captureException(new Error('POST /book'), { extra: { 
					err: err,
					status: res.status,
					response: res.text,
					email: res.req._data.email,
					url: res.req.url,
				} })
			}
			catch (e) {
				window.Raven && window.Raven.captureException(e, { extra: { 
					email: (req._data || {}).email,
					url: req.url,
				} })
			}
			handler(err, res)
		})
	}

	return {
		promise: new Promise(executor),
		cancelFn: () => req.abort(),
	}
}
