import {
	LOGIN_STATUS,
	RECOVER_PASSWORD_STATUS,
	RESET_PASSWORD_STATUS,
} from '@libs/account-data-fetcher'
import { FEEDBACK_SEND_STATUS } from '@libs/support-fetcher'
import {
	ACCOUNT_DATA_STATUS,
	OPERATION_STATUSES,
	REDIRECT_TO_CABINET_STATUS,
	EXTENDED_SERVICES,
	EXTENDED_SERVICES_SUBTYPE,
} from '@libs/foma/types'
import {
	SEND_FEEDBACK,
	SET_SEND_FEEDBACK_STATUS,
	LOAD_ACCOUNT_DATA,
	SET_ACCOUNT_DATA_STATUS,
	LOGIN,
	SET_LOGIN_STATUS,
	RECOVER_PASSWORD,
	SET_RECOVER_PASSWORD_STATUS,
	RESET_PASSWORD,
	SET_RESET_PASSWORD_STATUS,
	SET_OFFER_OP_STATUS,
	RECONFIGURE_OFFER,
	SET_OFFER_RECONFIGURE_STATUS,
	CHANGE_CONSIST,
	SET_CHANGE_CONSIST_STATUS,
	SET_REDIRECT_TO_CABINET_STATUS,
	SET_BOOK_STATUS,
	BOOK,
} from '@store/state/types'


const initialState = {
	sendFeedbackStatus: FEEDBACK_SEND_STATUS.INITIAL,
	accountDataStatus: ACCOUNT_DATA_STATUS.INITIAL,
	loginStatus: LOGIN_STATUS.INITIAL,
	recoverPasswordStatus: RECOVER_PASSWORD_STATUS.INITIAL,
	resetPasswordStatus: RESET_PASSWORD_STATUS.INITIAL,
	bookStatus: OPERATION_STATUSES.NONE,
	offerOpStatus: OPERATION_STATUSES.NONE,
	offerReconfigureStatuses: {
		[EXTENDED_SERVICES.CHECK_IN]: OPERATION_STATUSES.NONE,
		[EXTENDED_SERVICES_SUBTYPE.SMS.INFO]: OPERATION_STATUSES.NONE,
		[EXTENDED_SERVICES_SUBTYPE.SMS.ARRIVAL]: OPERATION_STATUSES.NONE,
		[EXTENDED_SERVICES_SUBTYPE.INSURANCE.PACKAGE_CUSTOM]: OPERATION_STATUSES.NONE,
		[EXTENDED_SERVICES_SUBTYPE.INSURANCE.TRAVEL]: OPERATION_STATUSES.NONE,
		[EXTENDED_SERVICES_SUBTYPE.INSURANCE.INABILITY_TO_TRAVEL]: OPERATION_STATUSES.NONE,
		[EXTENDED_SERVICES_SUBTYPE.INSURANCE.CARD_THEFT]: OPERATION_STATUSES.NONE,
		[EXTENDED_SERVICES_SUBTYPE.INSURANCE.TICKET_REFUND]: OPERATION_STATUSES.NONE,
		[EXTENDED_SERVICES_SUBTYPE.INSURANCE.NCOV_NSP]: OPERATION_STATUSES.NONE,
		[EXTENDED_SERVICES_SUBTYPE.INSURANCE.NCOV_RETURN]: OPERATION_STATUSES.NONE,
		[EXTENDED_SERVICES_SUBTYPE.INSURANCE.NCOV_ACCIDENT_BUDGET]: OPERATION_STATUSES.NONE,
		[EXTENDED_SERVICES_SUBTYPE.INSURANCE.NCOV_ACCIDENT_LUXURY]: OPERATION_STATUSES.NONE,
		[EXTENDED_SERVICES.TRANSFER_VARIANTS]: OPERATION_STATUSES.NONE,
	},
	changeConsistStatus: OPERATION_STATUSES.NONE,
	redirectToCabinetStatus: REDIRECT_TO_CABINET_STATUS.NONE,
}

export default function reducer (state = initialState, { type, payload }) {
	switch (type) {
		case SEND_FEEDBACK: {
			return { ...state, sendFeedbackStatus: FEEDBACK_SEND_STATUS.LOADING }
		}
		case SET_BOOK_STATUS: {
			return { ...state, bookStatus: payload }
		}
		case BOOK: {
			return { ...state, bookStatus: OPERATION_STATUSES.TRIED_TO_BOOK }
		}
		case SET_SEND_FEEDBACK_STATUS: {
			return { ...state, sendFeedbackStatus: payload }
		}
		case LOAD_ACCOUNT_DATA: {
			return { ...state, accountDataStatus: ACCOUNT_DATA_STATUS.LOADING }
		}
		case SET_ACCOUNT_DATA_STATUS: {
			return { ...state, accountDataStatus: payload }
		}
		case LOGIN: {
			return { ...state, loginStatus: LOGIN_STATUS.LOADING }
		}
		case SET_LOGIN_STATUS: {
			return { ...state, loginStatus: payload }
		}
		case RECOVER_PASSWORD: {
			return { ...state, recoverPasswordStatus: RECOVER_PASSWORD_STATUS.LOADING }
		}
		case SET_RECOVER_PASSWORD_STATUS: {
			return { ...state, recoverPasswordStatus: payload }
		}
		case RESET_PASSWORD: {
			return { ...state, resetPasswordStatus: RESET_PASSWORD_STATUS.LOADING }
		}
		case SET_RESET_PASSWORD_STATUS: {
			return { ...state, resetPasswordStatus: payload }
		}
		case SET_OFFER_OP_STATUS: {
			return { ...state, offerOpStatus: payload }
		}
		case RECONFIGURE_OFFER: {
			return {
				...state,
				offerReconfigureStatuses: {
					...state.offerReconfigureStatuses,
					[payload.serviceType]: OPERATION_STATUSES.LOADING,
				},
			}
		}
		case SET_OFFER_RECONFIGURE_STATUS: {
			const { serviceType, status } = payload
			return {
				...state,
				offerReconfigureStatuses: {
					...state.offerReconfigureStatuses,
					[serviceType]: status,
				},
			}
		}
		case CHANGE_CONSIST: {
			return { ...state, changeConsistStatus: OPERATION_STATUSES.LOADING }
		}
		case SET_CHANGE_CONSIST_STATUS: {
			return { ...state, changeConsistStatus: payload }
		}
		case SET_REDIRECT_TO_CABINET_STATUS: {
			return { ...state, redirectToCabinetStatus: payload }
		}
		default: return state
	}
}
