import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { routerDataSelector } from '@store/state/appState/selectors'

import BackBtn from '@atoms/BackBtn'
import OverlayWrapper from '@molecules/OverlayWrapper'

import $ from './styles/index.js'


const OverlayPage = ({ cq, ...props }) => {
	const { mobile } = cq

	// Properties
	const { children, onCloseClick, prevLocation } = props
	let width = 1140
	const clonedChildren = React.Children.map(children, (child) => {
		width = child.props.width // eslint-disable-line prefer-destructuring
		return React.cloneElement(child, { ...cq })
	})

	if (mobile) {
		return (
			<div className={$.container}>
				<BackBtn to={prevLocation}/>
				{clonedChildren}
			</div>
		)
	}
	else {
		return (
			<OverlayWrapper width={width} onClose={onCloseClick}>
				{clonedChildren}
			</OverlayWrapper>
		)
	}
}

const mapStateToProps = (state) => {
	const { prevLocation } = routerDataSelector(state)
	return {
		prevLocation,
	}
}
const mapDispatchToProps = (dispatch) => ({
	push: (...args) => dispatch(push(...args)),
})
const mergeProps = ({ prevLocation, ...stateProps }, { push }, ownProps) => ({
	...stateProps,
	...ownProps,
	prevLocation,
	onCloseClick: () => push(prevLocation),
})
const connector = connect(mapStateToProps, mapDispatchToProps, mergeProps)

export default connector(OverlayPage)
