import { intersection } from 'lodash'

import { getCitizenshipCode } from '@utils/citizenship'
import {
	outboundFlightFromOfferSelector,
	inboundFlightFromOfferSelector,
} from '@store/state/domainData/selectors'
import { byCurrentOfferId } from '@store/state/appState/selectors'
import { passengerSelectors } from '@store/state/uiState/forms/selectors'


const { paxKeysListSelector, citizenshipSelector } = passengerSelectors

const THAI_CITIZENSHIP_CODE = 'THA'
const THAI_FLIGHT_RE = /^\d{4}$/
const BANNED_THAI_AIRPORTS = [ 'BKK', 'DMK', 'HKT' ]

const isThaiFlight = ({ flight, arrive, depart }) => (
	THAI_FLIGHT_RE.test(flight)
		&& intersection([ arrive.airport, depart.airport ], BANNED_THAI_AIRPORTS).length > 0
)


export const citizenshipListSelector = (state) => (
	paxKeysListSelector(state).map((formIndex) =>
		getCitizenshipCode(citizenshipSelector(state, formIndex))
	)
)

export const isInvalidThaiTravelResult = (outboundFlight, inboundFlight, citizenshipList) => {
	const thaiFlight = [
		...outboundFlight,
		...inboundFlight,
	].find(isThaiFlight)

	const thaiPassenger = citizenshipList.find((citizenshipCode) => (citizenshipCode === THAI_CITIZENSHIP_CODE))

	return Boolean(thaiFlight && thaiPassenger)
}

const isInvalidThaiTravel = (state) => {
	const outboundFlight = byCurrentOfferId(outboundFlightFromOfferSelector)(state)
	const inboundFlight = byCurrentOfferId(inboundFlightFromOfferSelector)(state)
	const citizenshipList = citizenshipListSelector(state)

	return isInvalidThaiTravelResult(outboundFlight, inboundFlight, citizenshipList)
}

export default isInvalidThaiTravel
