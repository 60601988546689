import { LOAD_TYPE } from '@libs/foma/types'

import handleLoadOffer from './handleLoadOffer.js'
import handleReconfigureOffer from './handleReconfigureOffer.js'
import handleRebuldOffer from './handleRebuildOffer.js'
import handleBook from './handleBook.js'
import handleGenerateSbpPayment from './handleGenerateSbpPayment.js'
import handleGenerateTinkoffPayment from './handleGenerateTinkoffPayment.js'
import handleCheckOffer from './handleCheckOffer.js'
import handleAccountData from './handleAccountData'
import handleGetOrder from './handleGetOrder.js'
import handleHotelExt from './handleHotelExt.js'
import handleSearch from './handleSearch.js'
import {
	handleGetTopPrices,
	handleGetPricesByMonth,
	handleGetPricesByDay,
} from './handlePrices.js'


const _loadingHandlersByType = {
	[LOAD_TYPE.OFFER]: handleLoadOffer,
	[LOAD_TYPE.RECONFIGURE_OFFER]: handleReconfigureOffer,
	[LOAD_TYPE.REBUILD_OFFER]: handleRebuldOffer,
	[LOAD_TYPE.BOOK]: handleBook,
	[LOAD_TYPE.CHECK_OFFER]: handleCheckOffer,
	[LOAD_TYPE.ACCOUNT_DATA]: handleAccountData,
	[LOAD_TYPE.HOTEL_EXT]: handleHotelExt,
	[LOAD_TYPE.SEARCH]: handleSearch,
	[LOAD_TYPE.ORDER]: handleGetOrder,
	[LOAD_TYPE.GENERATE_SBP_PAYMENT]: handleGenerateSbpPayment,
	[LOAD_TYPE.GENERATE_TINKOFF_PAYMENT]: handleGenerateTinkoffPayment,
}
const _gettingHandlersByType = {
	[LOAD_TYPE.TOP_PRICES]: handleGetTopPrices,
	[LOAD_TYPE.PRICES_BY_MONTH]: handleGetPricesByMonth,
	[LOAD_TYPE.PRICES_BY_DAY]: handleGetPricesByDay,
}

const _warnWrongType = (loadType) => (_, meta) => {
	// eslint-disable-next-line no-console
	console.warn(`Called loadData with non-existing type: "${loadType}". This is a no-op. Meta:`, meta)
}

export const getHandlerByType = (type) => (_loadingHandlersByType[type] || _warnWrongType(type))
export const getGettingHandlerByType = (type) => (_gettingHandlersByType[type] || _warnWrongType(type))
