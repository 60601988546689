import { delay, call, put } from 'redux-saga/effects'
import feofan from '@libs/feofan'
import info from '@utils/info'

import { makeCancellable, withError } from '../utils'


export const cancellableGetAccountData = makeCancellable(feofan.getAccountData)
export const MAX_ATTEMPTS = 12
export const DELAY_TIME_MS = 5000
export const validateData = ({ data }, { orderSupportId }) => (
	!orderSupportId || Boolean((data.orders || []).find(({ support_id: id }) => id === orderSupportId))
)
export function* attemptGetAccountData (meta) {
	let attempts = 0
	let data = null
	let isDataValid = false
	while (attempts < MAX_ATTEMPTS && !isDataValid) {
		attempts++
		data = yield call(cancellableGetAccountData, meta)
		isDataValid = yield call(validateData, data, meta)
		if (!isDataValid && attempts < MAX_ATTEMPTS) yield delay(DELAY_TIME_MS)
	}
	if (!isDataValid) throw new feofan.OfferError('invalid account data', { status: 'ERROR' })
	return data
}

export default function* handleAccountData (evtChannel, meta) {
	try {
		const resData = yield call(attemptGetAccountData, meta)
		yield put(evtChannel, resData)

		return resData.status
	}
	catch (error) {
		yield put(evtChannel, withError(error.data))
		info('can\'t get account data', error)
		return error.data.status || error.data.err.statusCode
	}
}
