export const getNextItemSetter = (props) => (current) => {
	const {
		initial,
		comparator,
		getComparableEdge,
		getSettableEdge,
		transition,
		onNext,
	} = props
	if (current === null) {
		onNext(initial)
		return
	}
	const comparableEdge = getComparableEdge(current)
	const settableEdge = getSettableEdge(current)
	const next = comparator(current, comparableEdge) ? settableEdge : transition(current)
	onNext(next)
}

