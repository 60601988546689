import { get } from 'lodash'
import { Suspense, lazy } from 'react'
import { Route, Redirect, Switch, matchPath } from 'react-router-dom'
import { connect } from 'react-redux'

import { isPseudo } from '@utils/common/offerId'
import { getQueryFromString } from '@utils/common/searchQuery.js'
import { createEnhancer } from '@utils/decoract'
import { makeCX } from '@utils/taffy'
import { PATHS, toIndex } from '@utils/router'
import { OPERATION_STATUSES } from '@libs/foma/types'
import TABS from '@utils/serp-tabs'

import SearchBar from 'app-components/SearchBar'
import Header from '@organisms/Header'
import Footer from '@atoms/CaFooter'
import OverlayPage from '@ecosystems/OverlayPage'
import PageLoader from 'app-components/PageLoader'

import { checkBookSelector } from '@store/state/selectors'
import {
	routerDataSelector,
	currentSearchQuerySelector,
	currentOfferIdSelector,
	offerOpStatusSelector,
	locationQuerySelector,
	citiesSelector,
} from '@store/state/appState/selectors'

import 'normalize.css'
import './styles/index.css'
import $ from './styles/index.js'
import qs from 'qs'


const CaIndexPageAlt = lazy(() => import('@ecosystems/CaIndexPageAlt'))
const CaHistory = lazy(() => import('@ecosystems/CaHistory'))
const PersonalAccount = lazy(() => import('@ecosystems/PersonalAccount'))
const CaFaq = lazy(() => import('@organisms/CaFaq'))
const CaAboutCompany = lazy(() => import('@organisms/CaAboutCompany'))
const Shopfront = lazy(() => import('@ecosystems/Shopfront'))
const Advantages = lazy(() => import('@atoms/Advantages'))
const ContactsInfo = lazy(() => import('@molecules/ContactsInfo'))
const LoginForm = lazy(() => import('@organisms/LoginForm'))
const LoginRecoveryForm = lazy(() => import('@organisms/LoginRecoveryForm'))
const PassResetForm = lazy(() => import('@organisms/PassResetForm'))
const PartnerRedirect = lazy(() => import('@molecules/PartnerRedirect'))

const cx = makeCX()
const cq = {
	mobile: {
		maxWidth: 767,
	},
	tablet: {
		minWidth: 768,
		maxWidth: 1139,
	},
	desktop: {
		minWidth: 1140,
	},
}

const enhancer = createEnhancer({ cq })

const getIfLocation = (location, path, component, props) => (
	matchPath(location.pathname, { path, exact: true })
	&& React.createElement(component, props)
)

const Clickavia = ({ cq, ...props }) => {
	const { mobile } = cq
	const {
		location,
		prevLocation,
		isBookSuccess,
		hasRouteData,
		isCheckoutLoading,
		isCheckoutLoadFailed,
		isBookByRedirect,
	} = props

	// like the Switch
	const overlaidChildren = (
		getIfLocation(location, PATHS.ADVANTAGES, Advantages, { width: 1140 }) ||
		getIfLocation(location, PATHS.CONTACTS, ContactsInfo, { width: 1140 }) ||
		getIfLocation(location, PATHS.LOGIN, LoginForm, { width: 700 }) ||
		getIfLocation(location, PATHS.PASSRESET, PassResetForm, { width: 550 }) ||
		getIfLocation(location, PATHS.PASSRECOVERY, LoginRecoveryForm, { width: 700 }) ||
		null
	)

	const mainLocation = overlaidChildren ? prevLocation : location
	const isMainPageCheckout = Boolean(matchPath(mainLocation.pathname, { path: PATHS.CHECKOUT, exact: true }))

	const { search: mainPageSearch = '?' } = mainLocation
	const mainPageQuery = qs.parse(mainPageSearch.slice(1)) // cut the start '?' char

	const currentTab = isMainPageCheckout
		? TABS.CART
		: mainPageQuery.tab

	const propsShopfront = {
		tab: currentTab,
		hideTitles: isMainPageCheckout && isBookSuccess,
	}

	const mainPage = !(mobile && overlaidChildren) && (
		<Switch location={mainLocation}>
			<Route exact path={PATHS.SERP} render={() => (
				<Suspense fallback={<PageLoader/>}>
					<Shopfront {...propsShopfront}/>
				</Suspense>
			)}/>
			<Route exact path={PATHS.METRO_SERP} render={() => (
				<Suspense fallback={<PageLoader/>}>
					<Shopfront {...propsShopfront}/>
				</Suspense>
			)}/>
			<Route exact path={PATHS.CHECKOUT} render={() => (
				<Suspense fallback={<PageLoader/>}>
					<Shopfront {...propsShopfront}/>
				</Suspense>
			)}/>
			<Route exact path={PATHS.HISTORY} render={() => (
				<Suspense fallback={<PageLoader/>}>
					<CaHistory/>
				</Suspense>
			)}/>
			<Route path={PATHS.CABINET} render={() => (
				<Suspense fallback={<PageLoader/>}>
					<PersonalAccount {...mainPageQuery}/>
				</Suspense>
			)}/>
			<Route exact path={PATHS.ABOUT} render={() => (
				<Suspense fallback={<PageLoader/>}>
					<CaAboutCompany/>
				</Suspense>
			)}/>
			<Route exact path={PATHS.FAQ} render={() => (
				<Suspense fallback={<PageLoader/>}>
					<CaFaq/>
				</Suspense>
			)}/>
			<Route exact path={overlaidChildren ? null : PATHS.INDEX} render={() => (
				<Suspense fallback={<PageLoader/>}>
					<CaIndexPageAlt><SearchBar/></CaIndexPageAlt>
				</Suspense>
			)}/>
			{!overlaidChildren && <Redirect to={toIndex()}/>}
		</Switch>
	)

	const propsOverlaidPage = {
		cq,
	}

	const overlaidPage = overlaidChildren && (
		<Suspense fallback={<PageLoader isOverlayed/>}>
			<OverlayPage {...propsOverlaidPage}>
				{overlaidChildren}
			</OverlayPage>
		</Suspense>
	)
	const body = (
		<div className={$.content}>
			{mainPage}
			{overlaidPage}
		</div>
	)

	const containerClass = cx({
		[$.container]: cq.desktop,
		[$.containerMd]: cq.tablet,
		[$.containerSm]: cq.mobile,
	})

	// if is not indexPage
	const isSerp = Boolean(matchPath(mainLocation.pathname, {
		path: PATHS.SERP,
		exact: true,
	}))
	const isMetroSerp = Boolean(matchPath(mainLocation.pathname, {
		path: PATHS.METRO_SERP,
		exact: true,
	}))
	const isCabinet = Boolean(matchPath(mainLocation.pathname, {
		path: PATHS.CABINET,
	}))
	const isMainPage = Boolean(matchPath(mainLocation.pathname, {
		path: PATHS.INDEX,
		exact: true,
	}))
	const showSearchBar = (cq.desktop && (isSerp || isMetroSerp || isCabinet))
		|| (cq.tablet && isCabinet)
	const isSearchShown = hasRouteData && !isMainPage && !(cq.mobile && isCabinet)

	const propsHeader = {
		isSearchShown,
		showSearchBar,
		isSearchEditable: !isMainPageCheckout || (!isCheckoutLoading && !isBookSuccess),
		isCartShown: false,
		isTabsShown: isMainPageCheckout && !isCheckoutLoading && !isCheckoutLoadFailed,
	}
	const header = (
		<div className={$.headerWrapper}>
			<Header {...propsHeader}/>
		</div>
	)

	const content = (
		<React.Fragment>
			{header}
			{body}
			<Footer/>
		</React.Fragment>
	)

	const redirectPage = (
		<Suspense fallback={<PageLoader/>}>
			<PartnerRedirect/>
		</Suspense>
	)

	return (
		<div className={containerClass}>
			{isBookByRedirect ? redirectPage : content}
		</div>
	)
}

const mapStateToProps = (state) => {
	const { prevLocation, location } = routerDataSelector(state)
	const checkBook = checkBookSelector(state)
	const offerOpStatus = offerOpStatusSelector(state)
	const isCheckoutLoading = offerOpStatus === OPERATION_STATUSES.NONE
		|| offerOpStatus === OPERATION_STATUSES.LOADING

	const offerId = currentOfferIdSelector(state)
	const isPseudoOffer = isPseudo(offerId)
	const isCreateOfferFail = isPseudoOffer && offerOpStatus === OPERATION_STATUSES.UNAVAIL
	const isCheckoutLoadFailed = !isCheckoutLoading
		&& offerOpStatus !== OPERATION_STATUSES.AVAIL
		&& !isCreateOfferFail
	const isBookByRedirect = locationQuerySelector(state)['book_by_redirect']

	const cities = citiesSelector(state)
	const currentQuery = currentSearchQuerySelector(state)
	const query = currentQuery && getQueryFromString(currentQuery)
	const src = query && get(cities, `${query.src}.morpho.ru.nom`, '')
	const dst = query && get(cities, `${query.dst}.morpho.ru.nom`, '')

	return {
		prevLocation,
		location,
		isBookSuccess: checkBook.isSuccess,
		hasRouteData: Boolean(src && dst),
		isCheckoutLoading,
		isCheckoutLoadFailed,
		isBookByRedirect,
	}
}

export default connect(mapStateToProps)(enhancer(Clickavia))
