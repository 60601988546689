import {
	FOCUS,
	HANDLE_FOCUS_EVT,
	HANDLE_BLUR_EVT,
} from '@store/state/types'


export const focusElement = (elemKey) => ({
	type: FOCUS,
	payload: elemKey,
})
export const clearFocus = () => ({
	type: FOCUS,
	payload: null,
})
export const handleFocusEvent = (event) => ({
	type: HANDLE_FOCUS_EVT,
	payload: event,
})
export const handleBlurEvent = (event) => ({
	type: HANDLE_BLUR_EVT,
	payload: event,
})


