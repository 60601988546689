import { get } from 'lodash'
import { call, take, put, cancelled, select } from 'redux-saga/effects'
import { eventChannel, END } from 'redux-saga'

import { channelSelector } from '@store/state/appState/selectors'

import feofan from '@libs/feofan'
import { OPERATION_STATUSES, EXTENDED_SERVICES } from '@libs/foma/types'

import { withError } from '../utils'


export const createSocketChannel = (optsSearch) => eventChannel((emit) => {
	const { promise, cancelFn } = feofan.search(optsSearch, emit)
	promise
		.catch((error) => emit(error))
		.then(() => emit(END))

	return cancelFn
})

export const DEFAULT_SERVICE_TYPES = [ EXTENDED_SERVICES.ACCOMMODATION, EXTENDED_SERVICES.TRANSFER ]

export default function* handleSearch (evtChannel, meta) {
	let opStatus = OPERATION_STATUSES.SUCCESS

	const searchChannel = yield select(channelSelector)
	const optsSearch = {
		...meta,
		serviceTypes: meta.serviceTypes || DEFAULT_SERVICE_TYPES,
		channel: searchChannel,
	}

	const socketChannel = yield call(createSocketChannel, optsSearch)

	try {
		while (true) {
			const payload = yield take(socketChannel)
			yield put(evtChannel, payload)
		}
	}
	catch (error) {
		yield put(evtChannel, withError(error.data))
		opStatus = get(error, 'data.status', OPERATION_STATUSES.ERROR)
	}
	finally {
		if (yield cancelled()) {
			opStatus = OPERATION_STATUSES.CANCELLED
			yield call(socketChannel.close)
		}

		return opStatus
	}
}
