import { get } from 'lodash'
import { call, put, select } from 'redux-saga/effects'
import feofan from '@libs/feofan'
import info from '@utils/info'
import { byCurrentOfferId, channelSelector } from '@store/state/appState/selectors'
import { offerByIdSelector } from '@store/state/domainData/selectors'
import { setOfferOpStatus } from '@store/state/appState/actions'
import { OPERATION_STATUSES } from '@libs/foma/types'

import { makeCancellable, withError, getFrontUrl } from '../utils'


export const cancellableCheckOffer = makeCancellable(feofan.checkOffer)

export default function* handleCheckOffer (evtChannel, meta) {

	const frontUrl = yield call(getFrontUrl)
	const channel = yield select(channelSelector)

	const { offerId } = meta
	const optsCheckOffer = {
		id: offerId,
		frontUrl,
		channel,
	}

	try {
		const resData = yield call(cancellableCheckOffer, optsCheckOffer)
		const offer = yield select(byCurrentOfferId(offerByIdSelector))
		const offerPrice = offer.price
		const checkedOfferPrice = resData.data.offers[0].price
		yield put(evtChannel, resData)
		if (checkedOfferPrice !== offerPrice) {
			yield put(setOfferOpStatus(OPERATION_STATUSES.CHECK_PRICE_CHANGED))
		}

		return resData.status
	}
	catch (err) {
		info('cant check offer', err)
		const status = get(err, 'data.status', OPERATION_STATUSES.ERROR)
		yield put(evtChannel, withError({ ...err.data, status }))

		return status
	}
}
