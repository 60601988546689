import { select, call, all } from 'redux-saga/effects'
import loadData from '@store/sagas/apiService'
import { LOAD_TYPE } from '@libs/foma/types'
import {
	currentOfferIdSelector,
	byCurrentOfferId,
	byCurrentSearchQuery,
} from '@store/state/appState/selectors'

import getOnlyFlightEnabledOffer from './helpers/getOnlyFlightEnabledOffer.js'
import isOnlyFlightEnabledOfferSelector from './selectors/isOnlyFlightEnabledOffer.js'
import offerIdByConsistSelector from './selectors/offerIdByConsist.js'


export const enhancedIsOnlyFlightSelector = byCurrentOfferId(isOnlyFlightEnabledOfferSelector)
export const enhancedOfferIdByConsistSelector = byCurrentSearchQuery(offerIdByConsistSelector)


export default function* handlePreloadConsist ({ payload }) {
	const { consist: newConsist } = payload

	const { currentOfferId, isOnlyFlightEnabledOffer } = yield all({
		currentOfferId: select(currentOfferIdSelector),
		isOnlyFlightEnabledOffer: select(enhancedIsOnlyFlightSelector),
	})

	const offerId = isOnlyFlightEnabledOffer
		? currentOfferId
		: yield call(getOnlyFlightEnabledOffer, currentOfferId)

	const newOfferId = yield select(enhancedOfferIdByConsistSelector, offerId, newConsist)

	if (newOfferId) return // we already have offer with new consist, do nothing

	const loadOptions = {
		type: LOAD_TYPE.REBUILD_OFFER,
		updateSearchQuery: true,
		withoutChangingCurrentOffer: true,
		meta: { consist: newConsist, offerId },
	}
	yield call(loadData, loadOptions)
}
