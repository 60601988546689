import { values } from 'lodash'
import { createSelector } from 'reselect'
import {
	getFares,
	getServices,
	offerByIdSelector,
	productByIdSelector,
} from './core.js'
import {
	getFaresFromOffer,
	getFaresFromProduct,
	getServiceFromFare,
} from './itemsRelation.js'


export const hasCharterService = (services) => services.some(({ subtype }) => {
	const [ _, ticketType ] = subtype.split(':')
	return ticketType === 'charter'
})
export const hasHubProduct = (products = []) => products.some(([ type ]) => (
	type.includes('hub')
))
export const ticketsTypeResult = (services, products) => {
	if (hasCharterService(services)) return 'charter'
	if (hasHubProduct(products)) return 'hub'
	return 'regular'
}

const makeTicketsTypeSelector = (rootItemGetter, faresGetter) => {
	const faresSelector = createSelector(
		[ getFares, rootItemGetter ],
		(fares, rootItem) => values(faresGetter(fares, rootItem))
	)
	const servicesSelector = createSelector(
		[ getServices, faresSelector ],
		(services, fares) => fares.map((fare) => getServiceFromFare(services, fare))
	)
	const productsSelector = createSelector(
		rootItemGetter,
		(rootItem) => (rootItem.products || [ rootItem ])
	)
	return createSelector(
		[ servicesSelector, productsSelector ],
		ticketsTypeResult
	)
}

export const ticketsTypeFromOfferSelector =
	makeTicketsTypeSelector(offerByIdSelector, getFaresFromOffer)
export const ticketsTypeFromProductSelector =
	makeTicketsTypeSelector(productByIdSelector, getFaresFromProduct)
