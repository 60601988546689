/* OfferError
 * Custom error extends Error for sending data with message
 */

class OfferError extends Error {
	constructor (msg, data) {
		super(msg)
		this.data = data
	}
}

export default OfferError
