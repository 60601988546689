import { mapValues } from 'lodash'
import { stringify as stringifyOffer } from '@utils/common/offerId'
import {
	getFares,
	faresTotalPriceFromFareIdsSelector,
	routeInfoSelector,
} from '@store/state/domainData/selectors'


export const MIN_FARE_COUNT = 0

export const newFaresResult = (fares, products) => {
	const fareIds = products.reduce((acc, [ _, fareIds ]) => ([ ...acc, ...fareIds ]), [])
	const newFares = Object.keys(fares).reduce((acc, fareId) => (
		fareIds.includes(fareId) ? [ ...acc, fares[fareId] ] : acc
	), [])
	return newFares
}

const newFaresSelector = (state, searchQuery, products) => {
	const fares = getFares(state, searchQuery)
	return newFaresResult(fares, products)
}

export const newOfferResult = (enabledCounts, products, price, routeInfo) => {
	const fareIds = products.reduce((acc, [ _, fareIds ]) => ([ ...acc, ...fareIds ]), [])
	const newOfferId = stringifyOffer({ products, enabledCounts, routeInfo })
	const fareCounts = mapValues(enabledCounts, (count) => ([ MIN_FARE_COUNT, count ]))

	const newOffer = {
		agreements: [],
		id: newOfferId,
		'fare_counts': fareCounts,
		products,
		fares: fareIds,
		price,
	}
	return newOffer
}

const newOfferSelector = (state, searchQuery, enabledCounts, products) => {
	const fareIds = products.reduce((acc, [ _, fareIds ]) => ([ ...acc, ...fareIds ]), [])
	const faresTotalPrice = faresTotalPriceFromFareIdsSelector(state, searchQuery, fareIds)
	const routeInfo = routeInfoSelector(state, searchQuery)
	return newOfferResult(enabledCounts, products, faresTotalPrice, routeInfo)
}

export const resWithPseudoOfferResult = (newFares, newOffer) => ({
	data: {
		// fares: newFares, // TODO: need to test, experimental techonolgy
		offers: [ newOffer ],
	},
	meta: {},
})

const resWithPseudoOfferSelector = (state, searchQuery, enabledCounts, products) => {
	const newFares = newFaresSelector(state, searchQuery, products)
	const newOffer = newOfferSelector(state, searchQuery, enabledCounts, products)

	return resWithPseudoOfferResult(newFares, newOffer)
}

export default resWithPseudoOfferSelector
