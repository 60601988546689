import PropTypes from 'prop-types'
import cn from 'classnames'
import ReactDOM from 'react-dom'

import l from '@libs/lang'

import { TooltipTemplate, getPortal } from '@atoms/Tooltip'
import { Raw as RawButton } from 'core-components/Button'

import LocationInput from './locationInput.js'
import Datespicker from './datespicker.js'
import ConsistSelector from './consistSelector.js'

import getBorderRadiusSet from './utils/borderRadiusSet.js'
import styles from './styles'
import icoSearch from './images/icon-main-search.svg'
import icoSwap from './images/icon-swap.svg'


const SRC_LOCATION = 'SRC_LOCATION'
const DST_LOCATION = 'DST_LOCATION'
const OUTBOUND_DATE = 'OUTBOUND_DATE'
const INBOUND_DATE = 'INBOUND_DATE'
const CONSIST = 'CONSIST'
const DATES_HIGHLIGHT_DURATION = 1200

const txtSrcLocation = l('Пункт отправления')
const txtDstLocation = l('Пункт прибытия')
const txtSearch = l('Найти')
const txtTooltip = l('Даты изменены.\nНажмите «Найти»!')

class SearchBar extends React.Component {
	state = {
		focusedField: '',
		shownErrorFields: [],
		isTooltipVisible: false,
	}
	_addShownErrorFields = (fields) => this.setState((state) => ({
		...state,
		shownErrorFields: [ ...state.shownErrorFields, ...fields ],
	}))
	_removeShownErrorFields = (field) => this.setState((state) => ({
		...state,
		shownErrorFields: state.shownErrorFields.filter((errorField) => errorField !== field),
	}))
	_setFocusedField = (focusedField) => {
		this.setState({ focusedField, isTooltipVisible: false })
		this._removeShownErrorFields(focusedField)
	}
	_focusSrcLocation = () => this._setFocusedField(SRC_LOCATION)
	_focusDstLocation = () => this._setFocusedField(DST_LOCATION)
	_focusOutboundDate = () => this._setFocusedField(OUTBOUND_DATE)
	_focusInboundDate = () => this._setFocusedField(INBOUND_DATE)
	_focusConsist = () => this._setFocusedField(CONSIST)
	_blur = (bluredField, shouldShowError) => {
		this._setFocusedField('')
		shouldShowError && this._addShownErrorFields([ bluredField ])
	}
	_blurSrcLocation = () => this._blur(SRC_LOCATION, Boolean(this.props.src))
	_blurDstLocation = () => this._blur(DST_LOCATION, Boolean(this.props.dst))

	_swapLocations = () => {
		const {
			src,
			dst,
			onSrcChange,
			onDstChange,
		} = this.props
		onSrcChange(dst)
		onDstChange(src)
	}

	_renderSwapBtn = () => {
		const propsSwapBtn = {
			classNames: styles.swapBtn,
			onClick: this._swapLocations,
		}
		return (
			<RawButton {...propsSwapBtn}>
				<img src={icoSwap} alt="swapLocations"/>
			</RawButton>
		)
	}

	_renderLocationCaption = (location, suggestions) => {
		const [ _, __, locationId ] = location.split(':')
		const { iata } = suggestions.find(({ id }) => id === parseInt(locationId, 10)) || {}
		return iata && (
			<span className={styles.locationCaption}>{iata}</span>
		)
	}

	_renderSrcLocation = (normalStyle = null, focusedStyle = null) => {
		const {
			src: location,
			dst,
			isSrcValid,
			srcSuggestions: suggestions,
			onSrcChange: setLocation,
		} = this.props
		const { focusedField, shownErrorFields } = this.state
		const hasSuggestions = suggestions.length > 0
		const isFocused = focusedField === SRC_LOCATION
		const propsLocation = {
			style: isFocused && hasSuggestions ? focusedStyle : normalStyle,
			placeholder: txtSrcLocation,
			location,
			isFocused,
			isValid: !shownErrorFields.includes(SRC_LOCATION) || isSrcValid,
			suggestions,
			setLocation,
			onFocus: this._focusSrcLocation,
			onBlur: this._blurSrcLocation,
		}
		const isAnyLocationFocused = isFocused || focusedField === DST_LOCATION
		const isBothLocatioinFilled = Boolean(location && dst)
		const shouldRenderSwapBtn = !isAnyLocationFocused && isBothLocatioinFilled
		return (
			<div className={styles.srcLocation}>
				<LocationInput {...propsLocation}/>
				{shouldRenderSwapBtn && this._renderSwapBtn()}
				{this._renderLocationCaption(location, suggestions)}
			</div>
		)
	}

	_renderDstLocation = (normalStyle = null, focusedStyle = null) => {
		const {
			dst: location,
			isDstValid,
			dstSuggestions: suggestions,
			onDstChange: setLocation,
		} = this.props
		const { focusedField, shownErrorFields } = this.state
		const hasSuggestions = suggestions.length > 0
		const isFocused = focusedField === DST_LOCATION
		const propsLocation = {
			style: isFocused && hasSuggestions ? focusedStyle : normalStyle,
			placeholder: txtDstLocation,
			location,
			isFocused,
			isValid: !shownErrorFields.includes(DST_LOCATION) || isDstValid,
			suggestions,
			setLocation,
			onFocus: this._focusDstLocation,
			onBlur: this._blurDstLocation,
		}
		return (
			<div className={styles.dstLocation}>
				<LocationInput {...propsLocation}/>
				{this._renderLocationCaption(location, suggestions)}
			</div>
		)
	}

	_renderDatespicker = () => {
		const {
			outboundDate: startDate,
			inboundDate: endDate,
			isOutboundDateValid,
			isInboundDateValid,
			onOutboundDateChange: setStartDate,
			onInboundDateChange: setEndDate,
			cq,
			areDatesBlinking,
		} = this.props
		const { focusedField, shownErrorFields } = this.state
		const propsDatespicker = {
			startDate,
			endDate,
			setStartDate,
			setEndDate,
			isStartDateFocused: focusedField === OUTBOUND_DATE,
			isEndDateFocused: focusedField === INBOUND_DATE,
			isStartDateValid: !shownErrorFields.includes(OUTBOUND_DATE) || isOutboundDateValid,
			isEndDateValid: !shownErrorFields.includes(INBOUND_DATE) || isInboundDateValid,
			onStartDateInputFocus: this._focusOutboundDate,
			onEndDateInputFocus: this._focusInboundDate,
			onBlur: this._blur,
			isOverlaidPicker: cq.sm || false,
		}
		const datespickerClass = cn({
			[styles.datespicker]: true,
			[styles.blinkingDates]: areDatesBlinking,
		})
		return (
			<div className={datespickerClass}>
				<Datespicker {...propsDatespicker}/>
			</div>
		)
	}

	_renderConsistSelector = (normalStyle = null, focusedStyle = null) => {
		const {
			adults,
			children,
			infants,
			cq,
		} = this.props
		const isFocused = this.state.focusedField === CONSIST
		const propsConsistSelector = {
			style: isFocused ? focusedStyle : normalStyle,
			adults,
			children,
			infants,
			isOverlaidPicker: cq.sm || false,
			onFocus: this._focusConsist,
			onBlur: this._blur,
		}
		return (
			<div className={styles.consistSelector}>
				<ConsistSelector {...propsConsistSelector}/>
			</div>
		)
	}

	_handleSubmit = () => {
		this._addShownErrorFields([
			SRC_LOCATION,
			DST_LOCATION,
			OUTBOUND_DATE,
			INBOUND_DATE,
		])
		this.setState({ isTooltipVisible: false })
		const { isValid, search } = this.props
		if (isValid) search()
	}

	_renderTooltip = () => {
		const propsTooltip = {
			referenceElement: this._searchBtnRef,
			content: (
				<p className={styles.tooltipText}>{txtTooltip}</p>
			),
			placement: 'bottom',
			isVisible: this.state.isTooltipVisible,
		}
		return ReactDOM.createPortal(
			<TooltipTemplate {...propsTooltip}/>,
			getPortal(this._searchBtnRef)
		)
	}

	_searchBtnRef = null
	_setSearchBtnRef = (ref) => this._searchBtnRef = ReactDOM.findDOMNode(ref)
	_renderSearchBtn = (normalStyle = null, focusedStyle = null) => {
		const { isValid, isSearchButtonEnabled } = this.props
		const propsSearchBtn = {
			setRef: this._setSearchBtnRef,
			style: normalStyle,
			classNames: styles.searchBtn,
			disabled: !isValid || !isSearchButtonEnabled,
			onClick: this._handleSubmit,
		}
		return (
			<RawButton {...propsSearchBtn}>
				<img src={icoSearch} alt="search"/>
				{txtSearch}
			</RawButton>
		)
	}

	componentDidUpdate (prevProps) {
		const { areDatesBlinking, onDatesBlinkingAnimationEnd } = this.props
		if (!prevProps.areDatesBlinking && areDatesBlinking) {
			setTimeout(onDatesBlinkingAnimationEnd, DATES_HIGHLIGHT_DURATION)
			this.setState({ isTooltipVisible: true })
		}
	}

	render () {
		const { cq } = this.props
		const containerClass = cn({
			[styles.containerLg]: cq.lg,
			[styles.containerMd]: cq.md,
			[styles.containerSm]: cq.sm,
		})
		const [ field1, field2, field3, field4, field5 ] = getBorderRadiusSet(cq)
		return (
			<div className={containerClass}>
				{this._renderSrcLocation(...field1)}
				{this._renderDstLocation(...field2)}
				{this._renderDatespicker(...field3)}
				{this._renderConsistSelector(...field4)}
				{this._renderSearchBtn(...field5)}
				{this._renderTooltip()}
			</div>
		)
	}
}

const consistPropTypesShape = {
	value: PropTypes.number.isRequired,
	onValueChange: PropTypes.func.isRequired,
}
SearchBar.propTypes = {
	cq: PropTypes.shape({
		lg: PropTypes.bool,
		md: PropTypes.bool,
		sm: PropTypes.bool,
	}).isRequired,
	src: PropTypes.string.isRequired,
	dst: PropTypes.string.isRequired,
	isSrcValid: PropTypes.bool.isRequired,
	isDstValid: PropTypes.bool.isRequired,
	srcSuggestions: PropTypes.array.isRequired,
	dstSuggestions: PropTypes.array.isRequired,
	outboundDate: PropTypes.string.isRequired,
	inboundDate: PropTypes.string.isRequired,
	isOutboundDateValid: PropTypes.bool.isRequired,
	isInboundDateValid: PropTypes.bool.isRequired,
	adults: PropTypes.shape(consistPropTypesShape).isRequired,
	children: PropTypes.shape(consistPropTypesShape).isRequired,
	infants: PropTypes.shape(consistPropTypesShape).isRequired,
	isSearchButtonEnabled: PropTypes.bool.isRequired,
	isValid: PropTypes.bool.isRequired,
	areDatesBlinking: PropTypes.bool.isRequired,
	onDatesBlinkingAnimationEnd: PropTypes.func.isRequired,
	onSrcChange: PropTypes.func.isRequired,
	onDstChange: PropTypes.func.isRequired,
	onOutboundDateChange: PropTypes.func.isRequired,
	onInboundDateChange: PropTypes.func.isRequired,
	search: PropTypes.func.isRequired,
}

export default SearchBar
