import { flow, pick, pickBy } from 'lodash'
import { createSelector } from 'reselect'
import {
	getFares,
	offerByIdSelector,
	productByIdSelector,
} from './core.js'
import {
	getFaresFromOffer,
	getFaresFromProduct,
} from './itemsRelation.js'


export const faresTotalPriceResult = (fares) => Object.keys(fares).reduce(
	(acc, id) => (acc + fares[id].price.buyer),
	0
)

export const faresTotalPriceFromFareIdsSelector = createSelector(
	[ getFares, (_, __, ids) => ids ],
	flow(pick, faresTotalPriceResult)
)

const ENABLED_COUNT_OFFSET = 1
const MIN_ENABLED_COUNT = 0
const createFaresTotalPriceSelectorMaker = (paymentTypes) => (
	(rootItemGetter, faresGetter) => {
		const faresSelector = createSelector(
			[ getFares, rootItemGetter ],
			faresGetter
		)
		const filteredFaresSelector = createSelector(
			[ faresSelector, rootItemGetter ],
			(fares, rootItem) => {
				const fareCounts = rootItem['fare_counts'] || {}
				return pickBy(
					fares,
					(fare) => {
						const counts = fareCounts[fare.id]
						return (!counts || counts[ENABLED_COUNT_OFFSET] > MIN_ENABLED_COUNT)
							&& paymentTypes.includes(fare['payment_schedule'].type)
					}
				)
			}
		)
		return createSelector(
			filteredFaresSelector,
			faresTotalPriceResult
		)
	}
)

const makeFaresTotalPriceSelector = createFaresTotalPriceSelectorMaker([ 'pre-pay', 'post-pay' ])
export const faresTotalPriceFromOfferSelector =
	makeFaresTotalPriceSelector(offerByIdSelector, getFaresFromOffer)
export const faresTotalPriceFromProductSelector =
	makeFaresTotalPriceSelector(productByIdSelector, getFaresFromProduct)
export const makeFaresTotalPriceFromProductSelector = () => (
	makeFaresTotalPriceSelector(productByIdSelector, getFaresFromProduct)
)

const makePrePayFaresTotalPriceSelector =
	createFaresTotalPriceSelectorMaker([ 'pre-pay' ])
export const prePayFaresTotalPriceFromOfferSelector =
	makePrePayFaresTotalPriceSelector(offerByIdSelector, getFaresFromOffer)
export const prePayFaresTotalPriceFromProductSelector =
	makePrePayFaresTotalPriceSelector(productByIdSelector, getFaresFromProduct)

const makePostPayFaresTotalPriceSelector =
	createFaresTotalPriceSelectorMaker([ 'post-pay' ])
export const postPayFaresTotalPriceFromOfferSelector =
	makePostPayFaresTotalPriceSelector(offerByIdSelector, getFaresFromOffer)
export const postPayFaresTotalPriceFromProductSelector =
	makePostPayFaresTotalPriceSelector(productByIdSelector, getFaresFromProduct)
