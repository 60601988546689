import { createSelector } from 'reselect'
import paymentDetailsSelector from './paymentDetails.js'


const selector = (details) => {
	const total = details.find((item) => (item.type === 'total'))
	const prePaySubtotal = details.find((item) => (item.type === 'prePaySubtotal'))
	const postPaySubtotal = details.find((item) => (item.type === 'postPaySubtotal'))

	const totalPrice = total.price
	const totalTickets = details
		.filter((item) => (item.type === 'flightTicket'))
		.reduce((m, detail) => ((detail.price * detail.amount) + m), 0)
	const totalUpsales = details
		.filter((item) => (item.type !== 'flightTicket' && item.type !== 'total'))
		.reduce((m, detail) => ((detail.price * detail.amount) + m), 0)
	const totalDiff = totalPrice - totalTickets - totalUpsales

	const isOnlyOneTicketFlight = details.filter(({ type }) => (type === 'flightTicket')).length === 1

	return {
		isOnlyOneTicketFlight,
		totalPrice,
		totalDiff,
		prePaySubtotal,
		postPaySubtotal,
	}
}

export default createSelector(
	paymentDetailsSelector,
	selector,
)
