import PropTypes from 'prop-types'
import cn from 'classnames'
import l from '@libs/lang'
import { getDate } from '@utils/time-utils'

import DateRangePicker, { DateUtils } from 'core-components/DateRangePicker'
import { Raw as RawButton } from 'core-components/Button'

import icoClose from './images/icon-close.svg'
import styles from './styles/datespicker.js'


const noOp = () => {}
const txtDeparture = l('Туда')
const txtArrival = l('Обратно')
const txtOneway = l('Обратный билет не нужен')
const getTxtFocusedField = (isStartDateFocused, isEndDateFocused) => l(`{field, select,
	START_DATE {Дата вылета}
	END_DATE {Дата прилета}
	other {}
}`, { field: (isStartDateFocused && 'START_DATE') || (isEndDateFocused && 'END_DATE') || '' })

const getInitialMonth = (start, end, isStartDateFocused, isEndDateFocused) => {
	const dateIso = (isStartDateFocused && start)
		|| (isEndDateFocused && (end || start))
	return dateIso ? getDate(dateIso) : ''
}

class Datespicker extends React.Component {
	state = {
		hoveredDate: '',
	}

	_setHoveredDate = (hoveredDate) => this.setState({ hoveredDate })
	_blur = () => {
		const { onBlur } = this.props
		this._setHoveredDate('')
		onBlur && onBlur()
	}

	_renderClearBtn = (_, clearHandler) => (
		<RawButton classNames={styles.clearBtn} onClick={clearHandler}>
			{txtOneway}
		</RawButton>
	)

	_renderHeader = (closeHandler) => (
		<div className={styles.header}>
			{getTxtFocusedField(this.props.isStartDateFocused, this.props.isEndDateFocused)}
			<img src={icoClose} alt="close" onClick={closeHandler}/>
		</div>
	)

	_selectHandler = (date) => {
		const {
			isStartDateFocused,
			isEndDateFocused,
			setStartDate,
			setEndDate,
			endDate,
		} = this.props
		const onSelect = ((isStartDateFocused && setStartDate)
			|| (isEndDateFocused && setEndDate)
			|| noOp
		)
		onSelect(date)
		// try to reset end date
		if (!isStartDateFocused || !endDate) return
		const start = getDate(date)
		const end = getDate(endDate)
		if (date === endDate || DateUtils.isDayBefore(end, start)) setEndDate('')
	}

	render () {
		const {
			startDate,
			endDate,
			isOverlaidPicker,
			isStartDateFocused,
			isEndDateFocused,
			isStartDateValid,
			isEndDateValid,
			onStartDateInputFocus,
			onEndDateInputFocus,
		} = this.props
		const {
			hoveredDate,
		} = this.state

		const propsDateRangePicker = {
			initialMonth: getInitialMonth(startDate, endDate, isStartDateFocused, isEndDateFocused),
			startDate: (isStartDateFocused && hoveredDate) ? hoveredDate : startDate,
			endDate: (isEndDateFocused && hoveredDate) ? hoveredDate : endDate,
			isStartDateFocused,
			isEndDateFocused,
			startDatePlaceholder: txtDeparture,
			endDatePlaceholder: txtArrival,
			classNames: styles,
			isOverlaidPicker,
			isStartDateValid,
			isEndDateValid,
			onStartDateInputFocus,
			onEndDateInputFocus,
			onDateHover: this._setHoveredDate,
			onSelect: this._selectHandler,
			onBlur: this._blur,
			renderClearButton: isEndDateFocused && this._renderClearBtn,
			renderHeader: isOverlaidPicker && this._renderHeader,
		}

		const containerClass = cn({
			[styles.container]: !isOverlaidPicker,
			[styles.overlaidContainer]: isOverlaidPicker,
		})

		return (
			<div className={containerClass}>
				<DateRangePicker {...propsDateRangePicker}/>
			</div>
		)
	}
}

Datespicker.propTypes = {
	startDate: PropTypes.string.isRequired,
	endDate: PropTypes.string.isRequired,
	setStartDate: PropTypes.func.isRequired,
	setEndDate: PropTypes.func.isRequired,
	isOverlaidPicker: PropTypes.bool.isRequired,
	isStartDateFocused: PropTypes.bool.isRequired,
	isEndDateFocused: PropTypes.bool.isRequired,
	isStartDateValid: PropTypes.bool.isRequired,
	isEndDateValid: PropTypes.bool.isRequired,
	onStartDateInputFocus: PropTypes.func.isRequired,
	onEndDateInputFocus: PropTypes.func.isRequired,
	onBlur: PropTypes.func.isRequired,
}

export default Datespicker
