import store from 'store'
import expirePlugin from 'store/plugins/expire'


// default key for localstorage
const LS_KEY = 'channel'

// default LS duration (7 days)
const DEFAULT_DURATION = 7 * 24 * 60 * 60 * 1000

// expiration is a plugin for store,
store.addPlugin(expirePlugin)

// returns new expiry date
const getNewExpireTime = (duration = DEFAULT_DURATION) => (
	new Date().getTime() + duration
)

// gets channel from localstorage
export const getLs = () => {
	const channel = store.get(LS_KEY)

	return channel || null
}

// sets channel to localstorage
export const setLs = (channel) => {
	store.set(LS_KEY, channel, getNewExpireTime())
}
