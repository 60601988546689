import request from 'superagent'
import confy from '@utils/confy'
import { OPERATION_STATUSES } from './statuses'
import getResponseHandler from './responseHandler'


const OK_STATUSES = [
	OPERATION_STATUSES.NONE,
]
const ERROR_STATUSES = []

export default function () {
	const url = confy.get('API.cl.accountData.url')
	const acceptHeader = confy.get('API.cl.accountData.acceptHeader')

	const req = request
		.get(url)
		.withCredentials()
		.accept(acceptHeader)

	const promise = new Promise((resolve, reject) => {
		const handler = getResponseHandler({
			resolve,
			reject,
			okStatuses: OK_STATUSES,
			errStatuses: ERROR_STATUSES,
			requestType: 'GET /orders',
		})

		req.end(handler)
	})

	return {
		promise,
		cancelFn: () => req.abort(),
	}
}
