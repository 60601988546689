import request from 'superagent'
import confy from '@utils/confy'


const ERROR_MSG = 'Somic endpoints.create should have either { offerId } or { searchParams }'

export const create = ({ offerId, searchParams }) => {

	let data = null

	if (offerId) {
		data = { 'offer_id': offerId }
	}
	else if (searchParams) {
		const { outboundDate, inboundDate, ...rest } = searchParams
		data = {
			...rest,
			'depart_date': outboundDate,
			'return_date': inboundDate || null,
		}
	}
	else {
		throw new Error(ERROR_MSG)
	}

	const url = confy.get('API.sociomantic.product')

	const req = request
		.post(url)
		.set('Content-Type', 'application/json')
		.send(data)
		.withCredentials()

	const executor = (resolve, reject) => {
		const handler = (err, res) => {
			if (err || !res || !res.ok || !res.body) {
				return reject(err || res || {})
			}
			resolve(res.body)
		}
		req.end(handler)
	}

	return new Promise(executor)
}

export const remove = (productId) => {

	const endpoint = confy.get('API.sociomantic.product')
	const url = `${endpoint}/${productId}`

	const req = request
		.delete(url)
		.send()
		.withCredentials()

	const executor = (resolve, reject) => {
		const handler = (err, res) => {
			if (err || !res || !res.ok) {
				return reject(err || res || {})
			}
			resolve()
		}
		req.end(handler)
	}

	return new Promise(executor)
}

export const trackClick = (productId, price) => {
	const endpoint = confy.get('API.sociomantic.productClick')
	const url = endpoint.replace(':product_id', productId)
	let data = {}
	if (price) data = { price }

	const req = request
		.post(url)
		.send(data)

	const executor = (resolve, reject) => {
		const handler = (err, res) => {
			if (err || !res || !res.ok) {
				return reject(err || res || {})
			}
			resolve()
		}
		req.end(handler)
	}

	return new Promise(executor)
}
