import RawBtn from './rawBtn.js'


/* eslint-disable max-len */
const minusSvg = (fill = '#C3C3C3') => (
	<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
		<g fill="none" fillRule="evenodd">
			<rect width="32" height="32" fill="#FFF" rx="4"/>
			<path fill={fill} fillRule="nonzero" d="M28 0H4C1.8 0 0 1.8 0 4v24c0 2.2 1.8 4 4 4h24c2.2 0 4-1.8 4-4V4c0-2.2-1.8-4-4-4zM6 14h20v4H6v-4z"/>
		</g>
	</svg>
)
const plusSvg = (fill = '#c3c3c3') => (
	<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
		<g fill="none" fillRule="evenodd">
			<rect width="32" height="32" fill="#FFF" rx="4"/>
			<path fill={fill} fillRule="nonzero" d="M28 0H4C1.8 0 0 1.8 0 4v24c0 2.2 1.8 4 4 4h24c2.2 0 4-1.8 4-4V4c0-2.2-1.8-4-4-4zm-2 18h-8v8h-4v-8H6v-4h8V6h4v8h8v4z"/>
		</g>
	</svg>
)
/* eslint-enable max-len */

const activeFill = '#ff6d00'
const inactiveFill = '#c3c3c3'
const svgByType = {
	dec: minusSvg,
	inc: plusSvg,
}
export default ({ classNames, type, isActive, ...props }) => (
	<RawBtn classNames={classNames} {...props}>
		{svgByType[type](isActive ? activeFill : inactiveFill)}
	</RawBtn>
)
