import { flow, isEmpty } from 'lodash'

import {
	byCurrentOfferId,
} from '@store/state/appState/selectors'
import {
	enabledAccommodationFareIdSelector,
	flightFaresFromOfferSelector,
} from '@store/state/domainData/selectors'


const notEmpty = (obj) => (!isEmpty(obj))

export const hasAccommodationSelector = flow(
	byCurrentOfferId(enabledAccommodationFareIdSelector),
	Boolean
)

export const hasTicketsSelector = flow(
	byCurrentOfferId(flightFaresFromOfferSelector),
	notEmpty
)

