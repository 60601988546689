import $ from './styles/index.js'


const InfoMsg = (props) => {
	const { title, content, footer, t1139 } = props

	const containerClass = t1139 ? $.t1139Container : $.container
	const titleClass = t1139 ? $.t1139Title : $.title

	return (
		<div className={containerClass}>
			<div className={titleClass}>{title}</div>
			{content ? <div className={$.content}>{content}</div> : null}
			<div className={$.footer}>{footer}</div>
		</div>
	)
}

export default InfoMsg
