import l from '@libs/lang'

import { TicketsItem, HotelsItem, CartItem } from './tabItems'

import styles from '../styles/menu.js'


const Menu = (props) => {
	const {
		ticketsCount,
		isLoading,
		isTickets,
		hotelsCount,
		isHotels,
		ticketsPrice,
		hotelsPrice,
		isCheckout,
		shouldRenderHotelTab,
		onTicketsClick,
		onHotelsClick,
		onCartClick,
		onNewSearchClick,
		onContactsClick,
		onAboutClick,
	} = props

	const txtNewSearch = l('Новый поиск')
	const txtContacts = l('Контактная информация')
	const txtAbout = l('О компании')

	const propsTicketsItem = {
		count: ticketsCount,
		isLoading,
		isActive: isTickets,
		onClick: onTicketsClick,
	}

	const propsHotelsItem = {
		count: hotelsCount,
		isLoading,
		isActive: isHotels,
		onClick: onHotelsClick,
	}

	const propsCartItem = {
		ticketsPrice,
		hotelsPrice,
		isActive: isCheckout,
		onClick: onCartClick,
	}

	const shouldRenderTabs = isTickets || isHotels
	const txtVip = l('VIP залы')

	return (
		<div className={styles.wrapper}>
			<ul className={styles.list}>
				<li className={styles.item} onClick={onNewSearchClick}>{txtNewSearch}</li>
			</ul>
			<div className={styles.divider}/>
			{shouldRenderTabs && (
				<ul className={styles.list}>
					<TicketsItem {...propsTicketsItem}/>
					{shouldRenderHotelTab && (
						<HotelsItem {...propsHotelsItem}/>
					)}
					<CartItem {...propsCartItem}/>
				</ul>
			)}
			<div className={styles.divider}/>
			<ul className={styles.list}>
				<li className={styles.item} onClick={onContactsClick}>{txtContacts}</li>
				<li className={styles.item} onClick={onAboutClick}>{txtAbout}</li>
				<li className={styles.item}>
					<a className={styles.menuItem} href="https://lounge.clickavia.ru/vip/">
						{txtVip}
					</a>
				</li>
			</ul>
		</div>
	)
}

export default Menu
