import request from 'superagent'
import confy from '@utils/confy'
import { pickBy, identity } from 'lodash'


export default function ({ eventType, eventId, value, payload, checkUniqness }) {
	const url = confy.get('API.cl.events')

	const data = {
		'event_type': eventType,
		'event_id': eventId,
		'value': value,
		'payload': payload,
		'check_uniqness': checkUniqness,
	}

	const req = request
		.post(url)
		.set('Content-Type', 'application/json')
		.send(pickBy(data, identity))
		.withCredentials()

	const executor = (resolve, reject) => {
		const handler = (err, res) => {
			if (err || !res || !res.ok || !res.body) {
				return reject(err || res || {})
			}
			resolve(res.body)
		}
		req.end(handler)
	}

	return {
		promise: new Promise(executor),
		cancelFn: () => req.abort(),
	}
}
