import PropTypes from 'prop-types'
import cn from 'classnames'
import { get } from 'lodash'

import { FloatingLabel as FloatingLabelInput } from 'core-components/Input'
import Autocomplete from 'core-components/Autocomplete'

import styles from './styles/locationInput.js'


const stringifyLocation = ({ title, kind, id, locales }) => `${get(locales, 'ru', title)}:${kind}:${id}`
const parseLocation = (location = '') => {
	const [ title = '' ] = location.split(':')
	return title
}

class LocationInput extends React.Component {
	_getSuggestionItemMapper = (hovered, onMouseEnter, onItemClick, dataAttr) => (item, i) => {
		const { title, kind, id, parent, locales } = item
		const itemClass = cn({
			[styles.item]: true,
			[styles.itemHovered]: hovered === i,
		})
		const propsItem = {
			key: `${title}:${kind}:${id}`,
			[dataAttr]: JSON.stringify(i),
			className: itemClass,
			onMouseEnter,
			onClick: () => onItemClick(i),
		}
		return (
			<div {...propsItem}>
				<b>{get(locales, 'ru', title)}</b>
				{parent && <span>{parent}</span>}
			</div>
		)
	}

	_changeHandler = ({ target }) => {
		const { setLocation } = this.props
		setLocation(target.value)
	}

	_selectHandler = (location) => {
		const { setLocation } = this.props
		setLocation(stringifyLocation(location))
	}

	_blurHandler = (e) => {
		const { location, suggestions, onBlur } = this.props
		const stringifiedSuggestion = suggestions.length > 0 && stringifyLocation(suggestions[0])
		if (suggestions.length === 1 && stringifiedSuggestion !== location) {
			this._selectHandler(suggestions[0])
		}
		onBlur(e)
	}

	render () {
		const {
			placeholder,
			location,
			isFocused,
			isValid,
			suggestions,
			style,
			onFocus,
		} = this.props
		const propsAutocomplete = {
			inputComponent: FloatingLabelInput,
			value: parseLocation(location),
			placeholder,
			placeholderColor: isValid ? '#878787' : '#d50000',
			isFocused,
			isValid,
			style,
			classNames: styles,
			suggestions,
			getSuggestionItemMapper: this._getSuggestionItemMapper,
			isShownOnFocused: true,
			isHiddenOnBlur: true,
			onChange: this._changeHandler,
			onSelect: this._selectHandler,
			onFocus,
			onBlur: this._blurHandler,
		}
		return (<Autocomplete {...propsAutocomplete}/>)
	}
}

LocationInput.propTypes = {
	placeholder: PropTypes.string.isRequired,
	location: PropTypes.string.isRequired,
	isFocused: PropTypes.bool.isRequired,
	isValid: PropTypes.bool.isRequired,
	suggestions: PropTypes.array.isRequired,
	setLocation: PropTypes.func.isRequired,
	style: PropTypes.object,
	onFocus: PropTypes.func.isRequired,
	onBlur: PropTypes.func.isRequired,
}

export default LocationInput
