/* SHOULD BE REFACTORED */
const TABS = {
	TICKETS: 'tickets',
	HOTELS: 'hotels',
	CART: 'cart',
}

export const TABS_IDX = {
	[TABS.TICKETS]: 0,
	[TABS.HOTELS]: 1,
	[TABS.CART]: 2,
}

export const DIALOGS = {
	TICKET_ADDED: 'DIALOG_TICKET_ADDED',
	EMPTY_CART: 'DIALOG_EMPTY_CART',
	CART_WITHOUT_TICKET: 'DIALOG_CART_WITHOUT_TICKET',
	HOTEL_ADDED: 'DIALOG_HOTEL_ADDED',
	TOUR_COMPLETE: 'DIALOG_TOUR_COMPLETE',
	SEARCH_EXPIRED: 'DIALOG_SEARCH_EXPIRED',
	THAI_CHARTER_WARN: 'DIALOG_THAI_CHARTER_WARN',
	INDIAN_VISA_WARN: 'DIALOG_INDIAN_VISA_WARN',
	UAE_WARN: 'DIALOG_UAE_WARN',
}

export default TABS
