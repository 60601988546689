import { find } from 'lodash'
import { createSelector } from 'reselect'
import { getOrders } from './core.js'


export const orderFromOfferIdResult = (orders, offerId) => (
	find(orders, ({ offer }) => (offer === offerId)) || null
)

const orderFromOfferIdSelector = createSelector(
	[ getOrders, (_, __, offerId) => offerId ],
	orderFromOfferIdResult
)

export default orderFromOfferIdSelector
