import {
	SET_CURRENT_QUERY,
	SET_CURRENT_OFFER_ID,
	ADD_LOADING_EVENT,
	REMOVE_LOADING_EVENT,
	ADD_QR_LOADING_EVENT,
	REMOVE_QR_LOADING_EVENT,
	REMOVE_ALL_LOADING_EVENTS,
	GO_TO_INVALID_FORM_FIELD,
	SET_SHOPFRONT_DIALOG,
	ACCEPT_QUIZ_ANSWER,
	INIT_QUIZES,
	ACCEPT_QUIZ_ON_SUCCESS_ANSWER,
	INIT_SUCCESS_QUIZES,
	SET_PREVIOUS_PRICE,
	SET_CHANNEL,
	SET_IS_CC_FORM_MANUAL,
	SEARCH_TRANSFERS,
	SELECT_TRANSFER,
	SET_IS_SEARCHBAR_HIGHLIGHTED,
	SET_PAYMENT_METHOD,
} from '@store/state/types'


export const setCCFormMode = (isManualMode) => ({
	type: SET_IS_CC_FORM_MANUAL,
	payload: isManualMode,
})

export const setPaymentMethod = (paymentMethod) => ({
	type: SET_PAYMENT_METHOD,
	payload: paymentMethod,
})

export const setIsSearchbarHighlighted = (isSearchbarHighlighted) => ({
	type: SET_IS_SEARCHBAR_HIGHLIGHTED,
	payload: isSearchbarHighlighted,
})

export const setCurrentSearchQuery = (query) => ({
	type: SET_CURRENT_QUERY,
	payload: query,
})

export const setCurrentOfferId = (offerId) => ({
	type: SET_CURRENT_OFFER_ID,
	payload: offerId,
})

export const addLoadingEvent = (event) => ({
	type: ADD_LOADING_EVENT,
	payload: event,
})

export const addQrLoadingEvent = (event) => ({
	type: ADD_QR_LOADING_EVENT,
	payload: event,
})

export const removeLoadingEvent = (event) => ({
	type: REMOVE_LOADING_EVENT,
	payload: event,
})

export const removeQrLoadingEvent = (event) => ({
	type: REMOVE_QR_LOADING_EVENT,
	payload: event,
})
export const removeAllLoadingEvents = (event) => ({
	type: REMOVE_ALL_LOADING_EVENTS,
	payload: event,
})

export const goToInvalidFormField = (fieldPath) => ({
	type: GO_TO_INVALID_FORM_FIELD,
	payload: fieldPath,
})

export const setShopfrontDialog = (dialogName) => ({
	type: SET_SHOPFRONT_DIALOG,
	payload: dialogName,
})

export const acceptQuizAnswer = (quiz, answer) => ({
	type: ACCEPT_QUIZ_ANSWER,
	payload: {
		quiz,
		answer,
	},
})

export const initQuizes = (quizes) => ({
	type: INIT_QUIZES,
	payload: quizes,
})

export const acceptQuizOnSuccessAnswer = (quiz, answer, meta) => ({
	type: ACCEPT_QUIZ_ON_SUCCESS_ANSWER,
	payload: {
		quiz,
		answer,
		meta,
	},
})

export const initSuccessQuizes = (quiz, meta) => ({
	type: INIT_SUCCESS_QUIZES,
	payload: { quiz, meta },
})

export const setPreviousPrice = (price) => ({
	type: SET_PREVIOUS_PRICE,
	payload: price,
})

export const setChannel = (channel) => ({
	type: SET_CHANNEL,
	payload: channel,
})

// TODO: fake transfer, should be removed after test
export const searchTransfers = (payload) => ({
	type: SEARCH_TRANSFERS,
	payload,
})
export const selectTransfer = (transfer, meta) => ({
	type: SELECT_TRANSFER,
	payload: transfer,
	meta,
})
//^^^^^^^^^^^^
