import { makePropGetter as makeAppStatePropGetter, createPropGetterMaker } from './common'


const getStatusesState = makeAppStatePropGetter('requestStatuses')
const makePropGetter = createPropGetterMaker(getStatusesState)

export const sendFeedbackStatusSelector = makePropGetter('sendFeedbackStatus')
export const accountDataStatusSelector = makePropGetter('accountDataStatus')
export const loginStatusSelector = makePropGetter('loginStatus')
export const recoverPasswordStatusSelector = makePropGetter('recoverPasswordStatus')
export const resetPasswordStatusSelector = makePropGetter('resetPasswordStatus')
export const offerOpStatusSelector = makePropGetter('offerOpStatus')
export const offerReconfigureStatusSelector = (state, serviceType) => {
	const reconfigureStatuses = makePropGetter('offerReconfigureStatuses')(state)
	return reconfigureStatuses[serviceType]
}
export const changeConsistStatusSelector = makePropGetter('changeConsistStatus')
export const redirectToCabinetStatusSelector = makePropGetter('redirectToCabinetStatus')
export const bookStatusSelector = makePropGetter('bookStatus')

