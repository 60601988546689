import { flow } from 'lodash'
import { delay, select, call, put } from 'redux-saga/effects'
import accountDataFetcher from '@libs/account-data-fetcher'
import { REDIRECT_TO_CABINET_STATUS } from '@libs/foma/types'
import { toExternalCabinet } from '@utils/router'
import { setRedirectToCabinetStatus, setUserData } from '@store/state/appState/actions'
import { checkBookSelector } from '@store/state/selectors'
import { byCurrentOfferId } from '@store/state/appState/selectors'
import { orderFromOfferIdSelector, ticketsTypeFromOfferSelector } from '@store/state/domainData/selectors'
import { customerSelectors } from '@store/state/uiState/forms/selectors'
import { CREDIT_CARD_FORM } from '@store/state/uiState/forms/types'
import { clearForm } from '@store/state/uiState/forms/actions'


export const MAX_ATTEMPTS = 3
export const DELAY_TIME_MS = 10000

export function* attemptAuth (email, token) {
	let attempts = 0
	let userData = null
	while (attempts < MAX_ATTEMPTS && !userData) {
		attempts++
		try {
			userData = yield call(accountDataFetcher.authorizeByToken, email, token)
		}
		catch (e) {}
		if (!userData && attempts < MAX_ATTEMPTS) yield delay(DELAY_TIME_MS)
	}
	return userData
}

export const isCharterSelector = flow(
	byCurrentOfferId(ticketsTypeFromOfferSelector),
	(type) => (type === 'charter')
)
export const enhancedOrderFromOfferIdSelector = byCurrentOfferId(orderFromOfferIdSelector)

export const FAKE_PROGRESS_BAR_ANIMATION_TIME_MS = 500

export function* redirectToAccountOrderWorker () {
	const email = yield select(customerSelectors.emailSelector)
	const order = yield select(enhancedOrderFromOfferIdSelector)
	const isCharter = yield select(isCharterSelector)
	const userData = yield call(attemptAuth, email, order.id)
	if (userData) {
		yield put(setRedirectToCabinetStatus(REDIRECT_TO_CABINET_STATUS.SUCCESS))
		// wait progress bar animation
		yield delay(FAKE_PROGRESS_BAR_ANIMATION_TIME_MS)
		yield put(setUserData(userData))
		window.location = toExternalCabinet(order['support_id'], { showcharter: isCharter })
	}
	else {
		yield put(setRedirectToCabinetStatus(REDIRECT_TO_CABINET_STATUS.FAILED))
	}
}

export default function* checkBookingStatus ({ payload: bookOpStatus }) {
	const checkBook = yield select(checkBookSelector)
	if (!checkBook.isSuccess) return

	// clear credit card form
	yield put(clearForm(CREDIT_CARD_FORM.NAME))
	// scroll to top
	yield call([ window, scrollTo ], 0, 0)
	// redirect to account order
	// yield call(redirectToAccountOrderWorker)
}

