import { createSelector } from 'reselect'
import { OPERATION_STATUSES, ORDER_STATUSES, PAYMENT_STATUSES } from '@libs/foma/types'
import { orderFromOfferIdSelector } from '@store/state/domainData/selectors'
import {
	byCurrentOfferId,
	bookStatusSelector,
} from '@store/state/appState/selectors'


export const BOOK_FAILED_STATUSES = [
	OPERATION_STATUSES.INVALID,
	OPERATION_STATUSES.ERROR,
	OPERATION_STATUSES.PAYMENT_FAILED,
	OPERATION_STATUSES.INVALID_CARD_DATA,
	OPERATION_STATUSES.INSUFFICIENT_FUNDS,
	OPERATION_STATUSES.PAYMENT_3DS_FAILED,
	OPERATION_STATUSES.PAYMENT_REJECTED,
	OPERATION_STATUSES.VALIDATION_ERROR,
	OPERATION_STATUSES.PAYMENT_ERROR,
	OPERATION_STATUSES.SBP_ERROR,
	OPERATION_STATUSES.SBP_TIMELIMIT,
	OPERATION_STATUSES.TINKOFF_ERROR,
	OPERATION_STATUSES.TINKOFF_TIMELIMIT,
]

const BOOK_SUCCESS_STATUSES = {
	OPERATION: [
		OPERATION_STATUSES.BOOKED,
		OPERATION_STATUSES.BOOKING,
	],
	ORDER: [
		ORDER_STATUSES.BOOKED,
		ORDER_STATUSES.BOOKING,
	],
	PAYMENT: [
		PAYMENT_STATUSES.BLOCKED,
		PAYMENT_STATUSES.CHARGED,
		PAYMENT_STATUSES.CHARGING,
	],
}

const BOOK_CRITICAL_ERROR_STATUSES = {
	OPERATION: [
		OPERATION_STATUSES.UNAVAIL,
	],
	ORDER: [
		ORDER_STATUSES.UNAVAIL,
		ORDER_STATUSES.UNROLLING,
		ORDER_STATUSES.UNROLL_ERROR,
		ORDER_STATUSES.BOOKING,
	],
	PAYMENT: [
		PAYMENT_STATUSES.BLOCKED,
	],
}


const isSuccess = ({ bookingStatus, orderStatus }) => (
	BOOK_SUCCESS_STATUSES.OPERATION.some((status) => (status === bookingStatus))
	|| BOOK_SUCCESS_STATUSES.ORDER.some((status) => (status === orderStatus))
)

const isFailed = ({ bookingStatus, orderStatus }) => (
	BOOK_FAILED_STATUSES.some((status) => (status === bookingStatus))
	&& !BOOK_SUCCESS_STATUSES.ORDER.some((status) => (status === orderStatus))
)

const isCriticalError = ({ bookingStatus, orderStatus, paymentStatus }) => (
	BOOK_CRITICAL_ERROR_STATUSES.OPERATION.some((status) => (status === bookingStatus))
	&& BOOK_CRITICAL_ERROR_STATUSES.ORDER.some((status) => (status === orderStatus))
	&& BOOK_CRITICAL_ERROR_STATUSES.PAYMENT.some((status) => (status === paymentStatus))
)

export const checkBookSelector = createSelector(
	bookStatusSelector,
	byCurrentOfferId(orderFromOfferIdSelector),
	(bookingStatus, order) => {
		const orderStatus = order && order.status
		const paymentStatus = order && order['payment_status']
		return {
			isSuccess: isSuccess({ bookingStatus, orderStatus, paymentStatus }),
			isFailed: isFailed({ bookingStatus, orderStatus }),
			isCriticalError: isCriticalError({ bookingStatus, orderStatus, paymentStatus }),
		}
	}
)
